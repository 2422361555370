import React from 'react';
import Cropper from 'react-cropper';
import { useRef } from 'react';
import Modal from '../Modal';
import 'cropperjs/dist/cropper.css';

const ImageCropModal = ({ image, setImage, upload, setImageUpload }) => {
  const cropperRef = useRef(null);
  const getCropData = () => {
    if (typeof cropperRef.current?.cropper !== 'undefined') {
      upload(cropperRef.current?.cropper.getCroppedCanvas().toDataURL());
    }
  };

  const handleCloseModal = () => {
    setImageUpload(false);
    setImage(null);
  };

  return (
    <>
      {image ? (
        <Modal
          handleClose={() => handleCloseModal()}
          headerComponent={null}
          footerComponent={
            <>
              <button className="btn btn--secondary btn--md" onClick={() => handleCloseModal()}>
                Close
              </button>
              <button className="btn btn--primary btn-md" onClick={() => getCropData()}>
                Upload
              </button>
            </>
          }
          keyboard={false}
        >
          <Cropper ref={cropperRef} zoomTo={0.5} style={{ height: '100%', width: '100%' }} initialAspectRatio={1} preview=".img-preview" src={image} viewMode={1} minCropBoxHeight={10} minCropBoxWidth={10} background={false} responsive={true} autoCropArea={1} checkOrientation={false} guides={true} />
        </Modal>
      ) : null}
    </>
  );
};

export default ImageCropModal;
