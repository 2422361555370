import React from "react";
import FormInput from "../../../../../components/FormInput";
import Button from "../../../../../components/Button";
import { useNavigate } from "react-router-dom";

const NationalIdentify = ({ setUploaded }) => {
  const navigate = useNavigate();
  return (
    <>
      <div className="d--flex gap--md flex--column m-b--md">
        <FormInput
          label=" NiN"
          // placeholder="Enter first name"
          height="40"
        />
        <FormInput
          label=" First name"
          // placeholder="Enter first name"
          height="40"
        />
        <FormInput
          label=" Middle name"
          // placeholder="Enter first name"
          height="40"
        />
        <FormInput
          label=" Surname"
          // placeholder="Enter first name"
          height="40"
        />
        <FormInput
          type="date"
          label=" Date of Birth"
          // placeholder="Enter first name"
          height="40"
        />
      </div>
      <Button
        btnClasses="btn"
        variant="primary"
        disabled={false}
        onClick={() => setUploaded(true)}
      >
        {false ? "Please wait..." : "Next"}
      </Button>
    </>
  );
};

export default NationalIdentify;
