import { CARRIER, MEMBER } from '../../helpers/constants';
import { useAuth } from '../../hooks/useAuth';
import AllDashboard from './View/AllDashBoard';
import CarrierDashboard from './View/CarrierDashBoard';
import MemberDashBoard from './View/MemberDashBoard';

export default function Dashboard() {
  const { user } = useAuth();
  const userRole = user?.role || localStorage.getItem('nph-agency-user-role');

  if (userRole === MEMBER) {
    return <MemberDashBoard />;
  } else if (userRole === CARRIER) {
    return <CarrierDashboard />;
  } else {
    return <AllDashboard />;
  }
}
