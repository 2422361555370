import React, { useEffect, useState } from 'react';
import { useAuth } from '../../../../hooks/useAuth';
import { Controller, useForm } from 'react-hook-form';
import { useAlert } from '../../../../hooks/useAlert';
import { useMutation } from '@tanstack/react-query';
import OtherAgencyInfo from '../OtherAgencyInfo';
import ImageCropModal from '../../../../components/ImageCropModal';
import FormInput from '../../../../components/FormInput';
import { companyProfileUpdate } from '../../../../api';
import { getObjectLength } from '../../../../helpers/functions';
import FormSelect from '../../../../components/FormSelect';
import { City, State } from 'country-state-city';
import Button from '../../../../components/Button';
import Agencies from '../EditInfo/View/Agencies';

const initialValues = {
  agencyName: '',
  agencyEmail: '',
  agencyPhone: '',
  agencyNPN: '',
  agencyEIN: '',
  address_one: '',
  address_two: '',
  state: '',
  city: '',
  zipCode: '',
};
const statesList = State.getStatesOfCountry('US')?.map((state) => ({
  value: state.isoCode,
  label: state.name,
}));
const AgencyInfo = () => {
  const { user } = useAuth();
  const {
    isOwnAgency,
    isAssociatedAgency,
    ownAgencyDetail = {},
    associatedAgencyDetail = {},
  } = user || {};

  const [filename, setFilename] = useState('');

  const [companyImage, setCompanyImage] = useState(null);
  const [cityList, setCityList] = useState([]);
  const {
    control,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: initialValues,
    mode: 'onSubmit',
  });
  let [watchState] = watch(['state']);

  useEffect(() => {
    if (watchState) {
      const cities = City.getCitiesOfState('US', watchState).map((city) => ({
        value: city.name,
        label: city.name,
      }));
      setCityList(cities);
    }
  }, [watchState]);

  const { showAlert } = useAlert();

  const { mutate, isLoading } = useMutation({
    mutationFn: companyProfileUpdate,
    mutationKey: 'companyProfileUpdate',
    onSuccess: ({ data }) => {
      showAlert({ type: 'success', message: data?.data?.message });
    },
    onError: (error) => {
      const errMsg = error?.message ?? 'unexpected error';
      showAlert({ type: 'danger', message: errMsg });
    },
  });

  const onSubmit = (data) => {
    let formData = new FormData();
    formData.append('companyName', data?.companyName);
    if (filename) {
      formData.append('filename', filename);
    }
    mutate(formData);
  };

  const handleFileUpload = async (data) => {
    fetch(data)
      .then((res) => res.blob())
      .then(async (blob) => {
        const newfile = new File(
          [blob],
          `company_image.${blob?.type?.split('/')?.[1]}`,
          {
            type: blob.type,
            lastModified: Date.now(),
            size: blob.size,
          },
        );
        setCompanyImage(null);

        setFilename(newfile);
      })
      .catch((err) => {
        console.log({ err });
      });
  };

  // const handleFileSelect = async (e) => {
  //   let file = e.target.files[0];
  //   setCompanyImage(URL.createObjectURL(file));
  //   e.target.value = '';
  // };

  useEffect(() => {
    if (ownAgencyDetail && getObjectLength(ownAgencyDetail)) {
      let {
        agencyName,
        agencyEmail,
        agencyPhone,
        agencyNPN,
        agencyEIN,
        address_one,
        address_two,
        state,
        city,
        zipCode,
      } = ownAgencyDetail || {};
      reset({
        agencyName,
        agencyEmail,
        agencyPhone,
        agencyNPN,
        agencyEIN,
        address_one,
        address_two,
        state,
        city,
        zipCode,
      });
    }
  }, [user]);
  return (
    // <div className="w--full d--flex gap--xl">
    //   {!isOwnAgency && !isAssociatedAgency && 'There is no Associated Agency '}
    //   {isOwnAgency ? (
    //     <>
    //       <div className="w--full d--flex gap--md flex--column">
    //         <div className="label--control font--sm font--500 m-b--xs text--secondary bg--secondary-100 border-full--secondary-100 p-t--sm p-b--sm p-r--md p-l--md radius--sm ">
    //           Agency Info
    //         </div>
    //         <div className="d--flex gap--xl ">
    //           <form
    //             className="d--flex gap--xl w--full"
    //             onSubmit={handleSubmit(onSubmit)}
    //           >
    //             {/* <div className="box-shadow w--full w-max--150 h-max--100 h-min--100 d--flex align-items--center justify-content--center bg--secondary-100 radius--sm profileImg c--pointer position--relative">
    //               {Boolean(filename) ? (
    //                 <img
    //                   src={URL.createObjectURL(filename)}
    //                   className=" radius--sm w--full h--full"
    //                   alt="profile"
    //                 />
    //               ) : (
    //                 <img
    //                   src={
    //                     `${process.env.REACT_APP_BASE_API_URL}uploads/${agencyData?.agencyCompanyLogo}` ||
    //                     profileImg
    //                   }
    //                   className="radius--sm w--full h--full"
    //                   alt="profile"
    //                 />
    //               )}
    //               <label
    //                 className="text--secondary c--pointer position--absolute right--5 bottom--5"
    //                 htmlFor="upload"
    //               >
    //                 <UploadImg />
    //                 <input
    //                   {...register('companyLogo')}
    //                   name="companyLogo"
    //                   type="file"
    //                   id="upload"
    //                   onChange={handleFileSelect}
    //                   accept="image/*"
    //                   hidden
    //                 />
    //               </label>
    //             </div> */}

    //             <div className="w--full d--flex">
    //               <div className="d--flex flex--column gap--md w--full">
    //                 <div className="d--flex  gap--sm">
    //                   <div className="w--full">
    //                     <Controller
    //                       name="agencyName"
    //                       control={control}
    //                       render={({ field }) => (
    //                         <FormInput
    //                           {...field}
    //                           type="text"
    //                           label="Agency Name"
    //                           placeholder="Enter Company Name"
    //                           maxLength={30}
    //                           error={errors?.agencyName?.message}
    //                         />
    //                       )}
    //                     />
    //                   </div>
    //                   <div className="w--full">
    //                     <Controller
    //                       name="agencyEmail"
    //                       control={control}
    //                       render={({ field }) => (
    //                         <FormInput
    //                           {...field}
    //                           type="text"
    //                           label=" Email"
    //                           placeholder="Enter email"
    //                           maxLength={30}
    //                           error={errors?.agencyEmail?.message}
    //                         />
    //                       )}
    //                     />
    //                   </div>
    //                 </div>
    //                 <div className="d--flex  gap--sm">
    //                   <div className="w--full">
    //                     <Controller
    //                       name="agencyPhone"
    //                       control={control}
    //                       render={({ field }) => (
    //                         <FormInput
    //                           {...field}
    //                           type="text"
    //                           label="Phone"
    //                           placeholder="Enter phone"
    //                           maxLength={30}
    //                           error={errors?.agencyPhone?.message}
    //                         />
    //                       )}
    //                     />
    //                   </div>
    //                   <div className="w--full">
    //                     <Controller
    //                       name="agencyNPN"
    //                       control={control}
    //                       render={({ field }) => (
    //                         <FormInput
    //                           {...field}
    //                           type="text"
    //                           label="NPN"
    //                           placeholder="Enter NPN"
    //                           maxLength={30}
    //                           error={errors?.agencyNPN?.message}
    //                         />
    //                       )}
    //                     />
    //                   </div>
    //                 </div>
    //                 <div className="w--full">
    //                   <Controller
    //                     name="agencyEIN"
    //                     control={control}
    //                     render={({ field }) => (
    //                       <FormInput
    //                         {...field}
    //                         type="text"
    //                         label="EIN"
    //                         placeholder="Enter EIN "
    //                         maxLength={30}
    //                         error={errors?.agencyEIN?.message}
    //                       />
    //                     )}
    //                   />
    //                 </div>
    //                 <div className="d--flex  gap--sm">
    //                   <Controller
    //                     name="address_one"
    //                     control={control}
    //                     render={({ field }) => (
    //                       <FormInput
    //                         {...field}
    //                         label="Address 1"
    //                         error={errors?.address_one?.message}
    //                         height="40"
    //                       />
    //                     )}
    //                   />
    //                   <Controller
    //                     name="address_two"
    //                     control={control}
    //                     render={({ field }) => (
    //                       <FormInput
    //                         {...field}
    //                         label="Address 2"
    //                         error={errors?.address_two?.message}
    //                         height="40"
    //                         required={false}
    //                       />
    //                     )}
    //                   />
    //                 </div>
    //                 <div className="d--flex  gap--sm">
    //                   <Controller
    //                     name="state"
    //                     control={control}
    //                     rules={{
    //                       onChange() {
    //                         setValue('city', '');
    //                       },
    //                     }}
    //                     render={({ field }) => (
    //                       <FormSelect
    //                         {...field}
    //                         label="State "
    //                         error={errors?.state?.message}
    //                         height="40"
    //                         options={statesList}
    //                       />
    //                     )}
    //                   />
    //                   <Controller
    //                     name="city"
    //                     control={control}
    //                     render={({ field }) => (
    //                       <FormSelect
    //                         {...field}
    //                         label="City "
    //                         error={errors?.city?.message}
    //                         height="40"
    //                         options={cityList}
    //                       />
    //                     )}
    //                   />
    //                 </div>
    //                 <div className="d--flex  gap--sm">
    //                   <Controller
    //                     name="zipCode"
    //                     control={control}
    //                     render={({ field }) => (
    //                       <FormInput
    //                         {...field}
    //                         label="Zip Code"
    //                         error={errors?.zipCode?.message}
    //                         height="40"
    //                       />
    //                     )}
    //                   />
    //                 </div>

    //                 <div className="w--full d--flex    align-items--center justify-content--center m-t--md">
    //                   <label className="f m-b--sm h-min--16"></label>

    //                   <Button
    //                     btnClasses="btn  w-max--140 text--black"
    //                     variant="primary"
    //                     color="black"
    //                     disabled={isLoading}
    //                     type="submit"
    //                   >
    //                     {isLoading ? 'Please wait...' : 'Update'}
    //                   </Button>
    //                   {/* <button
    //                     type="submit"
    //                     className="btn font--sm  d--flex align-items--center justify-content--center bg--primary text--white radius--sm btn w--full border-full--primary c--pointer h-max--40"
    //                     disabled={isLoading}
    //                   >
    //                     {isLoading ? 'Please wait...' : 'Update'}
    //                   </button> */}
    //                 </div>
    //               </div>
    //             </div>
    //           </form>
    //         </div>
    //       </div>
    //       <ImageCropModal
    //         {...{
    //           image: companyImage,
    //           setImage: setCompanyImage,
    //           setImageUpload: () => null,
    //           upload: (data) => handleFileUpload(data),
    //         }}
    //       />
    //     </>
    //   ) : null}

    //   {isAssociatedAgency ? (
    //     <div className="w--full d--flex gap--xl">
    //       <OtherAgencyInfo agencyData={associatedAgencyDetail} />
    //     </div>
    //   ) : null}
    // </div>

    <Agencies />
  );
};

export default AgencyInfo;
