import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { UploadCloud } from '../../../assets/icons/useIcons';
import { uploadClaim } from '../../../api';
import { useMutation } from '@tanstack/react-query';
import { useAlert } from '../../../hooks/useAlert';
import CancelButton from '../../../components/Button/CancelButton';
import SubmitButton from '../../../components/Button/SubmitButton';
import { yupResolver } from '@hookform/resolvers/yup';
import { mixed, object } from 'yup';
import {
  requiredFieldFunction,
  requiredFileFunction,
} from '../../../helpers/yupValidations';
import { useAuth } from '../../../hooks/useAuth';
import { CARRIER } from '../../../helpers/constants';

const UploadClaim = ({ handleClose, rowData = {} }) => {
  const { user } = useAuth();
  const { showAlert } = useAlert();

  const schema = object({
    description: requiredFieldFunction('Description'),
    filename: user?.role === CARRIER ? mixed() : requiredFileFunction('File'),
  });

  const {
    handleSubmit,
    register,
    watch,
    setValue,
    reset,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: {
      description: '',
      filename: null,
    },
    resolver: yupResolver(schema),
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const { mutate, isLoading } = useMutation({
    mutationFn: uploadClaim,
    mutationKey: 'uploadClaim',
    onSuccess: ({ data }) => {
      showAlert({ type: 'success', message: data?.data?.message });
      handleClose();
    },
    onError: (error) => {
      const errMsg = error?.message ?? 'unexpected error';
      showAlert({ type: 'danger', message: errMsg });
    },
  });

  const onSubmit = (data) => {
    const { filename, description } = data || {};
    let formData = new FormData();

    formData?.append('files', filename || []);
    formData?.append('description', description);
    formData?.append('claimUuId', rowData?._id);
    mutate(formData);
  };

  useEffect(() => {
    reset();
    return () => {
      reset();
    };
  }, []);

  return (
    <form
      className="w--full h--full "
      onSubmit={handleSubmit(onSubmit)}
      onClick={(e) => e.stopPropagation()}
    >
      {errors?.filename?.message && (
        <div
          className={`label--control font--sm font--500 m-t--md  text--danger`}
        >
          {errors?.filename?.message}
        </div>
      )}
      <label
        className="d--flex m-b--md m-t--md border-full--primary border--dashed p--md radius--sm flex--column align-items--center justify-content--center c--pointer bg--white"
        htmlFor="file"
      >
        <input
          {...register('filename')}
          type="file"
          accept=".pdf"
          id="file"
          onChange={(e) => {
            const file = e?.target.files?.[0];
            if (file?.type.includes('pdf')) {
              if (file.size <= 1048576) {
                // 1 MB in bytes
                setValue('filename', file, {
                  shouldValidate: true,
                });
                e.target.value = null;
              } else {
                setError('filename', {
                  type: 'manual',
                  message: 'File size should be less than or equal to 1 MB',
                });
                setValue('filename', null);
              }
            } else {
              setError('filename', {
                type: 'manual',
                message: 'Please upload a PDF file',
              });
              setValue('filename', null);
            }
          }}
          hidden
        />
        <span className="text--primary">
          <UploadCloud width={30} height={30} />
        </span>
        <h3 className="text--grey">Upload New Document</h3>
        <p className="m-t--sm font--sm text--grey-600">Click to upload </p>
        <p className="m-t--sm text--danger">{watch('filename')?.name ?? ''}</p>
      </label>

      <div className="d--flex gap--md">
        <div className="w--full  d--flex flex--column">
          <label
            className={`label--control font--sm font--500 m-b--sm   ${
              errors?.description?.message ? 'text--danger' : 'text--grey'
            }`}
          >
            {errors?.description?.message
              ? errors?.description?.message
              : 'Description'}
          </label>
          <div className="w--full radius--sm bg--white">
            <ReactQuill
              theme="snow"
              value={watch('description')}
              onChange={(val) =>
                setValue('description', val, { shouldValidate: true })
              }
              modules={{
                toolbar: [
                  [
                    // { 'header': '1'}, {'header': '2'},
                    { font: [] },
                  ],
                  [{ size: [] }],
                  ['bold', 'italic', 'underline'],
                  // ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                  [
                    { list: 'ordered' },
                    { list: 'bullet' },
                    { indent: '-1' },
                    { indent: '+1' },
                  ],
                  // ['link', 'image', 'video'],
                  // ['clean']
                ],
                clipboard: {
                  matchVisual: false,
                },
              }}
              formats={[
                // "header",
                'font',
                'size',
                'bold',
                'italic',
                'underline',
                'strike',
                'blockquote',
                'list',
                'bullet',
                'indent',
                'link',
                'image',
                'video',
              ]}
            />
          </div>
        </div>
      </div>

      <div className="d--flex justify-content--end gap--sm  p-t--md border-top--primary-100">
        <CancelButton btnClasses="w-max--150" onClick={handleClose}>
          Cancel
        </CancelButton>
        <SubmitButton btnClasses="w-max--150" disabled={isLoading}>
          {isLoading ? 'Please wait..' : 'Submit'}
        </SubmitButton>
      </div>
    </form>
  );
};

export default UploadClaim;
