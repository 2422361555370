import { useEffect, useRef } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import CMS_EN from '../CMS/en';
import { useMutation } from '@tanstack/react-query';
import { getDocStatus } from '../../../api';
import Spinner from '../../Spinner';
import { toast } from 'react-toastify';
import CMS_ES from '../CMS/es';
import ER_ES from '../Eligibility/es';
import ER_EN from '../Eligibility/en';

const documentNameLookup = {
  CMS: 'CMS',
  ELIGIBILITY: 'ER',
};

const NoDocument = () => {
  return (
    <div className="d--flex align-items--center justify-content--center h--full w--full">
      No Document Found
    </div>
  );
};

const PreviewDocumentSignReadMode = () => {
  const { docId = '' } = useParams();
  const location = useLocation();
  const formRef = useRef();
  const params = new URLSearchParams(location.search);
  const docType = params.get('docType');

  const {
    mutate,
    data = null,
    isLoading,
  } = useMutation({
    mutationFn: getDocStatus,
    mutationKey: 'getDocStatus',
    onError: (error) => {
      const errMsg = error?.message ?? 'unexpected error';
      toast.error(errMsg);
    },
  });

  useEffect(() => {
    if (docId) {
      mutate({
        uuid: docId,
        docType,
      });
    }
  }, [docId, docType]);

  const documentData = isLoading ? null : data?.data?.data?.getConsentData;
  // const documentData = isLoading ? null : data?.data?.data?.getConxentData;

  const documentLookup = {
    ER_ES: <ER_ES documentData={documentData} ref={formRef} readMode={true} />,
    ER_EN: <ER_EN documentData={documentData} ref={formRef} readMode={true} />,
    CMS_ES: (
      <CMS_ES documentData={documentData} ref={formRef} readMode={true} />
    ),
    CMS_EN: (
      <CMS_EN documentData={documentData} ref={formRef} readMode={true} />
    ),
  };

  const document = documentLookup[
    `${documentNameLookup[docType]}_${
      documentData?.language === 'en' ? 'EN' : 'ES'
    }`
  ] || <NoDocument />;

  if (!docId) return <NoDocument />;

  if (isLoading) {
    return (
      <div className="d--flex align-items--center justify-content--center h--full w--full">
        <Spinner size="lg" />
      </div>
    );
  }

  return (
    <div className="d--flex h--full w--full ">
      <div className="d--flex gap--sm w--full">
        <div className="w--full h--full mx--auto box-shadow--xs overflow--auto p-b--4xl">
          {document}
        </div>
      </div>
    </div>
  );
};

export default PreviewDocumentSignReadMode;
