import React, { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import FormInput from '../../../../../components/FormInput';
import { updateTenant } from '../../../../../api';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAlert } from '../../../../../hooks/useAlert';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  email,
  firstName,
  lastName,
  phone,
} from '../../../../../helpers/yupValidations';
import * as yup from 'yup';
import { useAuth } from '../../../../../hooks/useAuth';
import Button from '../../../../../components/Button';
import { UserFill } from '../../../../../assets/icons/useIcons';

const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
};
const validationSchema = yup
  .object()
  .shape({ firstName, lastName, email, phone });

export default function TenantForm() {
  const { user } = useAuth();
  const { showAlert } = useAlert();
  const queryClient = useQueryClient();
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    mode: 'onSubmit',
  });

  const { mutate: updateTenantMutate, isLoading: changeTenantLoad } =
    useMutation({
      mutationKey: ['updateTenant'],
      mutationFn: updateTenant,
      onSuccess: (data) => {
        queryClient.invalidateQueries({
          queryKey: ['getUserDetails'],
          exact: false,
        });
        showAlert({
          type: 'success',
          message: data?.data?.data?.message || 'Profile updated successfully"',
        });
      },
      onError: (error) => {
        const errMsg = error?.message ?? 'unexpected error';
        showAlert({ type: 'danger', message: errMsg });
      },
    });
  const onSubmit = (data) => {
    updateTenantMutate(data);
  };
  useEffect(() => {
    if (user) {
      const {
        firstName = '',
        lastName = '',
        email = '',
        phone = '',
      } = user || {};
      reset({
        firstName,
        lastName,
        email,
        phone,
      });
    }
  }, [user]);
  return (
    <div className="w--full d--flex flex--column">
      {/* <div className="label--control font--sm font--500 m-b--xs text--secondary bg--secondary-100 border-full--secondary-100 p-t--sm p-b--sm p-r--md p-l--md radius--sm  m-t--md m-b--lg">
        Edit Information
      </div> */}
      <div className="d--flex flex--column gap--lg radius--sm border-full--black-100 p--md flex--column">
        {/* <div className="d--flex gap--md align-items--center">
          <div className="w-min--75 h-min--75 w-max--75 h-max--75 radius--full bg--black-25 p--sm d--flex align-items--center justify-content--center">
            <div className="w-min--60 h-min--60 w-max--60 h-max--60 radius--full bg--black-50 p--sm d--flex align-items--center justify-content--center">
              <UserFill width={30} height={30} />
            </div>
          </div>
          <div className="d--flex flex--column gap--xs ">
            <div className="font--sm font--600">Update photo</div>
          
          </div>
        </div> */}
        <form
          className="w--full d--flex flex--column gap--md"
          onSubmit={handleSubmit(onSubmit)}
          autoComplete="off"
        >
          <div className="d--flex gap--md flex--column-xs">
            <Controller
              name="firstName"
              control={control}
              render={({ field }) => (
                <FormInput
                  {...field}
                  label="Firstname"
                  placeholder="Enter your firstname"
                  error={errors?.firstName?.message}
                />
              )}
            />

            <Controller
              name="lastName"
              control={control}
              render={({ field }) => (
                <FormInput
                  {...field}
                  label="Lastname"
                  placeholder="Enter your lastname"
                  error={errors?.lastName?.message}
                />
              )}
            />
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <FormInput
                  {...field}
                  type="text"
                  label="Email"
                  placeholder="Enter your email address"
                  error={errors?.email?.message}
                  disabled={true}
                  extraClasses="c--not-allowed"
                />
              )}
            />
            <Controller
              name="phone"
              control={control}
              render={({ field }) => (
                <FormInput
                  {...field}
                  label="Phone"
                  placeholder="Enter your phone"
                  error={errors?.phone?.message}
                />
              )}
            />
          </div>

          <div className="w--full d--flex align-items--center m-t--md">
            <Button
              btnClasses="btn  w-max--140 text--black"
              variant="primary"
              color="black"
              disabled={changeTenantLoad}
              type="submit"
            >
              {changeTenantLoad ? 'Please wait...' : 'Save Changes'}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}
