import * as yup from 'yup';
import {
  email,
  firstName,
  lastName,
  phone,
} from '../../helpers/yupValidations';

export const API_ROUTES = {
  SIGNUP: {
    METHOD: 'POST',
    URL: 'auth/sign-up',
  },
  VERIFY_OTP: {
    METHOD: 'POST',
    URL: 'auth/verify-otp',
  },
  RESEND_OTP: {
    METHOD: 'POST',
    URL: 'auth/resend-otp',
  },
  LOGIN_VERIFY_OTP: {
    METHOD: 'POST',
    URL: 'auth/login-otp-verify',
  },
  LINK_CODE: {
    METHOD: 'POST',
    URL: 'auth/link-code',
  },
  SEND_LINK_TO_MOBILE: {
    METHOD: 'POST',
    URL: 'auth/send-otp-on-mobile',
  },
  RESET_PASSWORD: {
    METHOD: 'POST',
    URL: 'auth/reset-password',
  },
};

export const defaultValues = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  country: 'US',
  terms: false,
};

export const schema = yup.object({
  firstName,
  lastName,
  email,
  phone,
  terms: yup
    .boolean()
    .required('The terms and conditions must be accepted.')
    .oneOf([true], 'The terms and conditions must be accepted.'),
});
