import { createContext, useEffect, useState } from 'react';
import { io } from 'socket.io-client';
import { useAuth } from '../../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import { MEMBER } from '../../helpers/constants';

export const SocketContext = createContext(null);

const URL = process.env.REACT_APP_SOCKET_URL;

// const URL =
//   process.env.REACT_APP_MODE === 'DEV'
//     ? process.env.REACT_APP_LOCAL_SOCKET_URL
//     : process.env.REACT_APP_SOCKET_URL;

const PublicSocket = ({ children, uniqueCode }) => {
  const [socket, setSocket] = useState(null);
  const { setUser, setUserData } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!socket && !socket?.connected && uniqueCode) {
      const socketConnection = io(URL, {
        query: {
          userID: uniqueCode,
        },
        reconnection: true,
        reconnectionDelay: 1000,
        reconnectionDelayMax: 5000,
        reconnectionAttempts: 999999999999999,
      });
      setSocket(socketConnection);
      attachListeners(socketConnection);
    }
  }, [socket, uniqueCode]);

  function attachListeners(socket) {
    socket.on('disconnect', () => {
      console.log('Disconnected from server');
    });

    socket.on('received-reload-request', (data) => {
      console.log('🚀 ~ socket.on ~ data:', 'RELOAD REQUEST RECEIVED');
      localStorage.setItem('nph-agency-user-role', data?.data?.findUser?.role);
      localStorage.setItem('nph-agency-acc-tkn', data?.data?.tokenData?.token);
      window.location.reload();
    });
  }

  return (
    <SocketContext.Provider
      value={{
        publicSocket: socket,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};

export default PublicSocket;
