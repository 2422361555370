import React, { forwardRef } from 'react';

const RadioBoxInput = ({ label = '', htmlFor = '', value = '', checked, ...rest }, ref) => {
  return (
    <div className="d--flex gap--sm">
      <input type="radio" className="form-check-input" name={rest?.name} id={htmlFor} value={value} {...rest} ref={ref} checked={checked} />
      {label && (
        <label className="form-check-label" for={htmlFor}>
          {label}
        </label>
      )}
    </div>
  );
};

export default forwardRef(RadioBoxInput);
