import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  pdfFile: null,
};

export const pdfSlice = createSlice({
  name: 'pdfSlice',
  initialState,
  reducers: {
    setPdfFile: (state, action) => {
      state.pdfFile = action?.payload ?? null;
    },
  },
});

export const { setPdfFile } = pdfSlice.actions;

export default pdfSlice.reducer;
