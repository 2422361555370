import React, { useState } from "react";
import {
  CaptureIcon,
  UploadCloud,
  UploadImg,
} from "../../../../../assets/icons/useIcons";
import Modal from "../../../../../components/Modal";
import SelfieCaptureModal from "./SelfieCaptureModal";

const SelectCaptureOption = () => {
  const [openModal, setOpenModal] = useState(false);
  return (
    <div className="w--full">
      <h5 className="font--sm font--400 text--surface m-b--lg">
        Review and confirm uploaded information
        <div className="d--flex flex--column gap--md m-t--md">
          <span
            className="bg--primary radius--sm p--md d--flex align-items--center justify-content--between text--white c--pointer"
            onClick={() => setOpenModal(true)}
          >
            Capture a selfie
            <CaptureIcon width={20} height={20} />
          </span>
          <span className="border-full--primary-400 radius--sm p--md d--flex align-items--center justify-content--between border--dashed text--primary c--pointer">
            Upload a valid identification
            <UploadCloud width={20} height={20} />
          </span>
        </div>
      </h5>
      {openModal ? (
        <Modal
          handleClose={() => setOpenModal(false)}
          footerComponent={null}
          headerComponent={null}
          shouldCloseOnClickOutside={false}
          width="md"
          height="400"
          size="700"
        >
         <SelfieCaptureModal />
        </Modal>
      ) : (
        ""
      )}
    </div>
  );
};

export default SelectCaptureOption;
