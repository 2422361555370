import { apiClient } from '../../../../api/axios';
import { API_ROUTES } from './constants';

export const createWebhookConsentObj = (data) => {
  const { id, ...rest } = data || {};
  return apiClient({
    method: API_ROUTES.CONSENT_KEY.METHOD,
    url: `${API_ROUTES.CONSENT_KEY.URL}${id}`,
    data: rest,
  });
};
export const webhookConsenList = (data) => {
  return apiClient({
    method: API_ROUTES.CONSENTKEY_LIST.METHOD,
    url: API_ROUTES.CONSENTKEY_LIST.URL,
    data,
  });
};
