import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import Modal from '../../../../Modal';
import SignModule from '../../../../SignModule';
import signature from '../../../../../assets/images/signature.png';
import moment from 'moment';

const initialDocData = {
  Agency_Name: '',
  SUBMITTED_DATE: '',
  TYPE: '',
  DATE: '',
  SIGNATURE: '',
};

const HRA_AGREEMENT_ES = forwardRef(
  ({ readMode = false, documentData = null, preFillData = null }, ref) => {
    const [signatureModal, setSignatureModal] = useState(false);
    const [documentState, setDocumentState] = useState(initialDocData);
    const { getHraData } = documentData || {};

    useEffect(() => {
      if (preFillData) {
        Object.entries(preFillData).map(([key, value]) => {
          setDocumentState((prev) => ({
            ...prev,
            [key]: value,
          }));
        });
      }
    }, [preFillData]);

    useEffect(() => {
      if (documentData) {
        setDocumentState((prevState) => {
          prevState['Agency_Name'] = getHraData?.agentDetail?.fullName ?? '';
          prevState['SUBMITTED_DATE'] =
            getHraData?.agentDetail?.docsSubmittedDate ?? '';
          prevState['TYPE'] = getHraData?.agentDetail?.type;
          prevState['DATE'] = new Date();

          return { ...prevState };
        });
      }
    }, [documentData]);

    useImperativeHandle(
      ref,
      () => ({
        getFormValues() {
          return documentState;
        },
        getFormTemplate() {
          return document.getElementById('custom-signature-form-conxent')
            ?.outerHTML;
        },
      }),
      [documentState, documentData],
    );

    function handleChange(e, signatureValue = null) {
      if (signatureValue) {
        setDocumentState((prevState) => ({
          ...prevState,
          SIGNATURE: signatureValue,
        }));
        setSignatureModal(false);
      } else {
        const name = e.target.name;
        const value = e.target.value;
        setDocumentState((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      }
    }

    return (
      <>
        <div
          id="custom-signature-form-conxent"
          style={{
            backgroundColor: ' #fff',
            width: '100%',
            margin: '10px auto',
            padding: '10px 20px',
            maxWidth: '794px',
          }}
          className="pdfForm"
        >
          <table
            cellSpacing="0"
            style={{
              width: '100%',
              padding: '15px 0',
              borderCollapse: 'collapse',
              borderSpacing: '0',
            }}
          >
            <tr>
              <td colSpan="2">
                <h2
                  style={{
                    marginTop: '0',
                    textAlign: 'center',
                    fontSize: '16px',
                    marginBottom: '10px',
                    lineHeight: '1.5',
                  }}
                >
                  ACUERDO DE SERVICIOS DE EVALUACIÓN DE RIESGOS DE SALUD
                </h2>
              </td>
            </tr>

            <tr>
              <td colSpan="2">
                <div
                  style={{
                    width: '100%',
                    display: 'inline-block',
                    marginTop: '8px',
                    lineHeight: '2',
                  }}
                >
                  <div style={{ float: 'left' }}>
                    Este Acuerdo de Servicios de Evaluación de Riesgos de Salud
                    (el "Acuerdo") se realiza y se celebra a partir de
                  </div>

                  <span
                    style={{
                      // float: 'left',
                      maxWidth: '290px',
                      display: 'inline-block',
                    }}
                  >
                    <div
                      style={{
                        minWidth: '200px',
                        border: 'none',
                        width: '100%',
                        borderBottom: '1px solid #555',
                        minHeight: '16px',
                      }}
                    >
                      {moment(documentState.SUBMITTED_DATE).format(
                        'MMMM Do YYYY, h:mm A',
                      )}
                    </div>
                  </span>

                  <span
                    style={{
                      // float: 'left',
                      marginLeft: ' 10px',
                      marginRight: ' 10px',
                    }}
                  >
                    (la "Fecha de entrada en vigor"), entre Nextere, LLC, una
                    sociedad de responsabilidad limitada de Florida (“Nextere” o
                    la “Compañía”), y
                  </span>

                  <div
                    style={{
                      // float: 'left',
                      maxWidth: '290px',
                      display: 'inline-block',
                    }}
                  >
                    <div
                      style={{
                        minWidth: '200px',
                        border: 'none',
                        width: '100%',
                        borderBottom: '1px solid #555',
                        minHeight: '16px',
                      }}
                    >
                      {documentState.Agency_Name}
                    </div>
                  </div>
                  <span
                    style={{
                      // float: 'left',
                      marginLeft: ' 10px',
                      marginRight: ' 10px',
                    }}
                  >
                    un agente/agencia de seguros autorizado ("Agente"). Las
                    partes del presente acuerdan que se hará referencia a ellas
                    colectivamente como las "Partes" o individualmente como una
                    "Parte".
                  </span>
                </div>
              </td>
            </tr>

            <tr>
              <td colSpan="2">
                <h2
                  style={{
                    marginTop: '12px',
                    textAlign: 'center',
                    fontSize: '16px',
                    marginBottom: '10px',
                    lineHeight: '1.5',
                  }}
                >
                  Objetivo
                </h2>
              </td>
            </tr>

            <tr>
              <td colSpan="2">
                <p style={{ lineHeight: '2', marginBottom: '12px' }}>
                  La empresa ha desarrollado y posee tecnología patentada y
                  plataformas de software ("Plataforma"), que permiten a los
                  agentes de seguros autorizados, incluido el Agente, brindar
                  servicios a sus clientes, incluida la facilitación de una
                  Evaluación de Riesgos de Salud ("HRA") para dichos clientes
                  que buscan o están inscritos en ciertos planes de seguro. El
                  Agente desea utilizar la Plataforma para brindar servicios a
                  sus clientes, específicamente para ayudar a los clientes a
                  completar la HRA y transmitir la información a la compañía de
                  seguros del cliente.
                </p>
              </td>
            </tr>

            <tr>
              <td colSpan="2">
                <h2
                  style={{
                    marginTop: '12px',
                    textAlign: 'center',
                    fontSize: '16px',
                    marginBottom: '10px',
                    lineHeight: '1.5',
                  }}
                >
                  Alcance de los servicios
                </h2>
              </td>
            </tr>

            <tr>
              <td colSpan="2">
                <p style={{ lineHeight: '2', marginBottom: '12px' }}>
                  El Agente acepta utilizar la Plataforma para realizar
                  evaluaciones de riesgo de salud para los clientes del Agente,
                  durante las cuales los clientes responderán preguntas
                  específicas relacionadas con la salud y el estilo de vida,
                  según lo dictado y de conformidad con las pautas del Centro de
                  Servicios de Medicare y Medicaid (CMS) y el Centro para el
                  Control y la Prevención de Enfermedades (CDC) (“Pautas
                  gubernamentales”). Los datos recopilados durante la evaluación
                  de riesgo de salud ("Datos de evaluación de riesgo de salud")
                  se compilarán y se proporcionarán a ciertos terceros
                  autorizados, según lo acordado por el Cliente, para incluir la
                  compañía de seguros del Cliente.
                </p>
              </td>
            </tr>
            <tr>
              <td colSpan="2">
                <p style={{ lineHeight: '2', marginBottom: '12px' }}>
                  La Compañía otorgará al Agente de Seguros un derecho no
                  exclusivo e intransferible para acceder y utilizar la
                  Plataforma únicamente con el propósito de realizar
                  evaluaciones de riesgo de salud y brindar servicios
                  relacionados a los clientes del Agente de Seguros, como se
                  describe en este documento y/o en todos los documentos de
                  términos y condiciones asociados promulgados por Nextere.
                </p>
              </td>
            </tr>
            <tr>
              <td colSpan="2">
                <p style={{ lineHeight: '2', marginBottom: '12px' }}>
                  En todo momento, el Agente se asegurará de que todos los
                  clientes estén informados sobre el propósito de la HRA, los
                  tipos de preguntas que se formulan y cómo se utilizarán los
                  datos de la HRA, como se describe en las pautas
                  gubernamentales
                </p>
              </td>
            </tr>

            <tr>
              <td colSpan="2">
                <h2
                  style={{
                    marginTop: '12px',
                    textAlign: 'center',
                    fontSize: '16px',
                    marginBottom: '10px',
                    lineHeight: '1.5',
                  }}
                >
                  Confidencialidad y Protección de Datos
                </h2>
              </td>
            </tr>
            <tr>
              <td colSpan="2">
                <p style={{ lineHeight: '2', marginBottom: '12px' }}>
                  El Agente acepta mantener la confidencialidad de toda la
                  información de propiedad exclusiva proporcionada por Nextere,
                  incluida, entre otros, la Plataforma, los Datos de HRA y
                  cualquier otro dato o material que Nextere considere
                  confidencial, ya sea proporcionado directamente por Nextere al
                  Agente o accedido o accesible por el Agente a través de la
                  Plataforma o de los puntos de venta relacionados de la
                  Compañía ("Información confidencial").
                </p>
              </td>
            </tr>
            <tr>
              <td colSpan="2">
                <p style={{ lineHeight: '2', marginBottom: '12px' }}>
                  El agente reconoce y acepta que la información proporcionada
                  por los clientes a través de la HRA, incluida la información
                  relacionada con la salud, puede constituir "Información de
                  Salud Protegida" (PHI) según la Ley de Portabilidad y
                  Responsabilidad de Seguros Médicos de 1996 ("HIPAA"). El
                  agente acepta cumplir con todos los requisitos de HIPAA
                  aplicables en el manejo, almacenamiento y transmisión de PHI,
                  según se modifiquen dichas regulaciones. Esto incluye, entre
                  otros, la implementación de salvaguardas adecuadas para
                  garantizar la confidencialidad, integridad y seguridad de la
                  PHI al ser proporcionada por el cliente.
                </p>
              </td>
            </tr>
            <tr>
              <td colSpan="2">
                <p style={{ lineHeight: '2', marginBottom: '12px' }}>
                  El agente no divulgará información personal, de salud o
                  relacionada con el estilo de vida de ningún cliente
                  (incluidos, entre otros, los datos de HRA) a terceros no
                  autorizados, excepto cuando sea necesario para completar la
                  transacción, la intención de la HRA o según lo exija la ley.
                </p>
              </td>
            </tr>
            <tr>
              <td colSpan="2">
                <p style={{ lineHeight: '2', marginBottom: '12px' }}>
                  Nextere conserva todos los derechos, títulos e intereses en y
                  sobre la Plataforma y cualquier dato generado por o a través
                  de la Plataforma. El Agente solo puede usar dichos datos según
                  sea necesario para proporcionar los servicios al cliente en
                  virtud de este Acuerdo.
                </p>
              </td>
            </tr>

            <tr>
              <td colSpan="2">
                <h2
                  style={{
                    marginTop: '12px',
                    textAlign: 'center',
                    fontSize: '16px',
                    marginBottom: '10px',
                    lineHeight: '1.5',
                  }}
                >
                  Prohibición de tergiversaciones
                </h2>
              </td>
            </tr>

            <tr>
              <td colSpan="2">
                <p style={{ lineHeight: '2', marginBottom: '12px' }}>
                  El Agente acepta y reconoce que cualquier falsificación,
                  tergiversación o manipulación de datos de clientes,
                  incluyendo, pero no limitado a los Datos de HRA, directa o
                  indirectamente relacionados con el HRA o el uso de la
                  Plataforma por parte del Agente, está prohibido y constituye
                  un evento de incumplimiento no subsanable. Esto incluye, pero
                  no se limita a:
                </p>
                <li style={{ lineHeight: '2', paddingLeft: '45px' }}>
                  Proporcionar datos inexactos, falsos o engañosos en cualquier
                  etapa del proceso, ya sea a través de una entrada directa o de
                  alteraciones indirectas; y
                </li>
                <li style={{ lineHeight: '2', paddingLeft: '45px' }}>
                  Omitir, alterar o ocultar información crítica que pueda
                  afectar el resultado del HRA o los Datos de HRA o la calidad
                  de los servicios proporcionados por el Agente; y
                </li>
                <li style={{ lineHeight: '2', paddingLeft: '45px' }}>
                  Tergiversar deliberadamente cualquier respuesta o información
                  del cliente, independientemente de la intención de manipular
                  los resultados del HRA o influir en el HRA de cualquier
                  manera.
                </li>
                <li style={{ lineHeight: '2', paddingLeft: '45px' }}>
                  Cualquier acto de tergiversación, falsificación o manipulación
                  socava la integridad del proceso de HRA y de la Plataforma, y
                  sujeta a las partes a daños por incumplimiento como resultado.
                </li>
                <p style={{ lineHeight: '2', paddingLeft: '20px' }}>
                  El Agente acepta que bajo ninguna circunstancia se hará pasar
                  o asumirá la identidad de un cliente, directa o
                  indirectamente, relacionada con el HRA o los servicios
                  contemplados en el presente. Específicamente, el Agente debe:
                </p>
                <li style={{ lineHeight: '2', paddingLeft: '45px' }}>
                  Asegurarse de que únicamente el cliente real proporcione
                  respuestas al HRA o cualquier otra consulta relacionada, y que
                  las respuestas del cliente no sean alteradas, influenciadas o
                  fabricadas por el Agente o por terceros; y
                </li>
                <li style={{ lineHeight: '2', paddingLeft: '45px' }}>
                  Abstenerse de enviar respuestas o tomar decisiones en nombre
                  de un cliente sin la autorización adecuada, cuando
                  corresponda.
                </li>
              </td>
            </tr>

            <tr>
              <td colSpan="2">
                <h2
                  style={{
                    marginTop: '12px',
                    textAlign: 'center',
                    fontSize: '16px',
                    marginBottom: '10px',
                    lineHeight: '1.5',
                  }}
                >
                  Requisito de verificación
                </h2>
              </td>
            </tr>
            <tr>
              <td colSpan="2">
                <p style={{ lineHeight: '2', marginBottom: '12px' }}>
                  El Agente está obligado a verificar la identidad de cada
                  cliente de HRA, según lo establecido por la(s) regulación(es)
                  aplicable(s), para garantizar la atribución precisa de toda la
                  información, incluidos los Datos de HRA, proporcionados dentro
                  de la Plataforma. Esto incluye, pero no se limita a:
                </p>
                <li style={{ lineHeight: '2', paddingLeft: '45px' }}>
                  Seguir todos los protocolos prescritos de verificación de
                  identidad, que pueden incluir solicitar información personal
                  identificable (PII) u otros métodos seguros para confirmar la
                  identidad del consumidor antes de iniciar o procesar el HRA; y
                </li>
                <li style={{ lineHeight: '2', paddingLeft: '45px' }}>
                  Asegurarse de que no se intente eludir, evadir o manipular el
                  proceso de verificación de identidad, ya sea mediante
                  tergiversación, omisión o falsificación de datos de
                  identificación; y
                </li>
                <li style={{ lineHeight: '2', paddingLeft: '45px' }}>
                  Cooperar con cualquier auditoría, revisión o investigación
                  relacionada con los procesos de verificación de identidad, y
                  mantener un compromiso con la transparencia y precisión en
                  todo momento.
                </li>
              </td>
            </tr>

            <tr>
              <td colSpan="2">
                <h2
                  style={{
                    marginTop: '12px',
                    textAlign: 'center',
                    fontSize: '16px',
                    marginBottom: '10px',
                    lineHeight: '1.5',
                  }}
                >
                  Indemnización
                </h2>
              </td>
            </tr>
            <tr>
              <td colSpan="2">
                <p style={{ lineHeight: '2', marginBottom: '12px' }}>
                  El Agente acepta indemnizar, defender y eximir de
                  responsabilidad a Nextere y sus afiliados, funcionarios,
                  empleados, agentes, empresas matrices y/o subsidiarias, y
                  contratistas (colectivamente, los "Indemnizados") de y contra
                  cualquier y todas las reclamaciones, daños, pérdidas,
                  responsabilidades, costos y gastos (incluidos los honorarios
                  razonables de abogados) que surjan de o estén relacionados
                  con:
                </p>
                <li style={{ lineHeight: '2', paddingLeft: '45px' }}>
                  Cualquier acción u omisión del Agente relacionada con la
                  prestación de servicios a través de la Plataforma, incluyendo,
                  pero no limitado a, la realización del HRA o la transmisión de
                  los Datos de HRA a la aseguradora o a otra parte autorizada.
                </li>
                <li style={{ lineHeight: '2', paddingLeft: '45px' }}>
                  Cualquier incumplimiento de este Acuerdo, incluyendo, pero no
                  limitado a, violaciones de las obligaciones de
                  confidencialidad o de los requisitos de HIPAA.
                </li>
                <li style={{ lineHeight: '2', paddingLeft: '45px' }}>
                  Cualquier reclamación o acción de los clientes derivada del
                  manejo de su información o del incumplimiento de las leyes y
                  regulaciones de privacidad aplicables.
                </li>
              </td>
            </tr>

            <tr>
              <td colSpan="2">
                <h2
                  style={{
                    marginTop: '12px',
                    textAlign: 'center',
                    fontSize: '16px',
                    marginBottom: '10px',
                    lineHeight: '1.5',
                  }}
                >
                  Concesión de licencias y restricciones
                </h2>
              </td>
            </tr>
            <tr>
              <td colSpan="2">
                <p style={{ lineHeight: '2', marginBottom: '12px' }}>
                  Sujeto a los términos de este Acuerdo y a cualquier ley, norma
                  o regulación aplicable, Nextere otorga al Agente una licencia
                  limitada, no exclusiva y no transferible para usar la
                  Plataforma únicamente con el propósito de facilitar los HRA y
                  servicios relacionados a los clientes. El Agente no podrá
                  sublicenciar, distribuir, asignar ni poner la Plataforma a
                  disposición de ningún tercero no autorizado sin el
                  consentimiento expreso de Nextere y del cliente.
                </p>
                <p style={{ lineHeight: '2', marginBottom: '12px' }}>
                  Específicamente, el Agente no deberá:
                </p>
                <li style={{ lineHeight: '2', paddingLeft: '45px' }}>
                  Usar la Plataforma para cualquier propósito ilegal o de manera
                  inconsistente con este Acuerdo, o con las normas,
                  regulaciones, leyes o Directrices Gubernamentales aplicables.
                </li>
                <li style={{ lineHeight: '2', paddingLeft: '45px' }}>
                  Copiar, modificar, realizar ingeniería inversa, desensamblar o
                  descompilar la Plataforma, o intentar de cualquier otra manera
                  derivar su código fuente.
                </li>
              </td>
            </tr>

            <tr>
              <td colSpan="2">
                <h2
                  style={{
                    marginTop: '12px',
                    textAlign: 'center',
                    fontSize: '16px',
                    marginBottom: '10px',
                    lineHeight: '1.5',
                  }}
                >
                  Plazo y terminación
                </h2>
              </td>
            </tr>
            <tr>
              <td colSpan="2">
                <p style={{ lineHeight: '2', marginBottom: '12px' }}>
                  Este Acuerdo comenzará en la Fecha de Vigencia y continuará
                  hasta que cualquiera de las Partes lo dé por terminado según
                  lo previsto en el presente, o hasta que los servicios que el
                  Agente proporciona al(los) cliente(s) hayan cesado.
                </p>
                <p style={{ lineHeight: '2', marginBottom: '12px' }}>
                  Cualquiera de las Partes puede dar por terminado este Acuerdo
                  en cualquier momento proporcionando un aviso por escrito con
                  tres (3) días de antelación a la otra Parte, antes de que el
                  Agente facilite el HRA al cliente. En ese momento y
                  posteriormente, todos los términos de este Acuerdo
                  permanecerán en vigor, y aquellos contemplados para sobrevivir
                  a la terminación o expiración del mismo, como las obligaciones
                  de confidencialidad e indemnización del Agente, continuarán
                  vigentes.
                </p>
                <p style={{ lineHeight: '2', marginBottom: '12px' }}>
                  Cualquiera de las Partes puede dar por terminado este Acuerdo
                  de forma inmediata si la otra Parte incumple cualquier término
                  material de este Acuerdo y no subsana dicho incumplimiento
                  dentro de los tres (3) días posteriores a la recepción de un
                  aviso por escrito del incumplimiento.
                </p>
                <p style={{ lineHeight: '2', marginBottom: '12px' }}>
                  Al finalizar este Acuerdo, el Agente deberá cesar de inmediato
                  el uso de la Plataforma y devolver o destruir toda la
                  Información Confidencial y los datos de los clientes obtenidos
                  a través de la Plataforma.
                </p>
              </td>
            </tr>

            <tr>
              <td colSpan="2">
                <h2
                  style={{
                    marginTop: '12px',
                    textAlign: 'center',
                    fontSize: '16px',
                    marginBottom: '10px',
                    lineHeight: '1.5',
                  }}
                >
                  Cumplimiento con las Leyes
                </h2>
              </td>
            </tr>
            <tr>
              <td colSpan="2">
                <p style={{ lineHeight: '2', marginBottom: '12px' }}>
                  El Agente acepta cumplir con todas las leyes, normas y
                  regulaciones aplicables, incluyendo, pero no limitado a:
                </p>

                <li style={{ lineHeight: '2', paddingLeft: '45px' }}>
                  La Ley de Portabilidad y Responsabilidad de Seguros de Salud
                  (HIPAA) con respecto al manejo de la Información de Salud
                  Protegida (PHI).
                </li>
                <li style={{ lineHeight: '2', paddingLeft: '45px' }}>
                  Las leyes y regulaciones aplicables de privacidad y seguridad
                  de datos, incluyendo, pero no limitado al Reglamento General
                  de Protección de Datos (GDPR) y cualquier ley estatal de
                  protección de datos.
                </li>
                <li style={{ lineHeight: '2', paddingLeft: '45px' }}>
                  Todas las leyes, normas y regulaciones aplicables en el estado
                  en el cual el Agente está autorizado para realizar servicios
                  de venta de seguros, así como aquellas en el estado en el que
                  se proporcionan dichos servicios y/o en el que reside el
                  cliente del Agente.
                </li>
              </td>
            </tr>

            <tr>
              <td colSpan="2">
                <h2
                  style={{
                    marginTop: '12px',
                    textAlign: 'center',
                    fontSize: '16px',
                    marginBottom: '10px',
                    lineHeight: '1.5',
                  }}
                >
                  Disposiciones de Incumplimiento y Violación
                </h2>
              </td>
            </tr>
            <tr>
              <td colSpan="2">
                <p style={{ lineHeight: '2', marginBottom: '12px' }}>
                  En caso de que el Agente no cumpla con alguno de los términos
                  y obligaciones establecidos en este Acuerdo, dicho
                  incumplimiento constituirá un Incumplimiento. Un
                  Incumplimiento puede ocurrir como resultado de, pero no
                  limitado a:
                </p>

                <li style={{ lineHeight: '2', paddingLeft: '45px' }}>
                  Tergiversación o falsificación de la información del cliente,
                  incluidos los Datos de HRA; y
                </li>
                <li style={{ lineHeight: '2', paddingLeft: '45px' }}>
                  Suplantación de un cliente o proporcionar respuestas en nombre
                  de un cliente en violación de los protocolos establecidos; y
                </li>
                <li style={{ lineHeight: '2', paddingLeft: '45px' }}>
                  Incumplimiento de los procedimientos de verificación de
                  identidad o cualquier intento de eludir, manipular o socavar
                  el proceso de verificación; y
                </li>
                <li style={{ lineHeight: '2', paddingLeft: '45px' }}>
                  Violación de cualquier ley, regulación o política de la
                  empresa aplicable relacionada con la protección de la
                  información del cliente, la privacidad de los datos u otras
                  obligaciones relacionadas.
                </li>
              </td>
            </tr>

            <tr>
              <td colSpan="2">
                <h2
                  style={{
                    marginTop: '12px',
                    textAlign: 'center',
                    fontSize: '16px',
                    marginBottom: '10px',
                    lineHeight: '1.5',
                  }}
                >
                  Violación
                </h2>
              </td>
            </tr>
            <tr>
              <td colSpan="2">
                <p style={{ lineHeight: '2', marginBottom: '12px' }}>
                  Una Violación de este Acuerdo ocurre cuando un Agente realiza
                  una conducta o acción que:
                </p>

                <li style={{ lineHeight: '2', paddingLeft: '45px' }}>
                  Causa directa o indirectamente daño a la integridad del
                  sistema de HRA, la Plataforma, Nextere, el cliente o la
                  privacidad del cliente, o a las obligaciones legales o
                  regulatorias de la Compañía; y
                </li>
                <li style={{ lineHeight: '2', paddingLeft: '45px' }}>
                  Compromete la exactitud o integridad del HRA o de los Datos de
                  HRA, o de alguna manera distorsiona los resultados de
                  cualquier HRA; y
                </li>
                <li style={{ lineHeight: '2', paddingLeft: '45px' }}>
                  Viola el deber del Agente de mantener transparencia,
                  honestidad y pleno cumplimiento con todos los estándares
                  operativos, regulatorios y legales asociados con sus funciones
                  y licencias.
                </li>
                <p style={{ lineHeight: '2', marginBottom: '12px' }}>
                  Tras el descubrimiento de una Violación, la Compañía tendrá
                  derecho a:
                </p>

                <li style={{ lineHeight: '2', paddingLeft: '45px' }}>
                  Suspender o terminar el acceso a la Plataforma o cualquier
                  otro sistema de la Compañía hasta que se concluya cualquier
                  investigación de la Compañía y se implementen medidas
                  correctivas; y
                </li>
                <li style={{ lineHeight: '2', paddingLeft: '45px' }}>
                  Tomar medidas correctivas o disciplinarias según sea
                  necesario, incluyendo pero no limitado a emitir advertencias,
                  requerir capacitación adicional, suspensión de funciones o
                  terminación de este Acuerdo o de cualquier acuerdo que la
                  Compañía o sus afiliados tengan con el Agente.
                </li>
              </td>
            </tr>

            <tr>
              <td colSpan="2">
                <h2
                  style={{
                    marginTop: '12px',
                    textAlign: 'center',
                    fontSize: '16px',
                    marginBottom: '10px',
                    lineHeight: '1.5',
                  }}
                >
                  Recursos
                </h2>
              </td>
            </tr>
            <tr>
              <td colSpan="2">
                <p style={{ lineHeight: '2', marginBottom: '12px' }}>
                  En caso de un Incumplimiento o Violación, la Compañía tendrá
                  derecho a ejercer todos los recursos disponibles bajo este
                  Acuerdo, la ley o en equidad, incluidos, pero no limitados a,
                  medidas cautelares o acciones legales para recuperar los daños
                  derivados de la violación, incluyendo la pérdida de ingresos,
                  daño reputacional o sanciones o multas regulatorias impuestas
                  como resultado de las acciones o inacciones del Agente.
                  Además, la Compañía podrá notificar a los organismos
                  regulatorios pertinentes, agencias de aplicación de la ley u
                  otras autoridades, si la Violación implica actividad ilegal,
                  daño al cliente o violación de los requisitos regulatorios.
                </p>
              </td>
            </tr>
            <tr>
              <td colSpan="2">
                <h2
                  style={{
                    marginTop: '12px',
                    textAlign: 'center',
                    fontSize: '16px',
                    marginBottom: '10px',
                    lineHeight: '1.5',
                  }}
                >
                  Limitación de Responsabilidad
                </h2>
              </td>
            </tr>
            <tr>
              <td colSpan="2">
                <p style={{ lineHeight: '2', marginBottom: '12px' }}>
                  En ningún caso, cualquiera de las partes será responsable ante
                  la otra por daños indirectos, incidentales, especiales o
                  consecuentes, incluyendo pero no limitados a la pérdida de
                  beneficios, incluso si se ha informado a la parte de la
                  posibilidad de dichos daños.
                </p>
                <p style={{ lineHeight: '2', marginBottom: '12px' }}>
                  La responsabilidad máxima de Nextere hacia el Agente bajo este
                  Acuerdo no excederá los honorarios totales pagados por el
                  Agente de Seguros a Nextere en los doce (12) meses anteriores
                  al evento que dio origen a la reclamación.
                </p>
              </td>
            </tr>
            <tr>
              <td colSpan="2">
                <h2
                  style={{
                    marginTop: '12px',
                    textAlign: 'center',
                    fontSize: '16px',
                    marginBottom: '10px',
                    lineHeight: '1.5',
                  }}
                >
                  Misceláneos
                </h2>
              </td>
            </tr>
            <tr>
              <td colSpan="2">
                <p style={{ lineHeight: '2', marginBottom: '12px' }}>
                  Este Acuerdo constituye el acuerdo completo entre las Partes y
                  reemplaza todos los entendimientos, acuerdos o
                  representaciones previas o contemporáneas, ya sean escritos u
                  orales, relacionados con el objeto del mismo.
                </p>
                <p style={{ lineHeight: '2', marginBottom: '12px' }}>
                  Este Acuerdo se regirá e interpretará de acuerdo con las leyes
                  del Estado de Florida, sin tener en cuenta sus principios de
                  conflicto de leyes. El lugar para cualquier causa, caso o
                  controversia será un tribunal con jurisdicción competente en
                  el Condado de Miami-Dade, Florida.
                </p>
                <p style={{ lineHeight: '2', marginBottom: '20px' }}>
                  Cualquier disputa que surja de o en conexión con este Acuerdo
                  se resolverá mediante arbitraje vinculante en el Condado de
                  Miami-Dade, Florida, conforme a las reglas de la Asociación
                  Americana de Arbitraje.
                </p>
              </td>
            </tr>

            <tr>
              <td colSpan="2">
                <p style={{ lineHeight: '2', marginBottom: '20px' }}>
                  EN TESTIMONIO DE LO CUAL, las Partes han ejecutado este
                  Acuerdo en la Fecha Eficaz que se indica arriba.
                </p>
              </td>
            </tr>
            <tr>
              <td colSpan="2">
                <p style={{ lineHeight: '2', marginBottom: '25px' }}>
                  Nextere, LLC
                </p>
              </td>
            </tr>

            <tr
              style={{
                marginBottom: '25px',
              }}
            >
              <td
                style={{
                  paddingRight: '20px',
                  width: '50%',
                }}
              >
                <table
                  style={{
                    width: '100%',
                    padding: '15px 0',
                    borderCollapse: 'collapse',
                    borderSpacing: '0',
                  }}
                >
                  <tr>
                    <td
                    // style={{ width: '60px' }}
                    >
                      <p
                        style={{
                          display: 'inline-block',
                          lineHeight: '1',
                          margin: '0',
                          marginBottom: '25px',

                          // width: '130px',
                        }}
                      >
                        By:
                      </p>
                    </td>
                    <td>
                      <div
                        style={{
                          minWidth: '200px',
                          border: 'none',
                          width: '100%',
                          // borderBottom: '1px solid #555',
                          minHeight: '16px',
                          marginBottom: '25px',
                        }}
                      >
                        <div
                          style={{
                            minWidth: '200px',
                            border: 'none',
                            width: '100%',
                            padding: '5px',
                            cursor: 'pointer',
                            top: '-45px',
                            height: '60px',
                            // border: '1px dashed #ddd',
                          }}
                        >
                          <img
                            alt="signature"
                            src={signature}
                            style={{ maxHeight: '50px' }}
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                </table>
              </td>
              <td style={{ paddingLeft: '20px', width: '50%' }}></td>
            </tr>

            <tr>
              <td style={{ paddingRight: '20px', width: '50%' }}>
                <table
                  style={{
                    width: '100%',
                    padding: '15px 0',
                    borderCollapse: 'collapse',
                    borderSpacing: '0',
                  }}
                >
                  <tr>
                    <td
                    // style={{ width: '60px' }}
                    >
                      <p
                        style={{
                          display: 'inline-block',
                          lineHeight: '1',
                          margin: '0',
                          marginBottom: '25px',

                          // width: '130px',
                        }}
                      >
                        Name:
                      </p>
                    </td>
                    <td>
                      <div
                        style={{
                          minWidth: '200px',
                          border: 'none',
                          width: '100%',
                          borderBottom: '1px solid #555',
                          minHeight: '16px',
                          marginBottom: '25px',
                        }}
                      >
                        Ernesto Pena
                      </div>
                    </td>
                  </tr>
                </table>
              </td>
              <td style={{ paddingLeft: '20px', width: '50%' }}></td>
            </tr>

            <tr>
              <td style={{ paddingRight: '20px', width: '50%' }}>
                <table
                  style={{
                    width: '100%',
                    padding: '15px 0',
                    borderCollapse: 'collapse',
                    borderSpacing: '0',
                  }}
                >
                  <tr>
                    <td
                    // style={{ width: '60px' }}
                    >
                      <p
                        style={{
                          display: 'inline-block',
                          lineHeight: '1',
                          margin: '0',
                          // width: '130px',
                          marginBottom: '25px',
                        }}
                      >
                        Title:
                      </p>
                    </td>
                    <td>
                      <div
                        style={{
                          minWidth: '200px',
                          border: 'none',
                          width: '100%',
                          borderBottom: '1px solid #555',
                          minHeight: '16px',
                          marginBottom: '25px',
                        }}
                      >
                        CEO
                      </div>
                    </td>
                  </tr>
                </table>
              </td>
              <td style={{ paddingLeft: '20px', width: '50%' }}></td>
            </tr>

            <tr>
              <td style={{ paddingRight: '20px', width: '50%' }}>
                <table
                  style={{
                    width: '100%',
                    padding: '15px 0',
                    borderCollapse: 'collapse',
                    borderSpacing: '0',
                  }}
                >
                  <tr>
                    <td
                    // style={{ width: '60px' }}
                    >
                      <p
                        style={{
                          display: 'inline-block',
                          lineHeight: '1',
                          margin: '0',
                          // width: '130px',
                          marginBottom: '45px',
                        }}
                      >
                        Date:
                      </p>
                    </td>
                    <td>
                      <div
                        style={{
                          minWidth: '200px',
                          border: 'none',
                          width: '100%',
                          borderBottom: '1px solid #555',
                          minHeight: '16px',
                          marginBottom: '45px',
                        }}
                      >
                        {moment(documentState.SUBMITTED_DATE).format(
                          'MMMM Do YYYY, h:mm A',
                        )}
                      </div>
                    </td>
                  </tr>
                </table>
              </td>
              <td style={{ paddingLeft: '20px', width: '50%' }}></td>
            </tr>

            <tr>
              <td style={{ paddingRight: '20px', width: '50%' }}>
                <table
                  style={{
                    width: '100%',
                    padding: '15px 0',
                    borderCollapse: 'collapse',
                    borderSpacing: '0',
                  }}
                >
                  <tr>
                    <td
                    // style={{ width: '60px' }}
                    >
                      <p
                        style={{
                          display: 'inline-block',
                          lineHeight: '1',
                          margin: '0',
                          // width: '130px',
                          marginBottom: '81px',
                        }}
                      >
                        Agent/Agency:
                      </p>
                    </td>
                    <td>
                      <div
                        style={{
                          minWidth: '200px',
                          border: 'none',
                          width: '100%',
                          borderBottom: '1px solid #555',
                          minHeight: '16px',
                          marginBottom: '81px',
                        }}
                      >
                        {documentState.Agency_Name}
                      </div>
                    </td>
                  </tr>
                </table>
              </td>
              <td style={{ paddingLeft: '20px', width: '50%' }}></td>
            </tr>

            <tr
              style={{
                marginBottom: '25px',
              }}
            >
              <td
                style={{
                  paddingRight: '20px',
                  width: '50%',
                }}
              >
                <table
                  style={{
                    width: '100%',
                    padding: '15px 0',
                    borderCollapse: 'collapse',
                    borderSpacing: '0',
                  }}
                >
                  <tr>
                    <td
                    // style={{ width: '60px' }}
                    >
                      <p
                        style={{
                          display: 'inline-block',
                          lineHeight: '1',
                          margin: '0',
                          marginBottom: '25px',

                          // width: '130px',
                        }}
                      >
                        By:
                      </p>
                    </td>
                    <td>
                      <div
                        style={{
                          minWidth: '200px',
                          border: 'none',
                          width: '100%',
                          borderBottom: '1px solid #555',
                          minHeight: '16px',
                          marginBottom: '25px',
                        }}
                      >
                        <div style={{ position: 'relative' }}>
                          {!documentState?.SIGNATURE ? (
                            <div
                              onClick={() => {
                                if (readMode) return;
                                setSignatureModal(true);
                              }}
                              style={{
                                minWidth: '200px',
                                border: 'none',
                                height: '60px',
                                width: '100%',
                                padding: '5px',
                                cursor: 'pointer',
                                position: 'absolute',
                                top: '-45px',
                                border: '1px dashed #ddd',
                              }}
                            >
                              + Add Signature
                            </div>
                          ) : (
                            <div
                              onClick={() => {
                                if (readMode) return;
                                setSignatureModal(true);
                              }}
                              style={{
                                minWidth: '200px',
                                border: 'none',
                                width: '100%',
                                padding: '5px',
                                cursor: 'pointer',
                                position: 'absolute',
                                top: '-45px',
                                height: '60px',
                                border: '1px dashed #ddd',
                              }}
                            >
                              <img
                                alt="signature"
                                src={documentState?.SIGNATURE}
                                style={{ maxHeight: '50px' }}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </td>
                  </tr>
                </table>
              </td>
              <td style={{ paddingLeft: '20px', width: '50%' }}></td>
            </tr>

            <tr>
              <td style={{ paddingRight: '20px', width: '50%' }}>
                <table
                  style={{
                    width: '100%',
                    padding: '15px 0',
                    borderCollapse: 'collapse',
                    borderSpacing: '0',
                  }}
                >
                  <tr>
                    <td
                    // style={{ width: '60px' }}
                    >
                      <p
                        style={{
                          display: 'inline-block',
                          lineHeight: '1',
                          margin: '0',
                          marginBottom: '25px',

                          // width: '130px',
                        }}
                      >
                        Name:
                      </p>
                    </td>
                    <td>
                      <div
                        style={{
                          minWidth: '200px',
                          border: 'none',
                          width: '100%',
                          borderBottom: '1px solid #555',
                          minHeight: '16px',
                          marginBottom: '25px',
                        }}
                      >
                        {documentState.Agency_Name}
                      </div>
                    </td>
                  </tr>
                </table>
              </td>
              <td style={{ paddingLeft: '20px', width: '50%' }}></td>
            </tr>

            <tr>
              <td style={{ paddingRight: '20px', width: '50%' }}>
                <table
                  style={{
                    width: '100%',
                    padding: '15px 0',
                    borderCollapse: 'collapse',
                    borderSpacing: '0',
                  }}
                >
                  <tr>
                    <td
                    // style={{ width: '60px' }}
                    >
                      <p
                        style={{
                          display: 'inline-block',
                          lineHeight: '1',
                          margin: '0',
                          // width: '130px',
                          marginBottom: '25px',
                        }}
                      >
                        Title:
                      </p>
                    </td>
                    <td>
                      <div
                        style={{
                          minWidth: '200px',
                          border: 'none',
                          width: '100%',
                          borderBottom: '1px solid #555',
                          minHeight: '16px',
                          marginBottom: '25px',
                        }}
                      >
                        {documentState.TYPE}
                      </div>
                    </td>
                  </tr>
                </table>
              </td>
              <td style={{ paddingLeft: '20px', width: '50%' }}></td>
            </tr>

            <tr>
              <td style={{ paddingRight: '20px', width: '50%' }}>
                <table
                  style={{
                    width: '100%',
                    padding: '15px 0',
                    borderCollapse: 'collapse',
                    borderSpacing: '0',
                  }}
                >
                  <tr>
                    <td
                    // style={{ width: '60px' }}
                    >
                      <p
                        style={{
                          display: 'inline-block',
                          lineHeight: '1',
                          margin: '0',
                          // width: '130px',
                          marginBottom: '45px',
                        }}
                      >
                        Date:
                      </p>
                    </td>
                    <td>
                      <div
                        style={{
                          minWidth: '200px',
                          border: 'none',
                          width: '100%',
                          borderBottom: '1px solid #555',
                          minHeight: '16px',
                          marginBottom: '45px',
                        }}
                      >
                        {moment(documentState.DATE).format(
                          'MMMM Do YYYY, h:mm A',
                        )}
                      </div>
                    </td>
                  </tr>
                </table>
              </td>
              <td style={{ paddingLeft: '20px', width: '50%' }}></td>
            </tr>
          </table>
        </div>

        {signatureModal ? (
          <Modal
            handleClose={() => setSignatureModal(false)}
            title={'Signature'}
            headerComponent={null}
            shouldCloseOnClickOutside={true}
            footerComponent={null}
            height="300"
            size="700"
          >
            <SignModule handleChange={handleChange} />
          </Modal>
        ) : null}
      </>
    );
  },
);

export default HRA_AGREEMENT_ES;
