export const API_ROUTES = {
  GOOGLE_DRIVE_CREATE_FOLDER: {
    METHOD: 'POST',
    URL: 'storage/google-drive/create-folder',
  },
  GOOGLE_DRIVE_FOLDERS_LIST: {
    METHOD: 'GET',
    URL: 'storage/google-drive/folders',
  },
  GOOGLE_DRIVE_DELETE_FOLDER: {
    METHOD: 'DELETE',
    URL: 'storage/google-drive/delete-folder',
  },
  GOOGLE_DRIVE_FILES_LIST: {
    METHOD: 'POST',
    URL: 'storage/google-drive/file-listing',
  },
  GOOGLE_DRIVE_BULK_UPLOAD: {
    METHOD: 'POST',
    URL: 'storage/google-drive/bulk-upload',
  },
};
