import { apiClient } from '../../api/axios';
import { API_ROUTES } from './constants';

export const getAgentList = (data) => {
  return apiClient({
    method: API_ROUTES.GET_AGENT_LIST.METHOD,
    url: API_ROUTES.GET_AGENT_LIST.URL,
    data,
  });
};
export const consumerUpdateStatus = (data) => {
  return apiClient({
    method: API_ROUTES.CONSUMER_UDPATE_STATUS.METHOD,
    url: API_ROUTES.CONSUMER_UDPATE_STATUS.URL,
    data,
  });
};

export const createAgent = (data) => {
  return apiClient({
    method: API_ROUTES.CREATE_AGENT.METHOD,
    url: API_ROUTES.CREATE_AGENT.URL,
    data,
  });
};

export const updateAgent = (data) => {
  return apiClient({
    method: API_ROUTES.UPDATE_AGENT.METHOD,
    url: API_ROUTES.UPDATE_AGENT.URL,
    data,
  });
};

export const deleteAgent = (data) => {
  return apiClient({
    method: API_ROUTES.DELETE_AGENT.METHOD,
    url: API_ROUTES.DELETE_AGENT.URL,
    data,
  });
};

export const updateAgentStatus = (data) => {
  return apiClient({
    method: API_ROUTES.UPDATE_AGENT_STATUS.METHOD,
    url: API_ROUTES.UPDATE_AGENT_STATUS.URL,
    data,
  });
};
export const agencyCreateAgent = (data) => {
  return apiClient({
    method: API_ROUTES.AGENCY_CREATE_AGENT.METHOD,
    url: API_ROUTES.AGENCY_CREATE_AGENT.URL,
    data,
  });
};
export const agencyAgentList = (data) => {
  return apiClient({
    method: API_ROUTES.AGENCY_AGENT_LIST.METHOD,
    url: API_ROUTES.AGENCY_AGENT_LIST.URL,
    data,
  });
};
