import React, { useState } from 'react';
import { EyeCloseIcon, EyeIcon } from '../../assets/icons/useIcons';

const PasswordInput = (
  {
    placeholder,
    label,
    error,
    extraClasses = '',
    paddingLeft = 'md',
    paddingRight = 'md',
    height = '40',
    border = true,
    radiusLeft = 'sm',
    radiusRight = 'sm',
    required = true,
    ...rest
  },
  ref,
) => {
  const text = error || label || '';
  const [showPassword, setShowPassword] = useState(false);
  return (
    <div className="w--full  d--flex flex--column position--relative">
      {text && (
        <label
          className={`label--control font--sm font--500 m-b--sm   ${
            error ? 'text--danger' : 'text--grey'
          }`}
        >
          {text}
          {required && (
            <span className="m-l--xs font--sm text--danger ">*</span>
          )}
        </label>
      )}
      <input
        {...rest}
        ref={ref}
        type={showPassword ? 'text' : 'password'}
        placeholder={placeholder}
        className={`form--control w--full h-min--${height}  r-t-left--${radiusLeft} r-t-right--${radiusRight} r-b-left--${radiusLeft} r-b-right--${radiusRight} p-l--${paddingLeft} p-r--${paddingRight} ${extraClasses} ${
          error
            ? 'border-full--danger'
            : border
            ? 'border-full--black-200'
            : 'border-full--transparent'
        }`}
      />
      <span
        className="inputIcon c--pointer position--absolute bottom--5 right--10"
        onClick={() => setShowPassword((_) => !_)}
      >
        {showPassword ? <EyeIcon /> : <EyeCloseIcon />}
      </span>
    </div>
  );
};

export default React.forwardRef(PasswordInput);
