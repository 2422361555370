import React from 'react';
import Button from '.';

export default function CancelButton({
  size = '',
  children,
  btnClasses = '',
  icon = null,
  rounded = false,
  disabled = false,
  ...rest
}) {
  return (
    <Button
      {...rest}
      type="button"
      btnClasses={`btn ${btnClasses}`}
      variant="transparent"
      color="black"
      isOutline
      borderColor="primary"
      data-link-hover
      disabled={disabled}
      // className={`btn--${size} bg--white text--black radius--sm btn border-full--primary ${btnClasses}`}
    >
      <span className="d--flex">{icon && icon}</span> {children}
    </Button>
  );
}
