import { createContext, useEffect, useState } from 'react';
import { io } from 'socket.io-client';
import { useAuth } from '../../hooks/useAuth';
import { useChat } from '../../hooks/useChat';

export const SocketContext = createContext(null);

const URL =
  process.env.REACT_APP_MODE === 'DEV'
    ? process.env.REACT_APP_SOCKET_URL
    : process.env.REACT_APP_SOCKET_URL;

const Socket = ({ children }) => {
  const { user } = useAuth();
  const {
    handleAppendChat = () => {
      return;
    },
    handleAppendChatList = () => {
      return;
    },
    handleGetChats = () => {
      return;
    },
    handleGetChatsManual = () => {
      return;
    },
    handleGetChatMessages = () => {
      return;
    },
    handleUserTyping = () => {
      return;
    },
    handleDeleteMessage = () => {
      return;
    },
    handleUpdateMessage = () => {
      return;
    },
  } = useChat();
  const [socket, setSocket] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const [sessions, setSessions] = useState([]);

  useEffect(() => {
    if (!socket && !socket?.connected && user?._id) {
      const socketConnection = io(URL, {
        query: {
          userID: user?._id,
        },
        reconnection: true,
        reconnectionDelay: 1000,
        reconnectionDelayMax: 5000,
        reconnectionAttempts: 999999999999999,
      });
      setSocket(socketConnection);
      attachListeners(socketConnection);
    }
  }, [socket, user?._id]);

  function handleTyping(payload) {
    if (socket) {
      socket.emit('typing', payload);
    }
  }

  function attachListeners(socket) {
    socket.on('disconnect', () => {
      console.log('Disconnected from server');
    });

    socket.on('connect', function (connection) {
      console.log('Connect');
    });

    socket.on('get-message', (data) => {
      handleAppendChat(data, socket);
    });

    socket.on('get-chats', (data) => {
      const chats = data?.chats || [];
      handleGetChats(chats);
    });

    socket.on('get-chats-manual', (data) => {
      const chats = data?.chats || [];
      handleGetChatsManual(chats);
    });

    socket.on('message-list', (data) => {
      const messages = data?.chats || [];
      handleGetChatMessages(messages);
    });

    socket.on('received-new-chat', (data) => {
      handleAppendChatList(data);
    });

    socket.on('typing', (data) => {
      const typingObj = data?.typing || {};
      handleUserTyping(typingObj);
    });

    socket.on('received-notification', (data) => {
      setNotifications((prevNotifications) => [
        ...prevNotifications,
        data?.notification,
      ]);
    });

    socket.on('sessions', (data) => {
      const sessions = data?.sessions ?? {};
      setSessions(sessions);
    });

    socket.on('message-deleted', (data) => {
      handleDeleteMessage(data);
    });

    socket.on('message-updated', (data) => {
      handleUpdateMessage(data);
    });
  }

  return (
    <SocketContext.Provider
      value={{
        socket,
        notifications,
        sessions,
        handleTyping,
        setNotifications,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};

export default Socket;
