import React from 'react';
import FormInput from '../../components/FormInput';

export const EligibilityPdf = () => {
  return (
    <div className="w--full d--flex justify-content--center bg--contrast p--xl overflow--auto ">
      {/* <div className="w--full h--full  w-max--800 bg--white p--lg   d--flex flex--column gap--xl ">
        <div className="font--lg font--600 text--c">
          Eligibility Review Acknowledgement
        </div>
        <div className="d--flex flex--column gap--lg w--full">
          <div className="font--sm line-height--2 w--full">
            Eligibility Review Acknowledgement This document, and the
            acknowledgements and representations made herein are intended to
            ratify that the below listed Agent, broker, or web-broker has
            thoroughly explained and reviewed specific eligibility application
            information with the consumer or consumer’s authorized
            representative, such information has been understood and confirmed
            to be accurate by the consumer or the consumer’s authorized
            representative, and that the consumer or consumer’s representative
            authorizes such action(s) and such is necessary to effectuate
            consumer’s desires per such eligibility application.
          </div>

          <div className="font--sm line-height--2 w--full">
            The consumer understands and acknowledges that such action has
            produced a record in which the agent, broker, or Web-broker listed
            herein shall be required to maintain such information, action(s),
            and/or documentation for the protection of consumer and/or
            consumer’s authorized representative.
          </div>
        </div>
        <div className="d--flex flex--column gap--lg w--full">
          <div className="d--flex gap--2xl">
            <div className="d--flex  w--full gap--sm align-items--end">
              <div className="d--flex  font--sm white-space--nowrap font--500">
                Broker Full Name:
              </div>
              <FormInput
                radiusLeft={0}
                radiusRight={0}
                height="20"
                paddingLeft={'xs'}
                borderStyle="border-bottom--black-200 border-top--transparent border-left--transparent border-right--transparent  "
                extraClasses="bottomBorderFocus"
              />
            </div>
            <div className="d--flex  align-items--end w--full gap--sm"></div>
          </div>
          <div className="d--flex gap--2xl">
            <div className="d--flex  w--full gap--sm align-items--end">
              <div className="d--flex  font--sm white-space--nowrap font--500">
                Broker NPN:
              </div>
              <FormInput
                radiusLeft={0}
                radiusRight={0}
                height="20"
                paddingLeft={'xs'}
                borderStyle="border-bottom--black-200 border-top--transparent border-left--transparent border-right--transparent  "
                extraClasses="bottomBorderFocus"
              />
            </div>
            <div className="d--flex  align-items--end w--full gap--sm"></div>
          </div>
          <div className="d--flex gap--2xl">
            <div className="d--flex  w--full gap--sm align-items--end">
              <div className="d--flex  font--sm white-space--nowrap font--500">
                Broker Phone Number:
              </div>
              <FormInput
                radiusLeft={0}
                radiusRight={0}
                height="20"
                paddingLeft={'xs'}
                borderStyle="border-bottom--black-200 border-top--transparent border-left--transparent border-right--transparent  "
                extraClasses="bottomBorderFocus"
              />
            </div>
            <div className="d--flex  align-items--end w--full gap--sm"></div>
          </div>
          <div className="d--flex gap--2xl">
            <div className="d--flex  w--full gap--sm align-items--end">
              <div className="d--flex  font--sm white-space--nowrap font--500">
                Broker Email:
              </div>
              <FormInput
                radiusLeft={0}
                radiusRight={0}
                height="20"
                paddingLeft={'xs'}
                borderStyle="border-bottom--black-200 border-top--transparent border-left--transparent border-right--transparent  "
                extraClasses="bottomBorderFocus"
              />
            </div>
            <div className="d--flex  align-items--end w--full gap--sm"></div>
          </div>
        </div>
        <div className="d--flex flex--column gap--lg w--full">
          <div className="d--flex gap--2xl">
            <div className="d--flex  w--full gap--sm align-items--end">
              <div className="d--flex  font--sm white-space--nowrap font--500">
                Agency Name:
              </div>
              <FormInput
                radiusLeft={0}
                radiusRight={0}
                height="20"
                paddingLeft={'xs'}
                borderStyle="border-bottom--black-200 border-top--transparent border-left--transparent border-right--transparent  "
                extraClasses="bottomBorderFocus"
              />
            </div>
            <div className="d--flex  align-items--end w--full gap--sm"></div>
          </div>
          <div className="d--flex gap--2xl">
            <div className="d--flex  w--full gap--sm align-items--end">
              <div className="d--flex  font--sm white-space--nowrap font--500">
                Agency Email :
              </div>
              <FormInput
                radiusLeft={0}
                radiusRight={0}
                height="20"
                paddingLeft={'xs'}
                borderStyle="border-bottom--black-200 border-top--transparent border-left--transparent border-right--transparent  "
                extraClasses="bottomBorderFocus"
              />
            </div>
            <div className="d--flex  align-items--end w--full gap--sm"></div>
          </div>
          <div className="d--flex gap--2xl">
            <div className="d--flex  w--full gap--sm align-items--end">
              <div className="d--flex  font--sm white-space--nowrap font--500">
                Agency Phone Number :
              </div>
              <FormInput
                radiusLeft={0}
                radiusRight={0}
                height="20"
                paddingLeft={'xs'}
                borderStyle="border-bottom--black-200 border-top--transparent border-left--transparent border-right--transparent  "
                extraClasses="bottomBorderFocus"
              />
            </div>
            <div className="d--flex  align-items--end w--full gap--sm"></div>
          </div>
        </div>
        <div className="d--flex flex--column gap--lg w--full">
          <div className="d--flex gap--2xl">
            <div className="d--flex  w--full gap--sm align-items--end">
              <div className="d--flex  font--sm white-space--nowrap font--500">
                Consumer - Authorized Representative Name:
              </div>
              <FormInput
                radiusLeft={0}
                radiusRight={0}
                height="20"
                paddingLeft={'xs'}
                borderStyle="border-bottom--black-200 border-top--transparent border-left--transparent border-right--transparent  "
                extraClasses="bottomBorderFocus"
              />
            </div>
            <div className="d--flex  align-items--end w--full gap--sm w-max--100"></div>
          </div>
          <div className="d--flex gap--2xl">
            <div className="d--flex  w--full gap--sm align-items--end">
              <div className="d--flex  font--sm white-space--nowrap font--500">
                Consumer - Authorized Representative Phone Number:
              </div>
              <FormInput
                radiusLeft={0}
                radiusRight={0}
                height="20"
                paddingLeft={'xs'}
                borderStyle="border-bottom--black-200 border-top--transparent border-left--transparent border-right--transparent  "
                extraClasses="bottomBorderFocus"
              />
            </div>
            <div className="d--flex  align-items--end w--full gap--sm w-max--100"></div>
          </div>
          <div className="d--flex gap--2xl">
            <div className="d--flex  w--full gap--sm align-items--end">
              <div className="d--flex  font--sm white-space--nowrap font--500">
                Consumer - Authorized Representative Email:
              </div>
              <FormInput
                radiusLeft={0}
                radiusRight={0}
                height="20"
                paddingLeft={'xs'}
                borderStyle="border-bottom--black-200 border-top--transparent border-left--transparent border-right--transparent  "
                extraClasses="bottomBorderFocus"
              />
            </div>
            <div className="d--flex  align-items--end w--full gap--sm w-max--100"></div>
          </div>
          <div className="d--flex gap--2xl">
            <div className="d--flex  w--full gap--sm align-items--end">
              <div className="d--flex  font--sm white-space--nowrap font--500">
                Consumer - Authorized Representative Signature:
              </div>
              <FormInput
                radiusLeft={0}
                radiusRight={0}
                height="20"
                paddingLeft={'xs'}
                borderStyle="border-bottom--black-200 border-top--transparent border-left--transparent border-right--transparent  "
                extraClasses="bottomBorderFocus"
              />
            </div>
            <div className="d--flex  align-items--end w--full gap--sm w-max--100"></div>
          </div>
          <div className="d--flex gap--2xl">
            <div className="d--flex  w--full gap--sm align-items--end">
              <div className="d--flex  font--sm white-space--nowrap font--500">
                Date / Time:
              </div>
              <FormInput
                radiusLeft={0}
                radiusRight={0}
                height="20"
                paddingLeft={'xs'}
                borderStyle="border-bottom--black-200 border-top--transparent border-left--transparent border-right--transparent  "
                extraClasses="bottomBorderFocus"
              />
            </div>
            <div className="d--flex  align-items--end w--full gap--sm w-max--100"></div>
          </div>
        </div>
      </div> */}
    </div>
  );
};
