import * as yup from 'yup';
import {
  email,
  firstName,
  lastName,
  phone,
} from '../../helpers/yupValidations';
import { useMutation } from '@tanstack/react-query';
import { consumerUpdateStatus } from './actions';

export const API_ROUTES = {
  GET_AGENT_LIST: {
    METHOD: 'GET',
    URL: 'member/agent-list',
  },
  CONSUMER_UDPATE_STATUS: {
    METHOD: 'POST',
    URL: 'consumer/update-status',
  },
  CREATE_AGENT: {
    METHOD: 'POST',
    URL: 'agent/create',
  },
  UPDATE_AGENT: {
    METHOD: 'POST',
    URL: 'agent/update',
  },
  DELETE_AGENT: {
    METHOD: 'POST',
    URL: 'agent/delete',
  },
  UPDATE_AGENT_STATUS: {
    METHOD: 'POST',
    URL: 'agent/update-status',
  },
  AGENCY_CREATE_AGENT: {
    METHOD: 'POST',
    URL: 'agency/create-agent',
  },
  AGENCY_AGENT_LIST: {
    METHOD: 'POST',
    URL: 'agency/agent-list',
  },
};

export const schema = yup.object().shape({
  firstName,
  lastName,
  // extension: yup
  //   .number()
  //   .typeError('Extension is required')
  //   .required('Extension is required')
  //   .test(
  //     'maxLenght',
  //     'Extension must be of length 3 or 4.',
  //     (val) => !isNaN(val) && (`${val}`.length === 4 || `${val}`.length === 3),
  //   ),
  email,
  phone,
  // npn: yup.string().required('NPN number is required'),
});

export const useUpdateConsumerStatusMutate = () => {
  const {
    mutate: updateConsumerStatusMutate,
    isLoading: updateConsumerStatusLoading,
  } = useMutation({
    mutationFn: consumerUpdateStatus,
    mutationKey: 'consumerUpdateStatus',
  });

  return { updateConsumerStatusMutate, updateConsumerStatusLoading };
};
