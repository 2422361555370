import React, { useState } from 'react';
import useIcons, { FolderFillIcon } from '../../../../assets/icons/useIcons';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  getGoogleDriveDeleteFolder,
  getGoogleDriveFoldersList,
} from '../../../UploadDrive/actions';
import DriveFiles from './DriveFiles';
import Tooltip from '../../../../components/Tooltip';
import ActionConfirmation from '../../../../components/ActionConfirmation';
import { useAlert } from '../../../../hooks/useAlert';

const DriveFolders = ({ setShowInfo }) => {
  const { CrossIcon, ArrowLeftIcon, TrashIcon } = useIcons();
  const [folderId, setFolderId] = useState('');
  const { showAlert } = useAlert();

  const {
    data: googleDriveFolders,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: 'getGoogleDriveFoldersList',
    queryFn: getGoogleDriveFoldersList,
    select: (data) => data?.data?.data,
  });

  const { mutate, isLoading: deleteLoading } = useMutation({
    mutationKey: 'getGoogleDriveDeleteFolder',
    mutationFn: getGoogleDriveDeleteFolder,
    onSuccess: ({ data }) => {
      showAlert({ type: 'success', message: data?.data?.message });
      refetch();
    },
    onError: (error) => {
      const errMsg = error?.message ?? 'unexpected error';
      showAlert({ type: 'danger', message: errMsg });
    },
  });

  return (
    <div className="d--flex flex--column border-full--black-100 radius--sm w--full p--lg position--relative m-t--md">
      <div
        className="toggle-sidebar-icon d--flex align-items--center justify-content--center radius--full  bg--black  text--white 
      position--absolute w-max--28 w-min--28 h-max--28 h-min--28 box--shadow c--pointer  top---13 right---10 c--pointer"
        onClick={() => setShowInfo(false)}
      >
        <CrossIcon width={12} height={12} />
      </div>

      {folderId && (
        <div
          className="toggle-sidebar-icon d--flex align-items--center justify-content--center radius--full  bg--black  text--white 
      position--absolute w-max--28 w-min--28 h-max--28 h-min--28 box--shadow c--pointer  top---13 left---10 c--pointer"
          onClick={() => setFolderId('')}
        >
          <ArrowLeftIcon width={20} height={20} />
        </div>
      )}

      {folderId ? (
        <DriveFiles {...{ folderId }} />
      ) : (
        <div className="folderGrid d--grid grid--6 gap--xs   ">
          {isLoading
            ? 'Loading...'
            : googleDriveFolders && googleDriveFolders?.length > 0
            ? googleDriveFolders?.map(({ folderId, folderName }) => {
                return (
                  <div
                    className="folderItem c--pointer w--full w-max--200 p--sm h-min--100 w-min--100 d--flex flex--column justify-content--center align-items--center text--warning-600  radius--sm  border-full--black-100  p--md  position--relative"
                    key={folderId}
                    onClick={() => {
                      setFolderId(folderId);
                    }}
                  >
                    <div
                      className="d--flex gap--xs justify-content--end w--full position--absolute top--5 right--5"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <ActionConfirmation
                        message="Are you sure you want to delete this folder?"
                        onConfirm={() => {
                          if (!deleteLoading) {
                            mutate({ folderId });
                          }
                        }}
                      >
                        <Tooltip
                          text="Delete"
                          className="d--flex w-min--20 c--pointer justify-content--center text--black-400"
                        >
                          <TrashIcon width={16} height={16} />
                        </Tooltip>
                      </ActionConfirmation>
                    </div>

                    <FolderFillIcon width={80} height={80} />

                    <div className="font--sm text--black-600 font--600  text--c ellipsis w-max--150">
                      {folderName}
                    </div>
                  </div>
                );
              })
            : 'No folder available'}
        </div>
      )}
    </div>
  );
};

export default DriveFolders;
