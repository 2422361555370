import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';

const Access = ({ children, allowedRoles }) => {
  const { user } = useAuth();
  const userRole = user?.role || localStorage.getItem('nph-agency-user-role');
  const location = useLocation();
  return [userRole]?.find((role) => allowedRoles?.includes(role)) ? (
    children
  ) : (
    <Navigate to="/unauthorized" state={{ from: location }} replace />
  );
};

export default Access;
