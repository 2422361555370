import React, { useEffect, useRef } from 'react';
import useIcons from '../../assets/icons/useIcons';
import Button from '../Button';
export default function Modal({
  children,
  handleClose,
  title = '',
  shouldCloseOnClickOutside = false,
  headerComponent: HeaderComponent = () => null,
  footerComponent: FooterComponent = () => null,
  size = '500',
  height = '500',
  radius = 'sm',
  showCrossIcon = true,
  bodyBg = true,
}) {
  const { CrossIcon } = useIcons();
  const modalRef = useRef();

  useEffect(() => {
    if (modalRef.current) {
      const refrence = modalRef.current;
      refrence.showModal();
    }
  }, [modalRef]);

  return (
    <div className="position--fixed left--0 top--0 w--full h--full bg--black-400 z-index--sm d--flex align-items--center">
      <dialog
        data-modal
        ref={modalRef}
        class={`dialog-main  p-l--md p-r--md p-b--md  w--xs h-min--${height} w-min--${size}  w-max--${size} m--auto border-full--black-100 radius--${radius}`}
        onClick={(e) => {
          if (!shouldCloseOnClickOutside) {
            // e.preventDefault();
            e.stopPropagation();
          }
        }}
        onClose={() => handleClose()}
      >
        <dialog-head class="dialog-head h-min--50  m-b--md d--flex align-items--center justify-content--between border-bottom--black-100">
          {HeaderComponent ? (
            <HeaderComponent />
          ) : (
            <div class="font--md font--600">{title}</div>
          )}
          {showCrossIcon && (
            <Button
              type="button"
              variant="transparent"
              color="black"
              btnClasses="font--lg font--600 text--grey-600 d--flex c--pointer radius--full c--pointer"
              onClick={() => handleClose()}
            >
              <CrossIcon width={14} height={14} />
            </Button>
          )}
        </dialog-head>
        <dialog-body class="dialog-body ">
          <div
            className={` h--full ${bodyBg && 'p--md bg--contrast'} radius--sm`}
          >
            {children}
          </div>
        </dialog-body>
        {FooterComponent ? (
          <dialog-footer class="modal-foot h-max--50 h-min--50 p-l--md p-r--md d--flex align-items--center justify-content--between border-top--black-100">
            {FooterComponent}
          </dialog-footer>
        ) : null}
      </dialog>
    </div>
  );
}
