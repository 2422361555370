import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import Modal from '../../../Modal';
import SignModule from '../../../SignModule';

const initialDocData = {
  Primary_House_Hold_Name: '',
  Consumer_Consent_Name: '',
  Method_Revoke_Consent: '',
  Primary_Writing_Agent: '',
  Agent_National_Producer_Number: '',
  Agent_Phone_Number: '',
  Agent_Email: '',
  Name_Of_Agency: '',
  Agency_National_Producer_Number: '',
  Owner_Of_Agency: '',
  Agency_Phone_Number: '',
  Agency_Email: '',
  Household_Name: '',
  H_Phone_Number: '',
  H_Email: '',
  DATE: '',
  SIGNATURE: '',
  Consumer_Name: '',
  Consumer_Phone_Number: '',
  Consumer_Email: '',
  Agency_Name: '',
  Broker_Email: '',
  Broker_Full_Name: '',
  Broker_NPN: '',
  Broker_Phone_Number: '',
};

const ER_ES = forwardRef(
  ({ readMode = false, documentData = null, preFillData = null }, ref) => {
    const [signatureModal, setSignatureModal] = useState(false);
    const [documentState, setDocumentState] = useState(initialDocData);

    useEffect(() => {
      if (preFillData) {
        Object.entries(preFillData).map(([key, value]) => {
          setDocumentState((prev) => ({
            ...prev,
            [key]: value,
          }));
        });
      }
    }, [preFillData]);

    useEffect(() => {
      if (documentData) {
        setDocumentState((prevState) => {
          prevState['Primary_House_Hold_Name'] =
            documentData?.memberDetail?.fullName ?? '';
          prevState['Consumer_Consent_Name'] =
            documentData?.userDetail?.fullName ?? '';
          prevState['Method_Revoke_Consent'] = 'Email';
          prevState['Primary_Writing_Agent'] =
            documentData?.userDetail?.fullName ?? '';
          prevState['Agent_National_Producer_Number'] =
            documentData?.userDetail?.npn ?? '';
          prevState['Agent_Phone_Number'] =
            documentData?.userDetail?.phone ?? '';
          prevState['Agent_Email'] = documentData?.userDetail?.email ?? '';
          prevState['Name_Of_Agency'] =
            documentData?.agencyDetail?.agencyName ?? '';
          prevState['Agency_National_Producer_Number'] =
            documentData?.agencyDetail?.npn ?? '';
          prevState['Owner_Of_Agency'] =
            documentData?.agencyDetail?.owner ?? '';
          prevState['Agency_Phone_Number'] =
            documentData?.agencyDetail?.phone ?? '';
          prevState['Agency_Email'] = documentData?.agencyDetail?.email ?? '';
          prevState['Household_Name'] =
            documentData?.memberDetail?.fullName ?? '';
          prevState['H_Phone_Number'] = documentData?.memberDetail?.phone ?? '';
          prevState['H_Email'] = documentData?.memberDetail?.email ?? '';
          prevState['Consumer_Name'] =
            documentData?.memberDetail?.fullName ?? '';
          prevState['Consumer_Phone_Number'] =
            documentData?.memberDetail?.phone ?? '';
          prevState['Consumer_Email'] = documentData?.memberDetail?.email ?? '';
          prevState['Agency_Name'] =
            documentData?.agencyDetail?.agencyName ?? '';
          prevState['Broker_Email'] = documentData?.userDetail?.email ?? '';
          prevState['Broker_Full_Name'] =
            documentData?.userDetail?.fullName ?? '';
          prevState['Broker_NPN'] = documentData?.userDetail?.npn ?? '';
          prevState['Broker_Phone_Number'] =
            documentData?.userDetail?.phone ?? '';
          prevState['DATE'] = new Date();

          return { ...prevState };
        });
      }
    }, [documentData]);

    useImperativeHandle(
      ref,
      () => ({
        getFormValues() {
          return documentState;
        },
        getFormTemplate() {
          return document.getElementById('custom-signature-form-conxent')
            ?.outerHTML;
        },
      }),
      [documentState, documentData],
    );

    function handleChange(e, signatureValue = null) {
      if (signatureValue) {
        setDocumentState((prevState) => ({
          ...prevState,
          SIGNATURE: signatureValue,
        }));
        setSignatureModal(false);
      } else {
        const name = e.target.name;
        const value = e.target.value;
        setDocumentState((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      }
    }
    return (
      <>
        <div
          id="custom-signature-form-conxent"
          style={{
            backgroundColor: ' #fff',
            width: '794px',
            margin: '10px auto',
            padding: '20px',
            maxWidth: '960px',
          }}
          className="pdfForm"
        >
          <table cellSpacing="0">
            <tr>
              <td colSpan="2">
                <h2
                  style={{
                    marginTop: '0',
                    textAlign: 'center',
                    marginBottom: '15px',
                  }}
                >
                  Reconocimiento de Revisión de Elegibilidad
                </h2>
              </td>
            </tr>

            <tr>
              <td colSpan="2">
                <p style={{ lineHeight: '2', marginBottom: '12px' }}>
                  Este documento, y los reconocimientos y representaciones
                  hechos en el mismo, están destinados a ratificar que el
                  Agente, corredor o corredor web listado a continuación ha
                  explicado y revisado minuciosamente la información específica
                  de la solicitud de elegibilidad con el consumidor o el
                  representante autorizado del consumidor, dicha información ha
                  sido entendida y confirmada como precisa por el consumidor o
                  el representante autorizado del consumidor, y que el
                  consumidor o el representante del consumidor autoriza dicha(s)
                  acción(es) y dicha autorización es necesaria para efectuar los
                  deseos del consumidor según dicha solicitud de elegibilidad.
                </p>
              </td>
            </tr>
            <tr>
              <td colSpan="2">
                <p style={{ lineHeight: '2', marginBottom: '30px' }}>
                  El consumidor entiende y reconoce que dicha acción ha
                  producido un registro en el cual el agente, corredor o
                  corredor web listado en este documento estará obligado a
                  mantener dicha información, acción(es) y/o documentación para
                  la protección del consumidor y/o del representante autorizado
                  del consumidor.
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p
                  style={{
                    lineHeight: 1,
                    fontSize: '14px',
                    fontWeight: 500,
                    marginBottom: '20px',
                  }}
                >
                  Nombre del Agente:
                </p>
              </td>

              <td>
                {/* <input
                name="Broker_Full_Name"
                value={documentState.Broker_Full_Name}
                onChange={handleChange}
                type="text"
                style={{
                  minWidth: '200px',
                  border: 'none',
                  maxwidth: '600px',
                  marginBottom: '20px',
                  borderBottom: '1px solid #555',
                }}
              /> */}
                <div
                  style={{
                    minWidth: '200px',
                    border: 'none',
                    width: '100%',
                    borderBottom: '1px solid #555',
                    minHeight: '16px',
                    maxWidth: '200px',
                    marginBottom: '20px',
                  }}
                >
                  {documentState.Broker_Full_Name}
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <p
                  style={{
                    lineHeight: 1,
                    fontSize: '14px',
                    fontWeight: 500,
                    marginBottom: '20px',
                  }}
                >
                  NPN del Agente:
                </p>
              </td>

              <td>
                {/* <input
                name="Broker_NPN"
                value={documentState.Broker_NPN}
                onChange={handleChange}
                type="text"
                style={{
                  minWidth: '200px',
                  border: 'none',
                  maxwidth: '600px',
                  marginBottom: '20px',
                  borderBottom: '1px solid #555',
                }}
              /> */}
                <div
                  style={{
                    minWidth: '200px',
                    border: 'none',
                    width: '100%',
                    borderBottom: '1px solid #555',
                    minHeight: '16px',
                    maxWidth: '200px',
                    marginBottom: '20px',
                  }}
                >
                  {documentState.Broker_NPN}
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <p
                  style={{
                    lineHeight: 1,
                    fontSize: '14px',
                    fontWeight: 500,
                    marginBottom: '20px',
                  }}
                >
                  Telefono del Agente:
                </p>
              </td>

              <td>
                {/* <input
                name="Broker_Phone_Number"
                value={documentState.Broker_Phone_Number}
                onChange={handleChange}
                type="text"
                style={{
                  minWidth: '200px',
                  border: 'none',
                  maxwidth: '600px',
                  marginBottom: '20px',
                  borderBottom: '1px solid #555',
                }}
              /> */}
                <div
                  style={{
                    minWidth: '200px',
                    border: 'none',
                    width: '100%',
                    borderBottom: '1px solid #555',
                    minHeight: '16px',
                    maxWidth: '200px',
                    marginBottom: '20px',
                  }}
                >
                  {documentState.Broker_Phone_Number}
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <p
                  style={{
                    lineHeight: 1,
                    fontSize: '14px',
                    fontWeight: 500,
                    marginBottom: '20px',
                  }}
                >
                  Correo del Agente:
                </p>
              </td>

              <td>
                {/* <input
                name="Broker_Email"
                value={documentState.Broker_Email}
                onChange={handleChange}
                type="text"
                style={{
                  minWidth: '200px',
                  border: 'none',
                  maxwidth: '600px',
                  marginBottom: '20px',
                  borderBottom: '1px solid #555',
                }}
              /> */}
                <div
                  style={{
                    minWidth: '200px',
                    border: 'none',
                    width: '100%',
                    borderBottom: '1px solid #555',
                    minHeight: '16px',
                    maxWidth: '200px',
                    marginBottom: '20px',
                  }}
                >
                  {documentState.Broker_Email}
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <p
                  style={{
                    lineHeight: 1,
                    fontSize: '14px',
                    fontWeight: 500,
                    marginBottom: '20px',
                    marginTop: '30px',
                  }}
                >
                  Nombre de Agencia:
                </p>
              </td>

              <td>
                {/* <input
                name="Agency_Name"
                value={documentState.Agency_Name}
                onChange={handleChange}
                type="text"
                style={{
                  minWidth: '200px',
                  border: 'none',
                  maxwidth: '600px',
                  marginBottom: '20px',
                  marginTop: '30px',
                  borderBottom: '1px solid #555',
                }}
              /> */}
                <div
                  style={{
                    minWidth: '200px',
                    border: 'none',
                    width: '100%',
                    borderBottom: '1px solid #555',
                    minHeight: '16px',
                    maxWidth: '200px',
                    marginBottom: '20px',
                  }}
                >
                  {documentState.Agency_Name}
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <p
                  style={{
                    lineHeight: 1,
                    fontSize: '14px',
                    fontWeight: 500,
                    marginBottom: '20px',
                  }}
                >
                  Correo de Agencia :
                </p>
              </td>

              <td>
                {/* <input
                name="Agency_Email"
                value={documentState.Agency_Email}
                onChange={handleChange}
                type="text"
                style={{
                  minWidth: '200px',
                  border: 'none',
                  maxwidth: '600px',
                  marginBottom: '20px',
                  borderBottom: '1px solid #555',
                }}
              /> */}
                <div
                  style={{
                    minWidth: '200px',
                    border: 'none',
                    width: '100%',
                    borderBottom: '1px solid #555',
                    minHeight: '16px',
                    maxWidth: '200px',
                    marginBottom: '20px',
                  }}
                >
                  {documentState.Agency_Email}
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <p
                  style={{
                    lineHeight: 1,
                    fontSize: '14px',
                    fontWeight: 500,
                    marginBottom: '20px',
                    marginTop: '40px',
                  }}
                >
                  Telefono de Agencia :
                </p>
              </td>

              <td>
                {/* <input
                name="Agency_Phone_Number"
                value={documentState.Agency_Phone_Number}
                onChange={handleChange}
                type="text"
                style={{
                  minWidth: '200px',
                  border: 'none',
                  maxwidth: '600px',
                  marginBottom: '20px',
                  marginTop: '40px',
                  borderBottom: '1px solid #555',
                }}
              /> */}
                <div
                  style={{
                    minWidth: '200px',
                    border: 'none',
                    width: '100%',
                    borderBottom: '1px solid #555',
                    minHeight: '16px',
                    maxWidth: '200px',
                    marginBottom: '20px',
                  }}
                >
                  {documentState.Agency_Phone_Number}
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <p
                  style={{
                    lineHeight: 1,
                    fontSize: '14px',
                    fontWeight: 500,
                    marginBottom: '20px',
                  }}
                >
                  Nombre del Consumidor o Representante Autorizado:
                </p>
              </td>

              <td>
                {/* <input
                name="Consumer_Name"
                value={documentState.Consumer_Name}
                onChange={handleChange}
                type="text"
                style={{
                  minWidth: '200px',
                  border: 'none',
                  maxwidth: '600px',
                  marginBottom: '20px',
                  borderBottom: '1px solid #555',
                }}
              /> */}
                <div
                  style={{
                    minWidth: '200px',
                    border: 'none',
                    width: '100%',
                    borderBottom: '1px solid #555',
                    minHeight: '16px',
                    maxWidth: '200px',
                    marginBottom: '20px',
                  }}
                >
                  {documentState.Consumer_Name}
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <p
                  style={{
                    lineHeight: 1,
                    fontSize: '14px',
                    fontWeight: 500,
                    marginBottom: '20px',
                  }}
                >
                  Telefono del Consumidor o Representante Autorizado:
                </p>
              </td>

              <td>
                {/* <input
                name="Consumer_Phone_Number"
                value={documentState.Consumer_Phone_Number}
                onChange={handleChange}
                type="text"
                style={{
                  minWidth: '200px',
                  border: 'none',
                  maxwidth: '600px',
                  marginBottom: '20px',
                  borderBottom: '1px solid #555',
                }}
              /> */}
                <div
                  style={{
                    minWidth: '200px',
                    border: 'none',
                    width: '100%',
                    borderBottom: '1px solid #555',
                    minHeight: '16px',
                    maxWidth: '200px',
                    marginBottom: '20px',
                  }}
                >
                  {documentState.Consumer_Phone_Number}
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <p
                  style={{
                    lineHeight: 1,
                    fontSize: '14px',
                    fontWeight: 500,
                    marginBottom: '20px',
                  }}
                >
                  Correo del Consumidor o Representante Autorizado:
                </p>
              </td>

              <td>
                {/* <input
                name="Consumer_Email"
                value={documentState.Consumer_Email}
                onChange={handleChange}
                type="text"
                style={{
                  minWidth: '200px',
                  border: 'none',
                  maxwidth: '600px',
                  marginBottom: '20px',
                  borderBottom: '1px solid #555',
                }}
              /> */}

                <div
                  style={{
                    minWidth: '200px',
                    border: 'none',
                    width: '100%',
                    borderBottom: '1px solid #555',
                    minHeight: '16px',
                    maxWidth: '200px',
                    marginBottom: '20px',
                  }}
                >
                  {documentState.Consumer_Email}
                </div>
              </td>
            </tr>

            <tr>
              <td>
                <p
                  style={{
                    lineHeight: 1,
                    fontSize: '14px',
                    fontWeight: 500,
                    marginBottom: '20px',
                  }}
                >
                  Date / Time:
                </p>
              </td>

              <td>
                {/* <input
                name="DATE"
                value={documentState.DATE}
                onChange={handleChange}
                type="text"
                style={{
                  minWidth: '200px',
                  border: 'none',
                  maxwidth: '600px',
                  marginBottom: '20px',
                  borderBottom: '1px solid #555',
                }}
              /> */}

                <div
                  style={{
                    minWidth: '200px',
                    border: 'none',
                    width: '100%',
                    borderBottom: '1px solid #555',
                    minHeight: '16px',
                    maxWidth: '200px',
                    marginBottom: '20px',
                  }}
                >
                  {`${documentState.DATE}`}
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <p
                  style={{
                    lineHeight: 1,
                    fontSize: '14px',
                    fontWeight: 500,
                    marginBottom: '20px',
                  }}
                >
                  Firma del Consumidor o Representante Autorizado:
                </p>
              </td>

              <td>
                {!documentState?.SIGNATURE ? (
                  <div
                    onClick={() => {
                      if (readMode) return;
                      setSignatureModal(true);
                    }}
                    style={{
                      minWidth: '200px',
                      border: 'none',
                      height: '80px',
                      width: '100%',
                      padding: '5px',
                      cursor: 'pointer',
                      border: '1px dashed #ddd',
                    }}
                  >
                    + Add Signature
                  </div>
                ) : (
                  <div
                    onClick={() => {
                      if (readMode) return;
                      setSignatureModal(true);
                    }}
                    style={{
                      minWidth: '200px',
                      border: 'none',
                      height: '80px',
                      width: '100%',
                      padding: '5px',
                      cursor: 'pointer',
                      border: '1px dashed #ddd',
                    }}
                  >
                    <img
                      alt="signature"
                      src={documentState?.SIGNATURE}
                      style={{ maxHeight: '70px' }}
                    />
                  </div>
                )}
              </td>
            </tr>
          </table>
        </div>

        {signatureModal ? (
          <Modal
            handleClose={() => setSignatureModal(false)}
            title={'Signature'}
            headerComponent={null}
            shouldCloseOnClickOutside={true}
            footerComponent={null}
            height="300"
            size="700"
          >
            <SignModule handleChange={handleChange} />
          </Modal>
        ) : null}
      </>
    );
  },
);

export default ER_ES;
