import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import FormInput from '../../../../../components/FormInput';
import PhoneInput from 'react-phone-number-input/react-hook-form';
import { City, State } from 'country-state-city';
import FormSelect from '../../../../../components/FormSelect';
import RadioBoxInput from '../../../../../components/RadioBoxInput';
import { UserFill } from '../../../../../assets/icons/useIcons';
import Button from '../../../../../components/Button';
import useUploadFile from '../../../../../hooks/apiHooks';
import { useAuth } from '../../../../../hooks/useAuth';
import ImagePreview from '../../../../../components/ImagePreview';

const statesList = State.getStatesOfCountry('US')?.map((state) => ({
  value: state.isoCode,
  label: state.name,
}));

const OwnAgency = ({
  control,
  errors,
  setValue,
  watch,
  handleSubmit,
  onSubmit,
}) => {
  const { uploadMedia, isLoading: uploadMediaLoading } = useUploadFile();
  const [file, setFile] = useState('');
  const { user } = useAuth();

  const [cityList, setCityList] = useState([]);
  let [watchOwnAgency, watchAgencyType, watchState] = watch([
    'isOwnAgency',
    'ownAgencyType',
    'ownState',
  ]);

  useEffect(() => {
    if (watchState) {
      const cities = City.getCitiesOfState('US', watchState).map((city) => ({
        value: city.name,
        label: city.name,
      }));
      setCityList(cities);
    }
  }, [watchState]);

  const imgUrl = `${process.env.REACT_APP_BASE_API_URL}/media/${user?._id}/profile/${user?.ownAgencyDetail?.agencyLogo}`;

  return (
    <>
      <div className="w--full d--flex flex--column gap--xl justify-content--between align-items--center border-full--black-100 bg--white p--lg radius--sm  ">
        <div className="d--flex justify-content--between w--full flex--column-xs gap--sm">
          <h3 className="m-b--0 font--sm text--l font--500 w--full">
            Do you own an agency.
          </h3>
          <div className=" d--flex justify-content--center align-items--center gap--md">
            <Controller
              name="isOwnAgency"
              control={control}
              render={({ field }) => (
                <RadioBoxInput
                  {...field}
                  htmlFor="isOwnAgency_n"
                  label="No"
                  value="N"
                  checked={watchOwnAgency === 'N'}
                />
              )}
            />
            <Controller
              name="isOwnAgency"
              control={control}
              render={({ field }) => (
                <RadioBoxInput
                  {...field}
                  htmlFor="isOwnAgency_y"
                  label="Yes"
                  value="Y"
                  checked={watchOwnAgency === 'Y'}
                />
              )}
            />
          </div>
        </div>

        {watchOwnAgency === 'Y' ? (
          <>
            <div className="w--full d--flex align-items--center gap--lg font--sm font--500 flex--column-xs">
              I am
              <label
                id="regular"
                className="label--control font--sm font--500    text--grey d--flex align-items--center c--pointer"
              >
                <input
                  htmlFor="regular"
                  type="radio"
                  onClick={() => {
                    setValue('ownAgencyType', 'REGULAR');
                    setValue('ownAgencyNPN', '', {
                      // shouldValidate: true,
                    });
                  }}
                  checked={watchAgencyType === 'REGULAR'}
                />
                Regular Agency
              </label>
              <label className="label--control font--sm font--500    text--grey d--flex align-items--center c--pointer">
                <input
                  type="radio"
                  onClick={() => {
                    setValue('ownAgencyType', 'FMO');
                    setValue('ownAgencyNPN', '', {
                      // shouldValidate: true,
                    });
                  }}
                  checked={watchAgencyType === 'FMO'}
                />
                FMO
              </label>
            </div>
            {/* <div className="d--flex gap--lg w--full w-max--500 flex--column-xs">
              <Button
                type="button"
                btnClasses="btn"
                color="black"
                borderColor="primary"
                isOutline
                variant={
                  watchAgencyType === 'REGULAR' ? 'primary' : 'primary-100'
                }
                // isOutline={watchAgencyType === 'FMO'}
                onClick={() => {
                  setValue('ownAgencyType', 'REGULAR');
                  setValue('ownAgencyNPN', '', {
                    // shouldValidate: true,
                  });
                }}
              >
                Regular Agency
              </Button>
              <Button
                type="button"
                btnClasses="btn"
                variant={watchAgencyType === 'FMO' ? 'primary' : 'primary-100'}
                color="black"
                borderColor="primary"
                isOutline
                onClick={() => {
                  setValue('ownAgencyType', 'FMO');
                  setValue('ownAgencyNPN', '', {
                    // shouldValidate: true,
                  });
                }}
              >
                FMO
              </Button>
            </div> */}

            {/* <div className="w--full d--flex gap--lg"> */}
            <div className="d--flex gap--md w--full flex--column-xs">
              <div className="w--full">
                <Controller
                  name="ownAgencyName"
                  control={control}
                  render={({ field }) => (
                    <FormInput
                      {...field}
                      label="Agency Name "
                      error={errors?.ownAgencyName?.message}
                      height="40"
                    />
                  )}
                />
              </div>
              <div className="w--full">
                <Controller
                  name="ownAgencyEmail"
                  control={control}
                  render={({ field }) => (
                    <FormInput
                      {...field}
                      label="Agency Email "
                      error={errors?.ownAgencyEmail?.message}
                      height="40"
                    />
                  )}
                />
              </div>
            </div>
            <div className="d--flex gap--md w--full flex--column-xs">
              <div className="w--full">
                <label
                  className={`label--control font--sm font--500 m-b--sm d--inline-block ${
                    errors?.ownAgencyPhone?.message
                      ? 'text--danger'
                      : 'text--grey'
                  }`}
                >
                  {errors?.ownAgencyPhone?.message ?? 'Agency Phone'}{' '}
                  <span className=" font--sm text--danger ">*</span>
                </label>
                <PhoneInput
                  name="ownAgencyPhone"
                  control={control}
                  defaultCountry={'US'}
                  international={true}
                  withCountryCallingCode={true}
                  placeholder="Enter your phone"
                  internationalIcon={() => (
                    <span>
                      <i className="fa fa-phone icon-circle bg-danger"></i>
                    </span>
                  )}
                  limitMaxLength={true}
                  className={`form--control w--full h-min--36  radius--sm p-l--md p-r--md  d--flex align-items--center ${
                    errors?.ownAgencyPhone?.message
                      ? 'border-full--danger'
                      : 'border-full--black-200'
                  }`}
                />
              </div>
              <div className="w--full">
                <Controller
                  name="ownAgencyNPN"
                  control={control}
                  render={({ field }) => (
                    <FormInput
                      type="number"
                      {...field}
                      label="Agency NPN"
                      error={errors?.ownAgencyNPN?.message}
                      height="40"
                      required={watchAgencyType === 'FMO'}
                    />
                  )}
                />
              </div>
            </div>
            {/* </div> */}

            <div className="w--full d--flex gap--md flex--column-xs">
              <div className="w--full">
                <Controller
                  name="ownAgencyEIN"
                  control={control}
                  render={({ field }) => (
                    <FormInput
                      {...field}
                      label="Agency EIN"
                      type="number"
                      error={errors?.ownAgencyEIN?.message}
                      height="40"
                    />
                  )}
                />
              </div>

              <div className="w--full">
                <Controller
                  name="ownAgencyWebsite"
                  control={control}
                  render={({ field }) => (
                    <FormInput
                      {...field}
                      label="Agency Website"
                      height="40"
                      required={false}
                      error={errors?.ownAgencyWebsite?.message}
                    />
                  )}
                />
              </div>
            </div>

            <div className="d--flex flex--column gap--lg w--full">
              <div className="font--sm text-black--600">Agency Address</div>
              <div className="w--full d--flex gap--md flex--column-xs">
                <div className="w--full">
                  <Controller
                    name="ownAddressOne"
                    control={control}
                    render={({ field }) => (
                      <FormInput
                        {...field}
                        label="Address 1"
                        error={errors?.ownAddressOne?.message}
                        height="40"
                      />
                    )}
                  />
                </div>
                <div className="w--full">
                  <Controller
                    name="ownAddressTwo"
                    control={control}
                    render={({ field }) => (
                      <FormInput
                        {...field}
                        label="Address 2"
                        error={errors?.ownAddressTwo?.message}
                        height="40"
                        required={false}
                      />
                    )}
                  />
                </div>
              </div>{' '}
              <div className="w--full d--flex gap--md flex--column-xs">
                <div className="w--full">
                  <Controller
                    name="ownState"
                    control={control}
                    rules={{
                      onChange() {
                        setValue('ownCity', '');
                      },
                    }}
                    render={({ field }) => (
                      <FormSelect
                        {...field}
                        label="State "
                        error={errors?.ownState?.message}
                        height="40"
                        options={statesList}
                        placeholder="Select State"
                      />
                    )}
                  />
                </div>
                <div className="w--full">
                  <Controller
                    name="ownCity"
                    control={control}
                    render={({ field }) => (
                      <FormSelect
                        {...field}
                        label="City "
                        error={errors?.ownCity?.message}
                        height="40"
                        options={cityList}
                        placeholder="Select City"
                      />
                    )}
                  />
                </div>
              </div>
              <div className="w--full d--flex gap--md">
                <div className="w--full">
                  <Controller
                    name="ownZipCode"
                    control={control}
                    render={({ field }) => (
                      <FormInput
                        {...field}
                        label="Zip Code"
                        error={errors?.ownZipCode?.message}
                        height="40"
                      />
                    )}
                  />
                </div>
                <div className="d--flex gap--sm align-items--center w--full">
                  <label
                    htmlFor="upload_img"
                    className="w-min--75 h-min--75 w-max--75 h-max--75 radius--full bg--black-25 p--sm d--flex align-items--center justify-content--center c--pointer"
                  >
                    <input
                      type="file"
                      accept="image/png,image/jpg"
                      hidden
                      onChange={(e) => {
                        setFile(e.target.files[0]);
                      }}
                      id="upload_img"
                    />
                    <div className="w-min--60 h-min--60 w-max--60 h-max--60 radius--full bg--black-50 p--sm d--flex align-items--center justify-content--center">
                      {user?.ownAgencyDetail?.agencyLogo ? (
                        file ? (
                          <img
                            src={URL.createObjectURL(file)}
                            alt="logo"
                            className="w--full h--full object-cover"
                            width={40}
                          />
                        ) : (
                          <ImagePreview
                            mediaPath={`${process.env.REACT_APP_BASE_API_URL}/media/${user?._id}/profile/${user?.ownAgencyDetail?.agencyLogo}`}
                            alt="logo"
                            className="w--full h--full object-cover"
                            width={40}
                          />
                        )
                      ) : (
                        <UserFill width={30} height={30} />
                      )}
                    </div>
                  </label>
                  <div className="d--flex flex--column gap--xs ">
                    <Button
                      btnClasses="btn  w-max--140 font--2sm"
                      variant="primary-200"
                      color="black"
                      disabled={uploadMediaLoading || !file}
                      type="button"
                      size="sm"
                      borderColor="primary"
                      isOutline
                      rounded
                      onClick={async () => {
                        const res = await uploadMedia({
                          file,
                          type: 'profile',
                        });
                        setValue('agencyLogo', res.fileName);

                        handleSubmit(onSubmit)();
                      }}
                    >
                      {uploadMediaLoading ? 'Please wait...' : 'Upload Photo'}
                    </Button>
                    {/* <div className="font--xs text--black-600">
                      PNG or JPG max size of 5MB
                    </div> */}
                  </div>
                </div>

                {/* <div className="d--flex flex--column   w--full">
                  <label className="label--control font--sm font--500 m-b--sm   text--grey d--flex align-items--center">
                    Upload Logo{' '}
                  </label>
                  <label
                    for="file-upload"
                    className="w--full border-full--black-200 radius--sm p--xs d--flex align-items--center gap--sm c--pointer"
                  >
                    <div className="w-min--32 h-min--32 w-max--32 h-max--32 radius--full bg--black-25 p--sm d--flex align-items--center justify-content--center">
                      <div className="w-min--36 h-min--36 w-max--36 h-max--36 radius--full bg--black-50 p--sm d--flex align-items--center justify-content--center">
                 
                        <label
                          for="file-upload"
                          className="d--flex w-min--36 h-min--36 d--flex justify-content--center align-items--center c--pointer"
                        >
                          <img
                            src={Icloud}
                            alt="LogoImg"
                            className="w--full h-max--20 w-max--20"
                          />
                          <input
                            id="file-upload"
                            type="file"
                            className=""
                            hidden
                          />
                        </label>
                      </div>
                    </div>
                    <div className="font--sm text--black-600">File name</div>
                  </label>
                  <div className="font--xs text--black-600 m-t--sm ">
                    ( PNG or JPG max size of 5MB)
                  </div>
                </div> */}
              </div>
            </div>
          </>
        ) : null}
      </div>
    </>
  );
};

export default OwnAgency;
