import FormInput from '../../components/FormInput';
import FormSelect from '../../components/FormSelect';
import TableManager from '../../components/TableManager';
import { getAgentKpis } from './actions';

const AgentKpi = () => {
  const columns = [
    {
      accessorKey: 'agentDetail',
      header: () => 'Agent Name',
      cell: ({ getValue }) =>
        `${getValue()?.firstName} ${getValue()?.lastName}`,
    },
    {
      accessorKey: 'agentDetail.phone',
      header: () => 'Agent Phone',
    },
    {
      accessorKey: 'agentDetail.email',
      header: () => 'Agent Email',
    },
    {
      accessorKey: 'agentDetail.NPN',
      header: () => 'Agent NPN',
    },
    {
      accessorKey: 'consentCount',
      header: () => 'Consent',
      cell: ({ getValue }) => {
        return (
          <div className=" bg--primary-200 text--black radius--full w-max--32 h-min--32 d--flex align-items--center justify-content--center  m--auto  border-full--primary">
            {getValue()}
          </div>
        );
      },
      meta: {
        textAlign: 'center',
      },
    },
    {
      accessorKey: 'eligibilityCount',
      header: () => 'Eligibility',
      cell: ({ getValue }) => {
        return (
          <div className=" bg--primary-200 text--black radius--full w-max--32 h-min--32 d--flex align-items--center justify-content--center  m--auto  border-full--primary">
            {getValue()}
          </div>
        );
      },
      meta: {
        textAlign: 'center',
      },
    },
  ];

  const formActions = [
    {
      id: 1,
      access: true,
      component: <FormInput placeholder="Agent NPN" />,
    },
    {
      id: 2,
      access: true,
      component: (
        <FormSelect
          // placeholder=" Year"
          options={[
            // {
            //   label: '2022',
            //   value: '2022',
            // },
            // {
            //   label: '2023',
            //   value: '2023',
            // },
            {
              label: '2024',
              value: '2024',
            },
            // {
            //   label: '2025',
            //   value: '2025',
            // },
            // {
            //   label: '2026',
            //   value: '2026',
            // },
          ]}
          height="40"
        />
      ),
    },
  ];

  return (
    <>
      <div className="w--full d--flex flex--column  invitationPage">
        <TableManager
          {...{
            fetcherKey: 'getAgentKpis',
            fetcherFn: getAgentKpis,
            shouldFilter: false,
            columns,
            formActions,
          }}
        />
      </div>
    </>
  );
};

export default AgentKpi;
