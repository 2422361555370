import React from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { agentAgencyConfigurationTabs } from '../constants';

const AgencyAgentConfiguration = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <>
      <div className="d--flex gap--lg profilePageTabe  border-bottom--black-100 m-b--md overflow--auto">
        {agentAgencyConfigurationTabs.map(({ path, label }) => {
          return (
            <span
              className={`p-l--md p-r--md p-b--md c--pointer font--sm font--600 white-space--nowrap  ${
                pathname.split('/').pop() === path
                  ? 'border-bottom--black text--black'
                  : 'text--black-600'
              }`}
              onClick={() => {
                navigate(`/configuration/${path}`);
              }}
            >
              {label}
            </span>
          );
        })}
      </div>
      <div className="d--flex gap--md flex--column profilePage  bg--white radius--sm ">
        <Outlet />
      </div>
    </>
  );
};

export default AgencyAgentConfiguration;
