import React from 'react';

import { AGENCY_USER, AGENT, CARRIER } from '../../helpers/constants';
import AgentClaim from './View/AgentClaims';
import CarrierClaim from './View/CarrierClaim';
import AccessControl from '../Dashboard/AccessControl';

const Claims = () => {
  return (
    <>
      <AccessControl allowedRoles={[AGENCY_USER, AGENT]}>
        <AgentClaim />
      </AccessControl>
      <AccessControl allowedRoles={[CARRIER]}>
        <CarrierClaim />
      </AccessControl>
    </>
  );
};

export default Claims;
