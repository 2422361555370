import { Outlet } from 'react-router-dom';
import AlertContainer from '../components/AlertContainer';

const DocumentLayout = () => {
  return (
    <div
      className="d--flex gap--sm w--full h--full"
      style={{ background: '#f1f1f1' }}
    >
      <Outlet />
      <div className="main-alert position--absolute d--flex flex--column gap--sm">
        <AlertContainer />
      </div>
    </div>
  );
};

export default DocumentLayout;
