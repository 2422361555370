import React from 'react';
import CanvasJSReact from '@canvasjs/react-charts';
import { useAuth } from '../../hooks/useAuth';
import { AGENCY_USER, AGENT, CARRIER } from '../../helpers/constants';
import { useSelector } from 'react-redux';
const CanvasJSChart = CanvasJSReact.CanvasJSChart;

const dataPoints = [
  { label: 'Jan', totalConsumer: 0, agents: 0, agencies: 0 },
  { label: 'Feb', totalConsumer: 0, agents: 0, agencies: 0 },
  { label: 'Mar', totalConsumer: 0, agents: 0, agencies: 0 },
  { label: 'Apr', totalConsumer: 0, agents: 0, agencies: 0 },
  { label: 'May', totalConsumer: 0, agents: 0, agencies: 0 },
  { label: 'Jun', totalConsumer: 0, agents: 0, agencies: 0 },
  { label: 'Jul', totalConsumer: 0, agents: 0, agencies: 0 },
  { label: 'Aug', totalConsumer: 0, agents: 0, agencies: 0 },
  { label: 'Sep', totalConsumer: 0, agents: 0, agencies: 0 },
  { label: 'Oct', totalConsumer: 0, agents: 0, agencies: 0 },
  { label: 'Nov', totalConsumer: 0, agents: 0, agencies: 0 },
  { label: 'Dec', totalConsumer: 0, agents: 0, agencies: 0 },
];

const CustomChart = ({ graphData }) => {
  const { theme } = useSelector((state) => state?.theme);
  let isDark = theme === 'black';

  const { user } = useAuth();
  const options = {
    animationEnabled: true,
    // title: {
    //   text: graphData?.title ?? 'Yearly Billing Summary ',
    //   fontSize: 35,
    //   fontWeight: 'semibold',
    // },
    toolTip: {
      shared: true,
    },
    axisX: {
      lineColor: isDark ? '#c7cbd3' : '#28303f',
      labelFontColor: isDark ? '#c7cbd3' : '#28303f',
      labelFontSize: 12,
    },
    axisY: {
      lineThickness: 0,
      gridColor: 'transparent',
      gridThickness: 0,
      tickLength: 0,
      labelFormatter: () => '',
    },
    legend: {
      horizontalAlign: 'left',
      verticalAlign: 'top',
      fontColor: isDark ? '#c7cbd3' : '#28303f',
    },
    backgroundColor: isDark ? '#161a23' : '#ffffff',

    data: [
      [AGENCY_USER].includes(user?.role)
        ? {
            type: 'column',
            name: 'Agent ',
            showInLegend: true,
            color: '#344054',
            dataPoints: (graphData?.data ?? dataPoints).map((point) => ({
              label: point.label,
              y: parseFloat(point.agents),
            })),
          }
        : {},
      [AGENCY_USER, AGENT].includes(user?.role)
        ? {
            type: 'column',
            name: 'Consumer',
            showInLegend: true,
            color: '#15EDE7',
            dataPoints: (graphData?.data ?? dataPoints).map((point) => ({
              label: point.label,
              y: parseFloat(point.totalConsumer),
            })),
          }
        : {},

      [CARRIER].includes(user?.role)
        ? {
            type: 'column',
            name: 'Total Claims ',
            showInLegend: true,
            dataPoints: (graphData?.data ?? dataPoints).map((point) => ({
              label: point.label,
              y: parseFloat(point.totalClaim),
            })),
            fontColor: '#fff',
            color: '#00a8ff',
          }
        : {},
      [CARRIER].includes(user?.role)
        ? {
            type: 'column',
            name: 'New',
            showInLegend: true,
            dataPoints: (graphData?.data ?? dataPoints).map((point) => ({
              label: point.label,
              y: parseFloat(point.totalNewClaim),
            })),
            fontColor: '#fff',
            color: '#f06c15',
          }
        : {},
      [CARRIER].includes(user?.role)
        ? {
            type: 'column',
            name: 'In Progress  ',
            showInLegend: true,
            dataPoints: (graphData?.data ?? dataPoints).map((point) => ({
              label: point.label,
              y: parseFloat(point.totalInprogressClaim),
            })),
            fontColor: '#fff',
            color: '#d8c603',
          }
        : {},
      [CARRIER].includes(user?.role)
        ? {
            type: 'column',
            name: 'On Hold  ',
            showInLegend: true,
            dataPoints: (graphData?.data ?? dataPoints).map((point) => ({
              label: point.label,
              y: parseFloat(point.totalOn_holdClaim),
            })),
            fontColor: '#fff',
            color: '#dc362e',
          }
        : {},
      [CARRIER].includes(user?.role)
        ? {
            type: 'column',
            name: 'Complete  ',
            showInLegend: true,
            dataPoints: (graphData?.data ?? dataPoints).map((point) => ({
              label: point.label,
              y: parseFloat(point.totalCompletedClaim),
            })),
            fontColor: '#fff',
            color: '#1a9112',
          }
        : {},
    ],
  };

  return <CanvasJSChart options={options} />;
};

export default CustomChart;
