import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import Modal from '../../../../Modal';
import SignModule from '../../../../SignModule';
import signature from '../../../../../assets/images/signature.png';
import moment from 'moment';

const initialDocData = {
  Agency_Name: '',
  SUBMITTED_DATE: '',
  TYPE: '',
  DATE: '',
  SIGNATURE: '',
};

const HRA_AGREEMENT_EN = forwardRef(
  ({ readMode = false, documentData = null, preFillData = null }, ref) => {
    const [signatureModal, setSignatureModal] = useState(false);
    const [documentState, setDocumentState] = useState(initialDocData);
    const { getHraData } = documentData || {};

    useEffect(() => {
      if (preFillData) {
        Object.entries(preFillData).map(([key, value]) => {
          setDocumentState((prev) => ({
            ...prev,
            [key]: value,
          }));
        });
      }
    }, [preFillData]);

    useEffect(() => {
      if (documentData) {
        setDocumentState((prevState) => {
          prevState['Agency_Name'] = getHraData?.agentDetail?.fullName ?? '';
          prevState['SUBMITTED_DATE'] =
            getHraData?.agentDetail?.docsSubmittedDate ?? '';
          prevState['TYPE'] = getHraData?.agentDetail?.type;
          prevState['DATE'] = new Date();

          return { ...prevState };
        });
      }
    }, [documentData]);

    useImperativeHandle(
      ref,
      () => ({
        getFormValues() {
          return documentState;
        },
        getFormTemplate() {
          return document.getElementById('custom-signature-form-conxent')
            ?.outerHTML;
        },
      }),
      [documentState, documentData],
    );

    function handleChange(e, signatureValue = null) {
      if (signatureValue) {
        setDocumentState((prevState) => ({
          ...prevState,
          SIGNATURE: signatureValue,
        }));
        setSignatureModal(false);
      } else {
        const name = e.target.name;
        const value = e.target.value;
        setDocumentState((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      }
    }

    return (
      <>
        <div
          id="custom-signature-form-conxent"
          style={{
            backgroundColor: ' #fff',
            width: '100%',
            margin: '10px auto',
            padding: '10px 20px',
            maxWidth: '794px',
          }}
          className="pdfForm"
        >
          <table
            cellSpacing="0"
            style={{
              width: '100%',
              padding: '15px 0',
              borderCollapse: 'collapse',
              borderSpacing: '0',
            }}
          >
            <tr>
              <td colSpan="2">
                <h2
                  style={{
                    marginTop: '0',
                    textAlign: 'center',
                    fontSize: '16px',
                    marginBottom: '10px',
                    lineHeight: '1.5',
                  }}
                >
                  HEALTH RISK ASSESSMENT SERVICES AGREEMENT
                </h2>
              </td>
            </tr>

            <tr>
              <td colSpan="2">
                <div
                  style={{
                    width: '100%',
                    display: 'inline-block',
                    marginTop: '8px',
                    lineHeight: '2',
                  }}
                >
                  <div style={{ float: 'left' }}>
                    This Health Risk Assessment Services Agreement (the
                    "Agreement") is made and entered into as of
                  </div>

                  <span
                    style={{
                      // float: 'left',
                      maxWidth: '290px',
                      display: 'inline-block',
                    }}
                  >
                    <div
                      style={{
                        minWidth: '200px',
                        border: 'none',
                        width: '100%',
                        borderBottom: '1px solid #555',
                        minHeight: '16px',
                      }}
                    >
                      {moment(documentState.SUBMITTED_DATE).format(
                        'MMMM Do YYYY, h:mm A',
                      )}
                    </div>
                  </span>

                  <span
                    style={{
                      // float: 'left',
                      marginLeft: ' 10px',
                      marginRight: ' 10px',
                    }}
                  >
                    (the "Effective Date"), by and between Nextere, LLC, a
                    Florida limited liability company (“Nextere” or “Company”),
                    and
                  </span>

                  <div
                    style={{
                      // float: 'left',
                      maxWidth: '290px',
                      display: 'inline-block',
                    }}
                  >
                    <div
                      style={{
                        minWidth: '200px',
                        border: 'none',
                        width: '100%',
                        borderBottom: '1px solid #555',
                        minHeight: '16px',
                      }}
                    >
                      {documentState.Agency_Name}
                    </div>
                  </div>
                  <span
                    style={{
                      // float: 'left',
                      marginLeft: ' 10px',
                      marginRight: ' 10px',
                    }}
                  >
                    a licensed insurance agent/agency ("Agent"). The parties
                    hereto agree to be referred to collectively as the "Parties"
                    or individually as a "Party."
                  </span>
                </div>
              </td>
            </tr>

            <tr>
              <td colSpan="2">
                <h2
                  style={{
                    marginTop: '12px',
                    textAlign: 'center',
                    fontSize: '16px',
                    marginBottom: '10px',
                    lineHeight: '1.5',
                  }}
                >
                  Purpose
                </h2>
              </td>
            </tr>

            <tr>
              <td colSpan="2">
                <p style={{ lineHeight: '2', marginBottom: '12px' }}>
                  Company has developed and owns proprietary technology and
                  software platform(s) ("Platform"), which enables licensed
                  insurance agents, to include Agent, to provide services to
                  their customers, including facilitating a Health Risk
                  Assessment ("HRA") for such customers seeking or enrolled in
                  certain insurance plans. Agent desires to use the Platform to
                  provide services to their customers, specifically to assist
                  customers in completing the HRA and transmitting the
                  information to the customer's insurance carrier.
                </p>
              </td>
            </tr>

            <tr>
              <td colSpan="2">
                <h2
                  style={{
                    marginTop: '12px',
                    textAlign: 'center',
                    fontSize: '16px',
                    marginBottom: '10px',
                    lineHeight: '1.5',
                  }}
                >
                  Scope of Services
                </h2>
              </td>
            </tr>

            <tr>
              <td colSpan="2">
                <p style={{ lineHeight: '2', marginBottom: '12px' }}>
                  Agent agrees to use the Platform to conduct HRA’s for
                  customers of Agent, during which customers will answer
                  specific health and lifestyle-related questions, as dictated
                  and pursuant to the Center for Medicare and Medicaid Services
                  (CMS) and Center for Disease Control and Prevention (CDC)
                  guidelines (“Governmental Guidelines”). The data collected
                  during the HRA ("HRA Data") will be compiled and provided to
                  certain authorized third- parties, as agreed by Customer, to
                  include the Customer’s insurance carrier.
                </p>
              </td>
            </tr>
            <tr>
              <td colSpan="2">
                <p style={{ lineHeight: '2', marginBottom: '12px' }}>
                  Company will grant the Insurance Agent a non-exclusive,
                  non-transferable right to access and use the Platform solely
                  for the purpose of conducting HRA’s, and providing related
                  services to customers of the Insurance Agent, as outlined
                  herein and/or in any/all associated terms and conditions
                  documents promulgated by Nextere.
                </p>
              </td>
            </tr>
            <tr>
              <td colSpan="2">
                <p style={{ lineHeight: '2', marginBottom: '12px' }}>
                  At all times, Agent will ensure that all customers are
                  informed about the purpose of the HRA, the types of questions
                  being asked, and how the HRA Data will be used, as outlined by
                  Governmental Guidelines
                </p>
              </td>
            </tr>

            <tr>
              <td colSpan="2">
                <h2
                  style={{
                    marginTop: '12px',
                    textAlign: 'center',
                    fontSize: '16px',
                    marginBottom: '10px',
                    lineHeight: '1.5',
                  }}
                >
                  Confidentiality and Data Protection
                </h2>
              </td>
            </tr>
            <tr>
              <td colSpan="2">
                <p style={{ lineHeight: '2', marginBottom: '12px' }}>
                  Agent agrees to maintain the confidentiality of all
                  proprietary information provided by Nextere, including, but
                  not limited to, the Platform, HRA Data, and any other data or
                  materials that Nextere deems confidential, whether provided
                  directly by Nextere to Agent, or accessed or accessible by
                  Agent through the Platform or related Company outlets
                  ("Confidential Information").
                </p>
              </td>
            </tr>
            <tr>
              <td colSpan="2">
                <p style={{ lineHeight: '2', marginBottom: '12px' }}>
                  Agent acknowledges and agrees that the information provided by
                  customers through the HRA, including health-related
                  information, may constitute "Protected Health Information"
                  (PHI) under the Health Insurance Portability and
                  Accountability Act of 1996 ("HIPAA"). Agent agrees to comply
                  with all applicable HIPAA requirements in the handling,
                  storage, and transmission of PHI, as such regulations may be
                  amended. This includes but is not limited to implementing
                  appropriate safeguards to ensure the confidentiality,
                  integrity, and security of PHI upon being provided by
                  customer.
                </p>
              </td>
            </tr>
            <tr>
              <td colSpan="2">
                <p style={{ lineHeight: '2', marginBottom: '12px' }}>
                  Agent shall not disclose any customer’s personal, health, or
                  lifestyle-related information (including but not limited to
                  HRA Data) to any unauthorized third parties, except as
                  necessary to complete the transaction, the intention of the
                  HRA, or as required by law.
                </p>
              </td>
            </tr>
            <tr>
              <td colSpan="2">
                <p style={{ lineHeight: '2', marginBottom: '12px' }}>
                  Nextere retains all rights, title, and interest in and to the
                  Platform and any data generated by or through the Platform.
                  Agent may only use such data as necessary to provide the
                  services to customer under this Agreement.
                </p>
              </td>
            </tr>

            <tr>
              <td colSpan="2">
                <h2
                  style={{
                    marginTop: '12px',
                    textAlign: 'center',
                    fontSize: '16px',
                    marginBottom: '10px',
                    lineHeight: '1.5',
                  }}
                >
                  Prohibition Against Misrepresentation
                </h2>
              </td>
            </tr>

            <tr>
              <td colSpan="2">
                <p style={{ lineHeight: '2', marginBottom: '12px' }}>
                  Agent agrees and acknowledges that any falsifying,
                  misrepresenting, or manipulating of customer data, to include
                  but not be limited to HRA Data directly or indirectly related
                  to the HRA or Agent’s use of the Platform, is prohibited and
                  an uncurable event of default. This includes, but is not
                  limited to:
                </p>
                <li style={{ lineHeight: '2', paddingLeft: '45px' }}>
                  Providing inaccurate, false, or misleading data at any stage
                  of the process, whether through direct input or indirect
                  alterations; and
                </li>
                <li style={{ lineHeight: '2', paddingLeft: '45px' }}>
                  Omitting, altering, or concealing critical information that
                  may affect the outcome of the HRA or HRA Data or the quality
                  of services provided by Agent; and
                </li>
                <li style={{ lineHeight: '2', paddingLeft: '45px' }}>
                  Deliberately misrepresenting any customer response or
                  information, regardless of intending or not to manipulate the
                  HRA results or influence the HRA in any way.
                </li>
                <li style={{ lineHeight: '2', paddingLeft: '45px' }}>
                  Any act of misrepresentation, falsification, or manipulation
                  undermines the integrity of the HRA process and the Platform
                  and subjects the parties to default damages as a result.
                </li>
                <p style={{ lineHeight: '2', paddingLeft: '20px' }}>
                  Agent agrees that they shall at not time impersonate or assume
                  the identity of a customer under any circumstances, directly
                  or indirectly related to the HRA or services contemplated
                  hereunder. Specifically, Agent must:
                </p>
                <li style={{ lineHeight: '2', paddingLeft: '45px' }}>
                  Ensure that only the actual customer provides responses to the
                  HRA or any other related inquiry, and that the customer’s
                  responses are not altered, influenced, or fabricated by the
                  Agent or any third-party; and
                </li>
                <li style={{ lineHeight: '2', paddingLeft: '45px' }}>
                  Refrain from submitting responses or making decisions on
                  behalf of a customer without proper authorization, where
                  applicable.
                </li>
              </td>
            </tr>

            <tr>
              <td colSpan="2">
                <h2
                  style={{
                    marginTop: '12px',
                    textAlign: 'center',
                    fontSize: '16px',
                    marginBottom: '10px',
                    lineHeight: '1.5',
                  }}
                >
                  Verification Requirement
                </h2>
              </td>
            </tr>
            <tr>
              <td colSpan="2">
                <p style={{ lineHeight: '2', marginBottom: '12px' }}>
                  Agent is hereby required to verify the identity of each HRA
                  customer, as promulgated by applicable regulation(s), to
                  ensure the accurate attribution of all information, including
                  the HRA Data, provided within the Platform . This includes,
                  but is not limited to:
                </p>
                <li style={{ lineHeight: '2', paddingLeft: '45px' }}>
                  Following all prescribed identity verification protocols,
                  which may include requesting personal identifying information
                  (PII) or using other secure methods to confirm the consumer’s
                  identity prior to initiating or processing the HRA; and
                </li>
                <li style={{ lineHeight: '2', paddingLeft: '45px' }}>
                  Ensuring that no attempt is made to bypass, circumvent, or
                  manipulate the identity verification process, whether through
                  misrepresentation, omission, or falsification of
                  identification data; and
                </li>
                <li style={{ lineHeight: '2', paddingLeft: '45px' }}>
                  Cooperating with any audits, checks, or investigations related
                  to any identity verification processes, and maintaining a
                  commitment to transparency and accuracy at all times.
                </li>
              </td>
            </tr>

            <tr>
              <td colSpan="2">
                <h2
                  style={{
                    marginTop: '12px',
                    textAlign: 'center',
                    fontSize: '16px',
                    marginBottom: '10px',
                    lineHeight: '1.5',
                  }}
                >
                  Indemnification
                </h2>
              </td>
            </tr>
            <tr>
              <td colSpan="2">
                <p style={{ lineHeight: '2', marginBottom: '12px' }}>
                  Agent agrees to indemnify, defend, and hold harmless Nextere
                  and its affiliates, officers, employees, agents, parent
                  companies and/or subsidiaries, and contractors (collectively,
                  the "Indemnitees") from and against any and all claims,
                  damages, losses, liabilities, costs, and expenses (including
                  reasonable attorney’s fees) arising from or in connection
                  with:
                </p>
                <li style={{ lineHeight: '2', paddingLeft: '45px' }}>
                  Any actions or omissions of the Agent related to the provision
                  of services through the Platform, including but not limited to
                  conducting the HRA or transmitting HRA Data to the insurance
                  carrier or other authorized party.
                </li>
                <li style={{ lineHeight: '2', paddingLeft: '45px' }}>
                  Any breach of this Agreement, including but not limited to
                  violations of confidentiality obligations or HIPAA
                  requirements
                </li>
                <li style={{ lineHeight: '2', paddingLeft: '45px' }}>
                  Any claims or actions by customers arising from the handling
                  of their information or any failure to comply with applicable
                  privacy laws and regulations.
                </li>
              </td>
            </tr>

            <tr>
              <td colSpan="2">
                <h2
                  style={{
                    marginTop: '12px',
                    textAlign: 'center',
                    fontSize: '16px',
                    marginBottom: '10px',
                    lineHeight: '1.5',
                  }}
                >
                  License Grant and Restrictions
                </h2>
              </td>
            </tr>
            <tr>
              <td colSpan="2">
                <p style={{ lineHeight: '2', marginBottom: '12px' }}>
                  Subject to the terms of this Agreement and any applicable law,
                  rules, or regulation, Nextere grants Agent a limited,
                  non-exclusive, non-transferable license to use the Platform
                  solely for the purpose of facilitating HRA’s and related
                  services to customers. Agent may not sublicense, distribute,
                  assign, or make the Platform available to any unauthorized
                  third- party without the express consent of Nextere and the
                  customer.
                </p>
                <p style={{ lineHeight: '2', marginBottom: '12px' }}>
                  Specifically, the Agent shall not:
                </p>
                <li style={{ lineHeight: '2', paddingLeft: '45px' }}>
                  Use the Platform for any unlawful purpose or in any manner
                  inconsistent with this Agreement, or applicable rules,
                  regulations, law, or Governmental Guidelines.
                </li>
                <li style={{ lineHeight: '2', paddingLeft: '45px' }}>
                  Copy, modify, reverse engineer, disassemble, or decompile the
                  Platform, or otherwise attempt to derive its source code.
                </li>
              </td>
            </tr>

            <tr>
              <td colSpan="2">
                <h2
                  style={{
                    marginTop: '12px',
                    textAlign: 'center',
                    fontSize: '16px',
                    marginBottom: '10px',
                    lineHeight: '1.5',
                  }}
                >
                  Term and Termination
                </h2>
              </td>
            </tr>
            <tr>
              <td colSpan="2">
                <p style={{ lineHeight: '2', marginBottom: '12px' }}>
                  This Agreement shall commence on the Effective Date and shall
                  continue until terminated by either Party as provided herein,
                  or until the services Agent provides to the customer(s) has
                  ceased.
                </p>
                <p style={{ lineHeight: '2', marginBottom: '12px' }}>
                  Either Party may terminate this Agreement at any time by
                  providing three (3) days written notice to the other Party,
                  prior to the Agent facilitating the HRA to customer. At such
                  time and thereafter, all terms of this Agreement shall be in
                  force and those contemplated to survive the termination or
                  expiration hereof, such as the confidentiality and
                  indemnification obligations of Agent, shall survive same.
                </p>
                <p style={{ lineHeight: '2', marginBottom: '12px' }}>
                  Either Party may terminate this Agreement immediately if the
                  other Party breaches any material term of this Agreement and
                  fails to cure such breach within three (3) days after receipt
                  of written notice of the breach.
                </p>
                <p style={{ lineHeight: '2', marginBottom: '12px' }}>
                  Upon termination of this Agreement, Agent shall immediately
                  cease using the Platform and return or destroy all
                  Confidential Information and customer data obtained through
                  the Platform.
                </p>
              </td>
            </tr>

            <tr>
              <td colSpan="2">
                <h2
                  style={{
                    marginTop: '12px',
                    textAlign: 'center',
                    fontSize: '16px',
                    marginBottom: '10px',
                    lineHeight: '1.5',
                  }}
                >
                  Compliance with Laws
                </h2>
              </td>
            </tr>
            <tr>
              <td colSpan="2">
                <p style={{ lineHeight: '2', marginBottom: '12px' }}>
                  Agent agrees to comply with all applicable laws, rules, and
                  regulations, including but not limited to:
                </p>

                <li style={{ lineHeight: '2', paddingLeft: '45px' }}>
                  The Health Insurance Portability and Accountability Act
                  (HIPAA) with respect to the handling of PHI.
                </li>
                <li style={{ lineHeight: '2', paddingLeft: '45px' }}>
                  Applicable data privacy and security laws and regulations,
                  including but not limited to the General Data Protection
                  Regulation (GDPR) and any state-level data protection laws.
                </li>
                <li style={{ lineHeight: '2', paddingLeft: '45px' }}>
                  Any/all laws, rules, and applicable regulations in the state
                  in which they are licensed to engage in insurance sales
                  services, and those in the state in which such services are
                  provided, and/or those in which the Agent’s customer resides.
                </li>
              </td>
            </tr>

            <tr>
              <td colSpan="2">
                <h2
                  style={{
                    marginTop: '12px',
                    textAlign: 'center',
                    fontSize: '16px',
                    marginBottom: '10px',
                    lineHeight: '1.5',
                  }}
                >
                  Default and Breach Provisions
                </h2>
              </td>
            </tr>
            <tr>
              <td colSpan="2">
                <p style={{ lineHeight: '2', marginBottom: '12px' }}>
                  In the event Agent fails to comply with any of the terms and
                  obligations set forth in this Agreement, such failure shall
                  constitute a Default. A Default may occur as a result of, but
                  is not limited to:
                </p>

                <li style={{ lineHeight: '2', paddingLeft: '45px' }}>
                  Misrepresentation or falsification of customer information,
                  including any HRA Data; and
                </li>
                <li style={{ lineHeight: '2', paddingLeft: '45px' }}>
                  Impersonation of a customer or providing responses on behalf
                  of a customer in violation of established protocols; and
                </li>
                <li style={{ lineHeight: '2', paddingLeft: '45px' }}>
                  Failure to follow identity verification procedures or any
                  attempt to bypass, manipulate, or undermine the verification
                  process; and
                </li>
                <li style={{ lineHeight: '2', paddingLeft: '45px' }}>
                  Violation of any applicable laws, regulations, or company
                  policies concerning the protection of customer information,
                  data privacy, or other related obligations.
                </li>
              </td>
            </tr>

            <tr>
              <td colSpan="2">
                <h2
                  style={{
                    marginTop: '12px',
                    textAlign: 'center',
                    fontSize: '16px',
                    marginBottom: '10px',
                    lineHeight: '1.5',
                  }}
                >
                  Breach
                </h2>
              </td>
            </tr>
            <tr>
              <td colSpan="2">
                <p style={{ lineHeight: '2', marginBottom: '12px' }}>
                  A Breach of this Agreement occurs when an Agent engages in
                  conduct or action that:
                </p>

                <li style={{ lineHeight: '2', paddingLeft: '45px' }}>
                  Directly or indirectly causes harm to the integrity of the HRA
                  system, the Platform, Nextere, the customer or customer’s
                  privacy, or the Company's legal or regulatory obligations; and
                </li>
                <li style={{ lineHeight: '2', paddingLeft: '45px' }}>
                  Compromises the accuracy or completeness of the HRA or HRA
                  Data or in any way distorts the results of any HRA; and
                </li>
                <li style={{ lineHeight: '2', paddingLeft: '45px' }}>
                  Violates the Agent’s duty to maintain transparency, honesty,
                  and full compliance with all operational, regulatory, and
                  legal standards associated with their duties and licensure.
                </li>
                <p style={{ lineHeight: '2', marginBottom: '12px' }}>
                  Upon discovery of a Breach, the company shall have the right
                  to:
                </p>

                <li style={{ lineHeight: '2', paddingLeft: '45px' }}>
                  Suspend or terminate access to the Platform or any other
                  Company system until such time as any Company investigation is
                  concluded and corrective measures are implemented; and
                </li>
                <li style={{ lineHeight: '2', paddingLeft: '45px' }}>
                  Take corrective or disciplinary action as necessary, including
                  but not limited to issuing warnings, requiring additional
                  training, suspension of duties, or termination of this
                  Agreement or any agreement to which Company of its affiliates
                  have with Agent.
                </li>
              </td>
            </tr>

            <tr>
              <td colSpan="2">
                <h2
                  style={{
                    marginTop: '12px',
                    textAlign: 'center',
                    fontSize: '16px',
                    marginBottom: '10px',
                    lineHeight: '1.5',
                  }}
                >
                  Remedies
                </h2>
              </td>
            </tr>
            <tr>
              <td colSpan="2">
                <p style={{ lineHeight: '2', marginBottom: '12px' }}>
                  In the event of a Default or Breach, the Company shall be
                  entitled to pursue any and all remedies available under this
                  Agreement, at law, or in equity, including but not limited to
                  injunctive relief or legal action to recover damages arising
                  from the breach, including any loss of revenue, reputational
                  damage, or regulatory fines or penalties imposed as a result
                  of the Agent's actions/inactions. Additionally, Company shall
                  be entitled to notify the relevant regulatory bodies, law
                  enforcement agencies, or other authorities, if the Breach
                  involves illegal activity, customer harm, or a violation of
                  regulatory requirements.
                </p>
              </td>
            </tr>
            <tr>
              <td colSpan="2">
                <h2
                  style={{
                    marginTop: '12px',
                    textAlign: 'center',
                    fontSize: '16px',
                    marginBottom: '10px',
                    lineHeight: '1.5',
                  }}
                >
                  Limitation of Liability
                </h2>
              </td>
            </tr>
            <tr>
              <td colSpan="2">
                <p style={{ lineHeight: '2', marginBottom: '12px' }}>
                  In no event shall either Party be liable to the other for any
                  indirect, incidental, special, or consequential damages,
                  including but not limited to loss of profits, even if the
                  Party has been advised of the possibility of such damages.
                </p>
                <p style={{ lineHeight: '2', marginBottom: '12px' }}>
                  Nextere’s maximum liability to Agent under this Agreement
                  shall not exceed the total fees paid by the Insurance Agent to
                  Nextere in the twelve (12) months preceding the event giving
                  rise to the claim.
                </p>
              </td>
            </tr>
            <tr>
              <td colSpan="2">
                <h2
                  style={{
                    marginTop: '12px',
                    textAlign: 'center',
                    fontSize: '16px',
                    marginBottom: '10px',
                    lineHeight: '1.5',
                  }}
                >
                  Miscellaneous
                </h2>
              </td>
            </tr>
            <tr>
              <td colSpan="2">
                <p style={{ lineHeight: '2', marginBottom: '12px' }}>
                  This Agreement constitutes the entire agreement between the
                  Parties and supersedes all prior or contemporaneous
                  understandings, agreements, or representations, whether
                  written or oral, concerning the subject matter hereof.
                </p>
                <p style={{ lineHeight: '2', marginBottom: '12px' }}>
                  This Agreement shall be governed by and construed in
                  accordance with the laws of the State of Florida, without
                  regard to its conflicts of law principles. Venue for any
                  cause, case, or controversy shall be a court of competent
                  jurisdiction in Miami-Dade County, Florida.
                </p>
                <p style={{ lineHeight: '2', marginBottom: '20px' }}>
                  Any disputes arising out of or in connection with this
                  Agreement shall be resolved through binding arbitration in
                  Miami-Dade County, Florida, pursuant to the rules of the
                  American Arbitration Association.
                </p>
              </td>
            </tr>
            <tr>
              <td colSpan="2">
                <p style={{ lineHeight: '2', marginBottom: '20px' }}>
                  IN WITNESS WHEREOF, the Parties have executed this Agreement
                  as of the Effective Date as outlined above.
                </p>
              </td>
            </tr>
            <tr>
              <td colSpan="2">
                <p style={{ lineHeight: '2', marginBottom: '25px' }}>
                  Nextere, LLC
                </p>
              </td>
            </tr>

            <tr
              style={{
                marginBottom: '25px',
              }}
            >
              <td
                style={{
                  paddingRight: '20px',
                  width: '50%',
                }}
              >
                <table
                  style={{
                    width: '100%',
                    padding: '15px 0',
                    borderCollapse: 'collapse',
                    borderSpacing: '0',
                  }}
                >
                  <tr>
                    <td
                    // style={{ width: '60px' }}
                    >
                      <p
                        style={{
                          display: 'inline-block',
                          lineHeight: '1',
                          margin: '0',
                          marginBottom: '25px',

                          // width: '130px',
                        }}
                      >
                        By:
                      </p>
                    </td>
                    <td>
                      <div
                        style={{
                          minWidth: '200px',
                          border: 'none',
                          width: '100%',
                          // borderBottom: '1px solid #555',
                          minHeight: '16px',
                          marginBottom: '25px',
                        }}
                      >
                        <div
                          style={{
                            minWidth: '200px',
                            border: 'none',
                            width: '100%',
                            padding: '5px',
                            cursor: 'pointer',
                            top: '-45px',
                            height: '60px',
                            // border: '1px dashed #ddd',
                          }}
                        >
                          <img
                            alt="signature"
                            src={signature}
                            style={{ maxHeight: '50px' }}
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                </table>
              </td>
              <td style={{ paddingLeft: '20px', width: '50%' }}></td>
            </tr>

            <tr>
              <td style={{ paddingRight: '20px', width: '50%' }}>
                <table
                  style={{
                    width: '100%',
                    padding: '15px 0',
                    borderCollapse: 'collapse',
                    borderSpacing: '0',
                  }}
                >
                  <tr>
                    <td
                    // style={{ width: '60px' }}
                    >
                      <p
                        style={{
                          display: 'inline-block',
                          lineHeight: '1',
                          margin: '0',
                          marginBottom: '25px',

                          // width: '130px',
                        }}
                      >
                        Name:
                      </p>
                    </td>
                    <td>
                      <div
                        style={{
                          minWidth: '200px',
                          border: 'none',
                          width: '100%',
                          borderBottom: '1px solid #555',
                          minHeight: '16px',
                          marginBottom: '25px',
                        }}
                      >
                        Ernesto Pena
                      </div>
                    </td>
                  </tr>
                </table>
              </td>
              <td style={{ paddingLeft: '20px', width: '50%' }}></td>
            </tr>

            <tr>
              <td style={{ paddingRight: '20px', width: '50%' }}>
                <table
                  style={{
                    width: '100%',
                    padding: '15px 0',
                    borderCollapse: 'collapse',
                    borderSpacing: '0',
                  }}
                >
                  <tr>
                    <td
                    // style={{ width: '60px' }}
                    >
                      <p
                        style={{
                          display: 'inline-block',
                          lineHeight: '1',
                          margin: '0',
                          // width: '130px',
                          marginBottom: '25px',
                        }}
                      >
                        Title:
                      </p>
                    </td>
                    <td>
                      <div
                        style={{
                          minWidth: '200px',
                          border: 'none',
                          width: '100%',
                          borderBottom: '1px solid #555',
                          minHeight: '16px',
                          marginBottom: '25px',
                        }}
                      >
                        CEO
                      </div>
                    </td>
                  </tr>
                </table>
              </td>
              <td style={{ paddingLeft: '20px', width: '50%' }}></td>
            </tr>

            <tr>
              <td style={{ paddingRight: '20px', width: '50%' }}>
                <table
                  style={{
                    width: '100%',
                    padding: '15px 0',
                    borderCollapse: 'collapse',
                    borderSpacing: '0',
                  }}
                >
                  <tr>
                    <td
                    // style={{ width: '60px' }}
                    >
                      <p
                        style={{
                          display: 'inline-block',
                          lineHeight: '1',
                          margin: '0',
                          // width: '130px',
                          marginBottom: '45px',
                        }}
                      >
                        Date:
                      </p>
                    </td>
                    <td>
                      <div
                        style={{
                          minWidth: '200px',
                          border: 'none',
                          width: '100%',
                          borderBottom: '1px solid #555',
                          minHeight: '16px',
                          marginBottom: '45px',
                        }}
                      >
                        {moment(documentState.SUBMITTED_DATE).format(
                          'MMMM Do YYYY, h:mm A',
                        )}
                      </div>
                    </td>
                  </tr>
                </table>
              </td>
              <td style={{ paddingLeft: '20px', width: '50%' }}></td>
            </tr>

            <tr>
              <td style={{ paddingRight: '20px', width: '50%' }}>
                <table
                  style={{
                    width: '100%',
                    padding: '15px 0',
                    borderCollapse: 'collapse',
                    borderSpacing: '0',
                  }}
                >
                  <tr>
                    <td
                    // style={{ width: '60px' }}
                    >
                      <p
                        style={{
                          display: 'inline-block',
                          lineHeight: '1',
                          margin: '0',
                          // width: '130px',
                          marginBottom: '81px',
                        }}
                      >
                        Agent/Agency:
                      </p>
                    </td>
                    <td>
                      <div
                        style={{
                          minWidth: '200px',
                          border: 'none',
                          width: '100%',
                          borderBottom: '1px solid #555',
                          minHeight: '16px',
                          marginBottom: '81px',
                        }}
                      >
                        {documentState.Agency_Name}
                      </div>
                    </td>
                  </tr>
                </table>
              </td>
              <td style={{ paddingLeft: '20px', width: '50%' }}></td>
            </tr>

            <tr
              style={{
                marginBottom: '25px',
              }}
            >
              <td
                style={{
                  paddingRight: '20px',
                  width: '50%',
                }}
              >
                <table
                  style={{
                    width: '100%',
                    padding: '15px 0',
                    borderCollapse: 'collapse',
                    borderSpacing: '0',
                  }}
                >
                  <tr>
                    <td
                    // style={{ width: '60px' }}
                    >
                      <p
                        style={{
                          display: 'inline-block',
                          lineHeight: '1',
                          margin: '0',
                          marginBottom: '25px',

                          // width: '130px',
                        }}
                      >
                        By:
                      </p>
                    </td>
                    <td>
                      <div
                        style={{
                          minWidth: '200px',
                          border: 'none',
                          width: '100%',
                          borderBottom: '1px solid #555',
                          minHeight: '16px',
                          marginBottom: '25px',
                        }}
                      >
                        <div style={{ position: 'relative' }}>
                          {!documentState?.SIGNATURE ? (
                            <div
                              onClick={() => {
                                if (readMode) return;
                                setSignatureModal(true);
                              }}
                              style={{
                                minWidth: '200px',
                                border: 'none',
                                height: '60px',
                                width: '100%',
                                padding: '5px',
                                cursor: 'pointer',
                                position: 'absolute',
                                top: '-45px',
                                border: '1px dashed #ddd',
                              }}
                            >
                              + Add Signature
                            </div>
                          ) : (
                            <div
                              onClick={() => {
                                if (readMode) return;
                                setSignatureModal(true);
                              }}
                              style={{
                                minWidth: '200px',
                                border: 'none',
                                width: '100%',
                                padding: '5px',
                                cursor: 'pointer',
                                position: 'absolute',
                                top: '-45px',
                                height: '60px',
                                border: '1px dashed #ddd',
                              }}
                            >
                              <img
                                alt="signature"
                                src={documentState?.SIGNATURE}
                                style={{ maxHeight: '50px' }}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </td>
                  </tr>
                </table>
              </td>
              <td style={{ paddingLeft: '20px', width: '50%' }}></td>
            </tr>

            <tr>
              <td style={{ paddingRight: '20px', width: '50%' }}>
                <table
                  style={{
                    width: '100%',
                    padding: '15px 0',
                    borderCollapse: 'collapse',
                    borderSpacing: '0',
                  }}
                >
                  <tr>
                    <td
                    // style={{ width: '60px' }}
                    >
                      <p
                        style={{
                          display: 'inline-block',
                          lineHeight: '1',
                          margin: '0',
                          marginBottom: '25px',

                          // width: '130px',
                        }}
                      >
                        Name:
                      </p>
                    </td>
                    <td>
                      <div
                        style={{
                          minWidth: '200px',
                          border: 'none',
                          width: '100%',
                          borderBottom: '1px solid #555',
                          minHeight: '16px',
                          marginBottom: '25px',
                        }}
                      >
                        {documentState.Agency_Name}
                      </div>
                    </td>
                  </tr>
                </table>
              </td>
              <td style={{ paddingLeft: '20px', width: '50%' }}></td>
            </tr>

            <tr>
              <td style={{ paddingRight: '20px', width: '50%' }}>
                <table
                  style={{
                    width: '100%',
                    padding: '15px 0',
                    borderCollapse: 'collapse',
                    borderSpacing: '0',
                  }}
                >
                  <tr>
                    <td
                    // style={{ width: '60px' }}
                    >
                      <p
                        style={{
                          display: 'inline-block',
                          lineHeight: '1',
                          margin: '0',
                          // width: '130px',
                          marginBottom: '25px',
                        }}
                      >
                        Title:
                      </p>
                    </td>
                    <td>
                      <div
                        style={{
                          minWidth: '200px',
                          border: 'none',
                          width: '100%',
                          borderBottom: '1px solid #555',
                          minHeight: '16px',
                          marginBottom: '25px',
                        }}
                      >
                        {documentState.TYPE}
                      </div>
                    </td>
                  </tr>
                </table>
              </td>
              <td style={{ paddingLeft: '20px', width: '50%' }}></td>
            </tr>

            <tr>
              <td style={{ paddingRight: '20px', width: '50%' }}>
                <table
                  style={{
                    width: '100%',
                    padding: '15px 0',
                    borderCollapse: 'collapse',
                    borderSpacing: '0',
                  }}
                >
                  <tr>
                    <td
                    // style={{ width: '60px' }}
                    >
                      <p
                        style={{
                          display: 'inline-block',
                          lineHeight: '1',
                          margin: '0',
                          // width: '130px',
                          marginBottom: '45px',
                        }}
                      >
                        Date:
                      </p>
                    </td>
                    <td>
                      <div
                        style={{
                          minWidth: '200px',
                          border: 'none',
                          width: '100%',
                          borderBottom: '1px solid #555',
                          minHeight: '16px',
                          marginBottom: '45px',
                        }}
                      >
                        {moment(documentState.DATE).format(
                          'MMMM Do YYYY, h:mm A',
                        )}
                      </div>
                    </td>
                  </tr>
                </table>
              </td>
              <td style={{ paddingLeft: '20px', width: '50%' }}></td>
            </tr>
          </table>
        </div>

        {signatureModal ? (
          <Modal
            handleClose={() => setSignatureModal(false)}
            title={'Signature'}
            headerComponent={null}
            shouldCloseOnClickOutside={true}
            footerComponent={null}
            height="300"
            size="700"
          >
            <SignModule handleChange={handleChange} />
          </Modal>
        ) : null}
      </>
    );
  },
);

export default HRA_AGREEMENT_EN;
