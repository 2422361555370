import React from 'react';

const Settings = () => {
  return (
    <div className="settingPage h--full d--flex align-items--center justify-content--center font--2xl">
      Coming Soon
    </div>
  );
};

export default Settings;
