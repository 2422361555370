import React from 'react';
import { NotValidIcon } from '../../../assets/icons/useIcons';

const NoValidDocument = () => {
  return (
    <div className="d--flex align-items--center justify-content--center h--full w--full ">
      <div className="w--full w-max--400 radius--sm p--lg d--flex justify-content--center align-items--center h-min--300 bg--white flex--column gap--md">
        <div className="text--black-200 text--danger">
          <NotValidIcon width={50} height={50} />
        </div>
        <div className="w--full text--c font--md font--500 text--danger">
          No Valid Document Found
        </div>
      </div>
    </div>
  );
};

export default NoValidDocument;
