import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useAlert } from '../../../../../hooks/useAlert';
import { useForm } from 'react-hook-form';
import { createUser } from '../../../../Verify/View/VerifySignup/actions';
import { submitBtnTxt } from '../../../../../helpers/functions';
import AssociateAgency from './AssociateAgency';
import SubmitButton from '../../../../../components/Button/SubmitButton';
import OwnAgency from './OwnAgency';
import { useAuth } from '../../../../../hooks/useAuth';
import { yupResolver } from '@hookform/resolvers/yup';
import { defaultValues, schema } from '../constants';
import Modal from '../../../../../components/Modal';
import Button from '../../../../../components/Button';

const Agencies = () => {
  let queryClient = useQueryClient();
  const [show, setShow] = useState(false);

  const { clearUser, user } = useAuth();

  const { showAlert } = useAlert();

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
    mode: 'onSubmit',
  });

  const { mutate: createUserMutate, isLoading: createLoading } = useMutation({
    mutationFn: createUser,
    mutationKey: 'createUser',
    onSuccess: ({ data }) => {
      if (!user?.isOwnAgency) {
        return setShow(true);
      }
      showAlert({ type: 'success', message: data?.data?.message });
      queryClient.invalidateQueries({ queryKey: ['getUserDetails'] });
    },
    onError: (error) => {
      const errMsg = error?.message ?? 'unexpected error';
      showAlert({ type: 'danger', message: errMsg });
    },
  });

  const onSubmit = (_) => {
    const { isOwnAgency, isAssociatedAgency, ...rest } = _;
    let payload = {
      isAssociatedAgency: isAssociatedAgency === 'Y',
      isOwnAgency: isOwnAgency === 'Y',
      ...rest,
      // page: 'PROFILE',
    };
    createUserMutate(payload);
  };

  useEffect(() => {
    if (user) {
      const {
        isOwnAgency,
        isAssociatedAgency,
        ownAgencyDetail = {},
        associatedAgencyDetail = {},
        ownAgencyType,
        ownAgencyWebsite,
      } = user || {};
      let {
        agencyName: ownAgencyName,
        agencyEmail: ownAgencyEmail,
        agencyPhone: ownAgencyPhone,
        agencyNPN: ownAgencyNPN,
        agencyEIN: ownAgencyEIN,
        address_one: ownAddressOne,
        address_two: ownAddressTwo,
        state: ownState,
        city: ownCity,
        zipCode: ownZipCode,
      } = ownAgencyDetail || {};
      let {
        agencyName: associatedAgencyName,
        agencyEmail: associatedAgencyEmail,
        agencyPhone: associatedAgencyPhone,
        agencyNPN: associatedAgencyNPN,
        address_one: associatedAddressOne,
        address_two: associatedAddressTwo,
        state: associatedState,
        city: associatedCity,
        zipCode: associatedZipCode,
      } = associatedAgencyDetail || {};

      reset({
        isAssociatedAgency: isAssociatedAgency ? 'Y' : 'N',
        associatedAgencyName,
        associatedAgencyEmail,
        associatedAgencyPhone,
        associatedAgencyNPN,
        associatedAddressOne,
        associatedAddressTwo,
        associatedState,
        associatedCity,
        associatedZipCode,

        isOwnAgency: isOwnAgency ? 'Y' : 'N',
        ownAgencyType: ownAgencyType || 'REGULAR',
        ownAgencyName,
        ownAgencyEmail,
        ownAgencyPhone,
        ownAgencyNPN,
        ownAgencyEIN,
        ownAddressOne,
        ownAddressTwo,
        ownState,
        ownCity,
        ownZipCode,
        ownAgencyWebsite,
      });
    }
  }, [user]);

  useEffect(() => {
    if (show) {
      setTimeout(() => {
        clearUser();
      }, 5000);
    }
  }, [show]);

  return (
    <form
      className="w--full d--flex flex--column gap--lg h-min--500 justify-content--between"
      onSubmit={handleSubmit(onSubmit)}
      autoComplete="off"
    >
      {/* ---------- */}

      <div className="w--full d--flex gap--2xl flex--column-xs">
        <div className="w--full d--flex flex--column gap--sm">
          <AssociateAgency {...{ control, errors, watch, setValue }} />
        </div>
        {/* ---------- */}
        <div className="w--full d--flex flex--column gap--sm">
          <OwnAgency
            {...{
              control,
              errors,
              setValue,
              watch,
              handleSubmit,
              onSubmit,
            }}
          />
        </div>
      </div>

      <div className="d--flex align-items--center justify-content--center gap--sm w--full ">
        <SubmitButton
          btnClasses="btn w-max--170"
          size="md"
          disabled={createLoading}
        >
          {submitBtnTxt(createLoading, 'Update')}
        </SubmitButton>
      </div>

      {show && (
        <Modal
          headerComponent={null}
          shouldCloseOnClickOutside={false}
          showCrossIcon={false}
          footerComponent={
            <div className="d--flex gap--sm justify-content--end w--full p-t--md">
              <Button
                type="button"
                variant="primary"
                color="black"
                btnClasses="btn w-max--150 w-min--150"
                onClick={() => {
                  clearUser();
                }}
              >
                Ok
              </Button>
            </div>
          }
          size="500"
          height="250"
        >
          <div className="font--md m-b--0 text--black-600 line-height--1-dot-5">
            Adding your Agency has upgraded your account. Please sign out and
            sign back in for tha changes to take effect.
          </div>
        </Modal>
      )}
    </form>
  );
};

export default Agencies;
