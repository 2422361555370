import { MEMBER } from '../../helpers/constants';
import { useAuth } from '../../hooks/useAuth';
import AgentHra from './View/AgentHra';
import ConsumerHra from './View/ConsumerHra';

const HRA = () => {
  const { user } = useAuth();
  const userRole = user?.role || localStorage.getItem('nph-agency-user-role');

  if (userRole === MEMBER) {
    return <ConsumerHra />;
  } else {
    return <AgentHra />;
  }
};

export default HRA;
