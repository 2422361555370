import React, { useState } from 'react';
import Manual from './Manual';
import Creative from './Creative';

const SignModule = ({ handleChange = () => {} }) => {
  const [tabIndex, setTabIndex] = useState(0);

  const modalLookup = {
    0: <Manual handleChange={handleChange} />,
    1: <Creative handleChange={handleChange} />,
  };

  return (
    <div>
      <div className="d--flex gap--lg profilePageTabe  border-bottom--black-100 m-b--md">
        <div
          className={`p-l--md p-r--md p-b--md c--pointer font--sm font--600 ${
            tabIndex === 0
              ? 'border-bottom--black text--black'
              : 'text--black-600'
          }`}
          onClick={() => setTabIndex(0)}
        >
          Draw
        </div>
        <div
          className={`p-l--md p-r--md p-b--md c--pointer font--sm font--600 ${
            tabIndex === 1
              ? 'border-bottom--black text--black'
              : 'text--black-600'
          } `}
          onClick={() => setTabIndex(1)}
        >
          Type
        </div>
      </div>
      {modalLookup[tabIndex]}
    </div>
  );
};

export default SignModule;
