import React, { useState } from "react";
import AsideModal from "../../../../../components/AsideModal";
import SelectCaptureOption from "../Modal/SelectCaptureOption";
import Modal from "../../../../../components/Modal";
import { TickCircleIcon } from "../../../../../assets/icons/useIcons";
import Button from "../../../../../components/Button";

const UploadDoc = () => {
  const [openModal, setOpenModal] = useState(false);
  return (
    // <div onClick={() => setOpenModal(true)}>
    <div className="w--full">
      <h5 className="font--sm font--400 text--surface m-b--lg">
        Review and confirm uploaded information
      </h5>
      <div className="d--flex gap--sm">
        <span className="text--success">
          <TickCircleIcon width={30} height={30} />
        </span>
        <div className="">
          <h3 className="font--md m-b--sm font--600">Uploaded documents</h3>
          <h6 className="font--sm font--400 text--surface">
            Uploaded relevant data information
          </h6>
        </div>
      </div>
      <div className="w--sm  d--flex gap--lg flex--column m-t--lg">
        <div className="w--full  d--flex gap--md">
          <div className="w--full gap--md">
            <h6 className="font--sm font--400 text--surface">NIN</h6>
            <h3 className="font--sm m-t--sm font--600">NIN65465</h3>
          </div>
          <div className="w--full gap--md">
            <h6 className="font--sm font--400 text--surface">First name</h6>
            <h3 className="font--sm m-t--sm font--600">Jason</h3>
          </div>
        </div>
        <div className="w--full  d--flex gap--md">
          <div className="w--full gap--md">
            <h6 className="font--sm font--400 text--surface">Middle name</h6>
            <h3 className="font--sm m-t--sm font--600">Smith</h3>
          </div>
          <div className="w--full gap--md">
            <h6 className="font--sm font--400 text--surface">Surname</h6>
            <h3 className="font--sm m-t--sm font--600">Stewart</h3>
          </div>
        </div>
        <div className="w--full  d--flex gap--md">
          <div className="w--full gap--md">
            <h6 className="font--sm font--400 text--surface">Date of birth</h6>
            <h3 className="font--sm m-t--sm font--600">09/09/1988</h3>
          </div>
        </div>
        <div className="w--full  d--flex gap--md align-items--center m-t--md">
            <span className="text--primary font--sm font--600 c--pointer">Back</span>
            <Button
              btnClasses="btn w-max--200"
              variant="primary"
              disabled={false}
              onClick={() => setOpenModal(true)}
            >
              {false ? "Please wait..." : "Continue"}
            </Button>
        </div>
      </div>

      {openModal ? (
        <Modal
          handleClose={() => setOpenModal(false)}
          title={`Select option`}
          footerComponent={null}
          headerComponent={null}
          shouldCloseOnClickOutside={false}
          width="md"
          height="200"
          size="450"
        >
          <SelectCaptureOption handleClose={() => setOpenModal(false)} />
        </Modal>
      ) : null}
    </div>
  );
};

export default UploadDoc;
