import React, { useEffect, useState } from 'react';
import FormInput from '../../../../components/FormInput';
import { Controller, useForm } from 'react-hook-form';
import SubmitButton from '../../../../components/Button/SubmitButton';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { submitBtnTxt } from '../../../../helpers/functions';
import { webhookInbound } from '../../actions';
import { useAlert } from '../../../../hooks/useAlert';
import { object } from 'yup';
import { requiredFieldFunction } from '../../../../helpers/yupValidations';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAuth } from '../../../../hooks/useAuth';
import Button from '../../../../components/Button';
import AsideModal from '../../../../components/AsideModal';
import ShowNpnInfo from '../../../Claims/View/ShowNpnInfo';
import { CopyIcon, TickCircleIcon } from '../../../../assets/icons/useIcons';

export const schema = object().shape({
  webhookUrl: requiredFieldFunction('WebhookUrl'),
});
const InboundWebhook = () => {
  const { user } = useAuth();
  const queryclient = useQueryClient();
  const [openModal, setOpenModal] = useState('');
  const [copied, setCopied] = useState(false);

  const {
    control,
    watch,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    defaultValues: { type: 'JSON', webhookUrl: '' },
    resolver: yupResolver(schema),
    mode: 'onSubmit',
  });

  const { showAlert } = useAlert();

  const { mutate, isLoading } = useMutation({
    mutationFn: webhookInbound,
    mutationKey: 'webhookInbound',
    onSuccess: ({ data }) => {
      showAlert({ type: 'success', message: data?.data?.message });
      queryclient.invalidateQueries({ queryKey: ['getUserDetails'] });
    },
    onError: (error) => {
      const errMsg = error?.message ?? 'unexpected error';
      showAlert({ type: 'danger', message: errMsg });
    },
  });

  const onSubmit = (_) => mutate(_);

  function handleClose() {
    setOpenModal('');
  }

  const modalLookUp = {
    showInfoNpn: {
      component: <ShowNpnInfo handleClose={handleClose} />,
      title: 'Webhook Result',
    },
  };

  useEffect(() => {
    if (user) {
      const { inboundDataType, inboundWebhookUrl } = user || {};
      reset({ type: inboundDataType, webhookUrl: inboundWebhookUrl });
    }
  }, [user]);

  const handleCopy = (copyText) => {
    navigator.clipboard
      .writeText(copyText)
      .then(() => {
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 1000);
      })
      .catch((err) => {
        console.error('Failed to copy text: ', err);
      });
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)} className="webhook">
      {/* <h3 className=" font--md ">Choose your payload format</h3> */}
      <div className="d--flex gap--md flex--column ">
        {/* <div className="d--flex gap--md p--md border-bottom--black-100">
          <Controller
            name="type"
            control={control}
            render={({ field }) => (
              <RadioBoxInput
                {...field}
                htmlFor="JSON"
                value="JSON"
                checked={typeWatch === 'JSON'}
              />
            )}
          />
          <div className="w--full">
            <h4 className="m-b--sm font--500">JSON</h4>
            <p>
              The request is sent using the PUT/POST HTTP method in JSON type
            </p>
          </div>
        </div> */}
        {/* ------ */}
        {/* <div className="d--flex gap--md p--md border-bottom--black-100">
          <Controller
            name="type"
            control={control}
            render={({ field }) => (
              <RadioBoxInput
                {...field}
                htmlFor="FORMDATA"
                value="FORMDATA"
                checked={typeWatch === 'FORMDATA'}
              />
            )}
          />
          <div className="w--full">
            <h4 className="m-b--sm font--500">FORM DATA</h4>
            <p>
              The request is sent using the PUT/POST HTTP method containing
              form-encoded data
            </p>
          </div>
        </div> */}
        {/* ------ */}
        {/* <div className="d--flex gap--md p--md border-bottom--black-100">
          <Controller
            name="type"
            control={control}
            render={({ field }) => (
              <RadioBoxInput
                {...field}
                htmlFor="PLAINTEXT"
                value="PLAINTEXT"
                checked={typeWatch === 'PLAINTEXT'}
              />
            )}
          />
          <div className="w--full">
            <h4 className="m-b--sm font--500">PLAIN TEXT</h4>
            <p>
              The request is sent using the PUT/POST HTTP method containing plan
              text
            </p>
          </div>
        </div> */}
        {/* ------ */}
      </div>
      <div className="d--flex gap--md flex--column">
        <h3 className=" font--md ">
          Please provide your Webhook Url on which we will push data
        </h3>
        <div className="d--flex gap--md p-t---md  align-items--end flex--column-xs">
          <div className="w--full position--relative">
            <Controller
              name="webhookUrl"
              control={control}
              render={({ field }) => (
                <FormInput {...field} error={errors?.webhookUrl?.message} />
              )}
            />
            <div
              className="position--absolute top--5 p--xs right--5 c--pointer text--black-600 bg--white"
              onClick={() => handleCopy(watch('webhookUrl'))}
            >
              {copied ? <TickCircleIcon /> : <CopyIcon />}
            </div>
          </div>
          <SubmitButton
            btnClasses="btn w-max--170 h-max--40 w--full-xs w-max--auto-xs"
            variant="primary"
            disabled={isLoading}
          >
            {submitBtnTxt(isLoading, 'Save')}
          </SubmitButton>
        </div>
        {/* ------ */}
        <div className="d--flex gap--md  align-items--center justify-content--end flex--column-xs">
          {/* <p>Paste your app or CRM webhook here to get data from your system</p> */}
          <SubmitButton
            btnClasses="btn w-max--170  w--full-xs w-max--auto-xs"
            variant="primary"
            type="button"
            onClick={() => setOpenModal('showInfoNpn')}
            disabled={!user?.inboundWebhookUrl}
          >
            Test
          </SubmitButton>
        </div>
      </div>

      {openModal ? (
        <AsideModal
          handleClose={handleClose}
          title={modalLookUp?.[openModal]?.title}
          footerComponent={null}
          headerComponent={null}
          size="600"
        >
          {modalLookUp?.[openModal]?.component}
        </AsideModal>
      ) : null}
    </form>
  );
};

export default InboundWebhook;
