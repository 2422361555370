import React, { useEffect, useRef } from 'react';
import useIcons from '../../assets/icons/useIcons';

export default function AsideModal({
  children,
  handleClose,
  shouldCloseOnClickOutside = false,
  title = '',
  showHeader = true,
  footerComponent: FooterComponent = () => null,
  headerComponent: HeaderComponent = () => null,
  width = 'xs',
  size = '500',
}) {
  const { CrossRoundIcon } = useIcons();
  const asideModal = useRef();

  useEffect(() => {
    if (asideModal.current) {
      const refrence = asideModal.current;
      refrence.showModal();

      refrence.addEventListener('keydown', (event) => {
        if (event.key === 'Escape' || event.key === 'Esc') {
          event?.preventDefault();
          event = event || window?.event;
          let isEscape = false;
          if ('key' in event) {
            isEscape = event.key === 'Escape' || event.key === 'Esc';
          } else {
            isEscape = event.keyCode === 27;
          }
          if (isEscape) {
            closeModal();
          }
        }
      });
      if (shouldCloseOnClickOutside) {
        refrence.addEventListener(
          'click',
          (e) => {
            const modalDimesions = refrence.getBoundingClientRect();
            if (
              e.clientX < modalDimesions.left ||
              e.clientX > modalDimesions.right ||
              e.clientY < modalDimesions.top ||
              e.clientY > modalDimesions.bottom
            ) {
              closeModal();
            }
          },
          false,
        );
      }
      return () => {
        if (refrence && shouldCloseOnClickOutside) {
          refrence.removeEventListener('click', () => null, false);
          closeModal();
        }
      };
    }
    // }
  }, [asideModal, closeModal, shouldCloseOnClickOutside]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function closeModal() {
    if (asideModal.current) {
      asideModal.current.removeEventListener('click', () => null, false);
      asideModal.current.removeEventListener('keydown', () => null, false);
      asideModal.current.close();
      handleClose();
    }
  }

  return (
    <dialog
      data-aside-modal="right"
      ref={asideModal}
      className={`w--${width} w-min--${size} w-max--${size} `}
    >
      {showHeader && (
        <dialog-head class="dialog-head h-min--50 p-l--md p-r--md d--flex align-items--center justify-content--between border-bottom--black-100">
          {HeaderComponent ? (
            HeaderComponent
          ) : (
            <>
              <div className="font--md font--600 text--grey">{title}</div>
              <button
                type="button"
                className="font--md font--600 text--secondary d--flex c--pointer radius--full"
                onClick={() => closeModal()}
              >
                <CrossRoundIcon width={30} height={30} />
              </button>
            </>
          )}
        </dialog-head>
      )}
      <dialog-body class="dialog-body h--full">{children}</dialog-body>
      {FooterComponent && (
        <dialog-footer class="modal-foot h-max--50 h-min--50 p-l--md p-r--md d--flex align-items--center justify-content--end border-top--black-100 gap--sm">
          {FooterComponent}
        </dialog-footer>
      )}
    </dialog>
  );
}
