import { useEffect } from 'react';
import FormInput from '../../../components/FormInput';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { addMemberInitialValues, schema } from '../constants';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createMember, updateMember } from '../actions';
import { useAlert } from '../../../hooks/useAlert';
import PhoneInput from 'react-phone-number-input/react-hook-form';
import CancelButton from '../../../components/Button/CancelButton';
import SubmitButton from '../../../components/Button/SubmitButton';
import moment from 'moment';

const AddMember = ({ handleClose = () => {}, editMemberInstance }) => {
  const { isEdit, editData } = editMemberInstance || {};
  const queryclient = useQueryClient();
  const { showAlert } = useAlert();

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: addMemberInitialValues,
    mode: 'onSubmit',
  });
  console.log('🚀 ~ AddMember ~ errors:', errors);

  const { mutate, isLoading } = useMutation({
    mutationFn: isEdit ? updateMember : createMember,
    mutationKey: isEdit ? 'updateMember' : 'createMember',
    onSuccess: ({ data }) => {
      showAlert({ type: 'success', message: data?.data?.message });
      queryclient.invalidateQueries({
        queryKey: ['getMemberList'],
        exact: false,
      });
      handleClose();
    },
    onError: (error) => {
      const errMsg = error?.message ?? 'unexpected error';
      showAlert({ type: 'danger', message: errMsg });
    },
  });

  useEffect(() => {
    if (isEdit) {
      const {
        firstName = '',
        lastName = '',
        phone = '',
        email = '',
        enrollmentYear,
      } = editData;
      reset({
        firstName,
        lastName,
        email,
        phone,
        enrollmentYear: enrollmentYear || `${moment().year()}`,
      });
    }
  }, [editData, isEdit]);

  const onSubmit = (values) => {
    const { policyId, ...rest } = values;
    let payload = { ...rest };

    if (isEdit) {
      // delete payload.email;
      payload.uuid = editData?._id;
    }
    mutate(payload);
  };

  return (
    <form
      className="w--full h--full"
      onSubmit={handleSubmit(onSubmit)}
      autoComplete="off"
    >
      <div className="overflow--auto dialogScroll d--flex flex--column gap--md p--md">
        <div className="d--flex gap--lg flex--column">
          <div className="w--full">
            <Controller
              name="firstName"
              control={control}
              render={({ field }) => (
                <FormInput
                  {...field}
                  type="text"
                  label="First Name"
                  placeholder="Enter first name"
                  error={errors?.firstName?.message}
                  height="40"
                />
              )}
            />
          </div>
          <div className="w--full">
            <Controller
              name="lastName"
              control={control}
              render={({ field }) => (
                <FormInput
                  {...field}
                  type="text"
                  label="Last Name"
                  placeholder="Enter last name"
                  error={errors?.lastName?.message}
                  height="40"
                />
              )}
            />
          </div>
          <div className="w--full">
            <label
              className={`label--control font--sm font--500 m-b--sm d--inline-block ${
                errors?.phone?.message ? 'text--danger' : 'text--grey'
              }`}
            >
              {errors?.phone?.message ?? 'Phone'}
              <span className=" font--sm text--danger m--xs">*</span>
            </label>
            <PhoneInput
              name="phone"
              control={control}
              defaultCountry={'US'}
              international={true}
              withCountryCallingCode={true}
              placeholder="Enter your phone"
              internationalIcon={() => (
                <span>
                  <i className="fa fa-phone icon-circle bg-danger"></i>
                </span>
              )}
              limitMaxLength={true}
              className={`form--control w--full h-min--36  radius--sm p-l--md p-r--md  d--flex align-items--center ${
                errors?.phone?.message
                  ? 'border-full--danger'
                  : 'border-full--black-200'
              }`}
            />
          </div>

          <div className="w--full">
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <FormInput
                  {...field}
                  type="text"
                  label="Email"
                  placeholder="Enter email"
                  error={errors?.email?.message}
                  height="40"
                  // disabled={isEdit}
                  // extraClasses={isEdit ? 'c--not-allowed' : ''}
                />
              )}
            />
          </div>
          {!isEdit && (
            <div className="w--full">
              <div className="d--flex gap--sm">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="consent"
                  onClick={(e) =>
                    setValue('isSkipVerification', e.target.checked)
                  }
                  checked
                  disabled
                />
                <label
                  className="form-check-label label--control font--sm font--500"
                  htmlFor="consent"
                >
                  Skip Face/ID verification for this consumer
                </label>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="d--flex justify-content--between gap--md p-l--md p-r--md p-t--md border-top--primary-100">
        <CancelButton data-link-hover onClick={handleClose}>
          Cancel
        </CancelButton>
        <SubmitButton disabled={isLoading}>
          {isLoading ? 'Please wait...' : 'Submit'}
        </SubmitButton>
      </div>
    </form>
  );
};

export default AddMember;
