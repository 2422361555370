import { useState } from 'react';
import TableManager from '../../../components/TableManager';
import { hraAgentList } from '../actions';
import AsideModal from '../../../components/AsideModal';
import HRAView from '../HRAView';

const ConsumerHra = () => {
  const [openAsideModal, setOpenAsideModal] = useState(false);
  const [rowData, setRowData] = useState(false);

  const columns = [
    {
      accessorKey: 'agentDetail.fullName',
      header: () => 'Agent Name',
      meta: {
        width: '30%',
      },
    },
    {
      accessorKey: 'agentDetail.phone',
      header: () => 'Agent Phone',
      meta: {
        textAlign: 'center',
      },
    },
    {
      accessorKey: 'agentDetail.email',
      header: () => 'Agent Email',
      meta: {
        width: '25%',
      },
    },
    {
      accessorKey: 'agentDetail.npn',
      header: () => 'NPN',

      meta: {
        textAlign: 'center',
        width: '8%',
      },
    },
    {
      accessorKey: 'adultFamilyMembers',
      header: () => 'Members',
      cell: (props) => {
        return (
          <div
            onClick={() => {
              if (props.getValue()?.length > 0) {
                setOpenAsideModal(true);
                setRowData(props?.row?.original);
              }
            }}
            className="c--pointer bg--primary-200 w-max--28 h-min--28 d--flex align-items--center justify-content--center radius--full text--center m--auto"
          >
            {props.getValue()?.length}
          </div>
        );
      },

      meta: {
        textAlign: 'center',
        width: '8%',
      },
    },
  ];

  return (
    <div className="w--full d--flex flex--column gap--lg memberPage">
      <TableManager
        {...{
          fetcherKey: 'hraAgentList',
          fetcherFn: hraAgentList,
          shouldFilter: false,
          name: 'Consumer',
          columns: columns,
        }}
      />

      {openAsideModal ? (
        <AsideModal
          handleClose={() => setOpenAsideModal(false)}
          title={'HRA'}
          footerComponent={null}
          headerComponent={null}
          size={'lg'}
          width={'lg'}
        >
          <HRAView
            handleClose={() => setOpenAsideModal(false)}
            uuId={rowData?.agentDetail?.agentId}
          />
        </AsideModal>
      ) : null}
    </div>
  );
};

export default ConsumerHra;
