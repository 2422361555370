import React, { useState } from 'react';
import AccessControl from '../Dashboard/AccessControl';
import { AGENCY_USER, AGENT } from '../../helpers/constants';
import { activeTabLookUp } from './constants';

export default function MyAccount() {
  const [activeTab, setActiveTab] = useState('personalInfo');

  return (
    <>
      <div className="d--flex gap--lg profilePageTabe  border-bottom--black-100 m-b--md overflow--auto">
        <span
          className={`p-l--md p-r--md p-b--md c--pointer font--sm font--600 white-space--nowrap white--space-none   ${
            activeTab === 'personalInfo' && 'border-bottom--black text--black'
          }`}
          onClick={() => {
            setActiveTab('personalInfo');
          }}
        >
          Profile Info
        </span>

        <AccessControl allowedRoles={[AGENCY_USER, AGENT]}>
          <span
            className={`p-l--md p-r--md p-b--md c--pointer font--sm font--600 white-space--nowrap  white--space-none  ${
              activeTab === 'agency'
                ? 'border-bottom--black text--black'
                : 'text--black-600'
            }`}
            onClick={() => {
              setActiveTab('agency');
            }}
          >
            Agency Info
          </span>
        </AccessControl>

        <AccessControl allowedRoles={[]}>
          <span
            className={`p-l--md p-r--md p-b--md c--pointer font--sm font--600 white-space--nowrap white--space-none  ${
              activeTab === 'kyc'
                ? 'border-bottom--black text--black'
                : 'text--black-600'
            }`}
            onClick={() => {
              setActiveTab('kyc');
            }}
          >
            KYC
          </span>
        </AccessControl>
        <span
          className={`p-l--md p-r--md p-b--md c--pointer font--sm font--600 white-space--nowrap white--space-none  ${
            activeTab === 'changePassword'
              ? 'border-bottom--black text--black'
              : 'text--black-600'
          }`}
          onClick={() => {
            setActiveTab('changePassword');
          }}
        >
          Change Password
        </span>
        {/* <AccessControl allowedRoles={[AGENT, AGENCY_USER]}>
          <span
            className={`p-l--md p-r--md p-b--md c--pointer font--sm font--600 white-space--nowrap   ${
              activeTab === 'email'
                ? 'border-bottom--black text--black'
                : 'text--black-600'
            }`}
            onClick={() => {
              setActiveTab('email');
            }}
          >
            Email Integration
          </span>
        </AccessControl> */}
        {/* <AccessControl allowedRoles={[AGENT, AGENCY_USER]}>
          <span
            className={`p-l--md p-r--md p-b--md c--pointer font--sm font--600 white-space--nowrap   ${
              activeTab === 'uplineBranding'
                ? 'border-bottom--black text--black'
                : 'text--black-600'
            }`}
            onClick={() => {
              setActiveTab('uplineBranding');
            }}
          >
            Upline Branding
          </span>
        </AccessControl> */}
        <AccessControl allowedRoles={[AGENT, AGENCY_USER]}>
          <span
            className={`p-l--md p-r--md p-b--md c--pointer font--sm font--600 white-space--nowrap   ${
              activeTab === 'document'
                ? 'border-bottom--black text--black'
                : 'text--black-600'
            }`}
            onClick={() => {
              setActiveTab('document');
            }}
          >
            Documents
          </span>
        </AccessControl>
        {/* <AccessControl allowedRoles={[AGENT, AGENCY_USER, CARRIER]}>
          <span
            className={`p-l--md p-r--md p-b--md c--pointer font--sm font--600 white-space--nowrap   ${
              activeTab === 'generate_key'
                ? 'border-bottom--black text--black'
                : 'text--black-600'
            }`}
            onClick={() => {
              setActiveTab('generate_key');
            }}
          >
            Manage Key(s)
          </span>
        </AccessControl> */}
      </div>
      <div className="d--flex gap--md flex--column profilePage  bg--white radius--sm myAccountContent">
        {activeTabLookUp?.[activeTab]}
      </div>
    </>
  );
}
