import React, { useEffect, useRef, useState } from 'react';
import { usePsPdfKit } from '../../hooks/usePsPdfKit';
import { useAlert } from '../../hooks/useAlert';
import { useMutation } from '@tanstack/react-query';
import { getDocStatus } from '../../api';
import { useLocation } from 'react-router-dom';
import { SignatureItem } from '../VerifyPDF';
import moment from 'moment';
import { EmailIcon, TimeIcon } from '../../assets/icons/useIcons';

const formValuesArr = [];

const ViewSignedPDF = () => {
  const location = useLocation();
  const [file, setFile] = useState(null);
  const { loadPDFKit, psPdfKitInstance, PSPDFKit } = usePsPdfKit();
  const [signatureInfo, setSignatureInfo] = useState(null);
  const [signatureImage, setSignatureImage] = useState(null);
  const [currentData, setCurrentData] = useState(null);

  const pdfContainerRef = useRef(null);
  const { showAlert } = useAlert();

  const { mutate } = useMutation({
    mutationFn: getDocStatus,
    mutationKey: 'getDocStatus',
    onSuccess: (data) => {
      setFile(data?.data?.data?.filename);
      setCurrentData(data?.data?.data);
    },
    onError: (error) => {
      const errMsg = error?.message ?? 'unexpected error';
      showAlert({ type: 'danger', message: errMsg });
      setFile(null);
      setCurrentData(null);
    },
  });

  function generateSvgSign(annotation, observer) {
    if (annotation instanceof PSPDFKit.Annotations.InkAnnotation) {
      const svgSelector = `.PSPDFKit-Ink-Annotation[data-annotation-id="${annotation.id}"] svg`;
      const svgEl =
        psPdfKitInstance?.contentDocument?.querySelector(svgSelector);
      if (svgEl) {
        observer.disconnect();

        const svg = new XMLSerializer().serializeToString(svgEl);
        const img = new Image();
        img.src = `data:image/svg+xml; charset=utf-8, ${svg}`;
        img.onload = () => {
          const canvas = document.createElement('canvas');
          canvas.width = img.naturalWidth;
          canvas.height = img.naturalHeight;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0);
          canvas.toBlob(
            async (blob) => {
              if (blob) {
                setSignatureImage(URL.createObjectURL(blob));
              } else {
                setSignatureImage(null);
              }
            },
            { type: 'image/png' },
          );
        };
      } else {
        setSignatureImage(null);
      }
    }

    if (annotation instanceof PSPDFKit.Annotations.ImageAnnotation) {
      const imageSelector = `.PSPDFKit-Image-Annotation[data-annotation-id="${annotation.id}"]`;
      const imageTag = psPdfKitInstance.contentDocument
        .querySelector(imageSelector)
        .querySelector('img');
      if (imageTag) {
        observer.disconnect();
        const src = imageTag.getAttribute('src');
        setSignatureImage(src);
      } else {
        setSignatureImage(null);
      }
    }
  }

  async function getSignaturesAsObjectUrl(observer) {
    if (psPdfKitInstance) {
      const annotations = await psPdfKitInstance.getAnnotations(0);
      annotations.map((annotation) => {
        return generateSvgSign(annotation, observer);
      });
    }
  }

  // async function enableViewOnly() {
  //   const formFields = await psPdfKitInstance.getFormFields();
  //   const updatedFormFields = formFields.map((it) => it.set("readOnly", true));
  //   psPdfKitInstance.setViewState((viewState) => viewState.set("showToolbar", false));
  //   await psPdfKitInstance.update(updatedFormFields);
  // }

  async function handlePreFillValues() {
    const formFields = await psPdfKitInstance.getFormFields();
    const updatedFormFieldValues = formFields
      .filter(
        (formField) => formField instanceof PSPDFKit.FormFields.TextFormField,
      )
      .reduce((o, formField) => {
        if (formField.name === 'Consumer_Name') {
          o[formField.name] = currentData?.memberDetail?.fullName ?? '';
        }
        if (formField.name === 'Consumer_Phone_Number') {
          o[formField.name] = currentData?.memberDetail?.phone ?? '';
        }
        if (formField.name === 'Consumer_Email') {
          o[formField.name] = currentData?.memberDetail?.email ?? '';
        }
        if (formField.name === 'Agency_Email') {
          o[formField.name] = currentData?.agencyDetail?.email ?? '';
        }
        if (formField.name === 'Agency_Name') {
          o[formField.name] = currentData?.agencyDetail?.agencyName ?? '';
        }
        if (formField.name === 'Agency_Phone_Number') {
          o[formField.name] = currentData?.agencyDetail?.phone ?? '';
        }
        if (formField.name === 'Broker_Email') {
          o[formField.name] = currentData?.userDetail?.email ?? '';
        }
        if (formField.name === 'Broker_Full_Name') {
          o[formField.name] = currentData?.userDetail?.fullName ?? '';
        }
        if (formField.name === 'Broker_NPN') {
          o[formField.name] = currentData?.userDetail?.npn ?? '';
        }
        if (formField.name === 'Broker_Phone_Number') {
          o[formField.name] = currentData?.userDetail?.phone ?? '';
        }

        //CMS
        if (formField.name === 'Primary_House_Hold_Name') {
          o[formField.name] = currentData?.memberDetail?.fullName ?? '';
        }
        if (formField.name === 'Consumer_Consent_Name') {
          o[formField.name] = currentData?.userDetail?.fullName ?? '';
        }
        if (formField.name === 'Method_Revoke_Consent') {
          o[formField.name] = 'Email';
        }
        if (formField.name === 'Primary_Writing_Agent') {
          o[formField.name] = currentData?.userDetail?.fullName ?? '';
        }
        if (formField.name === 'Agent_National_Producer_Number') {
          o[formField.name] = currentData?.userDetail?.npn ?? '';
        }
        if (formField.name === 'Agent_Phone_Number') {
          o[formField.name] = currentData?.userDetail?.phone ?? '';
        }
        if (formField.name === 'Agent_Email') {
          o[formField.name] = currentData?.userDetail?.email ?? '';
        }
        if (formField.name === 'Name_of_Agency') {
          o[formField.name] = currentData?.agencyDetail?.agencyName ?? '';
        }
        if (formField.name === 'Agency_National_Producer_Number') {
          o[formField.name] = currentData?.agencyDetail?.npn ?? '';
        }
        if (formField.name === 'Owner_of_Agency') {
          o[formField.name] = currentData?.agencyDetail?.owner ?? '';
        }
        if (formField.name === 'Agency_Phone_Number') {
          o[formField.name] = currentData?.agencyDetail?.phone ?? '';
        }
        if (formField.name === 'Agency_Email') {
          o[formField.name] = currentData?.agencyDetail?.email ?? '';
        }
        if (formField.name === 'Household_Name') {
          o[formField.name] = currentData?.memberDetail?.fullName ?? '';
        }
        if (formField.name === 'H_Phone_Number') {
          o[formField.name] = currentData?.memberDetail?.phone ?? '';
        }
        if (formField.name === 'H_Email') {
          o[formField.name] = currentData?.memberDetail?.email ?? '';
        }
        if (formField.name === 'Date') {
          o[formField.name] = moment().format('DD-MM-YYYY');
        }

        return o;
      }, {});

    await psPdfKitInstance.setFormFieldValues(updatedFormFieldValues);
    const updatedFormFields = formFields.map((it) => it.set('readOnly', true));
    await psPdfKitInstance.update(updatedFormFields);
    psPdfKitInstance.setViewState((viewState) =>
      viewState.set('showToolbar', false),
    );
  }

  useEffect(() => {
    if (psPdfKitInstance) {
      if (currentData?.agreementStatus !== 'SIGNED') {
        handlePreFillValues();
      }

      psPdfKitInstance
        .getSignaturesInfo()
        .then(function (signaturesInfo) {
          setSignatureInfo(signaturesInfo);
        })
        .catch((e) => {
          setSignatureInfo(null);
        });

      const formFieldValues = psPdfKitInstance.getFormFieldValues();

      for (const [key, value] of Object.entries(formFieldValues)) {
        if (value) {
          formValuesArr.push({ key, value });
        }
      }

      const observer = new MutationObserver(async () => {
        getSignaturesAsObjectUrl(observer);
      });
      observer.observe(psPdfKitInstance.contentDocument, {
        subtree: true,
        childList: true,
      });
    } else {
      setSignatureInfo(null);
    }
  }, [psPdfKitInstance]);

  useEffect(() => {
    if (pdfContainerRef.current && file) {
      loadPDFKit(pdfContainerRef.current, true, file);
    }
  }, [file]);

  useEffect(() => {
    if (location?.search) {
      mutate({
        uuid: location?.search?.split('?')?.[1],
        docType: location?.search?.split('?')?.[2]?.split('type=')?.join(''),
      });
    }
  }, []);

  return (
    <div className="d--flex gap--lg w--full">
      <div
        className="w--full"
        ref={pdfContainerRef}
        style={{ width: '80%', minHeight: '100vh' }}
      />
      {signatureInfo && Object.keys(signatureInfo).length > 0 ? (
        <div
          className=" d--flex flex--column gap--md p--sm"
          style={{ width: '20%', minHeight: '100vh' }}
        >
          {/* {!signatureInfo?.signatures.length ? "No signatures found" : signatureInfo?.signatures?.map((signature) => <SignatureItem signature={signature} />)} */}
          {/* {formValuesArr && formValuesArr.length > 0 ? (
            <div className="bg--white  d--flex flex--column gap--md radius--sm p--md box--shadow border-bottom--black-100">
              {formValuesArr.map((value) => {
                return (
                  <div className="d--flex gap--md ">
                    <label className="font--sm font--600 w-min--200 ">{value?.key}</label>
                    <div className="w--full">{value?.value}</div>
                  </div>
                );
              })}

              {signatureImage && (
                <div className="d--flex gap--md ">
                  <label className="font--sm font--600 w-min--200 ">{"Signatures"}</label>
                  <div className="w--full">
                    <img src={signatureImage} alt="Signature" />
                  </div>
                </div>
              )}
            </div>
          ) : null} */}

          <div className="bg--white  d--flex flex--column gap--md radius--sm p--md  border-full--black-100 border--dashed m-t--md">
            {currentData?.memberDetail?.email ? (
              <div className="d--flex gap--sm p-md align-items--center">
                <span className=" ">
                  <EmailIcon width={20} height={20} />
                </span>
                <div className="w--full">
                  {currentData?.memberDetail?.email}
                </div>
              </div>
            ) : null}
            {currentData?.signedDate ? (
              <div className="d--flex gap--sm  align-items--center">
                <span className=" ">
                  <TimeIcon width={20} height={20} />
                </span>
                <div className="w--full">
                  {moment(currentData?.signedDate).format(
                    'MMMM Do YYYY, h:mm A',
                  )}
                </div>
              </div>
            ) : null}
          </div>

          <div className="bg--white  d--flex flex--column gap--md radius--sm">
            {signatureImage && (
              <div className="d--flex gap--md flex--column">
                <label className="font--sm font--600 w-min--200 ">
                  {'Signatures'}
                </label>
                <div className="w--full p--md radius--sm bg--primary-200 d--flex align-items--center justify-content--center h-min--90 h-max--90">
                  <img
                    src={signatureImage}
                    alt="Signature"
                    className="w-max--120  m-x--auto d--block object--contain "
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default ViewSignedPDF;
