import { useMutation, useQuery } from '@tanstack/react-query';
import { useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  getHraAgreementSignedDocStatus,
  hraSignAgreement,
} from '../../../../pages/HRA/actions';
import { toast } from 'react-toastify';
import { getSessionInfo } from '../../../../helpers/functions';
import NoDocument from '../../../NoDocument';
import Spinner from '../../../Spinner';
import Button from '../../../Button';
import HRA_AGREEMENT_EN from '../../HRA/Agreement/en';
import NoValidDocument from '../../NoValidDocument';
import HRA_AGREEMENT_ES from '../../HRA/Agreement/es';

const HRAAggrementSignMode = () => {
  const { docId = '' } = useParams();
  const formRef = useRef();
  const navigate = useNavigate();

  const { data = null, isLoading } = useQuery({
    queryFn: () =>
      getHraAgreementSignedDocStatus({
        uuId: docId,
      }),
    queryKey: 'getHraAgreementSignedDocStatus',
    onError: (error) => {
      const errMsg = error?.message ?? 'unexpected error';
      toast.error(errMsg);
    },
    enabled: Boolean(docId),
  });

  const { mutate, isLoading: isLoadingVerified } = useMutation({
    mutationFn: hraSignAgreement,
    mutationKey: 'hraSignAgreement',
    onSuccess: () => {
      toast.success('Document Saved.');
      navigate(`/hra-agreement-view-doc/${docId}`);
    },
    onError: (error) => {
      const errMsg = error?.message ?? 'unexpected error';
      toast.error(errMsg);
    },
  });

  const documentData = isLoading ? null : data?.data?.data;

  const documentLookup = {
    ES: <HRA_AGREEMENT_ES documentData={documentData} ref={formRef} />,
    EN: <HRA_AGREEMENT_EN documentData={documentData} ref={formRef} />,
  };

  const document = documentLookup[
    `${documentData?.getHraData?.language === 'en' ? 'EN' : 'ES'}`
  ] || <NoDocument />;

  function handleSubmit() {
    const formValues = formRef.current?.getFormValues();
    const template = formRef.current?.getFormTemplate();

    if (!formValues?.SIGNATURE) {
      return toast.error('Please Sign the document.');
    }

    const payload = {
      uuId: docId,
      language: documentData?.getHraData?.language || 'en',
      data: formValues,
      browserInfo: getSessionInfo(),
      htmlTemplate: template,
    };

    mutate(payload);
  }

  if (!docId) return <NoDocument />;

  if (documentData?.getHraData?.documentStatus === 'SIGNED')
    navigate(`/hra-agreement-view-doc/${docId}`);

  if (isLoading) {
    return (
      <div className="d--flex align-items--center justify-content--center h--full w--full">
        <Spinner size="lg" />
      </div>
    );
  }

  if (!isLoading && !documentData?.getHraData) {
    return <NoValidDocument />;
  }
  return (
    <div className="d--flex h--full w--full ">
      <div className="d--flex gap--sm w--full">
        <div className="w--full h--full mx--auto box-shadow--xs overflow--auto p-b--4xl">
          {document}
        </div>
        <div className="position--fixed left--0 bottom--0 h-min--50 gap--sm w--full bg--white d--flex align-items--center justify-content--center">
          <Button
            onClick={handleSubmit}
            variant="primary"
            color="black"
            isOutline
            borderColor="primary"
            btnClasses="btn w-max--150"
            data-link-hover
            size="sm"
            disabled={isLoadingVerified}
          >
            {isLoadingVerified ? 'Please wait...' : 'Submit'}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default HRAAggrementSignMode;
