import React, { useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useAuth } from '../../../hooks/useAuth';
import { MEMBER } from '../../../helpers/constants';

const LoginByAccessToken = () => {
  const { uuId = '' } = useParams();
  const { setUser } = useAuth();

  const [params] = useSearchParams();
  const role = params.get('role');

  useEffect(() => {
    if (uuId) {
      setUser({
        token: uuId,
        role,
      });
      localStorage.setItem('nph-agency-acc-tkn', uuId);
      window.location.href = role === MEMBER ? '/agents' : '/dashboard';
    }
  }, [uuId]);

  return <></>;
};

export default LoginByAccessToken;
