import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Button from '../../../../../components/Button';
import { submitBtnTxt } from '../../../../../helpers/functions';
import FormInput from '../../../../../components/FormInput';
import { createWebhookConsentObj } from '../../../actions';
import { useOutletContext } from 'react-router-dom';
import { useSelector } from 'react-redux';

const defaultValues = {
  apiKey: '',
  consumerFirstname: '',
  consumerLastname: '',
  consumerEmail: '',
  consumerPhone: '',
  docType: '',
};
const ConsentWebCode = () => {
  const queryClient = useQueryClient();
  const [viewJsonFormat, setViewJsonFormat] = useState(false);
  const { apiKey } = useSelector((state) => state?.theme);

  const { control, handleSubmit, reset, getValues, setValue } = useForm({
    defaultValues,
    mode: 'onSubmit',
  });

  const { mutate, isLoading } = useMutation({
    mutationFn: createWebhookConsentObj,
    mutationKey: 'createWebhookConsentObj',
    onSuccess: (data) => {
      reset(defaultValues);
      setValue('apiKey', apiKey);
      queryClient.invalidateQueries(['agentConsenList']);
    },
  });

  const onSubmit = (_) => {
    mutate(_);
    // mutate({ ..._, id: user?.consentWebhookUrl });
  };

  useEffect(() => {
    setValue('apiKey', apiKey);
  }, []);
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      autoComplete="off"
      className={`w--full preeCode ${
        viewJsonFormat ? 'bg--black text--white' : 'bg--black-25'
      }  border-full--black-100 radius--sm p--sm   d--flex flex--column justify-content--between overflow--auto`}
    >
      {viewJsonFormat ? (
        <pre>
          <code>{JSON.stringify(getValues(), null, 2)}</code>
        </pre>
      ) : (
        <div className="d--flex flex--column gap--md w--auto p--lg ">
          <div className="d--flex gap--md text--danger align-items--center ">
            <div className="label--control font--sm font--500 m-b--sm text--black-600  w-min--150">
              apiKey
            </div>
            <div className="d--flex font--2sm w--full gap--sm">
              <div className="w-max--350 w--full">
                <Controller
                  name="apiKey"
                  control={control}
                  render={({ field }) => (
                    <FormInput
                      {...field}
                      height="32"
                      disabled
                      extraClasses="c--not-allowed"
                    />
                  )}
                />
              </div>
            </div>
          </div>

          <div className="d--flex gap--md text--danger align-items--center ">
            <div className="label--control font--sm font--500 m-b--sm text--black-600  w-min--150">
              consumerFirstname
            </div>

            <div className="d--flex font--2sm w--full gap--sm">
              <div className="w-max--350 w--full">
                <Controller
                  name="consumerFirstname"
                  control={control}
                  render={({ field }) => <FormInput {...field} height="32" />}
                />
              </div>
            </div>
          </div>
          <div className="d--flex gap--md text--danger align-items--center ">
            <div className="label--control font--sm font--500 m-b--sm text--black-600  w-min--150">
              consumerLastname
            </div>

            <div className="d--flex font--2sm w--full gap--sm">
              <div className="w-max--350 w--full">
                <Controller
                  name="consumerLastname"
                  control={control}
                  render={({ field }) => <FormInput {...field} height="32" />}
                />
              </div>
            </div>
          </div>
          <div className="d--flex gap--md text--danger align-items--center ">
            <div className="label--control font--sm font--500 m-b--sm text--black-600  w-min--150">
              consumerEmail
            </div>

            <div className="d--flex font--2sm w--full gap--sm">
              <div className="w-max--350 w--full">
                <Controller
                  name="consumerEmail"
                  control={control}
                  render={({ field }) => <FormInput {...field} height="32" />}
                />
              </div>
            </div>
          </div>

          <div className="d--flex gap--md text--danger align-items--center ">
            <div className="label--control font--sm font--500 m-b--sm text--black-600  w-min--150">
              consumerPhone
            </div>

            <div className="d--flex font--2sm w--full gap--sm">
              <div className="w-max--350 w--full">
                <Controller
                  name="consumerPhone"
                  control={control}
                  render={({ field }) => <FormInput {...field} height="32" />}
                />
              </div>
            </div>
          </div>

          <div className="d--flex gap--md text--danger align-items--center ">
            <div className="label--control font--sm font--500 m-b--sm text--black-600  w-min--150">
              docType
            </div>

            <div className="d--flex font--2sm w--full gap--sm align-items--center">
              <div className="w-max--350 w--full">
                <Controller
                  name="docType"
                  control={control}
                  render={({ field }) => (
                    <FormInput
                      {...field}
                      height="32"
                      extraClasses="w-min--60"
                    />
                  )}
                />
              </div>
              <div className="font--2sm white-space--nowrap text--success ">
                Ex: C := Consent , E := Eligibility
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="d--flex justify-content--end align-items--center gap--sm">
        <Button
          variant="primary"
          type="button"
          color="black"
          btnClasses="btn w-max--150 white-space--nowrap"
          disabled={isLoading}
          onClick={() => {
            setViewJsonFormat((p) => !p);
          }}
        >
          {!viewJsonFormat ? 'Json' : 'Back'}
          {/* {!viewJsonFormat ? 'View Json Format' : 'Back'} */}
        </Button>
        <Button
          variant="primary"
          color="black"
          btnClasses="btn w-max--120 "
          disabled={isLoading}
        >
          {submitBtnTxt(isLoading, 'Run')}
        </Button>
      </div>
    </form>
  );
};

export default ConsentWebCode;
