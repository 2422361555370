import React from 'react';
import TableManager from '../../../components/TableManager';
import { agentStatusLookup, useUpdateInviteStatusMutate } from '../constants';
import Button from '../../../components/Button';
import { getAgentInviteList } from '../../../api';
import { convertDateFunction } from '../../../helpers/functions';
import ActionConfirmation from '../../../components/ActionConfirmation';
import { useQueryClient } from '@tanstack/react-query';

const AgentInvitation = () => {
  const queryClient = useQueryClient();
  const { updateInviteStatusMutate } = useUpdateInviteStatusMutate();

  const handleUpdateStatus = (status, rowData) => {
    updateInviteStatusMutate(
      { uuId: rowData?._id, status },
      {
        onSuccess: () => {
          queryClient?.invalidateQueries(['getAgentInviteList']);
        },
      },
    );
  };

  const columns = [
    {
      accessorKey: 'agencyDetail.agencyName',
      header: () => 'Agency Name',
    },
    {
      accessorKey: 'agencyDetail.agencyPhone',
      header: () => 'Agency Phone',
    },
    {
      accessorKey: 'agencyDetail.agencyEmail',
      header: () => 'Agency Email',
    },
    {
      accessorKey: 'agencyDetail.agencyNPN',
      header: () => 'Agency NPN',
    },
    {
      accessorKey: 'createdAt',
      header: () => 'Invite date',
      cell: ({ getValue }) => convertDateFunction(getValue()),
    },
    {
      // accessorKey: 'status',
      accessorKey: 'agentInvitationStatus',
      header: () => 'Status',
      cell: ({ getValue }) => {
        return (
          <span className={agentStatusLookup?.[getValue()]?.class}>
            {agentStatusLookup?.[getValue()]?.text}
          </span>
        );
      },
    },
    {
      accessorKey: 'action',
      header: () => 'Action',
      cell: ({ row }) => {
        const rowData = row?.original;
        if (rowData?.agentInvitationStatus === 'I') {
          return (
            <div className="d--flex gap--sm justify-content--center align-items--center">
              <ActionConfirmation
                message="Are you sure you want to accept this invite?"
                onConfirm={() => handleUpdateStatus('A', rowData)}
              >
                <Button
                      btnClasses="btn  "
                      variant="success-100"
                      color="success"
                  size="sm"
                >
                  Accept
                </Button>
              </ActionConfirmation>
              <ActionConfirmation
                message="Are you sure you want to decline this invite?"
                onConfirm={() => handleUpdateStatus('D', rowData)}
              >
                <Button
               btnClasses="btn  "
               variant="danger-100"
               color="danger"
                  size="sm"
                >
                  Decline
                </Button>
              </ActionConfirmation>
            </div>
          );
        } else if (rowData?.agentInvitationStatus === 'A') {
          return (
            <ActionConfirmation
              message="Are you sure you want to release this invite?"
              onConfirm={() => handleUpdateStatus('R', rowData)}
            >
              <Button
               
                btnClasses="btn  "
                variant="danger-100"
                color="danger"
                size="sm"
              >
                Release
              </Button>
            </ActionConfirmation>
          );
        }
      },
      meta: {
        textAlign: 'center',
        width: '8%',
      },
    },
  ];
  return (
    <>
      <div className="w--full d--flex flex--column  invitationPage">
        <TableManager
          {...{
            fetcherKey: 'getAgentInviteList',
            fetcherFn: getAgentInviteList,
            shouldFilter: false,
            name: 'Invites',
            columns,
          }}
        />
      </div>
    </>
  );
};

export default AgentInvitation;
