import React from 'react';
import Pdf from '../../../../assets/images/pdf.png';
import { useQuery } from '@tanstack/react-query';
import { getGoogleDriveFilesList } from '../../../UploadDrive/actions';

const DriveFiles = ({ folderId }) => {
  const { data: googleDriveFiles, isLoading } = useQuery({
    queryKey: ['getGoogleDriveFilesList', folderId],
    queryFn: () => getGoogleDriveFilesList({ folderId }),
    select: (data) => data?.data?.data,
    enabled: Boolean(folderId),
  });

  return (
    <div className="w--full grid--3 grid--3--xl d--grid gap--md">
      {isLoading
        ? 'Loading...'
        : googleDriveFiles && googleDriveFiles?.length > 0
        ? googleDriveFiles?.map(({ url, filename }) => {
            return (
              <div
                className=" radius--sm p--sm d--flex gap--sm align-items--center c--pointer"
                onClick={() => window.open(url)}
              >
                <div className="w-min--40 h-min--40 w-max--40 h-max--40 d--flex align-items--center justify-content--center">
                  <img
                    src={Pdf}
                    alt="..."
                    className=" object--contain radius--sm bg--black-100 "
                  />
                </div>
                <div className="font--600 font--sm w--full text--black-600">
                  {filename}
                </div>
              </div>
            );
          })
        : 'No files available'}
    </div>
  );
};

export default DriveFiles;
