import { useCallback, useState } from 'react';
import useIcons, {
  ClaimCompletedIcon,
  ClaimInprogressIcon,
  ClaimNewIcon,
  ClaimTotalIcon,
  ClaimUholdIcon,
  SignedConsents,
  SignedEligibilities,
} from '../../../assets/icons/useIcons';
import { useQuery } from '@tanstack/react-query';
import {
  getDashboarConsentAndEligibilityList,
  getDashboarConsentNoEligibilityList,
  getDashboardClaimSates,
} from '../actions';
import ViewCarrierDetails from '../../Claims/View/ViewCarrierDetails';
import ReplyClaim from '../../Claims/View/ReplyClaim';
import AccessControl from '../AccessControl';
import { AGENCY_USER, AGENT, CARRIER } from '../../../helpers/constants';
import CustomChart from '../../../components/Chart';
import TableManager from '../../../components/TableManager';
import AsideModal from '../../../components/AsideModal';
import Modal from '../../../components/Modal';
import UploadClaim from '../../Claims/View/UploadClaim';
import MemberChart from '../Chart/MemberChart';

export default function MemberDashBoard() {
  const [openModal, setOpenModal] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [rowData, setRowData] = useState(false);

  const { AgentsIcon, MemberIcon } = useIcons();

  // const { data: dashboardData } = useQuery({
  //   queryKey: 'getDashboardSates',
  //   queryFn: () => getDashboardSates(),
  //   select: (data) => data?.data?.data,
  // });
  const { data: dashboardData } = useQuery({
    queryKey: 'getDashboardClaimSates',
    queryFn: () => getDashboardClaimSates(),
    select: (data) => data?.data?.data,
  });
  const {
    dashboardSatesResponseData,
    totalAgents = 0,
    totalMembers = 0,
    totalSignedConsents = 0,
    totalSignedEligibilities = 0,
    totalClaim = 0,
    totalCompletedClaim = 0,
    totalInProgressClaim = 0,
    totalNewClaim = 0,
    totalOnHoldClaim = 0,
  } = dashboardData || {};

  const noSignedColumn = [
    {
      accessorKey: 'firstName',
      header: () => 'Full Name',
      cell: ({ row }) =>
        `${row?.original?.firstName} ${row?.original?.lastName}`,
    },

    {
      accessorKey: 'email',
      header: () => 'Email',
    },
    {
      accessorKey: 'phone',
      header: () => 'Phone',
    },
  ];

  const handleClose = useCallback(() => {
    setOpenModal(false);
  }, []);

  const modalLookUp = {
    view: {
      component: (
        <ViewCarrierDetails handleClose={handleClose} rowData={rowData} />
      ),
      width: 'lg',
      size: 1200,
      title: 'Claim Details',
    },
    reply: {
      component: <ReplyClaim handleClose={handleClose} rowData={rowData} />,
      width: 'lg',
      size: 1200,
      title: 'Claim Chat ',
    },
  };
  return (
    <div className="w--full h--full d--flex flex--column gap--lg dashboard-page ">
      <div className="d--flex w--full gap--lg">
        <div className="d--flex flex--column w--full gap--lg">
          <div className="w--full p--md bg--white radius--md d--flex flex--column align-items--center justify-content--center gap--md h-min--100 c--pointer overflow--hidden dashboard-page-chart">
            <MemberChart {...{ graphData: undefined }} />
          </div>
        </div>
      </div>
    </div>
  );
}
