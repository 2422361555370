import React, { useEffect } from 'react';
import useIcons from '../../../assets/icons/useIcons';
import FormInput from '../../../components/FormInput';
import PDF from '../../../assets/images/pdf.png';
import { useMutation } from '@tanstack/react-query';
import { getChats } from '../../../api';
import { useAuth } from '../../../hooks/useAuth';
const ReplyClaim = () => {
  const { PinIcon, SendIcon, SmileIcon } = useIcons();

  return (
    <div className="chatPage w--full d-flex ">
      <div className="d--flex w--full h--full ">
        <div className="chatListSec w--full w-max--400 bg--white d--flex flex--column radius--sm">
          {/* <div className="font--md font--600 d--flex align-items--center justify-content--between p--md">
            Claim Details
          </div> */}
          <div className="w--full h--full overflow--auto chatList claimDetailsList p--md d--flex flex--column">
            <div className="d--flex w--full gap--md flex--column">
              {/* Member form */}
              <div className="w--full bg--contrast p--md radius--sm d--flex flex--column gap--md ">
                <div className="w--full orText border-top--black-200 m-t--lg d--flex align-items--center justify-content--center m-b--xl position--relative">
                  <span className="w-min--60 font--sm p--md bg--white d--flex justify-content--center align-items--center position--absolute top:--5 text--black">
                    Consumer Information
                  </span>
                </div>
                <div className="d--flex gap--md">
                  <div className="w--full">
                    <h6 className="font--sm font--500 text--secondary m-b--sm">
                      First Name
                    </h6>
                    <h3 className="font--sm font--400 text--grey">Jhone </h3>
                  </div>
                  <div className="w--full">
                    <h6 className="font--sm font--500 text--secondary m-b--sm">
                      Last Name
                    </h6>
                    <h3 className="font--sm font--400 text--grey">Smith</h3>
                  </div>
                </div>
                <div className="d--flex gap--md">
                  <div className="w--full">
                    <h6 className="font--sm font--500 text--secondary m-b--sm">
                      Email
                    </h6>
                    <h3 className="font--sm font--400 text--grey">
                      demo@gmail.com{' '}
                    </h3>
                  </div>
                  <div className="w--full">
                    <h6 className="font--sm font--500 text--secondary m-b--sm">
                      Phone
                    </h6>
                    <h3 className="font--sm font--400 text--grey">
                      +123456789
                    </h3>
                  </div>
                </div>
                <div className="d--flex gap--md">
                  <div className="w--full">
                    <h6 className="font--sm font--500 text--secondary m-b--sm">
                      Date of Birth
                    </h6>
                    <h3 className="font--sm font--400 text--grey">
                      08/09/1994
                    </h3>
                  </div>
                </div>
              </div>

              {/* Agent Form */}
              <div className="w--full bg--contrast p--md radius--sm d--flex flex--column gap--md">
                <div className="w--full orText border-top--black-200 m-t--lg d--flex align-items--center justify-content--center m-b--xl position--relative">
                  <span className="w-min--60 font--sm p--md bg--white d--flex justify-content--center align-items--center position--absolute top:--5 text--black">
                    Agent Information
                  </span>
                </div>
                <div className="d--flex gap--md">
                  <div className="w--full">
                    <h6 className="font--sm font--500 text--secondary m-b--sm">
                      First Name
                    </h6>
                    <h3 className="font--sm font--400 text--grey">Jhone </h3>
                  </div>
                  <div className="w--full">
                    <h6 className="font--sm font--500 text--secondary m-b--sm">
                      Last Name
                    </h6>
                    <h3 className="font--sm font--400 text--grey">Smith</h3>
                  </div>
                </div>
                <div className="d--flex gap--md">
                  <div className="w--full">
                    <h6 className="font--sm font--500 text--secondary m-b--sm">
                      Email
                    </h6>
                    <h3 className="font--sm font--400 text--grey">
                      demo@gmail.com{' '}
                    </h3>
                  </div>
                  <div className="w--full">
                    <h6 className="font--sm font--500 text--secondary m-b--sm">
                      Phone
                    </h6>
                    <h3 className="font--sm font--400 text--grey">
                      +123456789
                    </h3>
                  </div>
                </div>
                <div className="d--flex gap--md">
                  <div className="w--full">
                    <h6 className="font--sm font--500 text--secondary m-b--sm">
                      NPN
                    </h6>
                    <h3 className="font--sm font--400 text--grey">74644646</h3>
                  </div>
                </div>
              </div>

              {/* Claim form */}
              <div className="w--full bg--contrast p--md radius--sm d--flex flex--column gap--md">
                <div className="w--full orText border-top--black-200 m-t--lg d--flex align-items--center justify-content--center m-b--xl position--relative">
                  <span className="w-min--60 font--sm p--md bg--white d--flex justify-content--center align-items--center position--absolute top:--5 text--black">
                    Claim Information
                  </span>
                </div>
                <div className="d--flex gap--md">
                  <div className="w--full">
                    <h6 className="font--sm font--500 text--secondary m-b--sm">
                      Consumer ID
                    </h6>
                    <h3 className="font--sm font--400 text--grey ">
                      JHJ98765YU{' '}
                    </h3>
                  </div>
                  <div className="w--full">
                    <h6 className="font--sm font--500 text--secondary m-b--sm">
                      Policy ID
                    </h6>
                    <h3 className="font--sm font--400 text--grey ">654643</h3>
                  </div>
                </div>
                <div className="d--flex gap--md">
                  <div className="w--full">
                    <h6 className="font--sm font--500 text--secondary m-b--sm">
                      Policy Effective Date
                    </h6>
                    <h3 className="font--sm font--400 text--grey ">
                      12/05/2023{' '}
                    </h3>
                  </div>
                  <div className="w--full">
                    <h6 className="font--sm font--500 text--secondary m-b--sm">
                      Subject{' '}
                    </h6>
                    <h3 className="font--sm font--400 text--grey ">Design</h3>
                  </div>
                </div>
              </div>
            </div>
            <h3 class="font--md font--600 text--grey m-b--sm m-t--sm">Notes</h3>
            <div className="d--flex w--full gap--md flex--column">
              <div className="d--flex notes-box">
                <div className="w-max--1200 w--auto bg--contrast p--md radius--sm d--flex flex--column gap--sm ">
                  <div className="d--flex  gap--sm align-items--center justify-content--start">
                    <div className="uploadItem radius--sm bg--white w-min--60 w-max--60 h-min--60 h-max--60 ">
                      <img
                        src={PDF}
                        className="w-min--60 w-max--60 h-min--60 h-max--60 object--contain"
                        alt="..."
                      />
                    </div>
                  </div>
                  <h5 className="font--sm font--400 text--grey">
                    Lorem ipsum dolor sit amet consectetur.
                  </h5>
                </div>
              </div>
              <div className="d--flex notes-box">
                <div className="w-max--1200 w--auto bg--contrast p--md radius--sm d--flex flex--column gap--sm ">
                  <div className="d--flex  gap--sm align-items--center justify-content--start">
                    <div className="uploadItem radius--sm bg--white w-min--60 w-max--60 h-min--60 h-max--60 ">
                      <img
                        src={PDF}
                        className="w-min--60 w-max--60 h-min--60 h-max--60 object--contain"
                        alt="..."
                      />
                    </div>
                  </div>
                  <h5 className="font--sm font--400 text--grey">
                    Lorem ipsum dolor sit amet consectetur. ipsum dolor sit amet
                    consectetur.
                  </h5>
                </div>
              </div>
              {/* <div className="w-max--1200 bg--contrast p--md radius--sm d--flex flex--column gap--sm ">
          <div className="d--flex  gap--sm ">
            <div className="uploadItem radius--sm bg--white w-min--60 w-max--60 h-min--60 h-max--60 ">
              <img
                src={PDF}
                className="w-min--60 w-max--60 h-min--60 h-max--60 object--contain"
                alt="..."
              />
            </div>
            <div className="d--flex  gap--sm flex--column">
              <h5 className="font--sm font--500 text--secondary">
                Upload Date & Time
              </h5>
              <h5 className="font--sm font--400 text--grey">
                10:00: AM, 10/05/2023
              </h5>
            </div>
          </div>
          <h5 className="font--sm font--400 text--grey">
            Lorem ipsum dolor sit amet consectetur. Odio sed felis curabitur
            nulla sed mi accumsan lectus auctor.
          </h5>
        </div>
        <div className="w-max--1200 bg--contrast p--md radius--sm d--flex flex--column gap--sm ">
          <div className="d--flex  gap--sm ">
            <div className="uploadItem radius--sm bg--white w-min--60 w-max--60 h-min--60 h-max--60 ">
              <img
                src={PDF}
                className="w-min--60 w-max--60 h-min--60 h-max--60 object--contain"
                alt="..."
              />
            </div>
            <div className="d--flex  gap--sm flex--column">
              <h5 className="font--sm font--500 text--secondary">
                Upload Date & Time
              </h5>
              <h5 className="font--sm font--400 text--grey">
                10:00: AM, 10/05/2023
              </h5>
            </div>
          </div>
          <h5 className="font--sm font--400 text--grey">
            Lorem ipsum dolor sit amet consectetur. Odio sed felis curabitur
            nulla sed mi accumsan lectus auctor. Consequat urna enim auctor sed
            velit. Amet interdum urna gravida nec eu. Vel nibh ut odio at id
            dictum aenean pellentesque. Imperdiet sagittis habitant at sed
            facilisis elementum interdum etiam. Eleifend ullamcorper leo eget
            nibh auctor nunc platea.Varius maecenas ac commodo.{" "}
          </h5>
        </div> */}
            </div>
          </div>
        </div>
        <div className="w--full chatRightContent bg--white radius--sm d--flex flex--column ">
          <div className="chatHead h--full h-max--60 border-bottom--black-100 d--flex p-t--md p-b--md p-l--md p-r--md">
            <div className="d--flex gap--sm  align-items--center w--full ">
              <div className="chtaAvtar w-min--40 w-max--40 h-min--40 h-max--40 radius--full bg--primary-100 text--primary d--flex align-items--center justify-content--center position--relative">
                A
                <div className="chatDots w-min--10 w-max--10 h-min--10 h-max--10 radius--full position--absolute bg--success-800 border-full--white"></div>
              </div>
              <div className="w--full d--flex flex--column  gap--xs">
                <div className="w--full font--sm font--500  text--grey">
                  Eva Walker
                </div>
                <div className="w--full font--400 text--success">Typing...</div>
              </div>
            </div>
            <div className="w--full w--max--300 d--flex justify-content--end align-items--center"></div>
          </div>
          <div className="chatContent claimChatSidebarContent overflow--auto h--full p-t--md p-b--md p-l--xl p-r--xl position--relative">
            <div className="incomingMsg w--full m-b--lg">
              <div className="w--sm ">
                <div className="w-max--500 w--full d--flex flex--column position--relative">
                  <div className="d--flex ">
                    <div className="w--auto w-min--75 d--flex  bg--primary-100 radius--lg p--lg line-height--1-dot-5 text--grey">
                      Wow! Today is October 30th! Halloween is tomorrow already!
                      Have you decided what you will be dressing up for
                      Halloween yet, Sara?
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="outgoingMsg w--full m-b--lg d--flex justify-content--end ">
              <div className="w--sm d--flex justify-content--end  ">
                <div className="w-max--500   w--full d--flex flex--column position--relative row-reverse">
                  <div className="d--flex ">
                    <div className="w--auto w-min--75 d--flex  bg--primary text--white  radius--lg p--lg line-height--1-dot-5">
                      I want to be Batman! I like wearing the cape and the mask.
                      I think you should be a butterfly. You are so much shorter
                      than me, and dressed in a pumpkin costume, someone might
                      think you really are one and try to make a pie out of you.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="incomingMsg w--full m-b--lg">
              <div className="w--sm ">
                <div className="w-max--500 w--full d--flex flex--column position--relative">
                  <div className="d--flex ">
                    <div className="w--auto w-min--75 d--flex  bg--primary-100 radius--lg p--lg line-height--1-dot-5">
                      <img
                        src={PDF}
                        className="w-min--100 w-max--100 h-min--100 h-max--100 object--contain"
                        alt="..."
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="outgoingMsg w--full m-b--lg d--flex justify-content--end ">
              <div className="w--sm d--flex justify-content--end  ">
                <div className="w-max--500   w--full d--flex flex--column position--relative row-reverse">
                  <div className="d--flex ">
                    <div className="w--auto w-min--75 d--flex  bg--primary text--white  radius--lg p--lg line-height--1-dot-5">
                      I want to be Batman! I like wearing the cape and the mask.
                      I think you should be a butterfly. You are so much shorter
                      than me, and dressed in a pumpkin costume, someone might
                      think you really are one and try to make a pie out of you.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="chatFooter h--full h-max--60 border-top--black-100 p-t--sm p-b--md p-l--md p-r--md d--flex gap--sm">
            <div className="text--black-600 c--pointer w--full font--xs font--300 w-min--36 w-max--36  radius--sm d--flex align-items--center justify-content--center">
              <SmileIcon width={20} height={20} />
            </div>
            <div className="w--full">
              <FormInput
                name="search"
                extraClasses="border--0 no-focus"
                placeholder="Type your Message...."
              />
            </div>
            <div className="d--flex gap--sm">
              <div className="text--black-600 c--pointer w--full font--xs font--300 w-min--36 w-max--36  radius--sm d--flex align-items--center justify-content--center">
                <PinIcon width={20} height={20} />
              </div>
              <div className="c--pointer w--full font--xs font--300 w-min--36 w-max--36 h-min--36 h-max--36 bg--primary text--white  radius--full d--flex align-items--center justify-content--center">
                <SendIcon width={20} height={20} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReplyClaim;
