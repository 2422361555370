import React from 'react';
import useIcons from '../../../../../assets/icons/useIcons';
import gmailSmtpVideo from '../../../../../assets/video/emailSmtp.mp4';

const GmailInfo = ({ setShowInfo = () => {} }) => {
  const { CrossIcon } = useIcons();

  return (
    <div className="d--flex flex--column  gap--xl flex--column border-full--black-100 radius--sm w--full p--md m-t--md position--relative">
      <div
        className="toggle-sidebar-icon d--flex align-items--center justify-content--center radius--full  bg--black  text--white 
position--absolute w-max--28 w-min--28 h-max--28 h-min--28 box--shadow c--pointer  top---13 right---10 c--pointer"
        onClick={() => setShowInfo(false)}
      >
        <CrossIcon width={12} height={12} />
      </div>
      <div className="d--flex flex--column gap--sm w--full ">
        <div className="font--sm font--600 bg--primary-100 border-full--primary text--black radius--sm h-min--40 d--flex align-items--center p-l--md">
          - To set up Gmail's SMTP server for sending emails, you'll need the
          following details:
          {/* Reference video for SMTP Gmail setup */}
        </div>
        <div className="w--full d--flex flex--column gap--lg font--sm">
          {/* <div className="w--full ">
            <div className="font--600"> 1. Create a Google Cloud project:</div>
            <div className="d--flex flex--column gap--sm font--sm p--md">
              <div className="w--full">
                - Go to the Google Cloud Console.
                (https://console.cloud.google.com)
              </div>
              <div className="w--full">- Create a new project.</div>
            </div>
          </div>

          <div className="w--full ">
            <div className="font--600">2. Enable the Google Drive API:</div>
            <div className="d--flex flex--column gap--sm font--sm p--md">
              <div className="w--full">
                - In the APIs & Services dashboard, enable the Google Drive API.
              </div>
            </div>
          </div>

          <div className="w--full ">
            <div className="font--sm font--600">
              3. Create OAuth2 credentials:
            </div>
            <div className="d--flex flex--column gap--sm font--sm p--md">
              <div className="w--full">- Go to the Credentials page.</div>
              <div className="w--full">
                - Click on "Create Credentials" and choose "OAuth 2.0 Client
                ID".
              </div>
              <div className="w--full">
                - Configure the OAuth consent screen if you haven't already.
              </div>
              <div className="w--full">
                - Choose "Web application" and set the redirect URIs.
              </div>
            </div>
          </div> */}

          {/* <div className="w--full">
            <span className="font--600 ">Redirect URI</span>:{' '}
            {process.env.REACT_APP_BASE_API_URL}storage
          </div> */}
          {/* <div className="w--full">
            <span className="font--600">Info</span>: Add this redirect URI in
            your google console Web App before configure .
          </div> */}
          <div className="w--full">
            <span className="font--600">SMTP Host</span>: smtp.gmail.com
          </div>
          <div className="w--full">
            <span className="font--600">SMTP Port and Type</span>: For type SSL
            port will be 465 and for type TLS port will be 587
          </div>
          <div className="w--full">
            <span className="font--600">Username and From Address</span>: Your
            full Gmail email address (e.g. youremail@gmail.com)
          </div>
          <div className="w--full">
            <span className="font--600">Password</span>: Your Gmail App Password
            (if you have 2-Step Verification enabled then you can create App
            Password)
          </div>
        </div>
        <div className="w--full d--flex align-items--center justify-content--center mx--auto m-t--xl w-max--600">
          <video src={gmailSmtpVideo} controls className="w--full"></video>
        </div>
      </div>
    </div>
  );
};

export default GmailInfo;
