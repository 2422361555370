import React, { useState } from 'react';
import { convertDateFunction } from '../../../../../../helpers/functions';
import { EyeIcon } from '../../../../../../assets/icons/useIcons';
import WebhookResponse from './WebhookResponse';
import WebhookError from './WebhookError';
import TableManager from '../../../../../../components/TableManager';
import { getWebhookList } from '../../../../../MyAccount/actions';
import Modal from '../../../../../../components/Modal';

const WebhookTable = () => {
  const [viewModal, setViewModal] = useState(false);
  const [rowData, setRowData] = useState(false);

  const columns = [
    {
      accessorKey: 'createdAt',
      header: () => 'Date',
      cell: ({ getValue }) => convertDateFunction(getValue()),
    },
    {
      accessorKey: 'webhookResponse',
      header: () => 'Posted Payload',
      cell: ({ getValue }) => {
        const elem = getValue();
        return (
          <div
            onClick={() => {
              setViewModal('webhookResponse');
              setRowData(elem);
            }}
            className="c--pointer"
          >
            {' '}
            <EyeIcon width={18} height={18} />
          </div>
        );
      },
      meta: {
        textAlign: 'center',
      },
    },
    // {
    //   accessorKey: 'webhookErrors',
    //   header: () => 'Webhook Error',
    //   cell: ({ getValue }) => {
    //     const elem = getValue();
    //     return (
    //       <div
    //         onClick={() => {
    //           setViewModal('webhookErrors');
    //           setRowData(elem);
    //         }}
    //         className="c--pointer"
    //       >
    //         {' '}
    //         <EyeIcon width={18} height={18} />
    //       </div>
    //     );
    //   },
    //   meta: {
    //     textAlign: 'center',
    //   },
    // },
    {
      accessorKey: 'claimResponse',
      header: () => 'Claim Response',
      cell: ({ getValue }) => {
        const elem = getValue();
        return (
          <div
            onClick={() => {
              setViewModal('claimResponse');
              setRowData(elem);
            }}
            className="c--pointer"
          >
            {' '}
            <EyeIcon width={18} height={18} />
          </div>
        );
      },
      meta: {
        textAlign: 'center',
      },
    },
    // {
    //   accessorKey: 'webhookMode',
    //   header: () => 'Mode',
    //   meta: {
    //     textAlign: 'center',
    //   },
    // },
    {
      accessorKey: 'status',
      header: () => 'Status',
      cell: ({ getValue }) => {
        return getValue() === 'PASS' ? (
          <div className="text--c text--success bg--success-100 p--sm radius--xs">
            Pass
          </div>
        ) : getValue() === 'FAIL' ? (
          <div className="text--c text--danger bg--danger-100 p--sm radius--xs">
            Fail
          </div>
        ) : (
          <div className="text--c text--danger bg--warning-100 p--sm radius--xs">
            Not Found
          </div>
        );
      },
      meta: {
        textAlign: 'center',
        width: '8%',
      },
    },
  ];

  function handleClose() {
    setViewModal('');
  }

  const modalLookUp = {
    webhookResponse: {
      component: (
        <WebhookResponse handleClose={handleClose} rowData={rowData} />
      ),
      title: 'Posted Payload',
    },
    webhookErrors: {
      component: <WebhookError handleClose={handleClose} rowData={rowData} />,
      title: 'Webhook Error',
    },
    claimResponse: {
      component: (
        <WebhookResponse handleClose={handleClose} rowData={rowData} />
      ),
      title: 'Claim Response',
    },
  };

  return (
    <div className="w--full d--flex flex--column  invitationPage">
      <TableManager
        {...{
          fetcherKey: 'getWebhookList',
          fetcherFn: getWebhookList,
          shouldFilter: false,
          columns,
          showGlobalSearch: false,
          showHeader: false,
        }}
      />

      {viewModal ? (
        <Modal
          handleClose={handleClose}
          title={modalLookUp?.[viewModal]?.title}
          footerComponent={null}
          headerComponent={null}
          size="600"
        >
          {modalLookUp?.[viewModal]?.component}
        </Modal>
      ) : null}
    </div>
  );
};

export default WebhookTable;
