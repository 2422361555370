export const API_ROUTES = {
  CONSENT_KEY: {
    METHOD: 'POST',
    URL: 'webhook/consent?conxentkey=',
  },
  CONSENTKEY_LIST: {
    METHOD: 'GET',
    URL: 'webhook/consent-list',
  },
};
