import { useState } from 'react';

export default function useCopyText() {
  const [copied, setCopied] = useState(false);
  const handleCopy = (copyText) => {
    navigator.clipboard
      .writeText(copyText)
      .then(() => {
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 1000);
      })
      .catch((err) => {
        console.error('Failed to copy text: ', err);
      });
  };
  return { copied, handleCopy };
}
