import React, { useState } from 'react';
import TableManager from '../../components/TableManager';
import { getAgentList } from './actions';
import Dropdown from '../../components/Dropdown';
import {
  convertDateFunction,
  createPrivateChatId,
} from '../../helpers/functions';
import { agentStatusLookup } from '../Invitation/constants';
import ActionConfirmation from '../../components/ActionConfirmation';
import { useQueryClient } from '@tanstack/react-query';
import Button from '../../components/Button';
import { dropEl } from '../../helpers/constants';
import Modal from '../../components/Modal';
import { useUpdateConsumerStatusMutate } from './constants';
import { useNavigate } from 'react-router-dom';
import { EyeIcon } from '../../assets/icons/useIcons';
import { useChat } from '../../hooks/useChat';
import { useSocket } from '../../hooks/useSocket';
import { useAlert } from '../../hooks/useAlert';
import { useAuth } from '../../hooks/useAuth';
import { getMemberEnrollmentList } from '../Members/actions';
import AsideModal from '../../components/AsideModal';
import HRAView from '../HRA/HRAView';

const Agents = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [elemData, setElemData] = useState({});
  const [openAsideModal, setOpenAsideModal] = useState(false);

  const queryClient = useQueryClient();
  const { user } = useAuth();
  const { chats } = useChat();
  const { socket } = useSocket();
  const { showAlert } = useAlert();
  const { updateConsumerStatusMutate } = useUpdateConsumerStatusMutate();

  const handleUpdateStatus = (status, rowData) => {
    updateConsumerStatusMutate(
      { uuId: rowData?._id, status },
      {
        onSuccess: () => {
          queryClient?.invalidateQueries(['getAgentList']);
        },
      },
    );
  };

  function handleCreateChat(rowData) {
    if (!rowData?.agentDetail?._id) {
      showAlert({ type: 'danger', message: 'Agent does not exist.' });
      return;
    }

    const chatId = createPrivateChatId([user?._id, rowData?.agentDetail?._id]);

    const consumerData = {
      firstname: user?.firstName,
      email: user?.email,
      lastname: user?.lastName,
      consumerId: user?._id,
    };
    const agentData = {
      firstname: rowData?.agentDetail?.firstName,
      email: rowData?.agentDetail?.email,
      lastname: rowData?.agentDetail?.lastName,
      agentId: rowData?.agentDetail._id,
    };

    const ifElementExist =
      chats && chats?.length > 0
        ? chats?.find((chat) => chat?.chatId === chatId)
        : null;

    if (ifElementExist) {
      navigate(`/chat/${ifElementExist?.chatId}`);
    } else {
      socket?.emit('create-new-chat', {
        chatId,
        claimId: rowData?.claimId,
        users: [agentData, consumerData],
      });

      navigate(`/chat/${chatId}`);
    }
  }

  const handleProfileDropClick = (clickCase = '', rowData, item) => {
    switch (clickCase) {
      case 'release':
        setShow(true);
        setElemData(rowData);
        break;
      case 'sign_consent':
        navigate(`/signature-document/${rowData?.agreementUuid}?docType=CMS`);

        break;
      case 'sign_eligibility':
        navigate(
          `/signature-document/${rowData?.eligibilityAgreementUuid}?docType=ELIGIBILITY`,
        );
        break;
      case 'chat':
        handleCreateChat(rowData);
        break;
      case 'view':
        window.open(
          `/view-document?${
            item?.id === 4
              ? rowData?.eligibilityAgreementUuid
              : rowData?.agreementUuid
          }?type=${item?.id === 4 ? 'ELIGIBILITY' : 'CMS'}`,
        );

        break;
      case 'view-hra':
        setOpenAsideModal(true);
        setElemData(rowData);

        break;

      default:
        break;
    }
  };

  const columns = [
    {
      accessorKey: 'agentDetail',
      header: () => '',
      columns: [
        {
          accessorKey: 'agentDetail.firstName',
          header: () => 'Name',
          cell: ({ row }) => {
            const element = row?.original?.agentDetail;
            return `${element?.firstName} ${element?.lastName}`;
          },
        },
        {
          accessorKey: 'agentDetail.email',
          header: () => 'Email',
        },
        {
          accessorKey: 'agentDetail.phone',
          header: () => 'Phone',
          meta: {
            textAlign: 'center',
          },
        },

        {
          accessorKey: 'agentDetail.NPN',
          header: () => 'NPN',
          meta: {
            textAlign: 'center',
          },
        },
      ],
      meta: {
        colSpan: 4,
        textAlign: 'center',
      },
    },
    {
      accessorKey: 'submittedDate',
      header: () => <span className="font--sm font--600">Signed Date</span>,
      columns: [
        {
          accessorKey: 'agentDetail.NPN',
          header: () => 'Consent',
          cell: (props) => {
            const rowData = props?.row?.original;
            // if (rowData?.consumerInvitationStatus === 'I') {
            //   return '---';
            // }
            if (rowData.signedDate) {
              return (
                <div className="d--flex align-items--center justify-content--center gap--sm">
                  {convertDateFunction(rowData.signedDate)}{' '}
                  <span
                    className="d--flex align-items--center justify-content--center c--pointer"
                    onClick={() => {
                      window.open(
                        // `/view-signed-pdf?${rowData?.agreementUuid}?type=CMS`,
                        `/view-document/${rowData?.agreementUuid}?docType=CMS`,
                      );
                    }}
                  >
                    <EyeIcon width={15} height={15} />
                  </span>
                </div>
              );
            }
            if (
              rowData?.consumerInvitationStatus === 'A' &&
              rowData?.submittedDate
            ) {
              return (
                <span
                  className="text--danger font--600 c--pointer"
                  onClick={() => {
                    navigate(
                      `/signature-document/${rowData?.agreementUuid}?docType=CMS`,
                    );
                  }}
                >
                  Click to Sign
                </span>
              );
            }
            return (
              <span className=" font--600  c--not-allowed opacity-5 ">---</span>
            );
          },
          meta: {
            textAlign: 'center',
          },
        },
        {
          accessorKey: 'agentDetail.NPN',
          header: () => 'Eligibility',
          cell: (props) => {
            const rowData = props?.row?.original;
            // if (rowData?.consumerInvitationStatus === 'I') {
            //   return '---';
            // }
            if (rowData.eligibilitySignedDate) {
              return (
                <div className="d--flex align-items--center justify-content--center gap--sm">
                  {convertDateFunction(rowData?.eligibilitySignedDate)}{' '}
                  <span
                    className="d--flex align-items--center justify-content--center c--pointer"
                    onClick={() => {
                      window.open(
                        // `/view-signed-pdf?${rowData?.eligibilityAgreementUuid}?type=ELIGIBILITY`,
                        `/view-document/${rowData?.eligibilityAgreementUuid}?docType=ELIGIBILITY`,
                      );
                    }}
                  >
                    <EyeIcon width={15} height={15} />
                  </span>
                </div>
              );
            }

            if (
              rowData?.consumerInvitationStatus === 'A' &&
              rowData?.signedDate &&
              rowData?.eligibilitySubmittedDate
            ) {
              return (
                <span
                  className="text--danger font--600 c--pointer"
                  onClick={() => {
                    navigate(
                      `/signature-document/${rowData?.eligibilityAgreementUuid}?docType=ELIGIBILITY`,
                    );
                  }}
                >
                  Click to Sign
                </span>
              );
            }
            return (
              <span className=" font--600  c--not-allowed opacity-5 ">---</span>
            );
          },
          meta: {
            textAlign: 'center',
          },
        },
      ],
      meta: {
        colSpan: 2,
        textAlign: 'center',
      },
    },
    {
      accessorKey: 'eligibilitySubmittedDate',
      header: () => '',
      columns: [
        {
          accessorKey: 'consumerInvitationStatus',
          header: () => 'Status',
          cell: ({ getValue }) => {
            return (
              <span className={agentStatusLookup?.[getValue()]?.class}>
                {agentStatusLookup?.[getValue()]?.text}
              </span>
            );
          },
          meta: {
            textAlign: 'center',
          },
        },
      ],
      meta: {
        colSpan: 1,
        textAlign: 'center',
      },
    },
    {
      accessorKey: 'status',
      header: () => '',
      columns: [
        {
          accessorKey: 'action',
          header: () => 'Action',
          cell: ({ row }) => {
            const rowData = row?.original;
            if (rowData?.consumerInvitationStatus === 'I') {
              return (
                <div className="d--flex gap--sm justify-content--center align-items--center">
                  <ActionConfirmation
                    message="Confirm you are accepting this agent as your Agent of Record(AOR)?"
                    onConfirm={() => handleUpdateStatus('A', rowData)}
                  >
                    <Button
                      btnClasses="btn  "
                      variant="success-100"
                      color="success"
                      size="sm"
                    >
                      Accept
                    </Button>
                  </ActionConfirmation>
                  <ActionConfirmation
                    message="Are you sure you want to decline?"
                    onConfirm={() => handleUpdateStatus('D', rowData)}
                  >
                    <Button
                      btnClasses="btn  "
                      variant="danger-100"
                      color="danger"
                      size="sm"
                    >
                      Decline
                    </Button>
                  </ActionConfirmation>
                </div>
              );
              // } else if (rowData?.consumerInvitationStatus === 'I') {
            } else if (rowData?.consumerInvitationStatus === 'A') {
              return (
                <div className="d--inline-block">
                  <Dropdown
                    closeOnClickOutside={true}
                    dropList={{
                      component: ({ item }) => {
                        if (!item?.access) return;
                        return (
                          <div
                            className="text--secondary p--sm w--full text--l"
                            onClick={() =>
                              handleProfileDropClick(item?.value, rowData, item)
                            }
                          >
                            {item?.title}
                          </div>
                        );
                      },

                      data: [
                        {
                          id: 1,
                          title: 'Release',
                          value: 'release',
                          access: true,
                        },
                        // {
                        //   title: 'Sign Consent',
                        //   value: 'sign_consent',
                        //   id: 2,
                        //   access:
                        //     rowData?.submittedDate && !rowData?.signedDate,
                        // },
                        // {
                        //   title: 'Sign Eligibility',
                        //   value: 'sign_eligibility',
                        //   id: 4,
                        //   access:
                        //     rowData?.signedDate &&
                        //     rowData?.eligibilitySubmittedDate &&
                        //     !rowData?.eligibilitySignedDate,
                        // },
                        {
                          title: 'Chat',
                          value: 'chat',
                          id: 5,
                          access: true,
                        },
                        // {
                        //   title: 'View HRA',
                        //   value: 'view-hra',
                        //   id: 6,
                        //   access: true,
                        //   // access: props?.row?.original?.hra === 1,
                        // },
                      ],
                    }}
                  >
                    {dropEl}
                  </Dropdown>
                </div>
              );
            }
          },
          meta: {
            textAlign: 'center',
            width: '8%',
          },
        },
      ],
      meta: {
        textAlign: 'center',
      },
    },
  ];
  const moreOptioncolumns = [
    {
      accessorKey: 'firstName',
      header: () => '',
      columns: [
        {
          accessorKey: 'firstName',
          header: () => 'Name',
        },
        {
          accessorKey: 'email',
          header: () => 'Email',
        },
        {
          accessorKey: 'phone',
          header: () => 'Phone',
          meta: {
            textAlign: 'center',
          },
        },

        {
          accessorKey: 'NPN',
          header: () => 'NPN',
          meta: {
            textAlign: 'center',
          },
        },
      ],
      meta: {
        colSpan: 4,
        textAlign: 'center',
      },
    },
    {
      accessorKey: 'submittedDate',
      header: () => <span className="font--sm font--600">Signed Date</span>,
      columns: [
        {
          accessorKey: 'submittedDate',
          header: () => 'Consent',
          cell: (props) => {
            const rowData = props?.row?.original;

            if (rowData.signedDate) {
              return (
                <div className="d--flex align-items--center justify-content--center gap--sm">
                  {convertDateFunction(rowData.signedDate)}{' '}
                  <span
                    className="d--flex align-items--center justify-content--center c--pointer"
                    onClick={() => {
                      window.open(
                        `/view-document/${rowData?.agreementUuid}?docType=CMS`,
                      );
                    }}
                  >
                    <EyeIcon width={15} height={15} />
                  </span>
                </div>
              );
            }
            if (
              rowData?.consumerInvitationStatus === 'A' &&
              rowData?.submittedDate
            ) {
              return (
                <span
                  className="text--danger font--600 c--pointer"
                  onClick={() => {
                    navigate(
                      `/signature-document/${rowData?.agreementUuid}?docType=CMS`,
                    );
                  }}
                >
                  Click to Sign
                </span>
              );
            }
            return (
              <span className=" font--600  c--not-allowed opacity-5 ">
                ---
                {/* Signed */}
              </span>
            );
          },
          meta: {
            textAlign: 'center',
          },
        },
        {
          accessorKey: 'submittedDate',
          header: () => 'Eligibility',
          cell: (props) => {
            const rowData = props?.row?.original;

            if (rowData.eligibilitySignedDate) {
              return (
                <div className="d--flex align-items--center justify-content--center gap--sm">
                  {convertDateFunction(rowData?.eligibilitySignedDate)}{' '}
                  <span
                    className="d--flex align-items--center justify-content--center c--pointer"
                    onClick={() => {
                      window.open(
                        `/view-document/${rowData?.eligibilityAgreementUuid}?docType=ELIGIBILITY`,
                      );
                    }}
                  >
                    <EyeIcon width={15} height={15} />
                  </span>
                </div>
              );
            }

            if (
              rowData?.consumerInvitationStatus === 'A' &&
              rowData?.signedDate &&
              rowData?.eligibilitySubmittedDate
            ) {
              return (
                <span
                  className="text--danger font--600 c--pointer"
                  onClick={() => {
                    navigate(
                      `/signature-document/${rowData?.eligibilityAgreementUuid}?docType=ELIGIBILITY`,
                    );
                  }}
                >
                  Click to Sign
                </span>
              );
            }
            return (
              <span className="text--danger font--600  c--not-allowed opacity-5 ">
                ---
              </span>
            );
          },
          meta: {
            textAlign: 'center',
          },
        },
      ],
      meta: {
        colSpan: 2,
        textAlign: 'center',
      },
    },
    {
      accessorKey: 'eligibilitySubmittedDate',
      header: () => '',
      columns: [
        {
          accessorKey: 'consumerInvitationStatus12',
          header: () => 'Status',
          cell: ({ getValue }) => {
            return (
              <span className={agentStatusLookup?.[getValue()]?.class}>
                {agentStatusLookup?.[getValue()]?.text}
              </span>
            );
          },
          meta: {
            textAlign: 'center',
          },
        },
      ],
      meta: {
        colSpan: 1,
        textAlign: 'center',
      },
    },
    {
      accessorKey: 'status',
      header: () => '',
      columns: [
        {
          accessorKey: 'action',
          header: () => 'Action',

          meta: {
            textAlign: 'center',
            width: '8%',
          },
        },
      ],
      meta: {
        textAlign: 'center',
      },
    },
  ];

  return (
    <>
      <div className="w--full ">
        <TableManager
          {...{
            fetcherKey: 'getAgentList',
            fetcherFn: getAgentList,
            shouldFilter: false,
            name: 'Agents',
            columns,

            enableMoreOption: true,
            showMoreOption: (_) => _?.enrollment > 0,
            moreDataFunction: getMemberEnrollmentList,
            moreDataFnPayload: (_) => ({
              email: _?.agentDetail?.email,
            }),
            moreOptioncolumns,
          }}
        />
      </div>

      {show && (
        <Modal
          handleClose={() => setShow(false)}
          title={''}
          headerComponent={null}
          shouldCloseOnClickOutside={true}
          footerComponent={
            <div className="d--flex gap--sm justify-content--end w--full p-t--md">
              <Button
                type="button"
                variant="transparent"
                color="black"
                isOutline
                borderColor="primary"
                data-link-hover
                btnClasses="btn w-max--150 w-min--150"
                onClick={() => {
                  setShow(false);
                }}
              >
                No
              </Button>
              <Button
                type="button"
                variant="primary"
                color="black"
                btnClasses="btn w-max--150 w-min--150"
                onClick={() => {
                  setShow();
                  handleUpdateStatus('R', elemData);
                }}
              >
                Yes
              </Button>
            </div>
          }
          size="500"
          height="250"
        >
          <div className="font--md m-b--0 text--black-600 line-height--1-dot-5">
            Are you sure you want to release?
          </div>
        </Modal>
      )}

      {openAsideModal ? (
        <AsideModal
          handleClose={() => setOpenAsideModal(false)}
          title={'HRA'}
          footerComponent={null}
          headerComponent={null}
          size={'lg'}
          width={'lg'}
          // width="md"
        >
          <HRAView
            handleClose={() => setOpenAsideModal(false)}
            uuId={elemData?.agentDetail?._id}
          />
        </AsideModal>
      ) : null}
    </>
  );
};

export default Agents;
