import React, { useEffect, useState } from 'react';
import {
  defineComponents,
  DocumentReaderService,
} from '@regulaforensics/vp-frontend-document-components';
import Spinner from '../../components/Spinner';
import { toast } from 'react-toastify';

const LISENCE_BASE_64 =
  'AAEAAGaRGjix6fbVBxcFcKKO2gvHEhhoG28tuTyIAgtFGvXf2dyNuwZmZoxzau8kIKgvQ6ozVB5So4QHi9mkggz8iBXCU3t6h/Tw1HlJw3UuWld0eggk4dFOFVNZOr7pRMsx59kMF9sigbzuwA+hoLJ65INTf4NZaIJZpluO+HD5xdXlgzhXXK3T6p3qfrmbpbnzRIMABaW2UZLxS+yVBEPw6b5pv/4+L7ZvPd6so8YzWuHKi4dwZlb8jkBsUgOj+ESWOX9/JR/wmHjaIx44jG/4KLfIqT7Bv8E5JdrEKQ168KzTRyP2664jR15uZj/OH00abR8QP/3AyEjM06L2TA1r/HjkAAAAAAAAEL2jGT29pBAGnVzwNij+FxWPZcqrHJ3MN2H8CtA3+M5BgMcnnJiZJMzhkTWWydf4XSFDuDQJX6S3Jhbo6Yp+ogdyXZucsnf/akrb1DMWkxkUI4uNEJNtvTZOic4xB6ypENQKu3ZwBy0gqK5PeCRpLor4k1VtapXuzD+U3CTqns7nI2/rgNK/WX80Wqme6B94urq1WZXHVH45JPUBXgodYP8bsm/ubOEwYdwa3lrpB8n7uQrgJC9Vz1Y0TTGLHt8eFW3/fhk/YeP90nvVuuHs5f1ZVvId9MBk+HP8Pabgo3HC';

const StepOne = ({ setStep, setStepOne }) => {
  useEffect(() => {
    window.RegulaDocumentSDK = new DocumentReaderService();
    window.RegulaDocumentSDK.recognizerProcessParam = {
      processParam: {
        scenario: 'MrzAndLocate',
      },
    };

    defineComponents()
      .then(() => {
        window.RegulaDocumentSDK.initialize({ license: LISENCE_BASE_64 });
        const documentReaderComponent =
          document.querySelector('document-reader');

        if (documentReaderComponent) {
          documentReaderComponent.addEventListener(
            'document-reader',
            (event) => {
              console.log(event);
              if (event?.detail?.action === 'CAMERA_PROCESS_CLOSED') {
                toast.info('Cannot Skip Verification Process');
              }
              if (event?.detail?.action === 'CLOSE') {
                toast.info(
                  'Please click try again to complete the verification process',
                );
              }
              if (event?.detail?.data?.status === 0) {
                console.log('Failure: ' + event.detail);
                toast.error('Verification Failed');
              }
              if (event?.detail?.data?.status === 1) {
                setStep(2);
                setStepOne(true);
                console.log('Success: ' + event.detail);
              }
              if (event?.detail?.data?.status === 2) {
                console.log('Timeout: ' + event.detail);
              }
            },
          );
        }

        return () => {
          if (documentReaderComponent) {
            documentReaderComponent.removeEventListener('document-reader');
          }
        };
      })
      .catch((err) => console.error(err));
  }, []);

  return (
    <div className="w--full h--full">
      <document-reader></document-reader>
    </div>
  );
};

const StepTwo = ({ setStepTwo }) => {
  function listener(event) {
    if (event?.detail?.action === 'CAMERA_PROCESS_CLOSED') {
      toast.info('Cannot Skip Verification Process');
    }
    if (event?.detail?.action === 'CLOSE') {
      toast.info('Cannot Skip Verification Process');
    }

    if (event?.detail?.data?.status === 0) {
      toast.error('Verification Failed');
    }

    if (
      event?.detail?.action === 'PROCESS_FINISHED' &&
      event?.detail?.data?.status === 1
    ) {
      setStepTwo(true);
    }
  }
  useEffect(() => {
    const component = document.getElementsByTagName('face-capture')?.[0];

    if (component) {
      component.addEventListener('face-capture', listener);
    }
  }, []);

  return (
    <div className="w--full h--full m-t--lg">
      <face-capture></face-capture>
    </div>
  );
};

const Verification = ({
  setStepOne = () => {
    return;
  },
  setStepTwo = () => {
    return;
  },
  isLoading = false,
}) => {
  const [step, setStep] = useState(1);

  if (isLoading) {
    return (
      <div className="d--flex align-items--center justify-content--center h--full w--full">
        <Spinner size="lg" />
      </div>
    );
  }

  return (
    <div className="w--full h--full h-min--300">
      <div className=" w--full verification-header d--flex align-items--center justify-content--center p-t--md p-l--sm p-r--sm m-b--sm">
        <h3 className="font--lg font--600">
          {step === 1 ? 'Document Verification' : 'Photo Verification'}
        </h3>
      </div>
      {step === 1 && <StepOne setStep={setStep} setStepOne={setStepOne} />}
      {step === 2 && <StepTwo setStepTwo={setStepTwo} />}
    </div>
  );
};

export default Verification;
