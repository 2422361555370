import * as yup from 'yup';
import {
  requiredFieldFunction,
  requiredFieldText,
} from '../../helpers/yupValidations';
export const API_ROUTES = {
  LOGIN: {
    METHOD: 'POST',
    URL: 'auth/login',
  },
};

export const initialValues = {
  email: '',
  password: '',
};

// Login
export const schema = yup.object({
  email: yup
    .string()
    .required(requiredFieldText('Email'))
    .email('Invalid email format')
    .max(50, 'Max length 50 characters long'),
  password: requiredFieldFunction('Password'),
});
