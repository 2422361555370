import { useEffect, useRef } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import CMS_EN from '../CMS/en';
import Button from '../../Button';
import { useMutation } from '@tanstack/react-query';
import { getDocStatus, verifySignDocNew } from '../../../api';
import Spinner from '../../Spinner';
import { getSessionInfo } from '../../../helpers/functions';
import { toast } from 'react-toastify';
import CMS_ES from '../CMS/es';
import ER_ES from '../Eligibility/es';
import ER_EN from '../Eligibility/en';

const documentNameLookup = {
  CMS: 'CMS',
  ELIGIBILITY: 'ER',
};

const NoDocument = () => {
  return (
    <div className="d--flex align-items--center justify-content--center h--full w--full">
      No Document Found
    </div>
  );
};

const PreviewDocumentSignMode = () => {
  const { docId = '' } = useParams();
  const location = useLocation();
  const formRef = useRef();
  const params = new URLSearchParams(location.search);
  const docType = params.get('docType');

  const {
    mutate,
    data = null,
    isLoading,
  } = useMutation({
    mutationFn: getDocStatus,
    mutationKey: 'getDocStatus',
    onError: (error) => {
      const errMsg = error?.message ?? 'unexpected error';
      toast.error(errMsg);
    },
  });

  const { mutate: mutateVerifyDoc, isLoading: isLoadingVerified } = useMutation(
    {
      mutationFn: verifySignDocNew,
      mutationKey: 'verifySignDocNew',
      onSuccess: () => {
        toast.success('Document Saved.');
        return (window.location.href = '/');
      },
      onError: (error) => {
        const errMsg = error?.message ?? 'unexpected error';
        toast.error(errMsg);
      },
    },
  );

  useEffect(() => {
    if (docId) {
      mutate({
        uuid: docId,
        docType,
      });
    }
  }, [docId, docType]);

  function handleSubmit() {
    const formValues = formRef.current?.getFormValues();
    const template = formRef.current?.getFormTemplate();

    if (!formValues?.SIGNATURE) {
      return toast.error('Please Sign the document.');
    }

    const payload = {
      type: 'signature',
      filename: '',
      uuid: docId,
      data: formValues,
      htmlTemplate: template,
      browserInfo: getSessionInfo(),
    };

    mutateVerifyDoc(payload);
  }

  const documentData = isLoading ? null : data?.data?.data?.getConsentData;
  // const documentData = isLoading ? null : data?.data?.data?.getConxentData;

  const documentLookup = {
    ER_ES: <ER_ES documentData={documentData} ref={formRef} />,
    ER_EN: <ER_EN documentData={documentData} ref={formRef} />,
    CMS_ES: <CMS_ES documentData={documentData} ref={formRef} />,
    CMS_EN: <CMS_EN documentData={documentData} ref={formRef} />,
  };

  const document = documentLookup[
    `${documentNameLookup[docType]}_${
      documentData?.language === 'en' ? 'EN' : 'ES'
    }`
  ] || <NoDocument />;

  if (!docId) return <NoDocument />;

  if (isLoading) {
    return (
      <div className="d--flex align-items--center justify-content--center h--full w--full">
        <Spinner size="lg" />
      </div>
    );
  }

  return (
    <div className="d--flex h--full w--full ">
      <div className="d--flex gap--sm w--full">
        <div className="w--full h--full mx--auto box-shadow--xs overflow--auto p-b--4xl">
          {document}
        </div>
        <div className="position--fixed left--0 bottom--0 h-min--50 gap--sm w--full bg--white d--flex align-items--center justify-content--center">
          <Button
            onClick={() => {
              return (window.location.href = '/');
            }}
            variant="secondary"
            btnClasses="btn w-max--150"
            data-link-hover
            size="sm"
            disabled={isLoadingVerified}
          >
            Back
          </Button>
          <Button
            onClick={handleSubmit}
            variant="primary"
            color="black"
            isOutline
            borderColor="primary"
            btnClasses="btn w-max--150"
            data-link-hover
            size="sm"
            disabled={isLoadingVerified}
          >
            {isLoadingVerified ? 'Please wait...' : 'Submit'}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PreviewDocumentSignMode;
