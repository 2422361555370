import React, { useState } from 'react';
import LogoX from '../../../assets/images/XlogoWhite.png';
import nextereLogoLong from '../../../assets/images/nextere-logo.png';

import useIcons, { MenuBar } from '../../../assets/icons/useIcons';

const OuterHeader = ({ showTabs = true }) => {
  const { CrossIcon } = useIcons();
  const [showMenu, setShowMenu] = useState(false);
  return (
    <div className="outerHeader w--full box--shadow z-index--lg position--fixed top--0 left--0 bg--white">
      <div className="h-min--64  d--flex align-items--center gap--md">
        <div className="w--full d--flex align-items--center ">
          <div className="h--full w-min--64 h-min--64 d--flex align-items--center justify-content--center bg--primary p-t--xs c--pointer smallLogoSec d--none-xs">
            <img
              src={LogoX}
              alt="logoX"
              width={24}
              height={35}
              className="m-t--sm "
            />
          </div>
          {showTabs && (
            <div
              className="position--relative left--10 d--none d--flex-xs"
              onClick={() => setShowMenu((p) => !p)}
            >
              {showMenu ? <CrossIcon width={18} height={18} /> : <MenuBar />}
            </div>
          )}
          <div className="d--flex align-items--center gap--lg p-l--sm p-b--sm navigatSec">
            <img
              src={nextereLogoLong}
              alt="nextereLogoLong"
              width={136}
              height={32}
              className="m-t--sm m-l--xs nextereLogoLong"
            />

            {showTabs && (
              <>
                <div className="h-min--45 h-max--45 d--flex w-min--1 w-max--1 bg--black-100 m-t--sm m-l--xs d--none-xs"></div>

                <div
                  className="d--flex gap--lg  navigat "
                  style={{ left: showMenu ? 0 : '100%' }}
                >
                  <div
                    className="font--md c--pointer text--black navigatItem"
                    style={{ marginLeft: '2px' }}
                    onClick={() =>
                      window.open(
                        'https://www.nextere.com/products/conxent#whyConxent',
                        '_self',
                      )
                    }
                  >
                    Why ConXent
                  </div>
                  <div
                    className="font--md c--pointer text--black navigatItem"
                    onClick={() =>
                      window.open(
                        'https://www.nextere.com/products/conxent#feature',
                        '_self',
                      )
                    }
                  >
                    Features
                  </div>
                  <div
                    className="font--md c--pointer text--black navigatItem"
                    onClick={() =>
                      window.open(
                        'https://www.nextere.com/products/conxent#pricing',
                        '_self',
                      )
                    }
                  >
                    Pricing
                    {/* Plans */}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="w--full d--flex gap--md w-max--400 justify-content--end p-r--3xl">
          {/* <Button
            variant="transparent"
            color="black"
            isOutline
            borderColor="black"
            btnClasses="btn w-max--180 white-space--nowrap"
            // data-link-hover-black-200
          >
            Register for a Webinar
          </Button> */}
          {/* <Button
            variant="primary"
            color="black"
            btnClasses="btn w-max--100 d--none-xs"
            onClick={() => navigate('/')}
          >
            Login
          </Button> */}
        </div>
      </div>
    </div>
  );
};

export default OuterHeader;
