import React from 'react';
import { useNavigate } from 'react-router-dom';
import CMS_EN from '../../assets/images/CMS_EN.png';
import CMS_ES from '../../assets/images/CMS_ES.png';
import ER_EN from '../../assets/images/ER_EN.png';
import ER_ES from '../../assets/images/ER_ES.png';

const documentSrc = {
  'Eligibility Review Spanish': ER_ES,
  'Eligibility Review English': ER_EN,
  'Consent Spanish': CMS_ES,
  'Consent English': CMS_EN,
};

const templateList = [
  { id: 1, name: 'Eligibility Review Spanish', value: 'ER_ES' },
  { id: 2, name: 'Eligibility Review English', value: 'ER_EN' },
  { id: 3, name: 'Consent Spanish', value: 'CMS_ES' },
  { id: 4, name: 'Consent English', value: 'CMS_EN' },
  // { id: 3, name: 'CMS Spanish', value: 'CMS_ES' },
  // { id: 4, name: 'CMS English', value: 'CMS_EN' },
];

const Templates = () => {
  const navigate = useNavigate();

  return (
    <>
      {/* <div className="font--md font--600 m-b--md text--black">Documents</div> */}
      <div className="w--full d--grid grid--4 grid--4--xs  gap--md m-b--md">
        {templateList &&
          templateList?.length > 0 &&
          templateList?.map((template) => {
            return (
              <div
                key={template?.id}
                className="w--full d--flex flex--column gap--sm   radius--sm  bg--white border-full--black-100 box--shadow p--md c--pointer document-template"
              >
                <span
                  className="position--relative p--xs overflow--hidden object--contain border-full--black-200 radius--sm border--dashed h-min--300 h-max--300 w--full"
                  onClick={() => {
                    navigate(`/read-document/${template?.value}`);
                  }}
                >
                  <img
                    src={documentSrc[template?.name]}
                    alt="template-name"
                    className="radius--sm h--full overflow--hidden object--contain   h-min--300 h-max--300 w--full"
                  />
                </span>
                <h3 className="font--md  font--600 text--secondary text--l m-t--sm">
                  {template?.name}
                </h3>
              </div>
            );
          })}
      </div>
    </>
  );
};

export default Templates;
