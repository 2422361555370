import { apiClient } from '../../api/axios';
import { API_ROUTES } from './constants';

export const getAgentKpis = (data) => {
  return apiClient({
    method: API_ROUTES.AGENT_KIPS_LIST.METHOD,
    url: API_ROUTES.AGENT_KIPS_LIST.URL,
    data,
  });
};
