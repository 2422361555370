import { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CMS_EN from '../CMS/en';
import Button from '../../Button';
import CMS_ES from '../CMS/es';
import ER_EN from '../Eligibility/en';
import ER_ES from '../Eligibility/es';

const NoDocument = () => {
  return (
    <div className="d--flex align-items--center justify-content--center h--full w--full">
      No Document Found
    </div>
  );
};

const PreviewDocumentReadMode = () => {
  const { name = '' } = useParams();
  const navigate = useNavigate();

  const documentLookup = {
    ER_ES: <ER_ES readMode={true} />,
    ER_EN: <ER_EN readMode={true} />,
    CMS_ES: <CMS_ES readMode={true} />,
    CMS_EN: <CMS_EN readMode={true} />,
  };

  const document = useMemo(
    () => documentLookup[name] || <NoDocument />,
    [name],
  );

  if (!name) return <NoDocument />;

  return (
    <div className="d--flex h--full w--full ">
      <div className="d--flex gap--sm w--full">
        <div className="w--full h--full mx--auto box-shadow--xs overflow--auto p-b--4xl">
          {document}
        </div>
        <div className="position--fixed left--0 bottom--0 h-min--50 gap--sm w--full bg--white d--flex align-items--center justify-content--center">
          <Button
            onClick={() => navigate('/documents')}
            variant="primary-100"
            color="black"
            isOutline
            borderColor="primary"
            btnClasses="btn w-max--150"
            data-link-hover
            size="sm"
          >
            Back
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PreviewDocumentReadMode;
