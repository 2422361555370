import React, { useState } from 'react';
import Button from '../../../../../components/Button';

const PlanPriceRate = () => {
  const [activeTab, setActiveTab] = useState('monthly');
  return (
    <div className="priceSection z-index--md position--relative p--sm">
      <div className="w--full radius--md bg--white box--shadow shadow--green position--relative ">
        <div className="d--flex align-items--center justify-content--end w--full p-t--md p-b--md p-l--lg p-r--lg border-bottom--black-100 flex--column-xs gap--md-xs">
          {/* <div className="font--2xl text--black-600">
            <i>
              Get started with our{' '}
              <span className="font--600 text--black">Free Plan now</span> and{' '}
              <br /> experience the benefits!
            </i>
          </div> */}
          <div className="d--flex flex--column gap--sm">
            <div className="d--flex bg--black-100 p--xs radius--md bg--black-100 w-min--200 gap--xs">
              <Button
                variant={activeTab === 'monthly' ? 'primary' : 'transparent'}
                color="black"
                btnClasses="btn"
                data-link-hover-primary
                onClick={() => setActiveTab('monthly')}
              >
                Monthly
              </Button>
              <Button
                variant={activeTab === 'yearly' ? 'primary' : 'transparent'}
                color="black"
                btnClasses="btn"
                data-link-hover-primary
                onClick={() => setActiveTab('yearly')}
              >
                Yearly
              </Button>
            </div>
            <div className="font--2sm text--c">(Yearly Save up to 20%)</div>
          </div>
        </div>
        {/* <div className="d--flex w--full flex--column-xs">
          <div className=" border-right--black-100 d--flex flex--column gap--lg w--full p--lg">
            <div className="w--full d--flex flex--column border-bottom--black-100 p-b--md">
              <div className="w--full d--flex flex--column gap--lg">
                <div className="p--sm d--flex align-items--center justify-content--center font--sm font--600 text-black radius--sm bg--primary w-min--85 w-max--85 h-min--36">
                  Free
                </div>
                <div className="d--flex gap--sm align-items--center">
                  <div className="font--2xl font--600">$29.99</div> /
                  <div className="font--md text--black-600">yearly</div>
                </div>
              </div>
            </div>

            <div className="d--flex w--full font--md line-height--1-dot-5">
              Reduce costs, grow revenue, and run your business more efficiently
              on a fully integrated platform. Use Stripe to handle all of your
              payments-related needs, manage revenue operations, and launch (or
              invent) new business models.
            </div>
            <div className="d--flex flex--column gap--md">
              <div className="w--full font--600 font--md d--flex gap--sm font--500 align-items--center">
                <div className="w-min--4 h-min--4 h-max--4 w-max--4 bg--black radius--full"></div>
                Innovative Solutions with Latest Technology
              </div>
              <div className="w--full font--600 font--md d--flex gap--sm font--500  align-items--center">
                <div className="w-min--4 h-min--4 h-max--4 w-max--4 bg--black radius--full"></div>
                Intuitive platform to effortlessly
              </div>
            </div>
            <div className="w--full">
              <Button
                variant="transparent"
                color="black"
                isOutline
                borderColor="primary"
                btnClasses="btn"
                data-link-hover-primary
              >
                Start For Free
              </Button>
            </div>
          </div>
          <div className=" border-right--black-100 d--flex flex--column gap--lg w--full p--lg">
            <div className="w--full d--flex flex--column border-bottom--black-100 p-b--md">
              <div className="w--full d--flex flex--column gap--lg">
                <div className="p--sm d--flex align-items--center justify-content--center font--sm font--600 text--white radius--sm bg--danger w-min--100 w-max--100 h-min--36">
                  Premium
                </div>
                <div className="d--flex gap--sm align-items--center">
                  <div className="font--2xl font--600">$49.99</div> /
                  <div className="font--md text--black-600">yearly</div>
                </div>
              </div>
            </div>

            <div className="d--flex  w--full font--md line-height--1-dot-5">
              Reduce costs, grow revenue, and run your business more efficiently
              on a fully integrated platform. Use Stripe to handle all of your
              payments-related needs, manage revenue operations, and launch (or
              invent) new business models.
            </div>
            <div className="d--flex flex--column gap--md">
              <div className="w--full font--600 font--md d--flex gap--sm font--500 align-items--center">
                <div className="w-min--4 h-min--4 h-max--4 w-max--4 bg--black radius--full"></div>
                Innovative Solutions with Latest Technology
              </div>
              <div className="w--full font--600 font--md d--flex gap--sm font--500  align-items--center">
                <div className="w-min--4 h-min--4 h-max--4 w-max--4 bg--black radius--full"></div>
                Intuitive platform to effortlessly
              </div>
            </div>
            <div className="w--full">
              <Button variant="danger-100" color="danger" btnClasses="btn">
                Pricing coming soon
              </Button>
            </div>
          </div>
          <div className="  d--flex flex--column gap--lg w--full p--lg">
            <div className="w--full d--flex flex--column border-bottom--black-100 p-b--md">
              <div className="w--full d--flex flex--column gap--lg">
                <div className="p--sm d--flex align-items--center justify-content--center font--sm font--600 text-black radius--sm bg--primary w-min--120 w-max--120 h-min--36">
                  Bussiness
                </div>
                <div className="d--flex gap--sm align-items--center">
                  <div className="font--2xl font--600">$69.99</div> /
                  <div className="font--md text--black-600">yearly</div>
                </div>
              </div>
            </div>

            <div className="d--flex w--full font--md line-height--1-dot-5">
              Reduce costs, grow revenue, and run your business more efficiently
              on a fully integrated platform. Use Stripe to handle all of your
              payments-related needs, manage revenue operations, and launch (or
              invent) new business models.
            </div>
            <div className="d--flex flex--column gap--md">
              <div className="w--full font--600 font--md d--flex gap--sm font--500 align-items--center">
                <div className="w-min--4 h-min--4 h-max--4 w-max--4 bg--black radius--full"></div>
                Innovative Solutions with Latest Technology
              </div>
              <div className="w--full font--600 font--md d--flex gap--sm font--500  align-items--center">
                <div className="w-min--4 h-min--4 h-max--4 w-max--4 bg--black radius--full"></div>
                Intuitive platform to effortlessly
              </div>
            </div>
            <div className="w--full">
              <Button
                variant="transparent"
                color="black"
                isOutline
                borderColor="primary"
                btnClasses="btn"
                data-link-hover-primary
              >
                Contact Sales
              </Button>
            </div>
          </div>
        </div> */}

        <div className="d--flex justify-content--center align-items--center p--sm">
          {activeTab === 'monthly' ? (
            <iframe
              width="1100"
              height="730"
              src="https://subscriptions.zoho.com/subscribe/b086a433b9c6e940d7960591fe6dafd22d1433f07e0c1f74ad482bf477cf719e/CONX-00001"
              title="Monthly Payment"
            ></iframe>
          ) : (
            <iframe
              width="1100"
              height="730"
              src="https://subscriptions.zoho.com/subscribe/b086a433b9c6e940d7960591fe6dafd22d1433f07e0c1f74ad482bf477cf719e/CONX-00002"
              title="Yearly Payment"
            ></iframe>
          )}
        </div>
      </div>
    </div>
  );
};

export default PlanPriceRate;
