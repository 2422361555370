import React from 'react';
import { useAlert } from '../../hooks/useAlert';
import Alert from '../Alert';

const AlertContainer = () => {
  const { alerts } = useAlert();
  return (
    <>
      {alerts?.map((alert) => (
        <Alert key={alert?.id} alert={alert} />
      ))}
    </>
  );
};

export default React.memo(AlertContainer);
