import { apiClient } from '../../api/axios';
import { API_ROUTES } from './constants';

export const hraConsumerList = (data) => {
  return apiClient({
    method: API_ROUTES.CONSUMER_LIST_FOR_HRA.METHOD,
    url: API_ROUTES.CONSUMER_LIST_FOR_HRA.URL,
    data,
  });
};
export const getSignedConsumerList = (data) => {
  return apiClient({
    method: API_ROUTES.SIGNED_CONSUMER_LIST.METHOD,
    url: API_ROUTES.SIGNED_CONSUMER_LIST.URL,
    data,
  });
};
export const getCarrierList = (data) => {
  return apiClient({
    method: API_ROUTES.CARRIER_LIST.METHOD,
    url: API_ROUTES.CARRIER_LIST.URL,
    data,
  });
};
export const hraDelete = (data) => {
  return apiClient({
    method: API_ROUTES.HRA_DELETE.METHOD,
    url: API_ROUTES.HRA_DELETE.URL,
    data,
  });
};
export const hraDetails = (data) => {
  return apiClient({
    method: API_ROUTES.HRA_DETAILS.METHOD,
    url: API_ROUTES.HRA_DETAILS.URL,
    data,
  });
};
export const getHraCarrierCount = (data) => {
  return apiClient({
    method: API_ROUTES.GET_HRA_CARRIER_COUNT.METHOD,
    url: API_ROUTES.GET_HRA_CARRIER_COUNT.URL,
    data,
  });
};
export const getHraStateCount = (data) => {
  return apiClient({
    method: API_ROUTES.GET_HRA_STATE_COUNT.METHOD,
    url: API_ROUTES.GET_HRA_STATE_COUNT.URL,
    data,
  });
};

export const sendHraAgreementConsent = (data) => {
  return apiClient({
    method: API_ROUTES.SEND_HRA_AGREEMENT_CONSENT.METHOD,
    url: API_ROUTES.SEND_HRA_AGREEMENT_CONSENT.URL,
    data,
  });
};
export const getHraAgreementSignedDocStatus = (data) => {
  return apiClient({
    method: API_ROUTES.GET_HRA_AGREEMENT_SIGNED_DOC_STATUS.METHOD,
    url: API_ROUTES.GET_HRA_AGREEMENT_SIGNED_DOC_STATUS.URL,
    data,
  });
};
export const hraSignAgreement = (data) => {
  return apiClient({
    method: API_ROUTES.HRA_SIGN_AGREEMENT.METHOD,
    url: API_ROUTES.HRA_SIGN_AGREEMENT.URL,
    data,
  });
};

// for consent

export const sendHraConsent = (data) => {
  return apiClient({
    method: API_ROUTES.SEND_HRA_CONSENT.METHOD,
    url: API_ROUTES.SEND_HRA_CONSENT.URL,
    data,
  });
};
export const getHraSignedDocStatus = (data) => {
  return apiClient({
    method: API_ROUTES.GET_HRA_SIGNED_DOC_STATUS.METHOD,
    url: API_ROUTES.GET_HRA_SIGNED_DOC_STATUS.URL,
    data,
  });
};
export const hraSignConsent = (data) => {
  return apiClient({
    method: API_ROUTES.HRA_SIGN_CONSENT.METHOD,
    url: API_ROUTES.HRA_SIGN_CONSENT.URL,
    data,
  });
};
// hra form status
export const getHraFormDocStatus = (data) => {
  return apiClient({
    method: API_ROUTES.GET_HRA_FORM_DOC_STATUS.METHOD,
    url: API_ROUTES.GET_HRA_FORM_DOC_STATUS.URL,
    data,
  });
};
export const hraSignForm = (data) => {
  return apiClient({
    method: API_ROUTES.HRA_SIGN_FORM.METHOD,
    url: API_ROUTES.HRA_SIGN_FORM.URL,
    data,
  });
};

// for agent
export const hraAgentList = (data) => {
  return apiClient({
    method: API_ROUTES.HRA_AGENT_LIST.METHOD,
    url: API_ROUTES.HRA_AGENT_LIST.URL,
    data,
  });
};
