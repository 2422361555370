import { apiClient } from '../../api/axios';
import { API_ROUTES } from './constants';

export const login = (data) => {
  return apiClient({
    method: API_ROUTES.LOGIN.METHOD,
    url: API_ROUTES.LOGIN.URL,
    data,
  });
};
