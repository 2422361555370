import { apiClient } from '../../api/axios';
import { API_ROUTES } from './constants';

export const getAgencyList = (data) => {
  return apiClient({
    method: API_ROUTES.GET_AGENCY_LIST.METHOD,
    url: API_ROUTES.GET_AGENCY_LIST.URL,
    data,
  });
};

export const createAgency = (data) => {
  return apiClient({
    method: API_ROUTES.CREATE_AGENCY.METHOD,
    url: API_ROUTES.CREATE_AGENCY.URL,
    data,
  });
};
export const updateAgency = (data) => {
  return apiClient({
    method: API_ROUTES.UPDATE_AGENCY.METHOD,
    url: API_ROUTES.UPDATE_AGENCY.URL,
    data,
  });
};

export const deleteAgency = (data) => {
  return apiClient({
    method: API_ROUTES.DELETE_AGENCY.METHOD,
    url: API_ROUTES.DELETE_AGENCY.URL,
    data,
  });
};
