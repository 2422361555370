import { apiClient } from '../../api/axios';
import { API_ROUTES } from './constants';

export const googleDriveCreateFolder = (data) => {
  return apiClient({
    method: API_ROUTES.GOOGLE_DRIVE_CREATE_FOLDER.METHOD,
    url: API_ROUTES.GOOGLE_DRIVE_CREATE_FOLDER.URL,
    data,
  });
};
export const getGoogleDriveFoldersList = (data) => {
  return apiClient({
    method: API_ROUTES.GOOGLE_DRIVE_FOLDERS_LIST.METHOD,
    url: API_ROUTES.GOOGLE_DRIVE_FOLDERS_LIST.URL,
    data,
  });
};
export const getGoogleDriveDeleteFolder = (data) => {
  return apiClient({
    method: API_ROUTES.GOOGLE_DRIVE_DELETE_FOLDER.METHOD,
    url: API_ROUTES.GOOGLE_DRIVE_DELETE_FOLDER.URL,
    data,
  });
};
export const getGoogleDriveFilesList = (data) => {
  return apiClient({
    method: API_ROUTES.GOOGLE_DRIVE_FILES_LIST.METHOD,
    url: API_ROUTES.GOOGLE_DRIVE_FILES_LIST.URL,
    data,
  });
};
export const googleDriveBulkUpload = (data) => {
  return apiClient({
    method: API_ROUTES.GOOGLE_DRIVE_BULK_UPLOAD.METHOD,
    url: API_ROUTES.GOOGLE_DRIVE_BULK_UPLOAD.URL,
    data,
  });
};
