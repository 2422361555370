import React, { useState } from "react";
import { DocTypeArray, modalLookUp } from "./constants";
import UploadDoc from "./components/UploadDoc";
import NationalIdentify from "./Modal/NationalIdentify";
import DriverLicense from "./Modal/DriverLicense";
import InternationalPassport from "./Modal/InternationalPassport";
import VoterCard from "./Modal/VoterCard";

const Kyc = () => {
  const [openModal, setOpenModal] = useState("national_identify");
  const [uploaded, setUploaded] = useState(false);

  const modalLookUp = {
    national_identify: {
      title: "National Identify",
      component: <NationalIdentify {...{ setUploaded }} />,
    },

    driver_license: {
      title: "Driver License",
      component: <DriverLicense />,
    },
    internation_passport: {
      title: "International Passport",
      component: <InternationalPassport />,
    },
    voter_card: {
      title: "Voter Card",
      component: <VoterCard {...{ setUploaded }}  />,
    },
  };
  return (
    <>
      {uploaded ? (
        <UploadDoc />
      ) : (
        <div className="w--lg m-x--auto">
          {" "}
          <h3 className="font--lg font--600 m-b--md m-t--md text--c">
            Let’s verify your identity
          </h3>
          <p className="font--sm m-b--md text--primary text--c">
            This process is designed to verify your identity and protect you
            from identity theft.
          </p>
          <h3 className="font--md font--600 m-b--md  text--c">
            Select document type for verification
          </h3>
          <div className="w--full d--flex flex--wrap gap--md m-b--md">
            {DocTypeArray?.map((val) => {
              const { label, type, icon } = val || {};
              return (
                <div
                  className={`w-min--200 d--flex flex--column gap--sm  align-items--center radius--sm  ${
                    type === openModal
                      ? "border-full--primary"
                      : "border-full--primary-200"
                  }  border--dashed p--md c--pointer`}
                  onClick={() => setOpenModal(type)}
                >
                  <span>{icon}</span>
                  <h3
                    className={`font--400   ${
                      type === openModal ? "text--primary" : "text--secondary"
                    }`}
                  >
                    {label}
                  </h3>
                </div>
              );
            })}
            <div className="w--full">{modalLookUp?.[openModal]?.component}</div>
          </div>
        </div>
      )}
    </>
  );
};

export default Kyc;
