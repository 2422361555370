import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import FormInput from '../../../../../components/FormInput';
import Button from '../../../../../components/Button';
import PhoneInput from 'react-phone-number-input/react-hook-form';
import { City, State } from 'country-state-city';
import FormSelect from '../../../../../components/FormSelect';
import RadioBoxInput from '../../../../../components/RadioBoxInput';
import { submitBtnTxt } from '../../../../../helpers/functions';
import SubmitButton from '../../../../../components/Button/SubmitButton';
import CancelButton from '../../../../../components/Button/CancelButton';

const statesList = State.getStatesOfCountry('US')?.map((state) => ({
  value: state.isoCode,
  label: state.name,
}));

const Step3 = ({
  control,
  errors,
  setValue,
  watch,
  setActiveStep,
  isLoading,
  clearErrors,
}) => {
  const [cityList, setCityList] = useState([]);
  let [watchOwnAgency, watchAgencyType, watchState] = watch([
    'step_three.isOwnAgency',
    'step_three.ownAgencyType',
    'step_three.ownState',
  ]);

  console.log('🚀 ~ watchOwnAgency:', watchOwnAgency);
  useEffect(() => {
    if (watchState) {
      const cities = City.getCitiesOfState('US', watchState).map((city) => ({
        value: city.name,
        label: city.name,
      }));
      setCityList(cities);
    }
  }, [watchState]);

  useEffect(() => {
    if (watchOwnAgency && watchOwnAgency === 'N') {
      clearErrors();
    }
  }, [watchOwnAgency]);

  return (
    <>
      <div className="w--full d--flex gap--md justify-content--center align-items--center flex--column m-t--xl m-b--xl">
        <h3 className="m-b--0 font--lg text--c font--500">
          Do you own an Agency.
        </h3>
        <div className="w--full d--flex justify-content--center align-items--center gap--md">
          <Controller
            name="step_three.isOwnAgency"
            control={control}
            render={({ field }) => (
              <RadioBoxInput
                {...field}
                htmlFor="isOwnAgency_y"
                label="Yes"
                value="Y"
                checked={watchOwnAgency === 'Y'}
              />
            )}
          />
          <Controller
            name="step_three.isOwnAgency"
            control={control}
            render={({ field }) => (
              <RadioBoxInput
                {...field}
                htmlFor="isOwnAgency_n"
                label="No"
                value="N"
                checked={watchOwnAgency === 'N'}
              />
            )}
          />
        </div>
      </div>

      {watchOwnAgency === 'Y' ? (
        <>
          <div className="d--flex gap--sm m-b--lg">
            <Button
              type="button"
              btnClasses="btn"
              color="black"
              borderColor="primary"
              isOutline
              variant={
                watchAgencyType === 'REGULAR' ? 'primary' : 'primary-100'
              }
              onClick={() => {
                setValue('step_three.ownAgencyType', 'REGULAR');
                setValue('step_three.ownAgencyNPN', '', {
                  // shouldValidate: true,
                });
              }}
            >
              Regular Agency
            </Button>
            <Button
              type="button"
              btnClasses="btn"
              variant={watchAgencyType === 'FMO' ? 'primary' : 'primary-100'}
              color="black"
              borderColor="primary"
              isOutline
              onClick={() => {
                setValue('step_three.ownAgencyType', 'FMO');
                setValue('step_three.ownAgencyNPN', '', {
                  // shouldValidate: true,
                });
              }}
            >
              FMO
            </Button>
          </div>
          <div className="w--full d--flex gap--lg m-b--lg flex--column-xs">
            <Controller
              name="step_three.ownAgencyName"
              control={control}
              render={({ field }) => (
                <FormInput
                  {...field}
                  label="Agency Name "
                  error={errors?.step_three?.ownAgencyName?.message}
                  height="40"
                />
              )}
            />

            <Controller
              name="step_three.ownAgencyEmail"
              control={control}
              render={({ field }) => (
                <FormInput
                  {...field}
                  label="Agency Email "
                  error={errors?.step_three?.ownAgencyEmail?.message}
                  height="40"
                />
              )}
            />
          </div>
          <div className="w--full d--flex gap--lg m-b--lg flex--column-xs">
            <div className="w--full">
              <label
                className={`label--control font--sm font--500 m-b--sm d--inline-block ${
                  errors?.step_three?.ownAgencyPhone?.message
                    ? 'text--danger'
                    : 'text--grey'
                }`}
              >
                {errors?.step_three?.ownAgencyPhone?.message ?? 'Agency Phone'}{' '}
                <span className=" font--sm text--danger ">*</span>
              </label>
              <PhoneInput
                name="step_three.ownAgencyPhone"
                control={control}
                defaultCountry={'US'}
                international={true}
                withCountryCallingCode={true}
                placeholder="Enter your phone"
                internationalIcon={() => (
                  <span>
                    <i className="fa fa-phone icon-circle bg-danger"></i>
                  </span>
                )}
                limitMaxLength={true}
                className={`form--control w--full h-min--36  radius--sm p-l--md p-r--md  d--flex align-items--center ${
                  errors?.step_three?.ownAgencyPhone?.message
                    ? 'border-full--danger'
                    : 'border-full--black-200'
                }`}
              />
            </div>
            <Controller
              name="step_three.ownAgencyNPN"
              control={control}
              render={({ field }) => (
                <FormInput
                  type="number"
                  {...field}
                  label="Agency NPN"
                  error={errors?.step_three?.ownAgencyNPN?.message}
                  height="40"
                  required={watchAgencyType === 'FMO'}
                />
              )}
            />
          </div>
          <div className="w--full d--flex gap--lg m-b--lg flex--column-xs">
            <Controller
              name="step_three.ownAgencyEIN"
              control={control}
              render={({ field }) => (
                <FormInput
                  {...field}
                  label="Agency EIN"
                  type="number"
                  error={errors?.step_three?.ownAgencyEIN?.message}
                  height="40"
                />
              )}
            />
          </div>

          <div className="w--full d--flex gap--lg m-b--lg flex--column-xs">
            <Controller
              name="step_three.ownAddressOne"
              control={control}
              render={({ field }) => (
                <FormInput
                  {...field}
                  label="Address 1"
                  error={errors?.step_three?.ownAddressOne?.message}
                  height="40"
                />
              )}
            />
            <Controller
              name="step_three.ownAddressTwo"
              control={control}
              render={({ field }) => (
                <FormInput
                  {...field}
                  label="Address 2"
                  error={errors?.step_three?.ownAddressTwo?.message}
                  height="40"
                  required={false}
                />
              )}
            />
          </div>
          <div className="w--full d--flex gap--lg m-b--lg flex--column-xs">
            <Controller
              name="step_three.ownState"
              control={control}
              rules={{
                onChange() {
                  setValue('step_three.ownCity', '');
                },
              }}
              render={({ field }) => (
                <FormSelect
                  {...field}
                  label="State "
                  error={errors?.step_three?.ownState?.message}
                  height="40"
                  options={statesList}
                />
              )}
            />
            <Controller
              name="step_three.ownCity"
              control={control}
              render={({ field }) => (
                <FormSelect
                  {...field}
                  label="City "
                  error={errors?.step_three?.ownCity?.message}
                  height="40"
                  options={cityList}
                />
              )}
            />
          </div>
          <div className="w--full d--flex gap--lg m-b--lg flex--column-xs">
            <Controller
              name="step_three.ownZipCode"
              control={control}
              render={({ field }) => (
                <FormInput
                  {...field}
                  label="Zip Code"
                  error={errors?.step_three?.ownZipCode?.message}
                  height="40"
                />
              )}
            />
          </div>
        </>
      ) : null}
      <div className="d--flex align-items--center justify-content--center gap--sm w--full ">
        <CancelButton
          btnClasses=" w-max--170"
          size="md"
          onClick={() => setActiveStep(2)}
        >
          Back
        </CancelButton>
        <SubmitButton
          btnClasses="btn w-max--170"
          type="submit"
          disabled={isLoading}
          size="md"
          variant="primary"
          color="white"
        >
          {submitBtnTxt(isLoading)}
        </SubmitButton>
      </div>
    </>
  );
};

export default Step3;
