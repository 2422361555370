import React from "react";
import FormInput from "../../../../../components/FormInput";
import Button from "../../../../../components/Button";
import { UploadCloud } from "../../../../../assets/icons/useIcons";

  const VoterCard = ({ setUploaded }) => {
  return (
    <>
  <div className="d--flex gap--md flex--column m-b--md">
        <div className="d--flex gap--md">
          <div className="w--full">
            <FormInput label=" VIN" height="40" />
          </div>
          <div className="w--full">
            <FormInput label=" First name" height="40" />
          </div>
        </div>
        <div className="d--flex gap--md">
          <div className="w--full">
            <FormInput label=" Middle name" height="40" />
          </div>
          <div className="w--full">
            <FormInput label=" Surname" height="40" />
          </div>
        </div>
        <div className="d--flex gap--md">
          <div className="w--full">
            <FormInput type="date" label=" Date of Birth" height="40" />
          </div>
          <div className="w--full">
          
          </div>
        </div>
        <div className="d--flex gap--md">
          <div className="w--full">
            <h5 className="font--sm font--500 m-b--sm   text--grey">
              Upload front
            </h5>
            <label
              className="border-full--primary-600 border--dashed radius--sm p--sm  w--full d--flex align-items--center justify-content--center gap--sm text--primary h-min--40 c--pointer"
              for="upload"
            >
              <input type="file" id="upload" hidden />
              <UploadCloud width={18} height={18} />
              Upload document
            </label>
          </div>
          <div className="w--full">
            <h5 className="font--sm font--500 m-b--sm   text--grey">
              Upload back
            </h5>
            <label
              className="border-full--primary-600 border--dashed radius--sm p--sm  w--full d--flex align-items--center justify-content--center gap--sm text--primary h-min--40 c--pointer"
              for="upload"
            >
              <input type="file" id="upload" hidden />
              <UploadCloud width={18} height={18} />
              Upload document
            </label>
          </div>
        </div>
      </div>
      <Button btnClasses="btn" variant="primary" disabled={false} type="submit"   onClick={() => setUploaded(true)}>
        {false ? "Please wait..." : "Next"}
      </Button>
    </>
  );
};

export default VoterCard;
