import { useMutation } from '@tanstack/react-query';
import { email, firstName, npn, phone } from '../../helpers/yupValidations';
import * as yup from 'yup';
import { inviteUpdateStatus } from '../../api';

export const API_ROUTES = {
  IMPORT_INVITES: {
    METHOD: 'POST',
    URL: 'invite/import-agent',
  },
};

export const recieveColumns = [
  {
    accessorKey: 'templateName',
    header: () => 'Agency Name',
  },
  {
    accessorKey: 'phone',
    header: () => 'Agency Phone',
  },
  {
    accessorKey: 'templateName',
    header: () => 'Agency Email',
  },
  {
    accessorKey: 'templateName',
    header: () => 'Invite recieved date',
  },
  {
    accessorKey: 'templateName',
    header: () => 'Action',
  },
];
export const agencyStatusLookup = {
  A: { text: 'Accepted', class: 'text--success' },
  I: { text: 'Invited', class: 'text--blue' },
  R: { text: 'Released', class: 'text--warning' },
  D: { text: 'Declined', class: 'text--danger' },
};
export const agentStatusLookup = {
  A: { text: 'Accepted', class: 'text--success' },
  I: { text: 'Pending', class: 'text--primary' },
  R: { text: 'Released', class: 'text--warning' },
  D: { text: 'Declined', class: 'text--danger' },
};
export const staticData = [
  {
    _id: '664ed1e54f08930cadd1fde6',
    agency_name: 'Agency A',
    agency_phone: '+184845641234',
    agency_email: 'agencyA@gmail.com',
    agency_npn: '01234567892',
    status: 'P',
    createdAt: '2024-05-24',
    updatedAt: '2024-05-23T05:19:33.094Z',
    __v: 0,
  },
  {
    _id: '664ed1e54f08930cadd1fde6',
    agency_name: 'Agency B',
    agency_phone: '+184845641236',
    agency_email: 'agencyB@gmail.com',
    agency_npn: '98745612312',
    status: 'A',
    createdAt: '2024-05-23',
    updatedAt: '2024-05-23T',
    __v: 0,
  },
  {
    _id: '664ed1e54f08930cadd1f21',
    agency_name: 'Agency C',
    agency_phone: '+184845641235',
    agency_email: 'agencyC@gmail.com',
    agency_npn: '987456123512',
    status: 'D',
    createdAt: '2024-05-22',
    updatedAt: '2024-05-23T05:19:33.094Z',
    __v: 0,
  },
];
export const agencyStaticData = [
  {
    _id: '664ed1e54f08930cadd1fde6',
    agency_name: 'Agent A',
    agency_phone: '+184845641234',
    agency_email: 'agentA@gmail.com',
    agency_npn: '01234567892',
    status: 'I',
    createdAt: '2024-05-24',
    updatedAt: '2024-05-23T05:19:33.094Z',
    __v: 0,
  },
  {
    _id: '664ed1e54f08930cadd1fde6',
    agency_name: 'Agent B',
    agency_phone: '+184845641236',
    agency_email: 'agentB@gmail.com',
    agency_npn: '98745612312',
    status: 'A',
    createdAt: '2024-05-23',
    updatedAt: '2024-05-23T',
    __v: 0,
  },
  {
    _id: '664ed1e54f08930cadd1f21',
    agency_name: 'Agent C',
    agency_phone: '+184845641235',
    agency_email: 'agentC@gmail.com',
    agency_npn: '987456123512',
    status: 'D',
    createdAt: '2024-05-22',
    updatedAt: '2024-05-23T05:19:33.094Z',
    __v: 0,
  },
];
export const schema = yup.object().shape({
  firstName,
  email,
  phone,
  npn,
});

export const useUpdateInviteStatusMutate = () => {
  const {
    mutate: updateInviteStatusMutate,
    isLoading: updateInviteStatusLoading,
  } = useMutation({
    mutationFn: inviteUpdateStatus,
    mutationKey: 'inviteUpdateStatus',
  });

  return { updateInviteStatusMutate, updateInviteStatusLoading };
};
