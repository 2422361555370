import * as yup from 'yup';
import { requiredFieldFunction, requiredFieldText } from '../../helpers/yupValidations';
export const API_ROUTES = {
  GET_AGENCY_LIST: {
    METHOD: 'GET',
    URL: 'agency/list',
  },
  CREATE_AGENCY: {
    METHOD: 'POST',
    URL: 'agency/create',
  },
  UPDATE_AGENCY: {
    METHOD: 'POST',
    URL: 'agency/create',
  },
  DELETE_AGENCY: {
    METHOD: 'POST',
    URL: 'agency/delete',
  },
};

export const schema = yup.object().shape({
  name: yup
    .string()
    .required(requiredFieldText('Name'))
    .matches(/^[A-Za-z'-]+[ A-Za-z'-]*$/, 'First name cannot contain numbers'),
  email: yup.string().email('Must be a valid email').required('Email is required').max(50, 'Maximum length can be 50 characters'),
  phone: yup.string().required('Phone number is required'),
  principalFirstname: yup
    .string()
    .required(requiredFieldText('Principal First Name'))
    .matches(/^[A-Za-z'-]+[ A-Za-z'-]*$/, 'Principal First name cannot contain numbers'),
  principalLastname: yup
    .string()
    .required(requiredFieldText('Principal First Name'))
    .matches(/^[A-Za-z'-]+[ A-Za-z'-]*$/, 'Principal First name cannot contain numbers'),
  addressLine1: requiredFieldFunction('Addreess Line 1'),
  // addressLine2: requiredFieldFunction('Addreess Line 2'),
  country: requiredFieldFunction('Country'),
  state: requiredFieldFunction('State'),
  city: requiredFieldFunction('City'),
  postalCode: requiredFieldFunction('Postal Code'),
});
