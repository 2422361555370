import { apiClient } from '../../api/axios';
import { API_ROUTES } from './constants';

export const importInvites = (data) => {
  return apiClient({
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    method: API_ROUTES.IMPORT_INVITES.METHOD,
    url: API_ROUTES.IMPORT_INVITES.URL,
    data,
  });
};
