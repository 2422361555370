import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import Modal from '../../../../Modal';
import SignModule from '../../../../SignModule';
import moment from 'moment';

const initialDocData = {
  Insurance_Agent: '',
  Agency_Name: '',
  Agent_Phone: '',
  Consumer: '',
  Consumer_Address: '',
  Consumer_Phone: '',
  DATE: '',
  SIGNATURE: '',
};

const HRA_ES = forwardRef(
  ({ readMode = false, documentData = null, preFillData = null }, ref) => {
    const [signatureModal, setSignatureModal] = useState(false);
    const [documentState, setDocumentState] = useState(initialDocData);
    const { getHraData, getConsumerAddress } = documentData || {};

    useEffect(() => {
      if (preFillData) {
        Object.entries(preFillData).map(([key, value]) => {
          setDocumentState((prev) => ({
            ...prev,
            [key]: value,
          }));
        });
      }
    }, [preFillData]);

    useEffect(() => {
      if (documentData) {
        setDocumentState((prevState) => {
          prevState['Insurance_Agent'] =
            getHraData?.agentDetail?.fullName ?? '';
          prevState['Agency_Name'] = '';
          prevState['Agent_Phone'] = getHraData?.agentDetail?.phone ?? '';
          prevState['Consumer'] = getHraData?.consumerDetail?.fullName ?? '';
          prevState['Consumer_Address'] =
            `${getConsumerAddress?.consumerInfo?.address},${getConsumerAddress?.consumerInfo?.state},${getConsumerAddress?.consumerInfo?.city},${getConsumerAddress?.consumerInfo?.zipCode}` ??
            '';
          prevState['Consumer_Phone'] = getHraData?.consumerDetail?.phone ?? '';
          prevState['DATE'] = new Date();

          return { ...prevState };
        });
      }
    }, [documentData]);

    useImperativeHandle(
      ref,
      () => ({
        getFormValues() {
          return documentState;
        },
        getFormTemplate() {
          return document.getElementById('custom-signature-form-conxent')
            ?.outerHTML;
        },
      }),
      [documentState, documentData],
    );

    function handleChange(e, signatureValue = null) {
      if (signatureValue) {
        setDocumentState((prevState) => ({
          ...prevState,
          SIGNATURE: signatureValue,
        }));
        setSignatureModal(false);
      } else {
        const name = e.target.name;
        const value = e.target.value;
        setDocumentState((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      }
    }

    return (
      <>
        <div
          id="custom-signature-form-conxent"
          style={{
            backgroundColor: ' #fff',
            width: '100%',
            margin: '10px auto',
            padding: '10px 20px',
            maxWidth: '794px',
          }}
          className="pdfForm"
        >
          <table
            cellSpacing="0"
            style={{
              width: '100%',
              padding: '15px 0',
              borderCollapse: 'collapse',
              borderSpacing: '0',
            }}
          >
            <tr>
              <td colSpan="2">
                <h2
                  style={{
                    marginTop: '0',
                    textAlign: 'center',
                    fontSize: '16px',
                    marginBottom: '10px',
                    lineHeight: '1.5',
                  }}
                >
                  Acuerdo de consentimiento y liberación del consumidor para la
                  evaluación de riesgos de salud de la cobertura del seguro
                </h2>
              </td>
            </tr>

            <tr>
              <td colSpan="2">
                <p style={{ lineHeight: '2', marginBottom: '20px' }}>
                  Este Acuerdo de consentimiento y liberación del consumidor
                  (“Acuerdo”) se realiza y se celebra en la fecha de la firma
                  entre
                </p>
              </td>
            </tr>

            <tr>
              <td style={{ paddingRight: '20px', width: '50%' }}>
                <table
                  style={{
                    width: '100%',
                    padding: '15px 0',
                    borderCollapse: 'collapse',
                    borderSpacing: '0',
                  }}
                >
                  <tr>
                    <td style={{ width: '60px' }}>
                      <p
                        style={{ lineHeight: '1', margin: '0', width: '130px' }}
                      >
                        Insurance Agent:
                      </p>
                    </td>
                    <td>
                      <div
                        style={{
                          minWidth: '200px',
                          border: 'none',
                          width: '100%',
                          borderBottom: '1px solid #555',
                          minHeight: '16px',
                        }}
                      >
                        {documentState.Insurance_Agent}
                      </div>
                    </td>
                  </tr>
                </table>
              </td>
              <td style={{ paddingLeft: '20px', width: '50%' }}></td>
            </tr>

            <tr style={{ marginTop: '15px' }}>
              <td style={{ paddingRight: '20px', width: '50%' }}>
                <table
                  style={{
                    marginTop: '15px',
                    width: '100%',
                    padding: '15px 0',
                    borderCollapse: 'collapse',
                    borderSpacing: '0',
                  }}
                >
                  <tr>
                    <td style={{ width: '60px' }}>
                      <p
                        style={{ lineHeight: '1', margin: '0', width: '110px' }}
                      >
                        Agency Name:
                      </p>
                    </td>
                    <td>
                      <div
                        style={{
                          minWidth: '200px',
                          border: 'none',
                          width: '100%',
                          borderBottom: '1px solid #555',
                          minHeight: '16px',
                        }}
                      >
                        {documentState.Agency_Name}
                      </div>
                    </td>
                  </tr>
                </table>
              </td>
              <td style={{ paddingLeft: '20px', width: '50%' }}></td>
            </tr>

            <tr style={{ marginTop: '15px' }}>
              <td style={{ paddingRight: '20px', width: '50%' }}>
                <table
                  style={{
                    marginTop: '15px',
                    width: '100%',
                    padding: '15px 0',
                    borderCollapse: 'collapse',
                    borderSpacing: '0',
                  }}
                >
                  <tr>
                    <td style={{ width: '60px' }}>
                      <p
                        style={{ lineHeight: '1', margin: '0', width: '140px' }}
                      >
                        Agent contact No.:
                      </p>
                    </td>
                    <td>
                      <div
                        style={{
                          minWidth: '200px',
                          border: 'none',
                          width: '100%',
                          borderBottom: '1px solid #555',
                          minHeight: '16px',
                        }}
                      >
                        {documentState.Agent_Phone}
                      </div>
                    </td>
                  </tr>
                </table>
              </td>
              <td style={{ paddingLeft: '20px', width: '50%' }}></td>
            </tr>

            <tr style={{ marginTop: '15px' }}>
              <td style={{ paddingRight: '20px', width: '50%' }}>
                <table
                  style={{
                    marginTop: '15px',
                    width: '100%',
                    padding: '15px 0',
                    borderCollapse: 'collapse',
                    borderSpacing: '0',
                  }}
                >
                  <tr>
                    <td style={{ width: '60px' }}>
                      <p
                        style={{
                          lineHeight: '1',
                          margin: '0',
                          width: '140px',
                          fontWeight: 'bold',
                          paddingLeft: '60px',
                        }}
                      >
                        And
                      </p>
                    </td>
                    <td></td>
                  </tr>
                </table>
              </td>
              <td style={{ paddingLeft: '20px', width: '50%' }}></td>
            </tr>

            <tr style={{ marginTop: '15px' }}>
              <td style={{ paddingRight: '20px', width: '50%' }}>
                <table
                  style={{
                    marginTop: '15px',
                    width: '100%',
                    padding: '15px 0',
                    borderCollapse: 'collapse',
                    borderSpacing: '0',
                  }}
                >
                  <tr>
                    <td style={{ width: '60px' }}>
                      <p
                        style={{ lineHeight: '1', margin: '0', width: '80px' }}
                      >
                        Consumer
                      </p>
                    </td>
                    <td>
                      <div
                        style={{
                          minWidth: '200px',
                          border: 'none',
                          width: '100%',
                          borderBottom: '1px solid #555',
                          minHeight: '16px',
                        }}
                      >
                        {documentState.Consumer}
                      </div>
                    </td>
                  </tr>
                </table>
              </td>
              <td style={{ paddingLeft: '20px', width: '50%' }}></td>
            </tr>

            <tr style={{ marginTop: '15px' }}>
              <td style={{ paddingRight: '20px', width: '50%' }}>
                <table
                  style={{
                    marginTop: '15px',
                    width: '100%',
                    padding: '15px 0',
                    borderCollapse: 'collapse',
                    borderSpacing: '0',
                  }}
                >
                  <tr>
                    <td style={{ width: '60px' }}>
                      <p
                        style={{ lineHeight: '1', margin: '0', width: '160px' }}
                      >
                        Consumer’s Address:
                      </p>
                    </td>
                    <td>
                      <div
                        style={{
                          minWidth: '200px',
                          border: 'none',
                          width: '100%',
                          borderBottom: '1px solid #555',
                          minHeight: '16px',
                        }}
                      >
                        {documentState.Consumer_Address}
                      </div>
                    </td>
                  </tr>
                </table>
              </td>
              <td style={{ paddingLeft: '20px', width: '50%' }}></td>
            </tr>

            <tr style={{ marginTop: '15px' }}>
              <td style={{ paddingRight: '20px', width: '50%' }}>
                <table
                  style={{
                    marginTop: '15px',
                    width: '100%',
                    padding: '15px 0',
                    borderCollapse: 'collapse',
                    borderSpacing: '0',
                  }}
                >
                  <tr>
                    <td style={{ width: '60px' }}>
                      <p
                        style={{ lineHeight: '1', margin: '0', width: '130px' }}
                      >
                        Consumer’s No.:
                      </p>
                    </td>
                    <td>
                      <div
                        style={{
                          minWidth: '200px',
                          border: 'none',
                          width: '100%',
                          borderBottom: '1px solid #555',
                          minHeight: '16px',
                        }}
                      >
                        {documentState.Consumer_Phone}
                      </div>
                    </td>
                  </tr>
                </table>
              </td>
              <td style={{ paddingLeft: '20px', width: '50%' }}></td>
            </tr>

            <tr>
              <td colSpan="2">
                <h2
                  style={{
                    marginTop: '20px',
                    fontSize: '16px',
                    marginBottom: '5px',
                    lineHeight: '1.5',
                  }}
                >
                  1. Propósito
                </h2>
              </td>
            </tr>
            <tr>
              <td colSpan="2">
                <p style={{ lineHeight: '2' }}>
                  El propósito de este Acuerdo es obtener el consentimiento del
                  Consumidor para participar en una Evaluación de Riesgo de
                  Salud (“HRA”) y permitir que el Agente de Seguros recopile y
                  comparta la información relacionada con la salud del
                  Consumidor según sea necesario para la evaluación y gestión
                  del plan de seguro de salud del Consumidor con su aseguradora
                  (“Aseguradora de Seguros”)
                </p>
              </td>
            </tr>
            <tr>
              <td colSpan="2">
                <h2
                  style={{
                    marginTop: '20px',
                    fontSize: '16px',
                    marginBottom: '5px',
                    lineHeight: '1.5',
                  }}
                >
                  2. Alcance del Consentimiento
                </h2>
              </td>
            </tr>
            <tr>
              <td colSpan="2">
                <p style={{ lineHeight: '2', marginBottom: '10px' }}>
                  Al firmar este Acuerdo, el Consumidor consiente:
                </p>
                <p style={{ lineHeight: '2', paddingLeft: '20px' }}>
                  - Participar en la Evaluación de Riesgo de Salud, que puede
                  implicar la recopilación de información sobre la salud para
                  evaluar las condiciones de salud actuales, el estilo de vida y
                  los factores de riesgo, según lo estipulado por las pautas
                  aplicables de los Centros de Servicios de Medicare y Medicaid
                  (CMS) y los Centros para el Control y la Prevención de
                  Enfermedades (CDC), así como los criterios de la Aseguradora
                  de Seguros.
                </p>
                <p style={{ lineHeight: '2', paddingLeft: '20px' }}>
                  - Permitir que el Agente de Seguros recopile, use y comparta
                  la información obtenida durante la HRA con la Aseguradora de
                  Seguros del Consumidor para facilitar recomendaciones precisas
                  de planes de salud, análisis de riesgos y acciones de
                  seguimiento apropiadas.
                </p>
              </td>
            </tr>
            <tr>
              <td colSpan="2">
                <h2
                  style={{
                    marginTop: '20px',
                    fontSize: '16px',
                    marginBottom: '5px',
                    lineHeight: '1.5',
                  }}
                >
                  3. Uso y Divulgación de Información de Salud Protegida (PHI)
                </h2>
              </td>
            </tr>
            <tr>
              <td colSpan="2">
                <p style={{ lineHeight: '2' }}>
                  Toda la información de salud recopilada se considera
                  Información de Salud Protegida (“PHI”) según lo definido en la
                  Ley de Portabilidad y Responsabilidad del Seguro de Salud de
                  1996 (“HIPAA”), y según pueda ser modificada. Al firmar este
                  Acuerdo, el Consumidor autoriza al Agente de Seguros a manejar
                  y divulgar PHI en cumplimiento con HIPAA y otras leyes
                  estatales y federales aplicables que rigen la privacidad y
                  seguridad de la información de salud, con el fin de facilitar
                  la HRA para el Consumidor y la Aseguradora de Seguros.
                </p>
                <p style={{ lineHeight: '2' }}>
                  El Agente de Seguros y la Aseguradora de Seguros solo
                  utilizarán y divulgarán la PHI del Consumidor para fines
                  directamente relacionados con el plan de seguro de salud, la
                  evaluación y las opciones de cobertura del Consumidor. Ningún
                  otro tercero no autorizado tendrá acceso a la PHI del
                  Consumidor, independientemente de si dicha PHI está contenida
                  en la HRA del Consumidor.
                </p>
              </td>
            </tr>
            <tr>
              <td colSpan="2">
                <h2
                  style={{
                    marginTop: '20px',
                    fontSize: '16px',
                    marginBottom: '5px',
                    lineHeight: '1.5',
                  }}
                >
                  4. Cumplimiento con HIPAA y Leyes Relacionadas
                </h2>
              </td>
            </tr>
            <tr>
              <td colSpan="2">
                <p style={{ lineHeight: '2' }}>
                  El Agente de Seguros se compromete a cumplir con HIPAA y todas
                  las leyes de privacidad estatales y federales aplicables al
                  manejar la PHI del Consumidor. La información del Consumidor
                  solo será utilizada y compartida como se establece en este
                  Acuerdo y según lo requieran fines legales y regulatorios.
                </p>
              </td>
            </tr>
            <tr>
              <td colSpan="2">
                <h2
                  style={{
                    marginTop: '20px',
                    fontSize: '16px',
                    marginBottom: '5px',
                    lineHeight: '1.5',
                  }}
                >
                  5. Derechos del Consumidor
                </h2>
              </td>
            </tr>
            <tr>
              <td colSpan="2">
                <p style={{ lineHeight: '2', marginBottom: '10px' }}>
                  El Consumidor tiene el derecho de:
                </p>
                <p style={{ lineHeight: '2', paddingLeft: '20px' }}>
                  - Retirar su consentimiento en cualquier momento
                  proporcionando un aviso por escrito al Agente de Seguros.
                </p>
                <p style={{ lineHeight: '2', paddingLeft: '20px' }}>
                  - Acceder, solicitar enmiendas u obtener una copia de la PHI
                  recopilada durante la HRA, según lo permita la ley.
                </p>
                <p style={{ lineHeight: '2', paddingLeft: '20px' }}>
                  - Recibir un aviso sobre cualquier acceso, uso o divulgación
                  no autorizada de PHI, conforme a las leyes aplicables.
                </p>
              </td>
            </tr>

            <tr>
              <td colSpan="2">
                <h2
                  style={{
                    marginTop: '20px',
                    fontSize: '16px',
                    marginBottom: '5px',
                    lineHeight: '1.5',
                  }}
                >
                  6. Exoneración de Responsabilidad
                </h2>
              </td>
            </tr>
            <tr>
              <td colSpan="2">
                <p style={{ lineHeight: '2' }}>
                  El Consumidor libera total y completamente al Agente de
                  Seguros y a la Aseguradora de Seguros de cualquier
                  responsabilidad derivada del uso, divulgación y manejo de PHI
                  según lo permitido en este Acuerdo, para los fines de la HRA
                  y/o según lo exija la ley aplicable.
                </p>
              </td>
            </tr>
            <tr>
              <td colSpan="2">
                <h2
                  style={{
                    marginTop: '20px',
                    fontSize: '16px',
                    marginBottom: '5px',
                    lineHeight: '1.5',
                  }}
                >
                  7. Reconocimiento
                </h2>
              </td>
            </tr>
            <tr>
              <td colSpan="2">
                <p style={{ lineHeight: '2' }}>
                  El Consumidor reconoce que la participación en la Evaluación
                  de Riesgo de Salud es voluntaria y que ha sido informado de
                  sus derechos bajo HIPAA y los relacionados con la HRA. El
                  Consumidor entiende que el no proporcionar o autorizar cierta
                  información de salud y/o información de salud precisa puede
                  afectar la exactitud de las recomendaciones y opciones de
                  cobertura.
                </p>
              </td>
            </tr>
            <tr>
              <td colSpan="2">
                <h2
                  style={{
                    marginTop: '20px',
                    fontSize: '16px',
                    marginBottom: '5px',
                    lineHeight: '1.5',
                  }}
                >
                  8. Plazo
                </h2>
              </td>
            </tr>
            <tr>
              <td colSpan="2">
                <p style={{ lineHeight: '2' }}>
                  Este consentimiento permanecerá en efecto hasta que se cumpla
                  el propósito de la Evaluación de Riesgo de Salud y la
                  evaluación asociada, a menos que sea revocado por escrito por
                  el Consumidor.
                </p>
              </td>
            </tr>

            <tr style={{ marginTop: '15px' }}>
              <td style={{ paddingRight: '20px', width: '50%' }}>
                <table
                  style={{
                    marginTop: '15px',
                    width: '100%',
                    padding: '15px 0',
                    borderCollapse: 'collapse',
                    borderSpacing: '0',
                  }}
                >
                  <tr>
                    <td style={{ width: '60px' }}>
                      <p
                        style={{ lineHeight: '1', margin: '0', width: '180px' }}
                      >
                        Consumer Name (Printed):
                      </p>
                    </td>
                    <td>
                      <div
                        style={{
                          minWidth: '200px',
                          border: 'none',
                          width: '100%',
                          borderBottom: '1px solid #555',
                          minHeight: '16px',
                        }}
                      >
                        {documentState.Consumer}
                      </div>
                    </td>
                  </tr>
                </table>
              </td>
              <td style={{ paddingLeft: '20px', width: '50%' }}> </td>
            </tr>

            <tr style={{ marginTop: '15px' }}>
              <td
                style={{
                  paddingRight: '20px',
                  width: '50%',
                }}
              >
                <table
                  style={{
                    marginTop: '15px',
                    width: '100%',
                    padding: '15px 0',
                    borderCollapse: 'collapse',
                    borderSpacing: '0',
                  }}
                >
                  <tr>
                    <td style={{ width: '60px' }}>
                      <p
                        style={{ lineHeight: '1', margin: '0', width: '60px' }}
                      >
                        Date:
                      </p>
                    </td>
                    <td>
                      <div
                        style={{
                          minWidth: '200px',
                          border: 'none',
                          width: '100%',
                          borderBottom: '1px solid #555',
                          minHeight: '16px',
                        }}
                      >
                        {moment(documentState.DATE).format('MMMM Do YYYY')}
                      </div>
                    </td>
                  </tr>
                </table>
              </td>
              <td style={{ paddingLeft: '20px' }}>
                <table
                  style={{
                    marginTop: '15px',
                    width: '100%',
                    padding: '15px 0',
                    borderCollapse: 'collapse',
                    borderSpacing: '0',
                  }}
                >
                  <tr>
                    <td style={{ width: '60px' }}>
                      <p
                        style={{ lineHeight: '1', margin: '0', width: '95px' }}
                      >
                        Consumer Signature:
                      </p>
                    </td>
                    <td>
                      <div style={{ position: 'relative' }}>
                        {!documentState?.SIGNATURE ? (
                          <div
                            onClick={() => {
                              if (readMode) return;
                              setSignatureModal(true);
                            }}
                            style={{
                              minWidth: '200px',
                              border: 'none',
                              height: '60px',
                              width: '100%',
                              padding: '5px',
                              cursor: 'pointer',
                              position: 'absolute',
                              top: '-60px',
                              border: '1px dashed #ddd',
                            }}
                          >
                            + Add Signature
                          </div>
                        ) : (
                          <div
                            onClick={() => {
                              if (readMode) return;
                              setSignatureModal(true);
                            }}
                            style={{
                              minWidth: '200px',
                              border: 'none',
                              width: '100%',
                              padding: '5px',
                              cursor: 'pointer',
                              position: 'absolute',
                              top: '-45px',
                              height: '60px',
                              border: '1px dashed #ddd',
                            }}
                          >
                            <img
                              alt="signature"
                              src={documentState?.SIGNATURE}
                              style={{ maxHeight: '50px' }}
                            />
                          </div>
                        )}
                      </div>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
        </div>

        {signatureModal ? (
          <Modal
            handleClose={() => setSignatureModal(false)}
            title={'Signature'}
            headerComponent={null}
            shouldCloseOnClickOutside={true}
            footerComponent={null}
            height="300"
            size="700"
          >
            <SignModule handleChange={handleChange} />
          </Modal>
        ) : null}
      </>
    );
  },
);

export default HRA_ES;
