import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import Button from '../../Button';
import { useMutation } from '@tanstack/react-query';
import { getDocStatus } from '../../../api';
import { useAlert } from '../../../hooks/useAlert';
import Spinner from '../../Spinner';
import {
  downloadFileFromURL,
  generateRandomNumber,
  generateUniqueId,
} from '../../../helpers/functions';
import useIcons from '../../../assets/icons/useIcons';
import moment from 'moment';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import ER_ES from '../Eligibility/es';
import ER_EN from '../Eligibility/en';
import CMS_ES from '../CMS/es';
import CMS_EN from '../CMS/en';
import AsideModal from '../../AsideModal';

const documentNameLookup = {
  CMS: 'CMS',
  ELIGIBILITY: 'ER',
};

const NoDocument = () => {
  return (
    <div className="d--flex align-items--center justify-content--center h--full w--full">
      No Document Found
    </div>
  );
};

const PreviewDocumentOuterMobile = ({ data }) => {
  const { CheckIcon, CrossIcon, CalendarIcon, FileIcon, UserLineIcon } =
    useIcons();
  const { docId = '' } = useParams();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const docType = params.get('docType');
  const [openModal, setOpenModal] = useState(false);

  const documentData = data?.data?.data?.getConsentData;

  const documentDetails = data?.data?.data?.getSignData;
  const certData = data?.data?.data?.verificationData;

  function handleDownload(url) {
    if (!url) return;
    downloadFileFromURL(url);
  }

  const documentLookup = {
    ER_ES: <ER_ES readMode={true} preFillData={documentDetails?.data} />,
    ER_EN: <ER_EN readMode={true} preFillData={documentDetails?.data} />,
    CMS_ES: <CMS_ES readMode={true} preFillData={documentDetails?.data} />,
    CMS_EN: <CMS_EN readMode={true} preFillData={documentDetails?.data} />,
  };

  const document = documentLookup[
    `${documentNameLookup[docType]}_${
      documentData?.language === 'en' ? 'EN' : 'ES'
    }`
  ] || <NoDocument />;

  if (!docId) return <NoDocument />;

  // if (isLoading || !documentData?.filename) {
  //   return (
  //     <div className="d--flex align-items--center justify-content--center h--full w--full">
  //       <Spinner size="lg" />
  //     </div>
  //   );
  // }

  if (documentData?.sendDocType === 'MANUALLY_DOC_UPLOAD_BY_AGENT') {
    window.open(documentData?.filename, '_self');
    return <></>;
  }

  return (
    <div className="d--flex h--full w--full ">
      {/* <div className="d--flex gap--sm w--full">
        <div className="w--full h--full mx--auto box-shadow--xs overflow--auto p-b--4xl">
          {document}
        </div>
      </div> */}
      <div className="d--flex flex--column gap--md   w--full h--full bg--white    ">
        <div className="d--flex flex--column gap--md   w--full h--full p--md overflow--auto  sidePdfDetails">
          {documentData?.agreementStatus === 'SIGNED' ? (
            <div className="d--flex w--full  border-full--success bg--success-200 radius--sm p--md gap--sm align-items--center justify-content--center">
              <CheckIcon /> This document is digitally signed.
            </div>
          ) : (
            <div className="d--flex w--full  border-full--danger bg--danger-200 radius--sm p--md gap--sm align-items--center justify-content--center">
              <CrossIcon width={20} height={20} /> This document is not
              digitally signed.
            </div>
          )}

          {certData && Object.keys(certData).length > 0 ? (
            <>
              <div className="d--flex w--full flex--column  gap--xs">
                <label className="label--control font--sm font--500  d--inline-block text--black-600">
                  Digital Sign Details
                </label>
                <div className="w--full bg--black-50 text--black h-min--75 radius--sm p--sm d--flex align-items--center justify-content--center">
                  <div className="d--flex gap--xs flex--column w--full">
                    <div className="d--flex gap--sm w--full align-items--center">
                      <div className="d--flex w-min--24 h-min--24 w-max--24 h-max--24 align-items--center justify-content--center text--black">
                        <UserLineIcon width={18} height={18} />
                      </div>
                      <div>{certData?.signatureMeta?.reason} </div>
                    </div>
                    <div className="d--flex gap--sm w--full align-items--center">
                      <div className="d--flex w-min--24 h-min--24 w-max--24 h-max--24 align-items--center justify-content--center text--black">
                        <CalendarIcon width={18} height={18} />
                      </div>
                      <div>
                        {moment(documentData?.signedDate).format(
                          'MMMM Do YYYY, h:mm A',
                        )}
                      </div>
                    </div>
                    <div className="d--flex gap--sm w--full align-items--center">
                      <div className="d--flex w-min--24 h-min--24 w-max--24 h-max--24 align-items--center justify-content--center text--black">
                        <FileIcon width={18} height={18} />
                      </div>
                      <div>{certData?.signatureMeta?.name}</div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="d--flex w--full flex--column  gap--xs">
                <label className="label--control font--sm font--500  d--inline-block text--black-600">
                  Browser Info
                </label>
                <div className="w--full bg--black-50 text--black h-min--75 h-max--75  radius--sm p--sm d--flex align-items--center justify-content--center">
                  <div className="d--flex gap--xs flex--column w--full">
                    <div className="d--flex gap--sm w--full align-items--center">
                      <div className="d--flex w-min--24 h-min--24 w-max--24 h-max--24 align-items--center justify-content--center text--black">
                        Device Type:
                      </div>
                      <div style={{ textTransform: 'uppercase' }}>
                        {documentDetails?.browserInfo?.device_type ?? ''}{' '}
                      </div>
                    </div>
                    <div className="d--flex gap--sm w--full align-items--center">
                      <div className="d--flex w-min--24 h-min--24 w-max--24 h-max--24 align-items--center justify-content--center text--black">
                        Browser:
                      </div>
                      <div style={{ textTransform: 'uppercase' }}>
                        {documentDetails?.browserInfo?.browser_version ?? ''}{' '}
                      </div>
                    </div>
                    <div className="d--flex gap--sm w--full align-items--center">
                      <div className="d--flex w-min--24 h-min--24 w-max--24 h-max--24 align-items--center justify-content--center text--black">
                        OS:
                      </div>
                      <div style={{ textTransform: 'uppercase' }}>
                        {documentDetails?.browserInfo?.os_version ?? ''}{' '}
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </>
          ) : null}

          <div className="d--flex w--full flex--column  gap--xs">
            <label className="label--control font--sm font--500  d--inline-block text--black-600">
              Signature
            </label>
            <div className="w--full bg--black-50 text--black h-min--75 h-max--75  radius--sm p--sm d--flex align-items--center justify-content--center">
              <img
                alt="signature"
                src={documentDetails?.data?.SIGNATURE}
                style={{ maxHeight: '65px' }}
              />
            </div>
          </div>

          <div className="d--flex w--full flex--column  gap--xs">
            <label className="label--control font--sm font--500  d--inline-block text--black-600">
              Email
            </label>
            <div className="w--full bg--black-50 text--black h-min--75 radius--sm p--sm d--flex align-items--center justify-content--center">
              {documentData?.memberDetail?.email}
            </div>
          </div>
          <div className="d--flex w--full flex--column  gap--xs">
            <label className="label--control font--sm font--500  d--inline-block text--black-600">
              Signature Time
            </label>
            <div className="w--full bg--black-50 text--black h-min--75 radius--sm p--sm d--flex align-items--center justify-content--center">
              {moment(documentData?.signedDate).format('MMMM Do YYYY, h:mm A')}
            </div>
          </div>

          <div className="d--flex w--full flex--column  gap--xs">
            <label className="label--control font--sm font--500  d--inline-block text--black-600">
              OS Details
            </label>
            <div className="w--full bg--black-50 text--black h-min--75 radius--sm p--sm d--flex align-items--center justify-content--center">
              <div className="d--flex gap--md flex--column w--full p--sm">
                <div className="d--flex gap--sm w--full align-items--center">
                  <div className="d--flex w-min--24 h-min--24 w-max--24 h-max--24 align-items--center justify-content--center text--black">
                    IP Address:
                  </div>
                  <div>{documentDetails?.ipAddress}</div>
                </div>
                <div className="d--flex gap--sm w--full align-items--center">
                  <div className="d--flex w-min--24 h-min--24 w-max--24 h-max--24 align-items--center justify-content--center text--black">
                    Session ID:
                  </div>
                  <div>{`${generateUniqueId()}`}</div>
                </div>
                <div className="d--flex gap--sm w--full align-items--center">
                  <div className="d--flex w-min--24 h-min--24 w-max--24 h-max--24 align-items--center justify-content--center text--black">
                    Device Type:
                  </div>
                  <div style={{ textTransform: 'uppercase' }}>
                    {documentDetails?.browserInfo?.device_type ?? ''}{' '}
                  </div>
                </div>
                <div className="d--flex gap--sm w--full align-items--center">
                  <div className="d--flex w-min--24 h-min--24 w-max--24 h-max--24 align-items--center justify-content--center text--black">
                    Browser:
                  </div>
                  <div style={{ textTransform: 'uppercase' }}>
                    {documentDetails?.browserInfo?.browser_version ?? ''}{' '}
                  </div>
                </div>
                <div className="d--flex gap--sm w--full align-items--center">
                  <div className="d--flex w-min--24 h-min--24 w-max--24 h-max--24 align-items--center justify-content--center text--black">
                    OS:
                  </div>
                  <div style={{ textTransform: 'uppercase' }}>
                    {documentDetails?.browserInfo?.os_version ?? ''}{' '}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="d--flex w--full flex--column  gap--xs">
            {/* <label className="label--control font--sm font--500  d--inline-block text--black-600">
              IP Address
            </label>
            <div className="w--full bg--black-50 text--black h-min--75 radius--sm p--sm d--flex align-items--center justify-content--center">
              {documentDetails?.ipAddress}
            </div> */}
          </div>
        </div>
        <div className="p--sm w--full d--flex align-items--center justify-content--center bg--white border-top--black-100 gap--sm">
          <Button
            onClick={() => handleDownload(documentDetails?.signedDocumentFile)}
            variant="primary"
            color="black"
            isOutline
            borderColor="primary"
            size="sm"
            btnClasses="c--pointer btn"
          >
            Download PDF
          </Button>
          <Button
            onClick={() => setOpenModal(true)}
            variant="primary"
            color="black"
            isOutline
            borderColor="primary"
            size="sm"
            btnClasses="c--pointer btn"
          >
            View
          </Button>
        </div>
      </div>

      {openModal ? (
        <AsideModal
          handleClose={() => setOpenModal(false)}
          title={'View'}
          footerComponent={null}
          headerComponent={null}
          width="full"
          size="auto-xs"
        >
          <div className="d--flex gap--sm w--full">
            <div className="w--full h--full mx--auto box-shadow--xs overflow--auto p-b--4xl">
              {document}
            </div>
          </div>
        </AsideModal>
      ) : null}
    </div>
  );
};

export default PreviewDocumentOuterMobile;
