import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './styles/styles.scss';
import Spinner from './components/Spinner';
import { persistStore } from 'redux-persist';
import { store } from './redux/store';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { PersistGate } from 'redux-persist/integration/react';
import 'react-toastify/dist/ReactToastify.css';
import { pdfjs } from 'react-pdf';
import '../node_modules/@regulaforensics/vp-frontend-face-components/dist/main.js';
import App from './App.js';
export const persistor = persistStore(store);

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url,
).toString();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Suspense
    fallback={
      <div className="d--flex align-items--center justify-content--center h--full w--full">
        <Spinner size="lg" />
      </div>
    }
  >
    <Provider store={store}>
      <PersistGate loading={<Spinner size="lg" />} persistor={persistor}>
        <ToastContainer />
        <App />
      </PersistGate>
    </Provider>
  </Suspense>,
);
