import React from 'react';

export default function Button({
  size = '',
  variant = '',
  isOutline = false,
  color = 'white',
  children,
  btnClasses = '',
  icon = null,
  rounded = false,
  disabled = false,
  borderColor = 'transparent',
  ...rest
}) {
  return (
    <button
      {...rest}
      disabled={disabled}
      className={` btn--${size} bg--${variant} ${
        isOutline ? `border-full--${borderColor}` : `text--${color}`
      } ${rounded ? `radius--full` : `radius--md`} ${btnClasses}`}
    >
      <span className="d--flex">{icon && icon}</span> {children}
    </button>
  );
}

// import React from 'react';

// export default function Button({ size = '', variant = '', isOutline = false, color = 'white', children, btnClasses = '', icon = null, rounded = false, disabled = false, ...rest }) {
//   return (
//     <button {...rest} disabled={disabled} className={` btn--${size} bg--${variant} ${isOutline ? `border-full--${variant} bg--transparent text--${variant}` : `text--${color}`} ${rounded ? `radius--full` : `radius--sm`} ${btnClasses}`}>
//       <span className="d--flex">{icon && icon}</span> {children}
//     </button>
//   );
// }
