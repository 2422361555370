import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useMutation } from '@tanstack/react-query';
import { updatePassword } from '../../../../api';
import { useAlert } from '../../../../hooks/useAlert';
import FormInput from '../../../../components/FormInput';
import Button from '../../../../components/Button';
import useIcons from '../../../../assets/icons/useIcons';
import {
  confirm_password,
  passwordValidation,
  requiredFieldText,
} from '../../../../helpers/yupValidations';
import { useAuth } from '../../../../hooks/useAuth';

const initialValues = {
  oldPassword: '',
  newPassword: '',
  confirmPassword: '',
};

const validationSchema = yup.object().shape({
  oldPassword: yup.string().required(`Old password field is required.`),
  newPassword: passwordValidation,
  confirmPassword: yup.string().when('newPassword', {
    is: (val) => (val && val.length > 0 ? true : false),
    then: () =>
      yup
        .string()
        .oneOf([yup.ref('newPassword')], 'Password not matched')
        .required(requiredFieldText('Confirm password')),
  }),
});

export default function ChangePassword() {
  const { PhoneProIcon, IpadeIcon, DesktopIcon } = useIcons();
  const { showAlert } = useAlert();
  const { clearUser } = useAuth();
  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
    mode: 'onSubmit',
  });

  const { mutate: updatePasswordMutate, isLoading: changePasswordLoad } =
    useMutation({
      mutationKey: ['updatePassword'],
      mutationFn: updatePassword,
      onSuccess: ({ data }) => {
        const { message = 'Password updated successfully.' } = data?.data || {};
        showAlert({
          type: 'success',
          message,
        });
        reset(initialValues);
        setTimeout(() => {
          clearUser();
        }, 2000);
      },
      onError: (error) => {
        const errMsg = error?.message ?? 'unexpected error';
        showAlert({ type: 'danger', message: errMsg });
      },
    });
  async function onSubmit(values) {
    updatePasswordMutate(values);
  }
  return (
    <div className="w--full d--flex gap--lg flex--column-xs">
      <div className="d--flex flex--column border-full--black-100 radius--sm w--full">
        <div className="label--control font--md font--500   border-bottom--secondary-100 h-min--60 p-l--lg p-r--md d--flex align-items--center ">
          Change Password
        </div>
        <form
          className="w--full d--flex flex--column gap--md p--lg"
          onSubmit={handleSubmit(onSubmit)}
          autoComplete="off"
        >
          <Controller
            name="oldPassword"
            control={control}
            render={({ field }) => (
              <FormInput
                {...field}
                label="Old Password"
                placeholder="Enter your old password"
                error={errors?.oldPassword?.message}
              />
            )}
          />
          <Controller
            name="newPassword"
            control={control}
            render={({ field }) => (
              <FormInput
                {...field}
                label="New Password"
                placeholder="Enter your new password"
                error={errors?.newPassword?.message}
              />
            )}
          />
          <Controller
            name="confirmPassword"
            control={control}
            render={({ field }) => (
              <FormInput
                {...field}
                label="Confirm Password"
                placeholder="Confirm your password"
                error={errors?.confirmPassword?.message}
              />
            )}
          />

          <div className="w--full d--flex align-items--center justify-content--end">
            <Button
              btnClasses="btn  w-max--150 text--black"
              variant="primary"
              color="black"
              type="submit"
              disabled={isSubmitting || changePasswordLoad}
            >
              {changePasswordLoad ? 'Please wait...' : 'Change Password'}
            </Button>
          </div>
        </form>
      </div>
      <div className="d--flex flex--column  radius--sm w--full">
        {/* <div className="d--flex flex--column border-full--black-100 radius--sm w--full"> */}
        {/* <div className="label--control font--md font--500   border-bottom--secondary-100 h-min--60 p-l--lg p-r--md d--flex align-items--center justify-content--between w--full">
          Login History
          <div>
            <Button
              variant="transparent"
              color="black"
              btnClasses="btn w-min--100  border-full--primary"
              data-link-hover
            >
              All Logout
            </Button>
          </div>
        </div>
        <div className="d--flex flex--column gap--lg w--full p--lg">
          <div className="w--full d--flex justify-content--between flex--column-xs gap--sm ">
            <div className="d--flex gap--sm flex--column-xs">
              <div className="d--flex h-min--40 w-min--40 h-max--40 w-max--40 d--flex align-items--center justify-content--center bg--black-50 radius--full">
                <PhoneProIcon />
              </div>
              <div className="d--flex flex--column gap--xs ">
                <div className="font--sm font--500">iPhone 12 Pro</div>
                <div className="font--2sm font--400">
                  Los Angeles, United States - March 16 at 2:47PM
                </div>
              </div>
            </div>
            <div>
              <Button
                variant="transparent"
                color="black"
                btnClasses="btn btn--sm  border-full--primary"
                data-link-hover
              >
                Logout
              </Button>
            </div>
          </div>
          <div className="w--full d--flex justify-content--between flex--column-xs gap--sm">
            <div className="d--flex gap--sm flex--column-xs">
              <div className="d--flex h-min--40 w-min--40 h-max--40 w-max--40 d--flex align-items--center justify-content--center bg--black-50 radius--full">
                <IpadeIcon />
              </div>
              <div className="d--flex flex--column gap--xs">
                <div className="font--sm font--500">Apple iPad Pro</div>
                <div className="font--2sm font--400">
                  Washington, United States - November 06 at 10:43AM
                </div>
              </div>
            </div>
            <div>
              <Button
                variant="transparent"
                color="black"
                btnClasses="btn btn--sm  border-full--primary"
                data-link-hover
              >
                Logout
              </Button>
            </div>
          </div>
          <div className="w--full d--flex justify-content--between flex--column-xs gap--sm">
            <div className="d--flex gap--sm flex--column-xs">
              <div className="d--flex h-min--40 w-min--40 h-max--40 w-max--40 d--flex align-items--center justify-content--center bg--black-50 radius--full">
                <DesktopIcon />
              </div>
              <div className="d--flex flex--column gap--xs">
                <div className="font--sm font--500">Dell Inspiron 14</div>
                <div className="font--2sm font--400">
                  Phoenix, United States - July 26 at 8:10AM
                </div>
              </div>
            </div>
            <div>
              <Button
                variant="transparent"
                color="black"
                btnClasses="btn btn--sm  border-full--primary"
                data-link-hover
              >
                Logout
              </Button>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}
