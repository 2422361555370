import { useEffect } from 'react';

const OtpTimer = ({ time, setTime, timeInSec = false }) => {
  useEffect(() => {
    if (time > 0) {
      const timer = setInterval(() => {
        setTime((prevTime) => prevTime - 1);
      }, 1000);

      // Cleanup interval on component unmount
      return () => clearInterval(timer);
    }
  }, [time]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
  };

  return (
    <>
      {timeInSec ? (
        time
      ) : (
        <div className="d--flex gap--sm">
          {time !== 0 ? (
            <p className="text--danger">{formatTime(time)}</p>
          ) : (
            <p className="text--danger">Otp is expired</p>
          )}
        </div>
      )}
    </>
  );
};

export default OtpTimer;
