import React, { useState } from 'react';
import Button from '../../../components/Button';
import { submitBtnTxt } from '../../../helpers/functions';
import SendAggrementModal from './SendAggrementModal';
import Modal from '../../../components/Modal';
import useIcons from '../../../assets/icons/useIcons';
import { useQueryClient } from '@tanstack/react-query';
import { useAuth } from '../../../hooks/useAuth';

const HRAStep1 = ({ setActiveStep }) => {
  const [openModal, setOpenModal] = useState(false);
  const [tncAgreed, setTncAgreed] = useState(false);
  const [tncError, setTncError] = useState(false);
  const { RefreshIcon } = useIcons();
  const queryClient = useQueryClient();
  const { user } = useAuth();

  return (
    <div className="w--full d--flex flex--column gap--xl HRAPage h--full position--relative">
      <div
        className="position--absolute bg--black-50 text--black c--pointer right--10 top--10 w-min--40 h-min--40 w-max--40 h-max--40 radius--md d--flex align-items--center justify-content--center"
        onClick={() => {
          queryClient.invalidateQueries({
            queryKey: ['getUserDetails'],
            exact: false,
          });
        }}
      >
        <RefreshIcon />
      </div>
      <div className="d--flex flex--column gap--md border-full--black-100 radius--md p--md   justify-content--between">
        <div className="w--full">
          <div className="font--xl font--800 h-min--50  d--flex  p-l--md p-r--md p-t--md ">
            Unlock More Revenue with Health Risk Assessments (HRAs)
          </div>
          <div className="font--lg font--500 h-min--50 d--flex align-items--center  p-l--md p-r--md">
            Streamline Consumer Engagement, Increase Earnings
          </div>
          <div className="font--sm font--400 h-min--50  d--flex align-items--center  p-l--md p-r--md">
            Completing Health Risk Assessments (HRAs) isn't just about
            generating more revenue. It's a valuable opportunity to grow your
            business while supporting consumers effectively.
            {/* {user?.agreementDocumentDetail?.agreementDocumentStatus === 'NO'
            ? 'We have send agreement to your email/sms, please check and sign to continue. If you have not received agreement yet, please try again.'
            : 'To Join and Start, please sign consent agreement.'} */}
          </div>
          <div className="d--flex flex--column gap--sm">
            <div className="font--sm font--600 h-min--28   p-l--md p-r--md">
              Here's how the HRA program can benefit you:
            </div>
            <div className="font--sm font--400 h-min--28   p-l--md p-r--md">
              <li>
                <span className="font--600">Increased Revenue : </span>
                Earn additional payments for every completed HRA submitted to
                carriers.
              </li>
            </div>
            <div className="font--sm font--400 h-min--28   p-l--md p-r--md">
              <li>
                <span className="font--600">Enhanced Consumer Support : </span>
                Help consumers make informed decisions by identifying their
                unique health needs.
              </li>
            </div>
            {/* <div className="font--sm font--400 h-min--28   p-l--md p-r--md">
              <li>
                Effortless Compliance: Stay compliant with CMS regulations while
                improving your professional credibility.
              </li>
            </div> */}
            <div className="font--sm font--400 h-min--28   p-l--md p-r--md">
              <li>
                <span className="font--600">Streamlined Process : </span>
                Use our tools to complete HRAs quickly and efficiently, saving
                time and effort.
              </li>
            </div>
            <div className="font--lg font--500 h-min--50    p-l--md p-r--md p-t--md">
              Take advantage of this program today and maximize your potential
              as an agent!
            </div>
          </div>
        </div>
        <div className="w--full border-full--black-100 p--md radius--md bg--black-50">
          <div className="font--xl font--800 h-min--50  d--flex  p-l--md p-r--md p-t--md ">
            Allowed Carriers and Agent Compensation for HRA Program - Open
            Enrollment 2025
          </div>
          <div className="font--sm font--400 line-height--1-dot-5 d--flex align-items--center  p-l--md p-r--md">
            Agents participating in the HRA (Health Risk Assessment) program for
            Open Enrollment 2025 will be compensated $25 per successfully
            completed HRA. The member has to be enrolled for 90 days and payment
            will be made on the 120th day to the agent after submission date.
            Any members that fall out in the 90 days will not count and not be
            paid the $25.
          </div>

          <div className="d--flex flex--column gap--sm">
            <label
              htmlFor="carrier-tnc"
              className={`font--sm font--400 h-min--50  d--flex align-items--center gap--sm p-l--md p-r--md c--pointer ${
                tncError ? 'text--danger' : 'text--black'
              }`}
            >
              <input
                type="checkbox"
                name=""
                id="carrier-tnc"
                onChange={(e) => {
                  setTncAgreed(e.target.checked);
                  if (e.target.checked) {
                    setTncError(false);
                  }
                }}
                checked={tncAgreed}
              />
              I acknowledge and agree to the terms of the HRA compensation
              program for 2025, including the Agent HRA Agreement Terms and
              Conditions.
            </label>
          </div>
        </div>
        {user?.agreementDocumentDetail?.agreementDocumentStatus ===
        'NO' ? null : (
          <div className="d--flex justify-content--center gap--md w--full  m-t--sm m-b--sm">
            <Button
              type="submit"
              variant="primary"
              color="black"
              btnClasses="btn w-max--200 w-min--200"
              disabled={false}
              onClick={() => {
                if (tncAgreed) {
                  setOpenModal(true);
                } else {
                  setTncError(true);
                }
              }}
            >
              {submitBtnTxt(false, 'Send HRA Agreement')}
            </Button>
          </div>
        )}
      </div>
      {user?.agreementDocumentDetail?.agreementDocumentStatus === 'NO' && (
        <div>
          We have sent agreement to your registered email Id, please check and
          sign. In case you have not received email, please try again.{' '}
          <span
            onClick={() => {
              if (tncAgreed) {
                setOpenModal(true);
              } else {
                setTncError(true);
              }
            }}
            className="text--primary c--pointer"
          >
            Resend Email
          </span>
        </div>
      )}

      {openModal ? (
        <Modal
          width="md"
          height="200"
          size="600"
          shouldCloseOnClickOutside={false}
          handleClose={() => setOpenModal(false)}
          headerComponent={null}
          footerComponent={null}
          title={'Send Agreement'}
        >
          <SendAggrementModal
            handleClose={() => setOpenModal(false)}
            {...{ setActiveStep }}
          />
        </Modal>
      ) : null}
    </div>
  );
};

export default HRAStep1;
