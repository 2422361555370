import React from 'react';
import CanvasJSReact from '@canvasjs/react-charts';
import { useSelector } from 'react-redux';
const CanvasJSChart = CanvasJSReact.CanvasJSChart;

const dataPoints = [
  { label: 'Jan', members: 0, agents: 0, agencies: 0 },
  { label: 'Feb', members: 0, agents: 0, agencies: 0 },
  { label: 'Mar', members: 0, agents: 0, agencies: 0 },
  { label: 'Apr', members: 0, agents: 0, agencies: 0 },
  { label: 'May', members: 0, agents: 0, agencies: 0 },
  { label: 'Jun', members: 0, agents: 0, agencies: 0 },
  { label: 'Jul', members: 0, agents: 0, agencies: 0 },
  { label: 'Aug', members: 0, agents: 0, agencies: 0 },
  { label: 'Sep', members: 0, agents: 0, agencies: 0 },
  { label: 'Oct', members: 0, agents: 0, agencies: 0 },
  { label: 'Nov', members: 0, agents: 0, agencies: 0 },
  { label: 'Dec', members: 0, agents: 0, agencies: 0 },
];

const MemberChart = ({ graphData }) => {
  const { theme } = useSelector((state) => state?.theme);
  let isDark = theme === 'black';

  const options = {
    animationEnabled: true,
    // title: {
    //   text: graphData?.title ?? 'Yearly Billing Summary ',
    //   fontSize: 35,
    //   fontWeight: 'semibold',
    // },
    toolTip: {
      shared: true,
    },
    axisX: {
      lineColor: isDark ? '#c7cbd3' : '#28303f',
      labelFontColor: isDark ? '#c7cbd3' : '#28303f',
      labelFontSize: 12,
    },
    axisY: {
      lineThickness: 0,
      gridColor: 'transparent',
      gridThickness: 0,
      tickLength: 0,
      labelFormatter: () => '',
    },
    legend: {
      horizontalAlign: 'left',
      verticalAlign: 'top',
      fontColor: isDark ? '#c7cbd3' : '#28303f',
    },
    backgroundColor: isDark ? '#161a23' : '#ffffff',

    data: [
      {
        type: 'column',
        name: 'Agent ',
        showInLegend: true,
        color: '#344054',
        dataPoints: (graphData?.data ?? dataPoints).map((point) => ({
          label: point.label,
          y: parseFloat(point.agents),
        })),
      },
    ],
  };

  return <CanvasJSChart options={options} />;
};

export default MemberChart;
