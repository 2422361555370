import React from 'react';
import { useRouteError } from 'react-router-dom';

export default function Error500Page() {
  let error = useRouteError();
  console.log('🚀 ~ Error500Page ~ props:', error);
  return (
    <div style={{ display: 'flex', color: '#000000' }}>
      {error} {JSON.stringify(error)}
      {window.alert(`${error}`)}
    </div>
  );
}
