import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import Modal from '../../../Modal';
import SignModule from '../../../SignModule';

const initialDocData = {
  Primary_House_Hold_Name: '',
  Consumer_Consent_Name: '',
  Method_Revoke_Consent: '',
  Primary_Writing_Agent: '',
  Agent_National_Producer_Number: '',
  Agent_Phone_Number: '',
  Agent_Email: '',
  Name_Of_Agency: '',
  Agency_National_Producer_Number: '',
  Owner_Of_Agency: '',
  Agency_Phone_Number: '',
  Agency_Email: '',
  Household_Name: '',
  H_Phone_Number: '',
  H_Email: '',
  DATE: '',
  SIGNATURE: '',
};

const CMS_ES = forwardRef(
  ({ readMode = false, documentData = null, preFillData = null }, ref) => {
    const [signatureModal, setSignatureModal] = useState(false);

    const [documentState, setDocumentState] = useState(initialDocData);

    useEffect(() => {
      if (preFillData) {
        Object.entries(preFillData).map(([key, value]) => {
          setDocumentState((prev) => ({
            ...prev,
            [key]: value,
          }));
        });
      }
    }, [preFillData]);

    useEffect(() => {
      if (documentData) {
        setDocumentState((prevState) => {
          prevState['Primary_House_Hold_Name'] =
            documentData?.memberDetail?.fullName ?? '';
          prevState['Consumer_Consent_Name'] =
            documentData?.userDetail?.fullName ?? '';
          prevState['Method_Revoke_Consent'] = 'Email';
          prevState['Primary_Writing_Agent'] =
            documentData?.userDetail?.fullName ?? '';
          prevState['Agent_National_Producer_Number'] =
            documentData?.userDetail?.npn ?? '';
          prevState['Agent_Phone_Number'] =
            documentData?.userDetail?.phone ?? '';
          prevState['Agent_Email'] = documentData?.userDetail?.email ?? '';
          prevState['Name_Of_Agency'] =
            documentData?.agencyDetail?.agencyName ?? '';
          prevState['Agency_National_Producer_Number'] =
            documentData?.agencyDetail?.npn ?? '';
          prevState['Owner_Of_Agency'] =
            documentData?.agencyDetail?.owner ?? '';
          prevState['Agency_Phone_Number'] =
            documentData?.agencyDetail?.phone ?? '';
          prevState['Agency_Email'] = documentData?.agencyDetail?.email ?? '';
          prevState['Household_Name'] =
            documentData?.memberDetail?.fullName ?? '';
          prevState['H_Phone_Number'] = documentData?.memberDetail?.phone ?? '';
          prevState['H_Email'] = documentData?.memberDetail?.email ?? '';
          prevState['DATE'] = new Date();

          return { ...prevState };
        });
      }
    }, [documentData]);

    useImperativeHandle(
      ref,
      () => ({
        getFormValues() {
          return documentState;
        },
        getFormTemplate() {
          return document.getElementById('custom-signature-form-conxent')
            ?.outerHTML;
        },
      }),
      [documentState, documentData],
    );

    function handleChange(e, signatureValue = null) {
      if (signatureValue) {
        setDocumentState((prevState) => ({
          ...prevState,
          SIGNATURE: signatureValue,
        }));
        setSignatureModal(false);
      } else {
        const name = e.target.name;
        const value = e.target.value;
        setDocumentState((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      }
    }

    return (
      <>
        <div
          id="custom-signature-form-conxent"
          style={{
            backgroundColor: ' #fff',
            width: '794px',
            margin: '10px auto',
            padding: '10px 20px',
            maxWidth: '960px',
          }}
          className="pdfForm"
        >
          <table
            cellSpacing="0"
            style={{
              width: '100%',
              padding: '15px 0',
              borderCollapse: 'collapse',
              borderSpacing: '0',
            }}
          >
            <tr>
              <td colSpan="2">
                <h2
                  style={{
                    marginTop: '0',
                    textAlign: 'center',
                    fontSize: '18px',
                  }}
                >
                  Consentimiento de CMS para Agentes y Corredores
                </h2>
              </td>
            </tr>

            <tr>
              <td colSpan="2">
                <div
                  style={{
                    width: '100%',
                    display: 'inline-block',
                    marginTop: '15px',
                  }}
                >
                  <span style={{ float: 'left' }}>Yo,</span>
                  <div style={{ float: 'left', maxWidth: '290px' }}>
                    {/* <input
                    name="Primary_House_Hold_Name"
                    value={documentState.Primary_House_Hold_Name}
                    onChange={handleChange}
                    style={{
                      minWidth: '280px',
                      border: 'none',
                      width: '100%',
                      borderBottom: '1px solid #555',
                    }}
                    type="text"
                  /> */}
                    <div
                      style={{
                        minWidth: '200px',
                        border: 'none',
                        width: '100%',
                        borderBottom: '1px solid #555',
                        minHeight: '16px',
                      }}
                    >
                      {documentState.Primary_House_Hold_Name}
                    </div>
                    <p
                      style={{
                        textAlign: 'center',
                        marginTop: ' 10px',
                        marginBottom: '0px',
                      }}
                    >
                      (Nombre del contacto principal del hogar)
                    </p>
                  </div>
                  <span
                    style={{
                      float: 'left',
                      marginLeft: ' 10px',
                      marginRight: ' 10px',
                    }}
                  >
                    doy mi permiso para,
                  </span>
                  <div style={{ float: 'left', maxWidth: '290px' }}>
                    {/* <input
                    name="Consumer_Consent_Name"
                    value={documentState.Consumer_Consent_Name}
                    onChange={handleChange}
                    type="text"
                    style={{
                      minWidth: '280px',
                      border: 'none',
                      width: '100%',
                      borderBottom: '1px solid #555',
                    }}
                  /> */}
                    <div
                      style={{
                        minWidth: '200px',
                        border: 'none',
                        width: '100%',
                        borderBottom: '1px solid #555',
                        minHeight: '16px',
                      }}
                    >
                      {documentState.Consumer_Consent_Name}
                    </div>
                    <p
                      style={{
                        textAlign: 'center',
                        marginTop: ' 10px',
                        marginBottom: '0px',
                      }}
                    >
                      (Nombre del Agente/Broker o Agencia)
                    </p>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colSpan="2">
                <p style={{ lineHeight: '2' }}>
                  Para que me de su servicio como agente o corredor de seguros
                  médicos para mí y para todo mi hogar, si es aplicable, a
                  efectos de inscripción, en un Plan de salud calificado
                  ofrecido en el Mercado, facilitado a nivel federal. Al dar mi
                  consentimiento a este acuerdo, autorizo al Agente mencionado
                  anteriormente a ver y utilizar la información confidencial
                  proporcionada por mí, por escrito, electrónicamente o por
                  teléfono, solo para fines de uno o más de los siguientes:
                </p>
                <p style={{ lineHeight: '1.7', marginTop: '5px' }}>
                  1. Buscar una aplicación de Marketplace existente.
                </p>
                <p style={{ lineHeight: '1.7' }}>
                  2. Completar una solicitud de elegibilidad e inscripción en un
                  plan de salud calificado del Mercado u otros programas
                  gubernamentales de asequibilidad de seguros, como Medicaid y
                  CHIP o créditos fiscales anticipados para ayudar a pagar las
                  primas del Mercado.
                </p>
                <p style={{ lineHeight: '1.7' }}>
                  3. Proporcionar mantenimiento continuo de la cuenta y
                  asistencia para la inscripción, según sea necesario.
                </p>
                <p style={{ lineHeight: '1.7', marginBottom: '5px' }}>
                  4. Responder a las consultas del Mercado sobre mi solicitud.
                </p>
              </td>
            </tr>
            <tr>
              <td colSpan="2">
                <p style={{ lineHeight: '2' }}>
                  Entiendo que el Agente no utilizará ni compartirá mi
                  información de identificación personal (PII) para ningún
                  propósito distinto de los enumerados anteriormente. El Agente
                  se asegurará de que mi PII se mantenga privada y seguro al
                  recopilar, almacenar y utilizar mi PII para los fines
                  indicados anteriormente.
                </p>
              </td>
            </tr>
            <tr>
              <td colSpan="2">
                <p style={{ lineHeight: '2' }}>
                  Confirmo que la información que proporciono para ingresar en
                  mi solicitud de inscripción y elegibilidad del Mercado será
                  verdadera. Entiendo que no tengo que compartir información
                  personal adicional sobre mí o mi salud con mi Agente más allá
                  de lo requerido en la solicitud para fines de elegibilidad e
                  inscripción.
                </p>
              </td>
            </tr>
            <tr>
              <td colSpan="2">
                <p style={{ lineHeight: '2' }}>
                  Entiendo que mi consentimiento permanece vigente hasta que lo
                  revoque, y puedo revocar o modificar mi consentimiento en
                  cualquier momento, presentando una solicitud por escrito al
                  agente/corredor/agencia. Al recibir la solicitud por escrito,
                  este consentimiento quedará inmediatamente invalidado.
                </p>
              </td>
            </tr>
            <tr>
              <td style={{ paddingRight: '20px' }}>
                <table
                  style={{
                    width: '100%',
                    padding: '15px 0',
                    borderCollapse: 'collapse',
                    borderSpacing: '0',
                  }}
                >
                  <tr>
                    <td colSpan="2">
                      <div style={{ width: '100%', marginRight: '10px' }}>
                        {/* <input
                        name="Primary_Writing_Agent"
                        value={documentState.Primary_Writing_Agent}
                        onChange={handleChange}
                        type="text"
                        style={{
                          minWidth: '200px',
                          border: 'none',
                          width: '100%',
                          borderBottom: '1px solid #555',
                        }}
                      /> */}
                        <div
                          style={{
                            minWidth: '200px',
                            border: 'none',
                            width: '100%',
                            borderBottom: '1px solid #555',
                            minHeight: '16px',
                          }}
                        >
                          {documentState.Primary_Writing_Agent}
                        </div>
                        <p style={{ lineHeight: '2', marginTop: '0' }}>
                          Nombre del Agente Primario.
                        </p>
                      </div>
                    </td>
                  </tr>
                </table>
              </td>
              <td style={{ paddingLeft: '20px' }}>
                <table
                  style={{
                    width: '100%',
                    padding: '15px 0',
                    borderCollapse: 'collapse',
                    borderSpacing: '0',
                  }}
                >
                  <tr>
                    <td colSpan="2">
                      <div style={{ width: '100%', marginRight: '10px' }}>
                        {/* <input
                        name="Name_Of_Agency"
                        value={documentState.Name_Of_Agency}
                        onChange={handleChange}
                        type="text"
                        style={{
                          minWidth: '200px',
                          border: 'none',
                          width: '100%',
                          borderBottom: '1px solid #555',
                        }}
                      /> */}

                        <div
                          style={{
                            minWidth: '200px',
                            border: 'none',
                            width: '100%',
                            borderBottom: '1px solid #555',
                            minHeight: '16px',
                          }}
                        >
                          {documentState.Name_Of_Agency}
                        </div>
                        <p style={{ lineHeight: '2', marginTop: '0' }}>
                          Nombre de la Agencia ( si es aplicable)
                        </p>
                      </div>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>

            <tr>
              <td style={{ paddingRight: '20px' }}>
                <table
                  style={{
                    width: '100%',
                    padding: '15px 0',
                    borderCollapse: 'collapse',
                    borderSpacing: '0',
                  }}
                >
                  <tr>
                    <td style={{ width: '60px' }}>
                      <p
                        style={{ lineHeight: '1', margin: '0', width: '95px' }}
                      >
                        Agente NPN:
                      </p>
                    </td>
                    <td>
                      {/* <input
                      name="Agent_National_Producer_Number"
                      value={documentState.Agent_National_Producer_Number}
                      onChange={handleChange}
                      type="text"
                      style={{
                        minWidth: '200px',
                        border: 'none',
                        width: '100%',
                        borderBottom: '1px solid #555',
                      }}
                    /> */}
                      <div
                        style={{
                          minWidth: '200px',
                          border: 'none',
                          width: '100%',
                          borderBottom: '1px solid #555',
                          minHeight: '16px',
                        }}
                      >
                        {documentState.Agent_National_Producer_Number}
                      </div>
                    </td>
                  </tr>
                </table>
              </td>
              <td style={{ paddingLeft: '20px' }}>
                <table
                  style={{
                    width: '100%',
                    padding: '15px 0',
                    borderCollapse: 'collapse',
                    borderSpacing: '0',
                  }}
                >
                  <tr>
                    <td style={{ width: '60px' }}>
                      <p
                        style={{ lineHeight: '1', margin: '0', width: '95px' }}
                      >
                        Numero de Telef:
                      </p>
                    </td>
                    <td>
                      {/* <input
                      name="Agency_Phone_Number"
                      value={documentState.Agency_Phone_Number}
                      onChange={handleChange}
                      type="text"
                      style={{
                        minWidth: '200px',
                        border: 'none',
                        width: '100%',
                        borderBottom: '1px solid #555',
                      }}
                    /> */}
                      <div
                        style={{
                          minWidth: '200px',
                          border: 'none',
                          width: '100%',
                          borderBottom: '1px solid #555',
                          minHeight: '16px',
                        }}
                      >
                        {documentState.Agency_Phone_Number}
                      </div>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>

            <tr style={{ marginTop: '15px' }}>
              <td style={{ paddingRight: '20px' }}>
                <table
                  style={{
                    marginTop: '15px',
                    width: '100%',
                    padding: '15px 0',
                    borderCollapse: 'collapse',
                    borderSpacing: '0',
                  }}
                >
                  <tr>
                    <td style={{ width: '60px' }}>
                      <p
                        style={{ lineHeight: '1', margin: '0', width: '95px' }}
                      >
                        Numero de Telef:
                      </p>
                    </td>
                    <td>
                      {/* <input
                      name="Agent_Phone_Number"
                      value={documentState.Agent_Phone_Number}
                      onChange={handleChange}
                      type="text"
                      style={{
                        minWidth: '200px',
                        border: 'none',
                        width: '100%',
                        borderBottom: '1px solid #555',
                      }}
                    /> */}
                      <div
                        style={{
                          minWidth: '200px',
                          border: 'none',
                          width: '100%',
                          borderBottom: '1px solid #555',
                          minHeight: '16px',
                        }}
                      >
                        {documentState.Agent_Phone_Number}
                      </div>
                    </td>
                  </tr>
                </table>
              </td>
              <td style={{ paddingLeft: '20px' }}>
                <table
                  style={{
                    marginTop: '15px',
                    width: '100%',
                    padding: '15px 0',
                    borderCollapse: 'collapse',
                    borderSpacing: '0',
                  }}
                >
                  <tr>
                    <td style={{ width: '60px' }}>
                      <p
                        style={{ lineHeight: '1', margin: '0', width: '95px' }}
                      >
                        Email:
                      </p>
                    </td>
                    <td>
                      {/* <input
                      name="Agency_Email"
                      value={documentState.Agency_Email}
                      onChange={handleChange}
                      type="text"
                      style={{
                        minWidth: '200px',
                        border: 'none',
                        width: '100%',
                        borderBottom: '1px solid #555',
                      }}
                    /> */}

                      <div
                        style={{
                          minWidth: '200px',
                          border: 'none',
                          width: '100%',
                          borderBottom: '1px solid #555',
                          minHeight: '16px',
                        }}
                      >
                        {documentState.Agency_Email}
                      </div>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
            <tr style={{ marginTop: '15px' }}>
              <td style={{ paddingRight: '20px' }}>
                <table
                  style={{
                    marginTop: '15px',
                    width: '100%',
                    padding: '15px 0',
                    borderCollapse: 'collapse',
                    borderSpacing: '0',
                  }}
                >
                  <tr>
                    <td style={{ width: '60px' }}>
                      <p
                        style={{ lineHeight: '1', margin: '0', width: '95px' }}
                      >
                        Email:
                      </p>
                    </td>
                    <td>
                      {/* <input
                      name="Agent_Email"
                      value={documentState.Agent_Email}
                      onChange={handleChange}
                      type="text"
                      style={{
                        minWidth: '200px',
                        border: 'none',
                        width: '100%',
                        borderBottom: '1px solid #555',
                      }}
                    /> */}
                      <div
                        style={{
                          minWidth: '200px',
                          border: 'none',
                          width: '100%',
                          borderBottom: '1px solid #555',
                          minHeight: '16px',
                        }}
                      >
                        {documentState.Agent_Email}
                      </div>
                    </td>
                  </tr>
                </table>
              </td>
              <td style={{ paddingLeft: '20px' }}> </td>
            </tr>
            <tr>
              <td style={{ paddingRight: '20px' }}>
                <table
                  style={{
                    marginTop: '30px',
                    width: '100%',
                    padding: '15px 0',
                    borderCollapse: 'collapse',
                    borderSpacing: '0',
                  }}
                >
                  <tr>
                    <td colSpan="2">
                      <div style={{ width: '100%', marginRight: '10px' }}>
                        {/* <input
                        name="Household_Name"
                        value={documentState.Household_Name}
                        onChange={handleChange}
                        type="text"
                        style={{
                          minWidth: '200px',
                          border: 'none',
                          width: '100%',
                          borderBottom: '1px solid #555',
                        }}
                      /> */}
                        <div
                          style={{
                            minWidth: '200px',
                            border: 'none',
                            width: '100%',
                            borderBottom: '1px solid #555',
                            minHeight: '16px',
                          }}
                        >
                          {documentState.Household_Name}
                        </div>
                        <p style={{ lineHeight: '2', marginTop: '0' }}>
                          (Nombre del Contacto Principal del Hogar )
                        </p>
                      </div>
                    </td>
                  </tr>
                </table>
              </td>
              <td style={{ paddingLeft: '20px' }}></td>
            </tr>

            <tr>
              <td style={{ paddingRight: '20px' }}>
                <table
                  style={{
                    width: '100%',
                    padding: '15px 0',
                    borderCollapse: 'collapse',
                    borderSpacing: '0',
                  }}
                >
                  <tr>
                    <td style={{ width: '60px' }}>
                      <p
                        style={{ lineHeight: '1', margin: '0', width: '95px' }}
                      >
                        Numero de Telef:
                      </p>
                    </td>
                    <td>
                      {/* <input
                      name="H_Phone_Number"
                      value={documentState.H_Phone_Number}
                      onChange={handleChange}
                      type="text"
                      style={{
                        minWidth: '200px',
                        border: 'none',
                        width: '100%',
                        borderBottom: '1px solid #555',
                      }}
                    /> */}
                      <div
                        style={{
                          minWidth: '200px',
                          border: 'none',
                          width: '100%',
                          borderBottom: '1px solid #555',
                          minHeight: '16px',
                        }}
                      >
                        {documentState.H_Phone_Number}
                      </div>
                    </td>
                  </tr>
                </table>
              </td>
              <td style={{ paddingLeft: '20px' }}></td>
            </tr>

            <tr style={{ marginTop: '15px' }}>
              <td style={{ paddingRight: '20px' }}>
                <table
                  style={{
                    marginTop: '15px',
                    width: '100%',
                    padding: '15px 0',
                    borderCollapse: 'collapse',
                    borderSpacing: '0',
                  }}
                >
                  <tr>
                    <td style={{ width: '60px' }}>
                      <p
                        style={{ lineHeight: '1', margin: '0', width: '95px' }}
                      >
                        Email:
                      </p>
                    </td>
                    <td>
                      {/* <input
                      name="H_Email"
                      value={documentState.H_Email}
                      onChange={handleChange}
                      type="text"
                      style={{
                        minWidth: '200px',
                        border: 'none',
                        width: '100%',
                        borderBottom: '1px solid #555',
                      }}
                    /> */}
                      <div
                        style={{
                          minWidth: '200px',
                          border: 'none',
                          width: '100%',
                          borderBottom: '1px solid #555',
                          minHeight: '16px',
                        }}
                      >
                        {documentState.H_Email}
                      </div>
                    </td>
                  </tr>
                </table>
              </td>
              <td style={{ paddingLeft: '20px' }}></td>
            </tr>
            <tr style={{ marginTop: '15px' }}>
              <td style={{ paddingRight: '20px' }}>
                <table
                  style={{
                    marginTop: '15px',
                    width: '100%',
                    padding: '15px 0',
                    borderCollapse: 'collapse',
                    borderSpacing: '0',
                  }}
                >
                  <tr>
                    <td style={{ width: '60px' }}>
                      <p
                        style={{ lineHeight: '1', margin: '0', width: '95px' }}
                      >
                        Fecha:
                      </p>
                    </td>
                    <td>
                      {/* <input
                      name="DATE"
                      value={documentState.DATE}
                      onChange={handleChange}
                      type="text"
                      style={{
                        minWidth: '200px',
                        border: 'none',
                        width: '100%',
                        borderBottom: '1px solid #555',
                      }}
                    /> */}
                      <div
                        style={{
                          minWidth: '200px',
                          border: 'none',
                          width: '100%',
                          borderBottom: '1px solid #555',
                          minHeight: '16px',
                        }}
                      >
                        {`${documentState.DATE}`}
                      </div>
                    </td>
                  </tr>
                </table>
              </td>
              <td style={{ paddingLeft: '20px' }}>
                <table
                  style={{
                    marginTop: '15px',
                    width: '100%',
                    padding: '15px 0',
                    borderCollapse: 'collapse',
                    borderSpacing: '0',
                  }}
                >
                  <tr>
                    <td style={{ width: '60px' }}>
                      <p
                        style={{ lineHeight: '1', margin: '0', width: '95px' }}
                      >
                        Firma:
                      </p>
                    </td>
                    <td>
                      <div style={{ position: 'relative' }}>
                        {!documentState?.SIGNATURE ? (
                          <div
                            onClick={() => {
                              if (readMode) return;
                              setSignatureModal(true);
                            }}
                            style={{
                              minWidth: '200px',
                              border: 'none',
                              height: '80px',
                              width: '100%',
                              padding: '5px',
                              cursor: 'pointer',
                              position: 'absolute',
                              top: '-80px',
                              border: '1px dashed #ddd',
                            }}
                          >
                            + Add Signature
                          </div>
                        ) : (
                          <div
                            onClick={() => {
                              if (readMode) return;
                              setSignatureModal(true);
                            }}
                            style={{
                              minWidth: '200px',
                              border: 'none',
                              width: '100%',
                              padding: '5px',
                              cursor: 'pointer',
                              position: 'absolute',
                              top: '-80px',
                              height: '80px',
                              border: '1px dashed #ddd',
                            }}
                          >
                            <img
                              alt="signature"
                              src={documentState?.SIGNATURE}
                              style={{ maxHeight: '70px' }}
                            />
                          </div>
                        )}
                      </div>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
        </div>

        {signatureModal ? (
          <Modal
            handleClose={() => setSignatureModal(false)}
            title={'Signature'}
            headerComponent={null}
            shouldCloseOnClickOutside={true}
            footerComponent={null}
            height="300"
            size="700"
          >
            <SignModule handleChange={handleChange} />
          </Modal>
        ) : null}
      </>
    );
  },
);

export default CMS_ES;
