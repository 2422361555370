import React, { useState } from 'react';
import FormInput from '../../components/FormInput';
import nextereLogo from '../../assets/images/nextere-logo.png';
import { useNavigate } from 'react-router-dom';
import OTPInput from 'react-otp-input';
import { useMutation } from '@tanstack/react-query';
import { loginVerifyOtp, resendOtp } from '../SignUp/actions';
import { submitBtnTxt } from '../../helpers/functions';
import { useAlert } from '../../hooks/useAlert';
import useIcons from '../../assets/icons/useIcons';
import SubmitButton from '../../components/Button/SubmitButton';
import OtpTimer from '../OTPVerify/OtpTimer';
import { useAuth } from '../../hooks/useAuth';
import { MEMBER } from '../../helpers/constants';

const LoginOtp = ({
  formData,
  setShowOtp = () => {},
  setResponseData = () => {},
}) => {
  const navigate = useNavigate();
  const { BackRoundIcon } = useIcons();
  const [time, setTime] = useState(180);
  const { showAlert } = useAlert();
  const { setUser, setUserData } = useAuth();

  const [otp, setOtp] = useState(false);

  const { mutate, isLoading } = useMutation({
    mutationFn: loginVerifyOtp,
    onSuccess: ({ data }) => {
      if (data?.data?.token) {
        setUser({
          token: data?.data?.token?.token,
          role: data?.data?.role,
        });
        setUserData(data?.data);
        navigate(data?.data?.role === MEMBER ? '/agents' : '/dashboard');
      } else {
        setResponseData(data?.data);
      }
    },
    onError: (error) => {
      const errMsg = error?.message ?? 'unexpected error';
      showAlert({ type: 'danger', message: errMsg });
    },
  });

  const { mutate: resendMutate, isLoading: resendLoading } = useMutation({
    mutationFn: resendOtp,
    onSuccess: ({ data }) => {
      showAlert({ type: 'success', message: data?.data?.message });
      setTime(180);
    },
    onError: (error) => {
      const errMsg = error?.message ?? 'unexpected error';
      showAlert({ type: 'danger', message: errMsg });
    },
  });

  return (
    <>
      {/* <OuterHeader showTabs={false} /> */}

      <div className="w--full  d--flex flex--column align-items--center justify-content--start loginPage bg--contrast overflow--y-auto">
        <div className="w--full h-min--60 d--flex justify-content--between align-items--center bg--white p-t--xs p-l--md p-r--md border-bottom--black-50">
          <img src={nextereLogo} alt="nextereLogo" className="w-max--150" />
        </div>
        <div className="w--full h--full p-t--xl d--flex align-items--center justify-content--start p-b--xl flex--column loginContent p-l--sm p-r--sm gap--lg">
          <div className="d--flex justify-content--start w--full w-max--600 m-b--md">
            <div
              onClick={() => setShowOtp(false)}
              className="d--flex align-items--center gap--xs text--black c--pointer font--sm"
            >
              <span className="d--flex">
                <BackRoundIcon width={25} height={25} />
              </span>
              <span className="font--500">Back</span>
            </div>
          </div>
          <div className="d--flex flex--column gap--lg  w--full w-max--600">
            <h3 className="text--c m-b--md font--md font--600">
              {formData?.isSkipVerification
                ? `A verification
              code has been sent to XXXXXX${formData?.phone?.slice(-4)}`
                : ` An email has been sent to ${formData?.email} with verification
              code.`}
            </h3>
            <div className="radius--md  gap--xl w-max--600 w--full bg--secondary p--xl z-index--xs bg--white box--shadow d--flex align-items--center justify-content--center flex--column gap--xs h-min--300">
              <form
                className="w--full d--flex flex--column gap--sm"
                autoComplete="off"
                onSubmit={(e) => {
                  e.preventDefault();
                  mutate({
                    email: formData?.email,
                    otp: parseInt(otp),
                  });
                }}
              >
                <h3 className="text--c m-b--md font--lg font--600">
                  Enter Verification code below
                </h3>
                <div className="d--flex justify-content--center align-items--center gap--md  m-x--auto out m-b--sm ">
                  <OTPInput
                    value={otp}
                    onChange={setOtp}
                    // onChange={(value) => {
                    //   if (/^\d*$/.test(value)) {
                    //     setOtp(value);
                    //   }
                    // }}
                    inputType="number"
                    numInputs={4}
                    renderInput={(props) => {
                      return (
                        <FormInput
                          extraClasses="otp-input-control"
                          {...props}
                        />
                      );
                    }}
                    shouldAutoFocus
                    containerStyle={{ gap: '10px' }}
                    inputStyle={{ fontSize: '16px', width: '4em' }}
                  />
                </div>

                <div className="d--flex align-items--center justify-content--center gap--sm w--full m-t--sm">
                  <SubmitButton
                    btnClasses="btn w-max--170"
                    disabled={isLoading || otp.length !== 4}
                    size="md"
                  >
                    {submitBtnTxt(isLoading, 'Verify')}
                  </SubmitButton>
                </div>

                <div className="w--full d--flex align-items--center justify-content--center gap--sm font--sm m-t--lg">
                  {time > 0 ? (
                    <>
                      Try Resend otp after{' '}
                      <OtpTimer {...{ time, setTime, timeInSec: true }} /> sec
                    </>
                  ) : (
                    <span
                      className="c--pointer text--primary font--500"
                      onClick={() => {
                        const {
                          firstName,
                          lastName,
                          email,
                          phone,
                          country,
                          userType,
                        } = formData || {};

                        if (!resendLoading) {
                          resendMutate({
                            firstName,
                            lastName,
                            email,
                            phone,
                            country,
                            userType,
                          });
                        }
                      }}
                    >
                      Resend
                    </span>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginOtp;
