import {
  domainValidation,
  email,
  name,
  npn,
  phone,
  postalCode,
  requiredFieldFunction,
} from '../../../../helpers/yupValidations';
import * as yup from 'yup';

export const defaultValues = {
  isAssociatedAgency: 'N',
  associatedAgencyName: '',
  associatedAgencyEmail: '',
  associatedAgencyPhone: '',
  associatedAgencyNPN: '',
  associatedAddressOne: '',
  associatedAddressTwo: '',
  associatedState: '',
  associatedCity: '',
  associatedZipCode: '',

  isOwnAgency: 'N',
  ownAgencyType: 'REGULAR',
  ownAgencyName: '',
  ownAgencyEmail: '',
  ownAgencyPhone: '',
  ownAgencyNPN: '',
  ownAgencyEIN: '',
  ownAddressOne: '',
  ownAddressTwo: '',
  ownState: '',
  ownCity: '',
  ownZipCode: '',
  ownAgencyWebsite: '',
};

export const schema = yup.object({
  isAssociatedAgency: yup.string(),
  associatedAgencyName: yup.string().when('isAssociatedAgency ', {
    is: (val) => val === 'Y',
    then: () => name,
    otherwise: () => yup.string(),
  }),
  associatedAgencyEmail: yup.string().when('isAssociatedAgency ', {
    is: (val) => val === 'Y',
    then: () => email,
    otherwise: () => yup.string(),
  }),
  associatedAgencyPhone: yup.string().when('isAssociatedAgency ', {
    is: (val) => val === 'Y',
    then: () => phone,
    otherwise: () => yup.string().nullable(),
  }),
  // associatedAgencyNPN: yup.string().when('isAssociatedAgency ', {
  //   is: (val) => val === 'Y',
  //   then: () => npn,
  //   otherwise: () => yup.string(),
  // }),
  associatedAddressOne: yup.string().when('isAssociatedAgency ', {
    is: (val) => val === 'Y',
    then: () => requiredFieldFunction('Agency address'),
    otherwise: () => yup.string(),
  }),
  associatedState: yup.string().when('isAssociatedAgency ', {
    is: (val) => val === 'Y',
    then: () => requiredFieldFunction('Agency state'),
    otherwise: () => yup.string(),
  }),
  associatedCity: yup.string().when('isAssociatedAgency ', {
    is: (val) => val === 'Y',
    then: () => requiredFieldFunction('Agency city'),
    otherwise: () => yup.string(),
  }),
  associatedZipCode: yup.string().when('isAssociatedAgency ', {
    is: (val) => val === 'Y',
    then: () => postalCode,
    otherwise: () => yup.string(),
  }),

  isOwnAgency: requiredFieldFunction('own agency'),
  ownAgencyType: yup.string(),
  ownAgencyName: yup.string().when('isOwnAgency', {
    is: (val) => val === 'Y',
    then: () => name,
    otherwise: () => yup.string(),
  }),
  ownAgencyEmail: yup.string().when('isOwnAgency', {
    is: (val) => val === 'Y',
    then: () => email,
    otherwise: () => yup.string(),
  }),
  ownAgencyPhone: yup.string().when('isOwnAgency', {
    is: (val) => val === 'Y',
    then: () => phone,
    otherwise: () => yup.string().nullable(),
  }),
  ownAgencyNPN: yup.string().when('ownAgencyType', {
    is: (val) => val === 'FMO',
    then: () => npn,
    otherwise: () => yup.string(),
  }),
  ownAgencyEIN: yup.string().when('isOwnAgency', {
    is: (val) => val === 'Y',
    then: () => requiredFieldFunction('Agency ein'),
    otherwise: () => yup.string(),
  }),
  ownAddressOne: yup.string().when('isOwnAgency', {
    is: (val) => val === 'Y',
    then: () => requiredFieldFunction('Agency address'),
    otherwise: () => yup.string(),
  }),
  ownState: yup.string().when('isOwnAgency', {
    is: (val) => val === 'Y',
    then: () => requiredFieldFunction('Agency state'),
    otherwise: () => yup.string(),
  }),
  ownCity: yup.string().when('isOwnAgency', {
    is: (val) => val === 'Y',
    then: () => requiredFieldFunction('Agency city'),
    otherwise: () => yup.string(),
  }),
  ownZipCode: yup.string().when('isOwnAgency', {
    is: (val) => val === 'Y',
    then: () => postalCode,
    otherwise: () => yup.string(),
  }),
  ownAgencyWebsite: yup.string().when('isOwnAgency', {
    is: (val) => val === 'Y',
    then: () => domainValidation,
    otherwise: () => yup.string(),
  }),
});
