import React, { useEffect } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAlert } from '../../../hooks/useAlert';
import { createAgency, updateAgency } from '../actions';
import { schema } from '../constants';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import FormInput from '../../../components/FormInput';
import Button from '../../../components/Button';
import PhoneInput from 'react-phone-number-input';
import { Country } from 'country-state-city';
import FormSelect from '../../../components/FormSelect';

const initialValues = {
  name: '',
  email: '',
  phone: '',
  principalFirstname: '',
  principalLastname: '',
  addressLine1: '',
  country: 'US',
  state: '',
  city: '',
  postalCode: '',
  // domain: '',
  // subDomain: '',
};
const countriesList = Country.getAllCountries().map((country) => {
  const { name, isoCode } = country;
  return { label: name, value: isoCode };
});

const AddAgency = ({ handleClose = () => {}, editAgentInstance }) => {
  const { isEdit, editData = {} } = editAgentInstance || {};
  const queryclient = useQueryClient();
  const { showAlert } = useAlert();

  const {
    watch,
    getValues,
    setValue,
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: initialValues,
    mode: 'onTouched',
  });

  const { mutate, isLoading } = useMutation({
    mutationFn: isEdit ? updateAgency : createAgency,
    mutationKey: isEdit ? 'updateAgency' : 'createAgency',
    onSuccess: ({ data }) => {
      showAlert({ type: 'success', message: data?.data?.message });
      queryclient.invalidateQueries({ queryKey: ['getAgentList'] });
      handleClose();
    },
    onError: (error) => {
      const errMsg = error?.message ?? 'unexpected error';
      showAlert({ type: 'danger', message: errMsg });
    },
  });

  const onSubmit = (values) => {
    const {
      name,
      email,
      phone,
      principalFirstname,
      principalLastname,
      addressLine1,
      country,
      state,
      city,
      postalCode,
    } = values;
    let payload = {
      agencyName: name,
      firstName: principalFirstname,
      lastName: principalLastname,
      email,
      phone,
      country,
      city,
      state,
      postalCode,
      address: addressLine1,
      // domain,
      // subDomain,
    };
    if (isEdit) {
      delete payload.email;
      delete payload.extension;
      payload.uuid = editData?.agencyUserId?._id;
    }
    mutate(payload);
  };

  useEffect(() => {
    if (isEdit && Object.keys(editData).length) {
      const { agencyName, agencyUserId } = editData || {};
      const {
        firstName,
        lastName,
        email,
        phone,
        country,
        state,
        city,
        postalCode,
        address,
      } = agencyUserId || {};
      reset({
        name: agencyName,
        principalFirstname: firstName,
        principalLastname: lastName,
        email,
        phone,
        country,
        city,
        state,
        postalCode,
        addressLine1: address,
      });
    }
  }, [editData]);
  return (
    <form
      className="w--full h--full"
      onSubmit={handleSubmit(onSubmit)}
      autoComplete="off"
    >
      <div className="overflow--auto dialogScroll d--flex flex--column gap--md p--md">
        <div>
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <FormInput
                {...field}
                type="text"
                label="Name"
                placeholder="Enter your name"
                maxLength={30}
                error={errors?.name?.message}
              />
            )}
          />
        </div>
        <div className="d--flex gap--md">
          <div className="w--full">
            <Controller
              name="principalFirstname"
              control={control}
              render={({ field }) => (
                <FormInput
                  {...field}
                  type="text"
                  label="Principal First Name"
                  placeholder="Enter your first name"
                  maxLength={30}
                  error={errors?.principalFirstname?.message}
                />
              )}
            />
          </div>
          <div className="w--full">
            <Controller
              name="principalLastname"
              control={control}
              render={({ field }) => (
                <FormInput
                  {...field}
                  type="text"
                  label="Principal Last Name"
                  placeholder="Enter your last name"
                  maxLength={30}
                  error={errors?.principalLastname?.message}
                />
              )}
            />
          </div>
        </div>
        <div className="d--flex gap--md">
          <div className="w--full">
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <FormInput
                  {...field}
                  type="text"
                  label="Email"
                  placeholder="Enter your email address"
                  error={errors?.email?.message}
                  disabled={editAgentInstance?.isEdit}
                  extraClasses={
                    editAgentInstance?.isEdit ? 'c--not-allowed' : ''
                  }
                />
              )}
            />
          </div>
          <div className="w--full">
            <label
              className={`label--control font--sm font--500 m-b--sm d--inline-block ${
                errors?.phone?.message ? 'text--danger' : 'text--grey'
              }`}
            >
              {errors?.phone?.message ?? 'Phone'}
            </label>
            <Controller
              name="phone"
              control={control}
              render={({ field }) => (
                <PhoneInput
                  {...field}
                  control={control}
                  defaultCountry={watch('country')}
                  international={true}
                  withCountryCallingCode={true}
                  placeholder="Enter your phone"
                  internationalIcon={() => (
                    <span>
                      <i className="fa fa-phone icon-circle bg-danger"></i>
                    </span>
                  )}
                  value={getValues(`phone`)}
                  limitMaxLength={true}
                  onChange={(phone) =>
                    setValue(`phone`, phone, {
                      shouldValidate: true,
                    })
                  }
                  className={`form--control w--full h-min--36  radius--sm p-l--md p-r--md  d--flex align-items--center ${
                    errors?.phone?.message
                      ? 'border-full--danger'
                      : 'border-full--black-200'
                  }`}
                />
              )}
            />
          </div>
        </div>

        <div className="d--flex gap--md">
          <div className="w--full">
            <Controller
              name="addressLine1"
              control={control}
              render={({ field }) => (
                <FormInput
                  {...field}
                  type="text"
                  label="Address"
                  placeholder="Enter your address"
                  maxLength={30}
                  error={errors?.addressLine1?.message}
                />
              )}
            />
          </div>
        </div>

        <div className="d--flex gap--md">
          <div className="w--full">
            <Controller
              name="country"
              control={control}
              render={({ field }) => (
                <FormSelect
                  {...field}
                  label="Country"
                  error={errors?.country?.message}
                  options={countriesList}
                  onChange={(v) => {
                    field.onChange(v);
                    setValue('phone', '');
                  }}
                  height="40"
                  disabled={true}
                />
              )}
            />
          </div>

          <div className="w--full">
            <Controller
              name="state"
              control={control}
              render={({ field }) => (
                <FormInput
                  {...field}
                  type="text"
                  label="State"
                  placeholder="Enter your state"
                  maxLength={30}
                  error={errors?.state?.message}
                />
              )}
            />
          </div>
        </div>

        <div className="d--flex gap--md">
          <div className="w--full">
            <Controller
              name="city"
              control={control}
              render={({ field }) => (
                <FormInput
                  {...field}
                  type="text"
                  label="City"
                  placeholder="Enter your city"
                  maxLength={30}
                  error={errors?.city?.message}
                />
              )}
            />
          </div>
          <div className="w--full">
            <Controller
              name="postalCode"
              control={control}
              render={({ field }) => (
                <FormInput
                  {...field}
                  type="text"
                  label="Postal Code"
                  placeholder="Enter your postal code"
                  maxLength={30}
                  error={errors?.postalCode?.message}
                />
              )}
            />
          </div>
        </div>

        {/* <div className="d--flex gap--md">
          <div className="w--full">
            <Controller name="domain" control={control} render={({ field }) => <FormInput {...field} type="text" label="Domain" placeholder="Enter your Domain" maxLength={30} error={errors?.domain?.message} />} />
          </div>

          <div className="w--full">
            <Controller name="subDomain" control={control} render={({ field }) => <FormInput {...field} type="text" label="Sub Domain" placeholder="Enter your Sub Domain" maxLength={30} error={errors?.subDomain?.message} />} />
          </div>
        </div> */}
      </div>
      <div className="d--flex justify-content--between gap--md p-l--md p-r--md p-t--md border-top--primary-100">
        <Button
          type="button"
          variant="contrast"
          color="primary"
          btnClasses="btn"
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          btnClasses="btn"
          type="submit"
          variant="primary"
          disabled={isLoading}
        >
          {isLoading ? 'Please wait..' : 'Save'}
        </Button>
      </div>
    </form>
  );
};

export default AddAgency;
