import React, { useEffect, useRef, useState } from 'react';
import Exl from '../../assets/images/excel.png';
import Pdf from '../../assets/images/pdf.png';
import Wrd from '../../assets/images/word.png';
import Gnr from '../../assets/images/generalFile.png';
import Play from '../../assets/images/play-button.png';
import Pause from '../../assets/images/pause.png';
import Video from '../../assets/images/video.png';
import Audio from '../../assets/images/audio.png';
import Img from '../../assets/images/img.png';

export const renderIcon = (type, withDownload = false, withName = true) => {
  switch (type) {
    case 'csv':
    case 'xls':
    case 'xlsx':
    case 'ms-excel':
    case 'msexcel':
      return (
        <>
          <img
            src={Exl}
            alt="..."
            className=" object--contain radius--sm bg--black-100 "
          />
          {withName && type}
        </>
      );

    case 'pdf':
      return (
        <>
          <img
            src={Pdf}
            alt="..."
            className=" object--contain radius--sm bg--black-100 "
          />

          {withName && type}
        </>
      );

    case 'msword':
    case 'ms-word':
    case 'docx':
    case 'doc':
    case 'rtf':
      return (
        <>
          <img
            src={Wrd}
            alt="..."
            className=" object--contain radius--sm bg--black-100 "
          />
          {withName && type}
        </>
      );

    case 'mp4':
      return (
        <>
          <img
            src={Video}
            alt="..."
            className=" object--contain radius--sm bg--black-100 "
          />
          {withName && type}
        </>
      );
    case 'png':
    case 'PNG':
    case 'jpg':
    case 'jpeg':
    case 'JPEG':
    case 'JPG':
      return (
        <>
          <img
            src={Img}
            alt="..."
            className=" object--contain radius--sm bg--black-100 "
          />
          {withName && type}
        </>
      );

    case 'wav':
    case 'mp3':
      return (
        <>
          <img
            src={Audio}
            alt="..."
            className=" object--contain radius--sm bg--black-100 "
          />
          {withName && type}
        </>
      );

    case 'txt':
      return (
        <>
          <img
            src={Gnr}
            alt="..."
            className=" object--contain radius--sm bg--black-100 "
          />
          {withName && type}
        </>
      );
    default:
      return (
        <>
          <img
            src={Gnr}
            alt="..."
            className=" object--contain radius--sm bg--black-100 "
          />
          {withName && type}
        </>
      );
  }
};

const ImagePreview = ({ file }) => {
  const [preview, setPreview] = useState(null);

  useEffect(() => {
    if (file) {
      const objectUrl = URL.createObjectURL(file);
      setPreview(objectUrl);
      return () => URL.revokeObjectURL(objectUrl);
    }
  }, [file]);

  return (
    <div className="d--flex flex--column">
      <div>
        <img
          src={preview}
          alt="file-preview"
          className=" object--contain radius--sm bg--black-100"
        />
      </div>
      <span className="w--75 text--ellipsis">{file?.name}</span>
    </div>
  );
};

const VideoPreview = ({ file }) => {
  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current) {
      const videoURL = URL.createObjectURL(file);
      videoRef.current.src = videoURL;
      videoRef.current.load();
      videoRef.current.onloadeddata = function () {
        videoRef.current.play();
      };
    }
  }, [videoRef, file]);

  return (
    <div className="d--flex flex--column">
      <video
        ref={videoRef}
        controls
        className=" object--contain radius--sm bg--black-100"
        loop
        controlsList="nodownload"
        muted={true}
      >
        <source />
      </video>
      <div className="w--75 text--ellipsis">{file?.name}</div>
    </div>
  );
};

const AudioPreview = ({ file }) => {
  const [audio, setAudio] = useState(null);
  const [playing, setPlaying] = useState(false);

  useEffect(() => {
    const reader = new FileReader();
    reader.onload = (evt) => {
      setAudio(new Audio(evt.target.result));
    };
    reader.readAsDataURL(file);
  }, [file]);

  useEffect(() => {
    if (audio) {
      playing ? audio?.play() : audio?.pause();
    }
  }, [playing]);

  const toggle = () => setPlaying(!playing);
  return (
    <div className="d--flex flex--column">
      <div
        onClick={() => toggle()}
        className=" object--contain radius--sm bg--black-100 d--flex justify-content--center align-items--center c--pointer"
      >
        {playing ? (
          <img
            src={Pause}
            alt="..."
            className="w-min--25 w-max--25 h-min--25 h-max--25 object--contain radius--sm bg--black-25 "
          />
        ) : (
          <img
            src={Play}
            alt="..."
            className="w-min--25 w-max--25 h-min--25 h-max--25 object--contain radius--sm bg--black-25 "
          />
        )}
      </div>
      <div className="w--75 text--ellipsis">{file?.name}</div>
    </div>
  );
};

const GeneralFile = ({ file }) => {
  const fileExtension = file?.name?.split('.')?.pop() || '';
  return (
    <div className="d--flex flex--column ">
      <div className="w--full">{renderIcon(fileExtension)}</div>
      <div className="w--75 text--ellipsis">{file?.name}</div>
    </div>
  );
};

const FilePreview = ({ file = null }) => {
  if (!file) return;

  if (file?.type?.includes('image')) {
    return <ImagePreview file={file} />;
  }
  if (file?.type?.includes('video')) {
    return <VideoPreview file={file} />;
  }
  if (file?.type?.includes('audio')) {
    return <AudioPreview file={file} />;
  }
  return <GeneralFile file={file} />;
};

export default FilePreview;
