import React from 'react';

export default function SubmitButton({
  size = '',
  children,
  btnClasses = '',
  icon = null,
  rounded = false,
  disabled = false,
  type = 'submit',
  ...rest
}) {
  return (
    <button
      {...rest}
      type={type}
      disabled={disabled}
      className={`btn--${size} bg--primary text--black radius--md btn ${btnClasses}`}
    >
      <span className="d--flex">{icon && icon}</span> {children}
    </button>
  );
}
