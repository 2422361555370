import React from 'react';

const NoDocument = () => {
  return (
    <div className="d--flex align-items--center justify-content--center h--full w--full">
      No Document Found
    </div>
  );
};

export default NoDocument;
