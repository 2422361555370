import { apiClient } from '../../api/axios';
import { API_ROUTES } from './constants';

export const setDefaultStorage = (data) => {
  return apiClient({
    method: API_ROUTES.SET_DEFAULT_STORAGE.METHOD,
    url: API_ROUTES.SET_DEFAULT_STORAGE.URL,
    data,
  });
};
export const getDefaultStorage = (data) => {
  return apiClient({
    method: API_ROUTES.GET_DEFAULT_STORAGE.METHOD,
    url: API_ROUTES.GET_DEFAULT_STORAGE.URL,
    data,
  });
};

//Google Storage
export const getGoogleDriveList = (data) => {
  return apiClient({
    method: API_ROUTES.GOOGLE_DRIVE_LISTING.METHOD,
    url: API_ROUTES.GOOGLE_DRIVE_LISTING.URL,
    data,
  });
};
export const getGoogleDriveConfigure = (data) => {
  return apiClient({
    method: API_ROUTES.GOOGLE_DRIVE_CONFIGURE.METHOD,
    url: API_ROUTES.GOOGLE_DRIVE_CONFIGURE.URL,
    data,
  });
};
export const googleDriveGetAccessToken = (data) => {
  return apiClient({
    method: API_ROUTES.GOOGLE_DRIVE_GET_ACCESS_TOKEN.METHOD,
    url: API_ROUTES.GOOGLE_DRIVE_GET_ACCESS_TOKEN.URL,
    data,
  });
};
export const googleDriveSetting = (data) => {
  return apiClient({
    method: API_ROUTES.GOOGLE_DRIVE_SETTING.METHOD,
    url: API_ROUTES.GOOGLE_DRIVE_SETTING.URL,
    data,
  });
};

// DropBox Storage
export const getDropboxConfigure = (data) => {
  return apiClient({
    method: API_ROUTES.DROPBOX_CONFIGURE.METHOD,
    url: API_ROUTES.DROPBOX_CONFIGURE.URL,
    data,
  });
};
export const dropBoxSetting = (data) => {
  return apiClient({
    method: API_ROUTES.DROPBOX_SETTING.METHOD,
    url: API_ROUTES.DROPBOX_SETTING.URL,
    data,
  });
};
export const dropBoxGetAccessToken = (data) => {
  return apiClient({
    method: API_ROUTES.DROPBOX_GET_ACCESS_TOKEN.METHOD,
    url: API_ROUTES.DROPBOX_GET_ACCESS_TOKEN.URL,
    data,
  });
};
export const createDropboxFolder = (data) => {
  return apiClient({
    method: API_ROUTES.DROPBOX_CREATE_FOLDER.METHOD,
    url: API_ROUTES.DROPBOX_CREATE_FOLDER.URL,
    data,
  });
};
export const getDropboxFolders = (data) => {
  return apiClient({
    method: API_ROUTES.DROPBOX_FOLDERS_LIST.METHOD,
    url: API_ROUTES.DROPBOX_FOLDERS_LIST.URL,
    data,
  });
};
export const dropboxDeleteFolder = (data) => {
  return apiClient({
    method: API_ROUTES.DROPBOX_DELETE_FOLDER.METHOD,
    url: API_ROUTES.DROPBOX_DELETE_FOLDER.URL,
    data,
  });
};
export const getDropboxFiles = (data) => {
  return apiClient({
    method: API_ROUTES.DROPBOX_FILES_LIST.METHOD,
    url: API_ROUTES.DROPBOX_FILES_LIST.URL,
    data,
  });
};
export const getDropboxLink = (data) => {
  return apiClient({
    method: API_ROUTES.DROPBOX_GET_LINK.METHOD,
    url: API_ROUTES.DROPBOX_GET_LINK.URL,
    data,
  });
};
export const dropBoxBulkUpload = (data) => {
  return apiClient({
    method: API_ROUTES.DROPBOX_BULK_UPLOAD.METHOD,
    url: API_ROUTES.DROPBOX_BULK_UPLOAD.URL,
    data,
  });
};
