import { useState } from 'react';
import { useAlert } from '../../../hooks/useAlert';
import { useMutation } from '@tanstack/react-query';
import FormInput from '../../../components/FormInput';
import Button from '../../../components/Button';

const CreateFolder = ({ refetch, setShowModal, mutationFn }) => {
  const [createFolder, setCreateFolder] = useState();
  const { showAlert } = useAlert();

  const { isLoading, mutate: createFolderMutate } = useMutation({
    mutationFn,
    onSuccess: ({ data }) => {
      showAlert({
        type: 'success',
        message: data?.data?.message || 'Folder created successfully',
      });
      refetch();
      setShowModal(false);
    },
    onError: (error) => {
      const errMsg = error?.message ?? 'unexpected error';
      showAlert({ type: 'danger', message: errMsg });
    },
  });

  return (
    <div className="d--flex flex--column gap--md">
      <div className="d--flex flex--column ">
        <div className="w--full gap--sm d--flex align-items--center">
          <>
            <FormInput
              label="Folder Name"
              placeholder="Enter your folder name"
              value={createFolder}
              onChange={(e) => setCreateFolder(e.target.value)}
            />
          </>
        </div>
      </div>

      <div className="d--flex justify-content--center w--full gap--sm">
        <Button
          onClick={() => {
            if (createFolder) {
              createFolderMutate({ folderName: createFolder });
            }
          }}
          type="button"
          variant="primary"
          color="black"
          isOutline
          borderColor="primary"
          btnClasses="btn w-max--150"
          disabled={isLoading}
        >
          {isLoading ? 'Please wait...' : 'Create'}
        </Button>
      </div>
    </div>
  );
};

export default CreateFolder;
