import React, { useEffect, useState } from 'react';

const ImagePreview = ({
  mediaPath = '',
  altText = '...',
  className = '',
  width,
  height,
}) => {
  const [src, setSrc] = useState();

  async function getData() {
    const url = mediaPath;

    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`Response status: ${response.status}`);
      }

      const json = await response.json();
      setSrc(json?.data?.url);
    } catch (error) {
      console.error(error.message);
    }
  }

  useEffect(() => {
    if (mediaPath) {
      getData();
    }
  }, [mediaPath]);

  return (
    <>
      {src && (
        <img
          src={src}
          className={className}
          alt={altText}
          width={width}
          height={height}
        />
      )}
    </>
  );
};
export default ImagePreview;
