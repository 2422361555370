import React, { useEffect } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAlert } from '../../../hooks/useAlert';
import { agencyCreateAgent, createAgent, updateAgent } from '../actions';
import { schema } from '../constants';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import FormInput from '../../../components/FormInput';
import Button from '../../../components/Button';
import PhoneInput from 'react-phone-number-input';

const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  country: 'US',
  phone: '',
  // npn: '',
};

const AddAgent = ({ handleClose = () => {}, editAgentInstance = {}, agencyId }) => {
  const { isEdit, editData } = editAgentInstance || {};
  const queryclient = useQueryClient();
  const { showAlert } = useAlert();

  const {
    watch,
    getValues,
    setValue,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: initialValues,
    mode: 'onTouched',
  });

  const { mutate, isLoading } = useMutation({
    mutationFn: agencyId ? agencyCreateAgent : isEdit ? updateAgent : createAgent,
    mutationKey: agencyId ? 'agencyCreateAgent' : isEdit ? 'updateAgent' : 'createAgent',
    onSuccess: ({ data }) => {
      showAlert({ type: 'success', message: data?.data?.message });
      queryclient.invalidateQueries({ queryKey: ['getAgentList'] });
      queryclient.invalidateQueries({ queryKey: ['getAgencyList'] });
      handleClose();
    },
    onError: (error) => {
      const errMsg = error?.message ?? 'unexpected error';
      showAlert({ type: 'danger', message: errMsg });
    },
  });

  useEffect(() => {
    if (isEdit) {
      const { firstName = '', lastName = '', phone = '', email = '', NPN = '' } = editData;
      reset({
        firstName,
        lastName,
        email,
        phone,
        // npn: NPN,
      });
    }
  }, [editData, isEdit, reset]);

  const onSubmit = (values) => {
    let payload = { ...values, uuid: agencyId || undefined };
    if (isEdit) {
      delete payload.email;
      delete payload.extension;
      payload.uuid = editData?._id;
    }
    mutate(payload);
  };

  return (
    <form className="w--full h--full" onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <div className="overflow--auto dialogScroll d--flex flex--column gap--md p--md">
        <div className="d--flex gap--md">
          <div className="w--full">
            <Controller name="firstName" control={control} render={({ field }) => <FormInput {...field} type="text" label="First Name" placeholder="Enter your first name" maxLength={30} error={errors?.firstName?.message} />} />
          </div>
          <div className="w--full">
            <Controller name="lastName" control={control} render={({ field }) => <FormInput {...field} type="text" label="Last Name" placeholder="Enter your last name" maxLength={30} error={errors?.lastName?.message} />} />
          </div>
        </div>
        <div className="d--flex gap--md">
          <div className="w--full">
            <Controller name="email" control={control} render={({ field }) => <FormInput {...field} type="text" label="Email" placeholder="Enter your email address" error={errors?.email?.message} disabled={editAgentInstance?.isEdit} extraClasses={editAgentInstance?.isEdit ? 'c--not-allowed' : ''} />} />
          </div>

          <div className="w--full">
            <label className={`label--control font--sm font--500 m-b--sm d--inline-block ${errors?.phone?.message ? 'text--danger' : 'text--grey'}`}>{errors?.phone?.message ?? 'Phone'}</label>
            <Controller
              name="phone"
              control={control}
              render={({ field }) => (
                <PhoneInput
                  {...field}
                  control={control}
                  defaultCountry={watch('country')}
                  international={true}
                  addInternationalOption={false}
                  withCountryCallingCode={true}
                  countryCallingCodeEditable={false}
                  placeholder="Enter your phone"
                  internationalIcon={() => null}
                  flagComponent={() => null}
                  countrySelectComponent={() => null}
                  value={getValues(`phone`)}
                  limitMaxLength={true}
                  onChange={(phone) =>
                    setValue(`phone`, phone, {
                      shouldValidate: true,
                    })
                  }
                  className={`form--control w--full h-min--36  radius--sm p-l--md p-r--md  ${errors?.phone?.message ? 'border-full--danger' : 'border-full--black-200'}`}
                />
              )}
            />
          </div>
        </div>
        {/* <div>
          <Controller
            name="npn"
            control={control}
            render={({ field }) => {
              const { onChange } = field;
              return (
                <FormInput
                  {...field}
                  onChange={(e) => {
                    if (`${e.target.value}`?.length <= 12) {
                      onChange(e.target.value);
                    } else {
                      return;
                    }
                  }}
                  type="number"
                  label="NPN"
                  placeholder="Enter npn"
                  maxLength="12"
                  error={errors?.npn?.message}
                  onKeyDown={(e) => {
                    if (['e', '+', '-'].includes(e.key)) e.preventDefault();
                  }}
                />
              );
            }}
          />
        </div> */}
      </div>
      <div className="d--flex justify-content--between gap--md p-l--md p-r--md p-t--md border-top--primary-100">
        <Button type="button" variant="contrast" color="primary" btnClasses="btn" onClick={handleClose}>
          Cancel
        </Button>
        <Button btnClasses="btn" type="submit" variant="primary" disabled={isLoading}>
          {isLoading ? 'Please wait..' : 'Save'}
        </Button>
      </div>
    </form>
  );
};

export default AddAgent;
