import React, { useState } from 'react';
import FormSelect from '../../../components/FormSelect';
import { useForm, Controller } from 'react-hook-form';
import Button from '../../../components/Button';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useAlert } from '../../../hooks/useAlert';
import { yupResolver } from '@hookform/resolvers/yup';
import { object } from 'yup';
import {
  getGoogleDriveBulkUpload,
  getGoogleDriveFoldersList,
  googleDriveBulkUpload,
  googleDriveCreateFolder,
} from '../../UploadDrive/actions';
import FormInput from '../../../components/FormInput';
import useIcons from '../../../assets/icons/useIcons';
import { requiredFieldFunction } from '../../../helpers/yupValidations';
import {
  dropBoxBulkUpload,
  getDefaultStorage,
  getDropboxFolders,
} from '../../Storage/actions';

export const schema = object().shape({
  folderId: requiredFieldFunction('Select Folder '),
});

const UploadToDrive = ({ handleClose }) => {
  const [activaTab, setActiveTab] = useState('GOOGLE_DRIVE');

  const { CrossIcon, CheckIcon } = useIcons();

  const queryclient = useQueryClient();
  const { showAlert } = useAlert();

  const [createFolder, setCreateFolder] = useState({ show: false, value: '' });

  const [selectedValues, setSelectedValues] = useState(['CMS']);

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      folderId: '',
      templateNames: [],
    },
    resolver: yupResolver(schema),
    mode: 'onSubmit',
  });

  const { data: defaultStorageData } = useQuery({
    queryFn: getDefaultStorage,
    queryKey: ['getDefaultStorage'],
    select: (data) => data?.data?.data,
  });
  const { defaultType } = defaultStorageData || {};

  const storageLookup = {
    GOOGLE_DRIVE: {
      mutationFn: googleDriveBulkUpload,
      foldersListApi: getGoogleDriveFoldersList,
    },
    DROPBOX: {
      mutationFn: dropBoxBulkUpload,
      foldersListApi: getDropboxFolders,
    },
  };

  const temps = watch('templateNames');
  const handleCheckChange = (e) => {
    const { value, checked } = e.target;
    setSelectedValues((prev) =>
      checked ? [...prev, value] : prev.filter((val) => val !== value),
    );

    setValue(
      'templateNames',
      checked ? [...temps, value] : temps.filter((val) => val !== value),
    );
  };
  const { data: foldersListData, refetch } = useQuery({
    queryKey: [`${storageLookup?.[defaultType]?.foldersListApi}`, defaultType],
    queryFn: storageLookup?.[defaultType]?.foldersListApi,
    select: (data) => data?.data?.data,
    enabled: Boolean(defaultType),
  });

  // const { data: dropboxFolders } = useQuery({
  //   queryKey: 'getDropboxFolders',
  //   queryFn: getDropboxFolders,
  //   select: (data) => data?.data?.data,
  // });

  const { isLoading, mutate: createFolderMutate } = useMutation({
    mutationKey: `googleDriveCreateFolder`,
    mutationFn: googleDriveCreateFolder,
    onSuccess: ({ data }) => {
      showAlert({
        type: 'success',
        message: data?.data?.message || 'Folder created successfully',
      });
      refetch();
      setCreateFolder({ show: false, value: '' });
    },
    onError: (error) => {
      const errMsg = error?.message ?? 'unexpected error';
      showAlert({ type: 'danger', message: errMsg });
    },
  });

  const { mutate, isLoading: uploadLoading } = useMutation({
    // mutationFn:
    //   activaTab === 'DROPBOX' ? dropBoxBulkUpload : googleDriveBulkUpload,

    mutationFn: storageLookup?.[defaultType]?.mutationFn,
    onSuccess: ({ data }) => {
      showAlert({ type: 'success', message: data?.data?.message });
      queryclient.invalidateQueries({
        queryKey: ['getMemberList'],
        exact: false,
      });
      handleClose();
    },
    onError: (error) => {
      const errMsg = error?.message ?? 'unexpected error';
      showAlert({ type: 'danger', message: errMsg });
    },
  });

  const onSubmit = (_) => {
    if (_?.folderId && selectedValues?.length > 0) {
      mutate({ folderId: _?.folderId, templateNames: selectedValues });
    }
  };

  return (
    <>
      {/* <div className="d--flex gap--lg profilePageTabe  border-bottom--black-100  align-items--center">
        <span
          className={`p-l--md p-r--md p-b--md c--pointer font--sm font--600    ${
            activaTab === 'GOOGLE_DRIVE'
              ? 'border-bottom--black text--black'
              : 'text--black-600'
          }`}
          onClick={() => {
            setActiveTab('GOOGLE_DRIVE');
          }}
        >
          Drive
        </span>
        <span
          className={`p-l--md p-r--md p-b--md c--pointer font--sm font--600  ${
            activaTab === 'DROPBOX'
              ? 'border-bottom--black text--black'
              : 'text--black-600'
          }`}
          onClick={() => {
            setActiveTab('DROPBOX');
          }}
        >
          Dropbox
        </span>
      </div> */}

      <form
        className="w--full d--flex flex--column gap--xl bg--contrast p--md radius--sm "
        onSubmit={handleSubmit(onSubmit)}
        onClick={(e) => {
          e.stopPropagation();
        }}
        autoComplete="off"
      >
        <div className="d--flex flex--column-xs gap--md-xs">
          <div className=" w--full d--flex  gap--lg flex--column">
            <label className="label--control font--sm font--500  text--black w-min--100 w--full">
              Save My
            </label>
            <div className="d--flex gap--md w--full flex--column-xs">
              <div className="d--flex gap--sm">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="consent"
                  value="CMS"
                  onClick={(e) => handleCheckChange(e)}
                  checked={selectedValues.includes('CMS')}
                />
                <label className="form-check-label" htmlFor="consent">
                  All Consent{' '}
                </label>
              </div>
              <div className="d--flex gap--sm">
                <input
                  value="ELIGIBILITY"
                  type="checkbox"
                  className="form-check-input"
                  id="eligibility"
                  onClick={(e) => handleCheckChange(e)}
                  checked={selectedValues.includes('ELIGIBILITY')}
                />
                <label htmlFor="eligibility" className="form-check-label">
                  All Eligibility
                </label>
              </div>
            </div>
          </div>
          <div className="d--flex gap--lg w--full">
            <div className="d--flex flex--column w--full ">
              <label className="label--control font--sm font--500 m-b--sm   text--grey">
                {createFolder.show ? (
                  'Folder Name'
                ) : errors?.folderId?.message ? (
                  <span className="text--danger">
                    {errors?.folderId?.message}
                  </span>
                ) : (
                  'To My Folder'
                )}
                <span class=" font--sm text--danger m-l--xs">*</span>
              </label>
              <div className="w--full gap--sm d--flex align-items--center">
                {createFolder.show ? (
                  <>
                    <FormInput
                      placeholder="Enter your folder name"
                      value={createFolder.value}
                      onChange={(e) =>
                        setCreateFolder({
                          ...createFolder,
                          value: e.target.value,
                        })
                      }
                    />
                    <div
                      className="w-min--40 h-min--40 w-max--40 h-max--40 d--flex align-items--center justify-content--center bg--success-400 radius--sm c--pointer"
                      onClick={() => {
                        if (!isLoading && createFolder.value) {
                          createFolderMutate({
                            folderName: createFolder.value,
                          });
                        }
                      }}
                    >
                      <CheckIcon width={17} height={17} />
                    </div>
                    <div
                      className="w-min--40 h-min--40 w-max--40 h-max--40 d--flex align-items--center justify-content--center bg--danger-400  radius--sm c--pointer"
                      onClick={() => {
                        setCreateFolder({ show: false, value: '' });
                      }}
                    >
                      <CrossIcon width={14} height={14} />
                    </div>
                  </>
                ) : (
                  <>
                    <Controller
                      name="folderId"
                      control={control}
                      render={({ field }) => (
                        <FormSelect
                          {...field}
                          placeholder="Select Folder"
                          // options={
                          //   activaTab === 'DROPBOX'
                          //     ? dropboxFolders &&
                          //       dropboxFolders?.length > 0 &&
                          //       dropboxFolders?.map(
                          //         ({ folderName, folderId }) => {
                          //           return {
                          //             label: folderName,
                          //             value: folderId,
                          //           };
                          //         },
                          //       )
                          //     : googleDriveFolders &&
                          //       googleDriveFolders?.length > 0 &&
                          //       googleDriveFolders?.map(
                          //         ({ folderName, folderId }) => {
                          //           return {
                          //             label: folderName,
                          //             value: folderId,
                          //           };
                          //         },
                          //       )
                          // }
                          options={
                            foldersListData &&
                            foldersListData?.length > 0 &&
                            foldersListData?.map(({ folderName, folderId }) => {
                              return {
                                label: folderName,
                                value: folderId,
                              };
                            })
                          }
                          height="40"
                        />
                      )}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className=" d--flex align-items--center justify-content--end m-t--lg gap--sm">
          <Button
            btnClasses="btn  w-max--150"
            variant="primary"
            color="black"
            disabled={uploadLoading}
            type="submit"
          >
            {uploadLoading ? 'Please wait...' : 'Submit'}
          </Button>
        </div>
      </form>
    </>
  );
};

export default UploadToDrive;
