import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import Modal from '../../Modal';
import SignModule from '../../SignModule';
import {
  addictionUseArr,
  diagnosedOptions,
  dietOptions,
  exerciseOptions,
  familyDiagnosedOptions,
  healthPrioritiesOptions,
  pastYearOptions,
  psaTestOptions,
  sleepCycleOptions,
  stressLevelOptions,
  timeSpanOptions,
  vaccinationOptions,
  wellnessOptions,
  yesNoQuestionsArr,
} from '../../../pages/HRA/constants';
import Spinner from '../../Spinner';

const initialDocData = {
  consumer_info: {
    year: '',
    state: '',
    city: '',
    address: '',
  },
  personal_demographic_info: {
    name: '',
    date_of_birth: '',
    gender: '',
    marital_status: '',
    occupation: '',
    primary_care_provider: '',
    carrierId: '',
    carrierName: '',
    relation: '',
  },
  diagnosed_with_conditions: [],
  diagnosed_with_conditions_otherValue: '',
  prescription_medications: '',
  allergies: '',
  surgery_in_past_5_years: '',
  chronic_pain: '',
  family_diagnosed_with_conditions: [],
  family_diagnosed_with_conditions_other: '',
  substance_use: {
    tobacco: false,
    alcohol: false,
    recreational_drugs: false,
  },
  sleep_hours: '',
  exercise_schedule: '',
  typeofEx: '',
  typeofExValue: '',
  stress_level: '',
  current_diet_rating: '',
  last_physical_exam: '',
  vaccinations_received_in_past: [],
  vaccinations_received_in_past_other: '',
  last_mammogram: '',
  last_pap_smear: '',
  prostate_exam_psa: '',
  had_colonoscopy: '',
  past_two_week: {
    feeling_down_depressed: '',
    little_please_in_things: '',
  },
  diagnosed_with_mental_health_condition: '',
  seeing_mental_health_professional: '',
  top_health_priorities: [],
  interests_in_wellness_programs: [],
  interests_in_wellness_programs_other: '',
  health_info_usage_permission: '',
  care_management_consent: '',
  DATE: '',
  SIGNATURE: '',
};

const HRA_FORM_HTML = forwardRef(
  ({ readMode = false, documentData = null, preFillData = null }, ref) => {
    const [signatureModal, setSignatureModal] = useState(false);
    const [documentState, setDocumentState] = useState(initialDocData);
    const { getHraFormData, getHraFormInfoData } = documentData || {};
    const { consumerRelationship } = getHraFormData || {};
    const { consumerInfo } = getHraFormInfoData || {};
    const [showView, setShowView] = useState(false);

    useEffect(() => {
      if (preFillData) {
        Object.entries(preFillData).map(([key, value]) => {
          setDocumentState((prev) => ({
            ...prev,
            [key]: value,
          }));
        });
        setShowView(true);
      }
    }, [preFillData]);

    useEffect(() => {
      if (documentData) {
        const newData = {
          ...documentState,
          ...consumerRelationship?.data,
          consumer_info: consumerInfo,
          DATE: new Date(),
        };
        setDocumentState(newData);
        setTimeout(() => {
          setShowView(true);
        }, 2000);
      }
    }, [documentData]);

    useImperativeHandle(
      ref,
      () => ({
        getFormValues() {
          return documentState;
        },
        getFormTemplate() {
          return document.getElementById('custom-signature-form-conxent')
            ?.outerHTML;
        },
      }),
      [documentState, documentData],
    );

    function handleChange(e, signatureValue = null) {
      if (signatureValue) {
        setDocumentState((prevState) => ({
          ...prevState,
          SIGNATURE: signatureValue,
        }));
        setSignatureModal(false);
      } else {
        const name = e.target.name;
        const value = e.target.value;
        setDocumentState((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      }
    }

    return (
      <>
        {!showView && (
          <div className="d--flex align-items--center justify-content--center h--full w--full">
            <Spinner size="lg" />
          </div>
        )}
        {showView && (
          <>
            <div
              id="custom-signature-form-conxent"
              style={{
                backgroundColor: ' #fff',
                width: '100%',
                margin: '10px auto',
                padding: '10px 20px 20px',
                maxWidth: '794px',
              }}
              className="pdfForm"
            >
              <table
                cellSpacing="0"
                style={{
                  width: '100%',
                  padding: '15px 0',
                  borderCollapse: 'collapse',
                  borderSpacing: '0',
                }}
              >
                <tr>
                  <td colSpan="2">
                    <h2
                      style={{
                        marginTop: '0',
                        textAlign: 'center',
                        fontSize: '16px',
                        marginBottom: '10px',
                        lineHeight: '1.5',
                        borderBottom: '1px solid #ebebeb',
                        paddingBottom: '10px',
                        cursor: 'pointer',
                      }}
                    >
                      HRA Form
                    </h2>
                  </td>
                </tr>

                <tr>
                  <td colSpan="3">
                    <div
                      style={{
                        borderRadius: '6px',
                        border: '1px solid #ebebeb',
                        padding: '20px',
                        width: '100%',
                        display: 'inline-block',
                      }}
                    >
                      <div
                        style={{
                          width: '12%',
                          display: 'inline-block',
                          float: 'left',
                          margin: '0px 5px',
                        }}
                      >
                        <p
                          style={{
                            color: '#000',
                            marginBottom: '5px',
                          }}
                        >
                          Year
                        </p>
                        <input
                          style={{
                            borderRadius: '6px',
                            border: '1px solid #ebebeb',
                            padding: '10px',
                            width: '100%',
                          }}
                          value={documentState?.consumer_info?.year}
                        />
                      </div>

                      <div
                        style={{
                          width: '18%',
                          display: 'inline-block',
                          float: 'left',
                          margin: '0px 5px',
                        }}
                      >
                        <p
                          style={{
                            color: '#000',
                            marginBottom: '5px',
                          }}
                        >
                          Zip Code
                        </p>
                        <input
                          style={{
                            borderRadius: '6px',
                            border: '1px solid #ebebeb',
                            padding: '10px',
                            width: '100%',
                          }}
                          value={documentState?.consumer_info?.zipCode}
                        />
                      </div>

                      <div
                        style={{
                          width: '26%',
                          display: 'inline-block',
                          float: 'left',
                          margin: '0px 5px',
                        }}
                      >
                        <p
                          style={{
                            color: '#000',
                            marginBottom: '5px',
                          }}
                        >
                          Address{' '}
                        </p>
                        <input
                          style={{
                            borderRadius: '6px',
                            border: '1px solid #ebebeb',
                            padding: '10px',
                            width: '100%',
                          }}
                          value={documentState?.consumer_info?.address}
                        />
                      </div>

                      <div
                        style={{
                          width: '18%',
                          display: 'inline-block',
                          float: 'left',
                          margin: '0px 5px',
                        }}
                      >
                        <p
                          style={{
                            color: '#000',
                            marginBottom: '5px',
                          }}
                        >
                          City
                        </p>
                        <input
                          style={{
                            borderRadius: '6px',
                            border: '1px solid #ebebeb',
                            padding: '10px',
                            width: '100%',
                          }}
                          value={documentState?.consumer_info?.city}
                        />
                      </div>
                      <div
                        style={{
                          width: '18%',
                          display: 'inline-block',
                          float: 'left',
                          margin: '0px 5px',
                        }}
                      >
                        <p
                          style={{
                            color: '#000',
                            marginBottom: '5px',
                          }}
                        >
                          State
                        </p>
                        <input
                          style={{
                            borderRadius: '6px',
                            border: '1px solid #ebebeb',
                            padding: '10px',
                            width: '100%',
                          }}
                          value={documentState?.consumer_info?.state}
                        />
                      </div>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td colSpan="2">
                    <h2
                      style={{
                        marginTop: '10px',
                        textAlign: 'left',
                        fontSize: '16px',
                        marginBottom: '10px',
                        lineHeight: '1.5',
                        borderBottom: '1px solid #ebebeb',
                        paddingBottom: '10px',
                        cursor: 'pointer',
                      }}
                    >
                      {documentState?.personal_demographic_info?.relation}
                    </h2>
                  </td>
                </tr>

                <tr>
                  <td colSpan="2">
                    <div
                      style={{
                        borderRadius: '6px',
                        border: '1px solid #ebebeb',
                        padding: '20px',
                        width: '100%',
                        display: 'inline-block',
                        marginTop: '15px',
                      }}
                    >
                      <h2
                        style={{
                          marginTop: '0',
                          textAlign: 'left',
                          fontSize: '16px',
                          marginBottom: '10px',
                          lineHeight: '1.5',
                          borderBottom: '1px solid #ebebeb',
                          paddingBottom: '10px',
                        }}
                      >
                        Personal and Demographic Information
                      </h2>

                      <div
                        style={{
                          width: '23.5%',
                          display: 'inline-block',
                          float: 'left',
                          margin: '5px 5px',
                        }}
                      >
                        <p
                          style={{
                            color: '#000',
                            marginBottom: '5px',
                          }}
                        >
                          Name{' '}
                        </p>
                        <input
                          style={{
                            borderRadius: '6px',
                            border: '1px solid #ebebeb',
                            padding: '10px',
                            width: '100%',
                          }}
                          value={documentState?.personal_demographic_info?.name}
                        />
                      </div>

                      <div
                        style={{
                          width: '16.5%',
                          display: 'inline-block',
                          float: 'left',
                          margin: '5px 5px',
                        }}
                      >
                        <p
                          style={{
                            color: '#000',
                            marginBottom: '5px',
                          }}
                        >
                          Date of birth{' '}
                        </p>
                        <input
                          style={{
                            borderRadius: '6px',
                            border: '1px solid #ebebeb',
                            padding: '10px',
                            width: '100%',
                          }}
                          value={
                            documentState?.personal_demographic_info
                              ?.date_of_birth
                          }
                        />
                      </div>

                      <div
                        style={{
                          width: '12%',
                          display: 'inline-block',
                          float: 'left',
                          margin: '5px 5px',
                        }}
                      >
                        <p
                          style={{
                            color: '#000',
                            marginBottom: '5px',
                          }}
                        >
                          Gender
                        </p>
                        <input
                          style={{
                            borderRadius: '6px',
                            border: '1px solid #ebebeb',
                            padding: '10px',
                            width: '100%',
                          }}
                          value={
                            documentState?.personal_demographic_info?.gender
                          }
                        />
                      </div>

                      <div
                        style={{
                          width: '17%',
                          display: 'inline-block',
                          float: 'left',
                          margin: '5px 5px',
                        }}
                      >
                        <p
                          style={{
                            color: '#000',
                            marginBottom: '5px',
                          }}
                        >
                          Relation
                        </p>
                        <input
                          style={{
                            borderRadius: '6px',
                            border: '1px solid #ebebeb',
                            padding: '10px',
                            width: '100%',
                          }}
                          value={
                            documentState?.personal_demographic_info?.relation
                          }
                        />
                      </div>

                      <div
                        style={{
                          width: '23.5%',
                          display: 'inline-block',
                          float: 'left',
                          margin: '5px 5px',
                        }}
                      >
                        <p
                          style={{
                            color: '#000',
                            marginBottom: '5px',
                          }}
                        >
                          Marital Status
                        </p>
                        <input
                          style={{
                            borderRadius: '6px',
                            border: '1px solid #ebebeb',
                            padding: '10px',
                            width: '100%',
                          }}
                          value={
                            documentState?.personal_demographic_info
                              ?.marital_status
                          }
                        />
                      </div>

                      <div
                        style={{
                          width: '23.5%',
                          display: 'inline-block',
                          float: 'left',
                          margin: '5px 5px',
                        }}
                      >
                        <p
                          style={{
                            color: '#000',
                            marginBottom: '5px',
                          }}
                        >
                          Email
                        </p>
                        <input
                          style={{
                            borderRadius: '6px',
                            border: '1px solid #ebebeb',
                            padding: '10px',
                            width: '100%',
                          }}
                          value={
                            documentState?.personal_demographic_info?.email
                          }
                        />
                      </div>
                      <div
                        style={{
                          width: '23.5%',
                          display: 'inline-block',
                          float: 'left',
                          margin: '5px 5px',
                        }}
                      >
                        <p
                          style={{
                            color: '#000',
                            marginBottom: '5px',
                          }}
                        >
                          Phone
                        </p>
                        <input
                          style={{
                            borderRadius: '6px',
                            border: '1px solid #ebebeb',
                            padding: '10px',
                            width: '100%',
                          }}
                          value={
                            documentState?.personal_demographic_info?.phone
                          }
                        />
                      </div>
                      <div
                        style={{
                          width: '23.5%',
                          display: 'inline-block',
                          float: 'left',
                          margin: '5px 5px',
                        }}
                      >
                        <p
                          style={{
                            color: '#000',
                            marginBottom: '5px',
                          }}
                        >
                          Occupation
                        </p>
                        <input
                          style={{
                            borderRadius: '6px',
                            border: '1px solid #ebebeb',
                            padding: '10px',
                            width: '100%',
                          }}
                          value={
                            documentState?.personal_demographic_info?.occupation
                          }
                        />
                      </div>

                      <div
                        style={{
                          width: '23.5%',
                          display: 'inline-block',
                          float: 'left',
                          margin: '5px 5px',
                        }}
                      >
                        <p
                          style={{
                            color: '#000',
                            marginBottom: '5px',
                          }}
                        >
                          Carrier
                        </p>
                        <input
                          style={{
                            borderRadius: '6px',
                            border: '1px solid #ebebeb',
                            padding: '10px',
                            width: '100%',
                          }}
                          value={
                            documentState?.personal_demographic_info
                              ?.carrierName
                          }
                        />
                      </div>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td colSpan="2">
                    <div
                      style={{
                        borderRadius: '6px',
                        border: '1px solid #ebebeb',
                        padding: '20px',
                        width: '100%',
                        display: 'inline-block',
                        marginTop: '15px',
                      }}
                    >
                      <h2
                        style={{
                          marginTop: '0',
                          textAlign: 'left',
                          fontSize: '16px',
                          marginBottom: '10px',
                          lineHeight: '1.5',
                        }}
                      >
                        Have you ever been diagnosed with any of the following
                        conditions?
                      </h2>
                      <p
                        style={{
                          marginTop: '0',
                          textAlign: 'left',
                          fontSize: '12px',
                          marginBottom: '10px',
                          lineHeight: '1.5',
                          borderBottom: '1px solid #ebebeb',
                          paddingBottom: '10px',
                        }}
                      >
                        (Select all that apply)
                      </p>
                      {documentState?.diagnosed_with_conditions &&
                        diagnosedOptions.map(({ label, value }) => {
                          const isChecked =
                            documentState?.diagnosed_with_conditions?.includes(
                              value,
                            );
                          return (
                            <div
                              style={{
                                maxWidth: '23.5%',
                                width: '100%',
                                display: 'inline-flex',
                                alignItems: 'start',
                                gap: '5px',
                                margin: '5px 5px',
                              }}
                              key={label}
                            >
                              <input
                                checked={isChecked}
                                id={value}
                                style={{
                                  marginTop: '2px',
                                }}
                                name="diagnosed_with_conditions"
                                type="checkbox"
                              />
                              <label htmlFor={value}>{label}</label>
                            </div>
                          );
                        })}
                      {documentState?.diagnosed_with_conditions?.includes(
                        'others',
                      ) && (
                        <div
                          style={{
                            maxWidth: '23.5%',
                            width: '100%',
                            display: 'inline-flex',
                            alignItems: 'start',
                            gap: '5px',
                            margin: '5px 5px',
                          }}
                        >
                          <input
                            style={{
                              borderRadius: '6px',
                              border: '1px solid #ebebeb',
                              padding: '10px',
                              width: '100%',
                            }}
                            value={
                              documentState?.diagnosed_with_conditions_otherValue
                            }
                          />
                        </div>
                      )}
                    </div>
                  </td>
                </tr>

                {yesNoQuestionsArr.map(({ label, value }) => {
                  return (
                    <tr>
                      <td
                        style={{
                          padding: '3px 10px',
                        }}
                      >
                        <h3
                          style={{
                            marginTop: '10px',
                            textAlign: 'left',
                            fontSize: '14px',
                            lineHeight: '1.5',
                          }}
                        >
                          {label}
                        </h3>
                      </td>
                      <td
                        style={{
                          padding: '3px 10px',
                        }}
                      >
                        <div
                          style={{
                            display: 'inline-flex',
                            alignItems: 'center',
                            gap: '5px',
                            float: 'left',
                          }}
                        >
                          <div
                            style={{
                              display: 'inline-flex',
                              alignItems: 'start',
                              gap: '5px',
                              float: 'left',
                              margin: '5px 5px',
                            }}
                          >
                            <input
                              style={{
                                marginTop: '1px',
                              }}
                              type="radio"
                              checked={documentState?.[value]}
                            />
                            <label> Yes</label>
                          </div>

                          <div
                            style={{
                              display: 'inline-flex',
                              alignItems: 'start',
                              gap: '5px',
                              float: 'left',
                              margin: '5px 5px',
                            }}
                          >
                            <input
                              style={{
                                marginTop: '1px',
                              }}
                              type="radio"
                              checked={!documentState?.[value]}
                            />
                            <label> No</label>
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}

                <tr>
                  <td colSpan="2">
                    <div
                      style={{
                        borderRadius: '6px',
                        border: '1px solid #ebebeb',
                        padding: '20px',
                        width: '100%',
                        display: 'inline-block',
                        marginTop: '15px',
                      }}
                    >
                      <h2
                        style={{
                          marginTop: '0',
                          textAlign: 'left',
                          fontSize: '16px',
                          marginBottom: '10px',
                          lineHeight: '1.5',
                          borderBottom: '1px solid #ebebeb',
                          paddingBottom: '10px',
                        }}
                      >
                        Has anyone in your immediate family (parents, siblings)
                        been diagnosed with any of the following conditions?
                      </h2>

                      {familyDiagnosedOptions.map(({ label, value }) => {
                        return (
                          <div
                            style={{
                              maxWidth: '23.5%',
                              width: '100%',
                              display: 'inline-flex',
                              alignItems: 'start',
                              gap: '5px',
                              margin: '5px 5px',
                            }}
                            key={value}
                          >
                            <input
                              style={{
                                marginTop: '2px',
                              }}
                              type="checkbox"
                              id={value}
                              name="test"
                              checked={documentState?.family_diagnosed_with_conditions?.includes(
                                value,
                              )}
                            />
                            <label> {label}</label>
                          </div>
                        );
                      })}
                      {documentState?.family_diagnosed_with_conditions?.includes(
                        'others',
                      ) && (
                        <div
                          style={{
                            maxWidth: '23.5%',
                            width: '100%',
                            display: 'inline-flex',
                            alignItems: 'start',
                            gap: '5px',
                            margin: '5px 5px',
                          }}
                        >
                          <input
                            style={{
                              borderRadius: '6px',
                              border: '1px solid #ebebeb',
                              padding: '10px',
                              width: '100%',
                            }}
                            value={
                              documentState?.family_diagnosed_with_conditions_other
                            }
                          />
                        </div>
                      )}
                    </div>
                  </td>
                </tr>

                <tr>
                  <td colSpan="2">
                    <div
                      style={{
                        borderRadius: '6px',
                        border: '1px solid #ebebeb',
                        padding: '20px',
                        width: '100%',
                        display: 'inline-block',
                        marginTop: '15px',
                      }}
                    >
                      <h2
                        style={{
                          marginTop: '0',
                          textAlign: 'left',
                          fontSize: '16px',
                          marginBottom: '10px',
                          lineHeight: '1.5',
                          borderBottom: '1px solid #ebebeb',
                          paddingBottom: '10px',
                        }}
                      >
                        Do you currently use any of the following?
                      </h2>

                      {addictionUseArr.map(({ label, value }) => {
                        return (
                          <div
                            style={{
                              width: '47.5%',
                              display: 'inline-flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                              gap: '5px',
                              float: 'left',
                              margin: '5px 5px',
                            }}
                          >
                            <h3
                              style={{
                                marginTop: '0',
                                textAlign: 'left',
                                fontSize: '14px',
                                marginBottom: '0px',
                                lineHeight: '1.5',
                              }}
                            >
                              {label}
                            </h3>
                            <div
                              style={{
                                display: 'inline-flex',
                                alignItems: 'start',
                                gap: '5px',
                                float: 'right',
                              }}
                            >
                              <div
                                style={{
                                  display: 'inline-flex',
                                  alignItems: 'start',
                                  gap: '5px',
                                  float: 'left',
                                  margin: '5px 5px',
                                }}
                              >
                                <input
                                  style={{
                                    marginTop: '1px',
                                  }}
                                  type="radio"
                                  checked={
                                    documentState?.substance_use?.[value]
                                  }
                                />
                                <label> Yes</label>
                              </div>

                              <div
                                style={{
                                  display: 'inline-flex',
                                  alignItems: 'start',
                                  gap: '5px',
                                  float: 'left',
                                  margin: '5px 5px',
                                }}
                              >
                                <input
                                  style={{
                                    marginTop: '1px',
                                  }}
                                  type="radio"
                                  checked={
                                    !documentState?.substance_use?.[value]
                                  }
                                />
                                <label> No</label>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </td>
                </tr>

                <tr>
                  <td colSpan="2">
                    <div
                      style={{
                        borderRadius: '6px',
                        border: '1px solid #ebebeb',
                        padding: '20px',
                        width: '100%',
                        display: 'inline-block',
                        marginTop: '15px',
                      }}
                    >
                      <h2
                        style={{
                          marginTop: '0',
                          textAlign: 'left',
                          fontSize: '16px',
                          marginBottom: '10px',
                          lineHeight: '1.5',
                          borderBottom: '1px solid #ebebeb',
                          paddingBottom: '10px',
                        }}
                      >
                        On average, how many hours of sleep do you get per
                        night?
                      </h2>

                      {sleepCycleOptions.map(({ label, value }) => {
                        return (
                          <div
                            style={{
                              width: '23.5%',
                              display: 'inline-flex',
                              alignItems: 'start',
                              gap: '5px',
                              float: 'left',
                              margin: '5px 5px',
                            }}
                          >
                            <input
                              style={{
                                marginTop: '2px',
                              }}
                              type="radio"
                              checked={value === documentState?.sleep_hours}
                            />
                            <label>{label}</label>
                          </div>
                        );
                      })}
                    </div>
                  </td>
                </tr>

                <tr>
                  <td colSpan="2">
                    <div
                      style={{
                        borderRadius: '6px',
                        border: '1px solid #ebebeb',
                        padding: '20px',
                        width: '100%',
                        display: 'inline-block',
                        marginTop: '15px',
                      }}
                    >
                      <h2
                        style={{
                          marginTop: '0',
                          textAlign: 'left',
                          fontSize: '16px',
                          marginBottom: '10px',
                          lineHeight: '1.5',
                          borderBottom: '1px solid #ebebeb',
                          paddingBottom: '10px',
                        }}
                      >
                        How often do you exercise?
                      </h2>

                      {exerciseOptions.map(({ label, value }) => {
                        return (
                          <div
                            style={{
                              width: '23.5%',
                              display: 'inline-flex',
                              alignItems: 'start',
                              gap: '5px',
                              float: 'left',
                              margin: '5px 5px',
                            }}
                          >
                            <input
                              style={{
                                marginTop: '2px',
                              }}
                              type="radio"
                              checked={
                                value === documentState?.exercise_schedule
                              }
                            />
                            <label>{label}</label>
                          </div>
                        );
                      })}
                      <div
                        style={{
                          maxWidth: '23.5%',
                          width: '100%',
                          display: 'inline-flex',
                          alignItems: 'start',
                          gap: '5px',
                          margin: '5px 5px',
                        }}
                      >
                        <input
                          style={{
                            marginTop: '2px',
                          }}
                          type="checkbox"
                          name="test"
                          checked={documentState?.typeofEx}
                        />
                        <label> Type of exercise</label>
                      </div>
                      {documentState?.typeofEx && (
                        <div
                          style={{
                            maxWidth: '23.5%',
                            width: '100%',
                            display: 'inline-flex',
                            alignItems: 'start',
                            gap: '5px',
                            margin: '5px 5px',
                          }}
                        >
                          <input
                            style={{
                              borderRadius: '6px',
                              border: '1px solid #ebebeb',
                              padding: '10px',
                              width: '100%',
                            }}
                            value={documentState?.typeofExValue}
                          />
                        </div>
                      )}
                    </div>
                  </td>
                </tr>

                <tr>
                  <td colSpan="2">
                    <div
                      style={{
                        borderRadius: '6px',
                        border: '1px solid #ebebeb',
                        padding: '20px',
                        width: '100%',
                        display: 'inline-block',
                        marginTop: '15px',
                      }}
                    >
                      <h2
                        style={{
                          marginTop: '0',
                          textAlign: 'left',
                          fontSize: '16px',
                          marginBottom: '10px',
                          lineHeight: '1.5',
                          borderBottom: '1px solid #ebebeb',
                          paddingBottom: '10px',
                        }}
                      >
                        On average, how would you rate your daily stress levels?
                      </h2>

                      {stressLevelOptions.map(({ label, value }) => {
                        return (
                          <div
                            style={{
                              width: '23.5%',
                              display: 'inline-flex',
                              alignItems: 'start',
                              gap: '5px',
                              float: 'left',
                              margin: '5px 5px',
                            }}
                          >
                            <input
                              style={{
                                marginTop: '2px',
                              }}
                              type="radio"
                              checked={value === documentState?.stress_level}
                            />
                            <label>{label}</label>
                          </div>
                        );
                      })}
                    </div>
                  </td>
                </tr>

                <tr>
                  <td colSpan="2">
                    <div
                      style={{
                        borderRadius: '6px',
                        border: '1px solid #ebebeb',
                        padding: '20px',
                        width: '100%',
                        display: 'inline-block',
                        marginTop: '15px',
                      }}
                    >
                      <h2
                        style={{
                          marginTop: '0',
                          textAlign: 'left',
                          fontSize: '16px',
                          marginBottom: '10px',
                          lineHeight: '1.5',
                          borderBottom: '1px solid #ebebeb',
                          paddingBottom: '10px',
                        }}
                      >
                        How would you rate your current diet?
                      </h2>

                      {dietOptions.map(({ label, value }) => {
                        return (
                          <div
                            style={{
                              width: '23.5%',
                              display: 'inline-flex',
                              alignItems: 'start',
                              gap: '5px',
                              float: 'left',
                              margin: '5px 5px',
                            }}
                          >
                            <input
                              style={{
                                marginTop: '2px',
                              }}
                              type="radio"
                              checked={
                                value === documentState?.current_diet_rating
                              }
                            />
                            <label>{label}</label>
                          </div>
                        );
                      })}
                    </div>
                  </td>
                </tr>

                <tr>
                  <td colSpan="2">
                    <div
                      style={{
                        borderRadius: '6px',
                        border: '1px solid #ebebeb',
                        padding: '20px',
                        width: '100%',
                        display: 'inline-block',
                        marginTop: '15px',
                      }}
                    >
                      <h2
                        style={{
                          marginTop: '0',
                          textAlign: 'left',
                          fontSize: '16px',
                          marginBottom: '10px',
                          lineHeight: '1.5',
                          borderBottom: '1px solid #ebebeb',
                          paddingBottom: '10px',
                        }}
                      >
                        When was your last physical exam?
                      </h2>

                      {pastYearOptions.map(({ label, value }) => {
                        return (
                          <div
                            style={{
                              width: '23.5%',
                              display: 'inline-flex',
                              alignItems: 'start',
                              gap: '5px',
                              float: 'left',
                              margin: '5px 5px',
                            }}
                          >
                            <input
                              style={{
                                marginTop: '2px',
                              }}
                              type="radio"
                              checked={
                                value === documentState?.last_physical_exam
                              }
                            />
                            <label>{label}</label>
                          </div>
                        );
                      })}
                    </div>
                  </td>
                </tr>

                <tr>
                  <td colSpan="2">
                    <div
                      style={{
                        borderRadius: '6px',
                        border: '1px solid #ebebeb',
                        padding: '20px',
                        width: '100%',
                        display: 'inline-block',
                        marginTop: '15px',
                      }}
                    >
                      <h2
                        style={{
                          marginTop: '0',
                          textAlign: 'left',
                          fontSize: '16px',
                          marginBottom: '10px',
                          lineHeight: '1.5',
                          borderBottom: '1px solid #ebebeb',
                          paddingBottom: '10px',
                        }}
                      >
                        Have you received any of the following vaccinations in
                        the past?
                      </h2>

                      {vaccinationOptions.map(({ label, value }) => {
                        return (
                          <div
                            style={{
                              maxWidth: '23.5%',
                              width: '100%',
                              display: 'inline-flex',
                              alignItems: 'start',
                              gap: '5px',
                              margin: '5px 5px',
                            }}
                            key={value}
                          >
                            <input
                              style={{
                                marginTop: '2px',
                              }}
                              type="checkbox"
                              checked={documentState?.vaccinations_received_in_past?.includes(
                                value,
                              )}
                            />
                            <label> {label}</label>
                          </div>
                        );
                      })}
                      {documentState?.vaccinations_received_in_past?.includes(
                        'others',
                      ) && (
                        <div
                          style={{
                            maxWidth: '23.5%',
                            width: '100%',
                            display: 'inline-flex',
                            alignItems: 'start',
                            gap: '5px',
                            margin: '5px 5px',
                          }}
                        >
                          <input
                            style={{
                              borderRadius: '6px',
                              border: '1px solid #ebebeb',
                              padding: '10px',
                              width: '100%',
                            }}
                            value={
                              documentState?.vaccinations_received_in_past_other
                            }
                          />
                        </div>
                      )}
                    </div>
                  </td>
                </tr>

                <tr>
                  <td colSpan="2">
                    <div
                      style={{
                        borderRadius: '6px',
                        border: '1px solid #ebebeb',
                        padding: '20px',
                        width: '100%',
                        display: 'inline-block',
                        marginTop: '15px',
                      }}
                    >
                      <h2
                        style={{
                          marginTop: '0',
                          textAlign: 'left',
                          fontSize: '16px',
                          marginBottom: '10px',
                          lineHeight: '1.5',
                          borderBottom: '1px solid #ebebeb',
                          paddingBottom: '10px',
                        }}
                      >
                        For women only:
                      </h2>
                      <h4
                        style={{
                          marginTop: '10',
                          textAlign: 'left',
                          fontSize: '14px',
                          marginBottom: '10px',
                          lineHeight: '1.5',
                          width: '100%',
                          display: 'inline-block',
                        }}
                      >
                        When was your last mammogram?
                      </h4>

                      {pastYearOptions.map(({ value, label }) => {
                        return (
                          <div
                            style={{
                              width: '23.5%',
                              display: 'inline-flex',
                              alignItems: 'start',
                              gap: '5px',
                              float: 'left',
                              margin: '5px 5px',
                            }}
                          >
                            <input
                              style={{
                                marginTop: '2px',
                              }}
                              type="radio"
                              checked={value === documentState?.last_mammogram}
                            />
                            <label> {label}</label>
                          </div>
                        );
                      })}

                      <h4
                        style={{
                          marginTop: '10',
                          textAlign: 'left',
                          fontSize: '14px',
                          marginBottom: '10px',
                          lineHeight: '1.5',
                          width: '100%',
                          display: 'inline-block',
                        }}
                      >
                        When was your last Pap smear?
                      </h4>

                      {pastYearOptions.map(({ value, label }) => {
                        return (
                          <div
                            style={{
                              width: '23.5%',
                              display: 'inline-flex',
                              alignItems: 'start',
                              gap: '5px',
                              float: 'left',
                              margin: '5px 5px',
                            }}
                          >
                            <input
                              style={{
                                marginTop: '2px',
                              }}
                              type="radio"
                              checked={value === documentState?.last_pap_smear}
                            />
                            <label> {label}</label>
                          </div>
                        );
                      })}
                    </div>
                  </td>
                </tr>

                <tr>
                  <td colSpan="2">
                    <div
                      style={{
                        borderRadius: '6px',
                        border: '1px solid #ebebeb',
                        padding: '20px',
                        width: '100%',
                        display: 'inline-block',
                        marginTop: '15px',
                      }}
                    >
                      <h2
                        style={{
                          marginTop: '0',
                          textAlign: 'left',
                          fontSize: '16px',
                          marginBottom: '10px',
                          lineHeight: '1.5',
                          borderBottom: '1px solid #ebebeb',
                          paddingBottom: '10px',
                        }}
                      >
                        For men only:
                      </h2>
                      <h4
                        style={{
                          marginTop: '10',
                          textAlign: 'left',
                          fontSize: '14px',
                          marginBottom: '10px',
                          lineHeight: '1.5',
                        }}
                      >
                        Have you had a prostate exam or PSA test in the past?
                      </h4>

                      {psaTestOptions.map(({ value, label }) => {
                        return (
                          <div
                            style={{
                              width: '23.5%',
                              display: 'inline-flex',
                              alignItems: 'start',
                              gap: '5px',
                              float: 'left',
                              margin: '5px 5px',
                            }}
                          >
                            <input
                              style={{
                                marginTop: '2px',
                              }}
                              type="radio"
                              checked={
                                value === documentState?.prostate_exam_psa
                              }
                            />
                            <label> {label}</label>
                          </div>
                        );
                      })}
                    </div>
                  </td>
                </tr>

                <tr>
                  <td
                    style={{
                      padding: '3px 10px',
                    }}
                  >
                    <h3
                      style={{
                        marginTop: '10px',
                        textAlign: 'left',
                        fontSize: '14px',
                        lineHeight: '1.5',
                      }}
                    >
                      Have you ever had a colonoscopy?
                    </h3>
                  </td>
                  <td
                    style={{
                      padding: '3px 10px',
                    }}
                  >
                    <div
                      style={{
                        display: 'inline-flex',
                        alignItems: 'start',
                        gap: '5px',
                        float: 'left',
                        margin: '5px 5px',
                      }}
                    >
                      <input
                        style={{
                          marginTop: '1px',
                        }}
                        type="radio"
                        checked={documentState?.had_colonoscopy}
                      />
                      <label>Yes</label>
                    </div>

                    <div
                      style={{
                        display: 'inline-flex',
                        alignItems: 'start',
                        gap: '5px',
                        float: 'left',
                        margin: '5px 5px',
                      }}
                    >
                      <input
                        style={{
                          marginTop: '1px',
                        }}
                        type="radio"
                        checked={!documentState?.had_colonoscopy}
                      />
                      <label>No</label>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td colSpan="2">
                    <div
                      style={{
                        borderRadius: '6px',
                        border: '1px solid #ebebeb',
                        padding: '20px',
                        width: '100%',
                        display: 'inline-block',
                        marginTop: '15px',
                      }}
                    >
                      <h2
                        style={{
                          marginTop: '0',
                          textAlign: 'left',
                          fontSize: '16px',
                          marginBottom: '10px',
                          lineHeight: '1.5',
                          borderBottom: '1px solid #ebebeb',
                          paddingBottom: '10px',
                        }}
                      >
                        Over the past 2 weeks, how often have you experienced
                        any of the following?
                      </h2>
                      <h4
                        style={{
                          marginTop: '10',
                          textAlign: 'left',
                          fontSize: '14px',
                          marginBottom: '10px',
                          lineHeight: '1.5',
                          width: '100%',
                          display: 'inline-block',
                        }}
                      >
                        Feeling down, depressed, or hopeless
                      </h4>

                      {timeSpanOptions.map(({ value, label }) => {
                        return (
                          <div
                            style={{
                              width: '23.5%',
                              display: 'inline-flex',
                              alignItems: 'start',
                              gap: '5px',
                              float: 'left',
                              margin: '5px 5px',
                            }}
                          >
                            <input
                              style={{
                                marginTop: '2px',
                              }}
                              type="radio"
                              checked={
                                value ===
                                documentState?.past_two_week
                                  ?.feeling_down_depressed
                              }
                            />
                            <label> {label}</label>
                          </div>
                        );
                      })}

                      <h4
                        style={{
                          marginTop: '10',
                          textAlign: 'left',
                          fontSize: '14px',
                          marginBottom: '10px',
                          lineHeight: '1.5',
                          width: '100%',
                          display: 'inline-block',
                        }}
                      >
                        Little interest or pleasure in doing things
                      </h4>

                      {timeSpanOptions.map(({ value, label }) => {
                        return (
                          <div
                            style={{
                              width: '23.5%',
                              display: 'inline-flex',
                              alignItems: 'start',
                              gap: '5px',
                              float: 'left',
                              margin: '5px 5px',
                            }}
                          >
                            <input
                              style={{
                                marginTop: '2px',
                              }}
                              type="radio"
                              checked={
                                value ===
                                documentState?.past_two_week
                                  ?.little_please_in_things
                              }
                            />
                            <label> {label}</label>
                          </div>
                        );
                      })}
                    </div>
                  </td>
                </tr>

                <tr>
                  <td
                    style={{
                      padding: '3px 10px',
                    }}
                  >
                    <h3
                      style={{
                        marginTop: '10px',
                        textAlign: 'left',
                        fontSize: '14px',
                        lineHeight: '1.5',
                      }}
                    >
                      Have you ever been diagnosed with a mental health
                      condition (e.g., anxiety, depression, bipolar disorder,
                      etc.)?
                    </h3>
                  </td>
                  <td
                    style={{
                      padding: '3px 10px',
                    }}
                  >
                    <div
                      style={{
                        display: 'inline-flex',
                        alignItems: 'start',
                        gap: '5px',
                        float: 'left',
                        margin: '5px 5px',
                      }}
                    >
                      <input
                        style={{
                          marginTop: '1px',
                        }}
                        type="radio"
                        checked={
                          documentState?.diagnosed_with_mental_health_condition
                        }
                      />
                      <label> Yes</label>
                    </div>

                    <div
                      style={{
                        display: 'inline-flex',
                        alignItems: 'start',
                        gap: '5px',
                        float: 'left',
                        margin: '5px 5px',
                      }}
                    >
                      <input
                        style={{
                          marginTop: '1px',
                        }}
                        type="radio"
                        checked={
                          !documentState?.diagnosed_with_mental_health_condition
                        }
                      />
                      <label> No</label>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td
                    style={{
                      padding: '3px 10px',
                    }}
                  >
                    <h3
                      style={{
                        marginTop: '10px',
                        textAlign: 'left',
                        fontSize: '14px',
                        lineHeight: '1.5',
                      }}
                    >
                      Do you currently see a mental health professional
                      (psychiatrist, therapist, counselor)?
                    </h3>
                  </td>
                  <td
                    style={{
                      padding: '3px 10px',
                    }}
                  >
                    <div
                      style={{
                        display: 'inline-flex',
                        alignItems: 'start',
                        gap: '5px',
                        float: 'left',
                        margin: '5px 5px',
                      }}
                    >
                      <input
                        style={{
                          marginTop: '1px',
                        }}
                        type="radio"
                        checked={
                          documentState?.seeing_mental_health_professional
                        }
                      />
                      <label> Yes</label>
                    </div>

                    <div
                      style={{
                        display: 'inline-flex',
                        alignItems: 'start',
                        gap: '5px',
                        float: 'left',
                        margin: '5px 5px',
                      }}
                    >
                      <input
                        style={{
                          marginTop: '1px',
                        }}
                        type="radio"
                        checked={
                          !documentState?.seeing_mental_health_professional
                        }
                      />
                      <label> No</label>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td colSpan="2">
                    <div
                      style={{
                        borderRadius: '6px',
                        border: '1px solid #ebebeb',
                        padding: '20px',
                        width: '100%',
                        display: 'inline-block',
                        marginTop: '15px',
                      }}
                    >
                      <h2
                        style={{
                          marginTop: '0',
                          textAlign: 'left',
                          fontSize: '16px',
                          marginBottom: '10px',
                          lineHeight: '1.5',
                        }}
                      >
                        What are your top health priorities?
                      </h2>

                      {healthPrioritiesOptions.map(({ label, value }) => {
                        return (
                          <div
                            style={{
                              width: '23.5%',
                              display: 'inline-flex',
                              alignItems: 'start',
                              gap: '5px',
                              float: 'left',
                              margin: '5px 5px',
                            }}
                            key={value}
                          >
                            <input
                              style={{
                                marginTop: '2px',
                              }}
                              type="checkbox"
                              checked={documentState?.top_health_priorities?.includes(
                                value,
                              )}
                            />
                            <label> {label}</label>
                          </div>
                        );
                      })}
                    </div>
                  </td>
                </tr>

                <tr>
                  <td colSpan="2">
                    <div
                      style={{
                        borderRadius: '6px',
                        border: '1px solid #ebebeb',
                        padding: '20px',
                        width: '100%',
                        display: 'inline-block',
                        marginTop: '15px',
                      }}
                    >
                      <h2
                        style={{
                          marginTop: '0',
                          textAlign: 'left',
                          fontSize: '16px',
                          marginBottom: '10px',
                          lineHeight: '1.5',
                        }}
                      >
                        Are you interested in participating in wellness programs
                        or receiving guidance on any of the following?
                      </h2>

                      {wellnessOptions.map(({ label, value }) => {
                        return (
                          <div
                            style={{
                              width: '23.5%',
                              display: 'inline-flex',
                              alignItems: 'start',
                              gap: '5px',
                              float: 'left',
                              margin: '5px 5px',
                            }}
                            key={value}
                          >
                            <input
                              style={{
                                marginTop: '2px',
                              }}
                              type="checkbox"
                              checked={documentState?.interests_in_wellness_programs?.includes(
                                value,
                              )}
                            />
                            <label> {label}</label>
                          </div>
                        );
                      })}
                    </div>
                  </td>
                </tr>

                <tr>
                  <td
                    style={{
                      padding: '3px 10px',
                    }}
                  >
                    <h3
                      style={{
                        marginTop: '10px',
                        textAlign: 'left',
                        fontSize: '14px',
                        lineHeight: '1.5',
                      }}
                    >
                      Do you consent to your health information being used to
                      provide personalized care recommendations and
                      interventions?
                    </h3>
                  </td>
                  <td
                    style={{
                      padding: '3px 10px',
                    }}
                  >
                    <div
                      style={{
                        display: 'inline-flex',
                        alignItems: 'start',
                        gap: '5px',
                        float: 'left',
                        margin: '5px 5px',
                      }}
                    >
                      <input
                        style={{
                          marginTop: '1px',
                        }}
                        type="radio"
                        checked={documentState?.health_info_usage_permission}
                      />
                      <label> Yes</label>
                    </div>

                    <div
                      style={{
                        display: 'inline-flex',
                        alignItems: 'start',
                        gap: '5px',
                        float: 'left',
                        margin: '5px 5px',
                      }}
                    >
                      <input
                        style={{
                          marginTop: '1px',
                        }}
                        type="radio"
                        checked={!documentState?.health_info_usage_permission}
                      />
                      <label> No</label>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td
                    style={{
                      padding: '3px 10px',
                    }}
                  >
                    <h3
                      style={{
                        marginTop: '10px',
                        textAlign: 'left',
                        fontSize: '14px',
                        lineHeight: '1.5',
                        marginBottom: '50px',
                      }}
                    >
                      Do you consent to being contacted by our care management
                      team to discuss your health assessment and potential care
                      options?
                    </h3>
                  </td>
                  <td
                    style={{
                      padding: '3px 10px',
                    }}
                  >
                    <div
                      style={{
                        display: 'inline-flex',
                        alignItems: 'start',
                        gap: '5px',
                        float: 'left',
                        margin: '5px 5px',
                      }}
                    >
                      <input
                        style={{
                          marginTop: '1px',
                        }}
                        type="radio"
                        checked={documentState?.care_management_consent}
                      />
                      <label> Yes</label>
                    </div>

                    <div
                      style={{
                        display: 'inline-flex',
                        alignItems: 'start',
                        gap: '5px',
                        float: 'left',
                        margin: '5px 5px',
                      }}
                    >
                      <input
                        style={{
                          marginTop: '1px',
                        }}
                        type="radio"
                        checked={!documentState?.care_management_consent}
                      />
                      <label> No</label>
                    </div>
                  </td>
                </tr>
                {/* //signature */}
                <tr
                  style={{
                    marginBottom: '0px',
                  }}
                >
                  <td
                    colSpan={2}
                    style={{
                      padding: '10px',
                      position: 'relative',
                    }}
                  >
                    <span
                      style={{
                        width: '58%',
                        textAlign: 'right',
                        display: 'inline-block',
                        float: 'left',
                      }}
                    >
                      By:
                    </span>
                    <div
                      style={{
                        minWidth: '200px',
                        maxWidth: '300px',
                        border: 'none',
                        width: '100%',
                        borderBottom: '1px solid #555',
                        minHeight: '16px',
                        marginBottom: '25px',
                        position: 'absolute',
                        right: '0',
                      }}
                    >
                      <div style={{ position: 'relative' }}>
                        {!documentState?.SIGNATURE ? (
                          <div
                            onClick={() => {
                              if (readMode) return;
                              setSignatureModal(true);
                            }}
                            style={{
                              minWidth: '200px',
                              border: 'none',
                              height: '60px',
                              width: '100%',
                              padding: '5px',
                              cursor: 'pointer',
                              position: 'absolute',
                              top: '-45px',
                              // border: '1px dashed #ddd',
                            }}
                          >
                            + Add Signature
                          </div>
                        ) : (
                          <div
                            onClick={() => {
                              if (readMode) return;
                              setSignatureModal(true);
                            }}
                            style={{
                              minWidth: '200px',
                              border: 'none',
                              width: '100%',
                              padding: '5px',
                              cursor: 'pointer',
                              position: 'absolute',
                              top: '-45px',
                              height: '60px',
                              // border: '1px dashed #ddd',
                            }}
                          >
                            <img
                              alt="signature"
                              src={documentState?.SIGNATURE}
                              style={{ maxHeight: '50px' }}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </>
        )}

        {signatureModal ? (
          <Modal
            handleClose={() => setSignatureModal(false)}
            title={'Signature'}
            headerComponent={null}
            shouldCloseOnClickOutside={true}
            footerComponent={null}
            height="300"
            size="700"
          >
            <SignModule handleChange={handleChange} />
          </Modal>
        ) : null}
      </>
    );
  },
);

export default HRA_FORM_HTML;
