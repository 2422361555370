import React, { useEffect, useRef, useState } from 'react';
import Button from '../../../../components/Button';
import { useAuth } from '../../../../hooks/useAuth';
import { LogoutIcon } from '../../../../assets/icons/useIcons';
import nextereLogo from '../../../../assets/images/nextere-logo.png';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAlert } from '../../../../hooks/useAlert';
import { verifyConsumer } from '../VerifySignup/actions';
import Spinner from '../../../../components/Spinner';

const VerifyByIframe = () => {
  const ref = useRef();
  const { clearUser } = useAuth();
  let queryClient = useQueryClient();
  const { showAlert } = useAlert();
  const [isVerified, setIsVerified] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const { mutate } = useMutation({
    mutationFn: verifyConsumer,
    mutationKey: 'verifyConsumer',
    onSuccess: ({ data }) => {
      showAlert({ type: 'success', message: data?.data?.message });
      queryClient.invalidateQueries({ queryKey: ['getUserDetails'] });
    },
    onError: (error) => {
      const errMsg = error?.message ?? 'unexpected error';
      showAlert({ type: 'danger', message: errMsg });
    },
  });

  useEffect(() => {
    const iframeElement = ref.current;
    console.log(iframeElement);
    if (iframeElement) {
      window.addEventListener('message', function (event) {
        console.log('ðŸš€ ~ event:', event);
        if (event.source === iframeElement.contentWindow) {
          var response = event.data;
          console.log(
            'Received response data from iframe:',
            response,
            event.origin,
          );
          if (response?.respuesta) {
            setIsVerified(true);
          }
        }
      });
    }
  }, [ref.current]);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 5000);
  }, []);

  // if (isLoading) {
  //   return (
  // <div className="d--flex align-items--center justify-content--center h--full w--full">
  //   <Spinner size="lg" />
  // </div>
  //   );
  // }

  return (
    <div className="w--full h--full  d--flex  flex--column loginPage  overflow--y-auto verification-iframe">
      <div className="w--full h-min--60 d--flex justify-content--between align-items--center bg--white p-t--xs p-l--md p-r--md border-bottom--black-50">
        <img src={nextereLogo} alt="nextereLogo" className="w-max--150" />
        <Button
          btnClasses="btn w-max--100 "
          size="md"
          variant="primary"
          color="black"
          onClick={() => {
            clearUser();
          }}
        >
          <span className="m-r--xs">
            {' '}
            <LogoutIcon />
          </span>
          Logout
        </Button>
      </div>
      <div className="d--flex align-items--center justify-content--center w--full h--full flex--column p--lg h-max--645">
        <div className="d--flex align-items--center justify-content--center flex--column gap--sm  w--full w-max--645  h--full   radius--sm p--xs">
          <div className=" w--full verification-header d--flex align-items--center justify-content--center p-t--md p-l--sm p-r--sm m-b--sm">
            <h3 className="font--lg font--600">Photo Verification</h3>
          </div>
          {isLoading ? (
            <div className="d--flex align-items--center justify-content--center h--full w--full">
              <Spinner size="lg" />
            </div>
          ) : (
            <iframe
              title="iframhubhai"
              ref={ref}
              // allow="camera *;https://conxent-dev.nextere.com/"
              allow="camera *;https://conxent.nextere.com/"
              src="https://portal.onid.us/wa/auth?authmech=OnID%20Password&do=login&username=NHP001&password=H_9u2E.p?K4x})umM0P~&cbval_vendorBase64=AAAk%2FwMGAAAAAQ%3D%3D&Submit1=Yes&&location=https://webcomponentpsv.onid.us"
            ></iframe>
          )}
          {
            isVerified ? (
              <Button
                btnClasses="btn skip-verify-btn text--white"
                size="md"
                variant="danger"
                onClick={() => {
                  mutate();
                }}
              >
                <span className="m-r--xs"></span>
                Go to my account
              </Button>
            ) : null
            // <Button
            //   btnClasses="btn skip-verify-btn text--white"
            //   size="md"
            //   variant="danger"
            //   onClick={() => mutate()}
            // >
            //   <span className="m-r--xs"></span>
            //   Skip for later
            // </Button>
          }
        </div>
      </div>
    </div>
  );
};

export default VerifyByIframe;
