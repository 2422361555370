import React from 'react';
import TableManager from '../../components/TableManager';
import { agencyAgentList } from '../Agents/actions';
import useIcons from '../../assets/icons/useIcons';

const AgentSidebar = ({ agencyId, handleClose }) => {
  const { CrossRoundIcon } = useIcons();
  const columns = [
    {
      accessorKey: 'firstName',
      header: () => 'Name',
      cell: ({ row }) => {
        const element = row?.original;
        return `${element?.firstName} ${element?.lastName}`;
      },
    },
    {
      accessorKey: 'email',
      header: () => 'Email',
    },
    {
      accessorKey: 'phone',
      header: () => 'Phone',
      meta: {
        textAlign: 'center',
      },
    },

    {
      accessorKey: 'NPN',
      header: () => 'NPN',
      meta: {
        textAlign: 'center',
      },
    },
  ];
  const tableActions = [
    {
      id: 1,
      access: true,
      component: (
        <button type="button" className="font--md font--600 text--primary d--flex c--pointer radius--full" onClick={() => handleClose()}>
          <CrossRoundIcon width={30} height={30} />
        </button>
      ),
    },
  ];
  return (
    <div className="p--md w--full">
      <TableManager
        {...{
          fetcherKey: 'agencyAgentList',
          fetcherFn: agencyAgentList,
          shouldFilter: false,
          showPagination: false,
          shouldRefresh: false,
          tableActions,
          name: 'Agency Agents List',
          columns,
          extraParams: {
            uuid: agencyId,
          },
        }}
      />
    </div>
  );
};

export default AgentSidebar;
