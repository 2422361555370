import { apiClient } from '../../api/axios';
import { API_ROUTES } from './constants';

export const signUp = (data) => {
  return apiClient({
    method: API_ROUTES.SIGNUP.METHOD,
    url: API_ROUTES.SIGNUP.URL,
    data,
  });
};
export const verifyOtp = (data) => {
  return apiClient({
    method: API_ROUTES.VERIFY_OTP.METHOD,
    url: API_ROUTES.VERIFY_OTP.URL,
    data,
  });
};
export const loginVerifyOtp = (data) => {
  return apiClient({
    method: API_ROUTES.LOGIN_VERIFY_OTP.METHOD,
    url: API_ROUTES.LOGIN_VERIFY_OTP.URL,
    data,
  });
};
export const resendOtp = (data) => {
  return apiClient({
    method: API_ROUTES.RESEND_OTP.METHOD,
    url: API_ROUTES.RESEND_OTP.URL,
    data,
  });
};
export const linkCodeApi = (data) => {
  return apiClient({
    method: API_ROUTES.LINK_CODE.METHOD,
    url: API_ROUTES.LINK_CODE.URL,
    data,
  });
};
export const sendLinkToMobileApi = (data) => {
  return apiClient({
    method: API_ROUTES.SEND_LINK_TO_MOBILE.METHOD,
    url: API_ROUTES.SEND_LINK_TO_MOBILE.URL,
    data,
  });
};
export const resetPassword = (data) => {
  return apiClient({
    method: API_ROUTES.RESET_PASSWORD.METHOD,
    url: API_ROUTES.RESET_PASSWORD.URL,
    data,
  });
};
