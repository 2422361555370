import { useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import CMS_EN from '../CMS/en';
import Button from '../../Button';
import { useMutation, useQuery } from '@tanstack/react-query';
import { getDocumentStatus, verifySignatureDoc } from '../../../api';
import Spinner from '../../Spinner';
import { getOS, getSessionInfo } from '../../../helpers/functions';
import { toast } from 'react-toastify';
import CMS_ES from '../CMS/es';
import ER_ES from '../Eligibility/es';
import ER_EN from '../Eligibility/en';
import DocumentOtp from './DocumentOtp';

import PreviewDocumentOuter from '../PreviewDocumentOuter';
import PreviewDocumentOuterMobile from '../PreviewDocumentOuter/PreviewDocumentOuterMobile';

const documentNameLookup = {
  CMS: 'CMS',
  ELIGIBILITY: 'ER',
};

const NoDocument = () => {
  return (
    <div className="d--flex align-items--center justify-content--center h--full w--full">
      No Document Found
    </div>
  );
};

const PreviewDocumentSignModeOuter = () => {
  const { docId = '' } = useParams();
  const location = useLocation();
  const formRef = useRef();
  const params = new URLSearchParams(location.search);
  const docType = params.get('docType');

  const [showOtp, setShowOtp] = useState(true);

  const {
    data = null,
    isLoading,
    refetch,
  } = useQuery({
    queryFn: () =>
      getDocumentStatus({
        uuid: docId,
        docType,
      }),
    queryKey: 'getDocumentStatus',
    onError: (error) => {
      const errMsg = error?.message ?? 'unexpected error';
      toast.error(errMsg);
    },
    enabled: Boolean(docId) && Boolean(docType),
  });

  const { mutate: mutateVerifyDoc, isLoading: isLoadingVerified } = useMutation(
    {
      mutationFn: verifySignatureDoc,
      mutationKey: 'verifySignatureDoc',
      onSuccess: () => {
        toast.success('Document Saved.');
        refetch();
      },
      onError: (error) => {
        const errMsg = error?.message ?? 'unexpected error';
        toast.error(errMsg);
      },
    },
  );

  function handleSubmit() {
    const formValues = formRef.current?.getFormValues();
    const template = formRef.current?.getFormTemplate();

    if (!formValues?.SIGNATURE) {
      return toast.error('Please Sign the document.');
    }

    const payload = {
      type: 'signature',
      filename: '',
      uuid: docId,
      data: formValues,
      htmlTemplate: template,
      browserInfo: getSessionInfo(),
    };

    mutateVerifyDoc(payload);
  }

  const documentData = isLoading ? null : data?.data?.data?.getConsentData;

  const documentLookup = {
    ER_ES: <ER_ES documentData={documentData} ref={formRef} />,
    ER_EN: <ER_EN documentData={documentData} ref={formRef} />,
    CMS_ES: <CMS_ES documentData={documentData} ref={formRef} />,
    CMS_EN: <CMS_EN documentData={documentData} ref={formRef} />,
  };

  const document = documentLookup[
    `${documentNameLookup[docType]}_${
      documentData?.language === 'en' ? 'EN' : 'ES'
    }`
  ] || <NoDocument />;

  if (!docId) return <NoDocument />;

  if (isLoading) {
    return (
      <div className="d--flex align-items--center justify-content--center h--full w--full">
        <Spinner size="lg" />
      </div>
    );
  }

  if (
    data?.data?.data?.getConsentData &&
    data?.data?.data?.getConsentData?.agreementStatus === 'SIGNED'
  ) {
    return (
      <>
        {getOS() && ['windows', 'macos'].includes(getOS()) ? (
          <PreviewDocumentOuter {...{ data }} />
        ) : (
          <PreviewDocumentOuterMobile {...{ data }} />
        )}
      </>
    );
  }

  if (
    data?.data?.data?.getConsentData &&
    !data?.data?.data?.getConsentData?.isAllowDirectSignature &&
    showOtp
  ) {
    return (
      <DocumentOtp
        {...{
          phone: data?.data?.data?.getConsumerPhoneNumber?.phone,
          setShowOtp,
        }}
      />
    );
  }

  return (
    <div className="d--flex h--full w--full ">
      <div className="d--flex gap--sm w--full">
        <div className="w--full h--full mx--auto box-shadow--xs overflow--auto p-b--4xl">
          {document}
        </div>
        <div className="position--fixed left--0 bottom--0 h-min--50 gap--sm w--full bg--white d--flex align-items--center justify-content--center">
          {/* <Button
            onClick={() => {
              return (window.location.href = '/');
            }}
            variant="secondary"
            btnClasses="btn w-max--150"
            data-link-hover
            size="sm"
            disabled={isLoadingVerified}
          >
            Back
          </Button> */}
          <Button
            onClick={handleSubmit}
            variant="primary"
            color="black"
            isOutline
            borderColor="primary"
            btnClasses="btn w-max--150"
            data-link-hover
            size="sm"
            disabled={isLoadingVerified}
          >
            {isLoadingVerified ? 'Please wait...' : 'Submit'}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PreviewDocumentSignModeOuter;
