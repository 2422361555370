import React from 'react';
import Pdf from '../../../../assets/images/pdf.png';
import { useMutation, useQuery } from '@tanstack/react-query';
import { getDropboxFiles, getDropboxLink } from '../../actions';
import { useAlert } from '../../../../hooks/useAlert';

const DropboxFiles = ({ folderId }) => {
  const { showAlert } = useAlert();
  const { data: googleDriveFiles, isLoading } = useQuery({
    queryKey: ['getDropboxFiles', folderId],
    queryFn: () => getDropboxFiles({ folderId }),
    select: (data) => data?.data?.data,
    enabled: Boolean(folderId),
  });

  const { mutate, isLoading: dropBoxLinkLoading } = useMutation({
    mutationFn: getDropboxLink,
    mutationKey: 'getDropboxLink',
    onSuccess: ({ data }) => {
      window.open(data?.data?.url);
    },
    onError: (error) => {
      const errMsg = error?.message ?? 'unexpected error';
      showAlert({ type: 'danger', message: errMsg });
    },
  });

  return (
    <div className="w--full grid--3 grid--3--xl d--grid gap--md">
      {isLoading
        ? 'Loading...'
        : googleDriveFiles && googleDriveFiles?.length > 0
        ? googleDriveFiles?.map(({ filename }) => {
            return (
              <div
                className=" radius--sm p--sm d--flex gap--sm align-items--center c--pointer"
                onClick={() => {
                  if (!dropBoxLinkLoading) {
                    mutate({ fileName: filename, folderId });
                  }
                }}
              >
                <div className="w-min--40 h-min--40 w-max--40 h-max--40 d--flex align-items--center justify-content--center">
                  <img
                    src={Pdf}
                    alt="..."
                    className=" object--contain radius--sm bg--black-100 "
                  />
                </div>
                <div className="font--600 font--sm w--full text--black-600">
                  {filename}
                </div>
              </div>
            );
          })
        : 'No files available'}
    </div>
  );
};

export default DropboxFiles;
