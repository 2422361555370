import React from 'react';

import Button from '../../../components/Button';
import { UploadCloud } from '../../../assets/icons/useIcons';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { importInvites } from '../actions';
import { useAlert } from '../../../hooks/useAlert';
import { object } from 'yup';
import { requiredFileFunction } from '../../../helpers/yupValidations';
import { downloadFileFromURL } from '../../../helpers/functions';

const schema = object({
  file: requiredFileFunction('File'),
});
const ImportInvites = ({ handleClose }) => {
  const queryclient = useQueryClient();
  const { showAlert } = useAlert();
  const {
    handleSubmit,
    register,
    watch,

    setError,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      file: null,
    },
    resolver: yupResolver(schema),
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const { mutate, isLoading } = useMutation({
    mutationFn: importInvites,
    mutationKey: 'importInvites',
    onSuccess: ({ data }) => {
      showAlert({ type: 'success', message: data?.data?.message });
      queryclient.invalidateQueries({
        queryKey: ['getAgentInviteList'],
        exact: false,
      });
      handleClose();
    },
    onError: (error) => {
      const errMsg = error?.message ?? 'unexpected error';
      showAlert({ type: 'danger', message: errMsg });
    },
  });
  const onSubmit = (data) => {
    mutate(data);
  };

  return (
    <form
      className="w--full h--full "
      onSubmit={handleSubmit(onSubmit)}
      onClick={(e) => e.stopPropagation()}
    >
      {errors?.file?.message && (
        <label
          className={`label--control font--sm font--500 m-t--md  text--danger`}
        >
          {errors?.file?.message}
        </label>
      )}
      <label
        className="d--flex m-b--md m-t--md border-full--primary border--dashed p--xl radius--sm flex--column align-items--center justify-content--center c--pointer"
        htmlFor="file"
      >
        <input
          {...register('file')}
          type="file"
          accept=".csv"
          id="file"
          onChange={(e) => {
            if (e?.target.files?.[0]?.type.includes('csv')) {
              setValue('file', e.target.files?.[0], { shouldValidate: true });
            } else {
              setError('file', {
                type: 'manual',
                message: 'Please upload a CSV file',
              });
            }
          }}
          hidden
        />
        <span className="text--primary">
          <UploadCloud width={30} height={30} />
        </span>
        <h3 className="text--grey">Upload New Document</h3>
        <p className="m-t--sm font--sm text--grey-600">Click to upload </p>
        <p className="m-t--sm text--danger">{watch('file')?.name ?? ''}</p>
      </label>
      <div className="w--full text--r decoration--none d--flex justify-content--end gap--sm  text--danger p-b--lg">
        <UploadCloud width={20} height={20} />
        <span
          onClick={() =>
            downloadFileFromURL(
              `${process.env.REACT_APP_BASE_API_URL}/files/agent-invite-sample-file.csv`,
              () => {},
              'agent-invite-sample-file',
            )
          }
          className="font--sm text--danger c--pointer"
        >
          Sample file
        </span>
      </div>
      <div className="d--flex justify-content--end gap--sm p-t--md border-top--black-100">
        <Button
          btnClasses="btn  w-max--150"
          type="submit"
          variant="primary"
          color="black"
          disabled={isLoading}
        >
          {isLoading ? 'Please wait..' : 'Invite'}
        </Button>
      </div>
    </form>
  );
};

export default ImportInvites;
