import React from 'react';
import { webhoookModeArr } from '../../pages/Configuration/constants';
import Button from '../Button';
import { useAuth } from '../../hooks/useAuth';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { webhookUpdateMode } from '../../pages/MyAccount/actions';

const WebhookModeButton = () => {
  const { user } = useAuth();
  const queryClient = useQueryClient();
  const {
    mutate: webhookUpdateModeMutate,
    isLoading: webhookUpdateModeLoading,
  } = useMutation({
    mutationFn: webhookUpdateMode,
    mutationKey: 'webhookUpdateMode',
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['getUserDetails'],
        exact: false,
      });
    },
  });
  return (
    <>
      {webhoookModeArr.map(({ type, label }) => (
        <Button
          key={type}
          type="button"
          btnClasses="btn btn--p-0"
          color="black"
          borderColor="primary"
          isOutline
          size="xs"
          variant={type === user?.webhookMode ? 'white' : 'primary'}
          onClick={() => {
            if (type !== user?.webhookMode) {
              webhookUpdateModeMutate({
                webhookType: 'OUTBOUND',
                webhookMode: type,
              });
            }
          }}
          disabled={
            type !== user?.webhookMode ? webhookUpdateModeLoading : false
          }
        >
          {label}
        </Button>
      ))}
    </>
  );
};

export default WebhookModeButton;
