import { useAuth } from '../../../hooks/useAuth';
import HRAStep1 from '../components/HRAStep1';
import HRAStep2 from '../components/HRAStep2';

const AgentHra = () => {
  const { user } = useAuth();

  if (!user) {
    return null;
  }
  return (
    <>
      {user?.agreementDocumentDetail?.agreementDocumentStatus === 'SIGNED' ? (
        <HRAStep2 />
      ) : (
        <HRAStep1 />
      )}
    </>
  );
};

export default AgentHra;
