import { useMutation } from '@tanstack/react-query';
import { uploadMedia } from '../../api';
import { useAlert } from '../useAlert';
import { useState } from 'react';

function loadAudioFileAsync(url) {
  return new Promise((resolve, reject) => {
    var xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.responseType = 'arraybuffer';

    xhr.onload = function () {
      if (xhr.status === 200) {
        var arrayBuffer = xhr.response;
        resolve(arrayBuffer);
      } else {
        reject('Error loading audio file');
      }
    };

    xhr.onerror = function () {
      reject('Network error');
    };

    xhr.send();
  });
}

const audioContext = new window.AudioContext();
export default function useUploadFile() {
  const { showAlert } = useAlert();

  const [isLoading, setIsLoading] = useState(false);
  const { mutateAsync: uploadMediaMutate } = useUploadMedia();

  const uploadMedia = async ({ file, type = '' }) => {
    if (!file || !type) return;
    setIsLoading(true);
    const payload = {
      fileName: file?.name,
      type,
    };
    let duration = null;

    const response = await uploadMediaMutate(payload);
    console.log('🚀 ~ uploadMedia ~ response:', response);

    if (response?.status !== 200) {
      setIsLoading(false);

      const errMsg = response?.data?.error?.message ?? 'unexpected error';
      return showAlert({ type: 'danger', message: errMsg });
    }

    const { fileName, url: uploadUrl } = response?.data?.data ?? {};

    // const mimeType = new URL(uploadUrl).searchParams.get('Content-Type');

    if (uploadUrl) {
      const uploadFileResponse = await fetch(response?.data?.data?.url, {
        headers: { 'Content-Type': file?.type },

        // headers: { 'Content-Type': mimeType },
        method: 'PUT',
        body: file,
        redirect: 'follow',
      });
      if (uploadFileResponse?.status === 200) {
        setIsLoading(false);
        if (['greeting'].includes(type)) {
          const arrayBuffer = await loadAudioFileAsync(
            URL.createObjectURL(file),
          );
          const decodeAudioData = await audioContext.decodeAudioData(
            arrayBuffer,
          );
          duration = decodeAudioData.duration;
        }
        return {
          fileName,
          duration,
        };
      } else {
        setIsLoading(false);
        return null;
      }
    } else {
      setIsLoading(false);
      return null;
    }
  };

  return { uploadMedia, isLoading };
}

const useUploadMedia = ({ handleSuccess = () => null } = {}) => {
  const { showAlert } = useAlert();

  const Request = useMutation({
    mutationFn: uploadMedia,
    mutationKey: 'uploadMedia',
    onSuccess: ({ data }) => {
      handleSuccess(data);
    },
    onError: (error) => {
      const errMsg = error?.message ?? 'unexpected error';
      showAlert({ type: 'danger', message: errMsg });
    },
  });
  return Request;
};
