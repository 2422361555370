import React, { useState } from 'react';
import TableManager from '../../../components/TableManager';
import { dropEl } from '../../../helpers/constants';
import Dropdown from '../../../components/Dropdown';
import { useNavigate } from 'react-router-dom';
import {
  getHraCarrierCount,
  getHraStateCount,
  hraConsumerList,
} from '../actions';
import { filterDefaultValuesNew } from '../../Members/constants';
import AsideModal from '../../../components/AsideModal';
import HRAView from '../HRAView';
import PieChart from '../Charts/PieChart';
import { useQueries } from '@tanstack/react-query';

const HRAStep2 = () => {
  const [openAsideModal, setOpenAsideModal] = useState(false);
  const [rowData, setRowData] = useState(false);
  const navigate = useNavigate();
  const handleProfileDropClick = (clickCase = '', rowData, item) => {
    switch (clickCase) {
      case 'HRA':
        navigate(`/view-hra?id=${rowData?.consumerId}`, {
          state: rowData,
        });

        break;

      default:
        break;
    }
  };

  const [{ data: carrierCount }, { data: stateCount }] = useQueries({
    queries: [
      {
        queryKey: ['getHraCarrierCount'],
        queryFn: getHraCarrierCount,
        select: (data) => data?.data?.data,
      },
      {
        queryKey: ['getHraStateCount'],
        queryFn: getHraStateCount,
        select: (data) => data?.data?.data,
      },
    ],
  });

  const columns = [
    {
      accessorKey: 'firstName',
      header: () => 'Name',
      cell: (props) => `${props.getValue()} ${props.row.original?.lastName}`,
      meta: {
        width: '30%',
      },
    },
    {
      accessorKey: 'phone',
      header: () => 'Phone',
      meta: {
        textAlign: 'center',
      },
    },
    {
      accessorKey: 'email',
      header: () => 'Email',
      meta: {
        width: '25%',
      },
    },
    {
      accessorKey: 'adultFamilyMembers',
      header: () => 'Members',
      cell: (props) => {
        return (
          <div className="d--flex gap--xs justify-content--center align-items--center">
            <div
              onClick={() => {
                if (props.getValue()?.length > 0) {
                  setOpenAsideModal(true);
                  setRowData(props?.row?.original);
                }
              }}
              data-tooltip="Adult"
              tooltip-position="top"
              className="c--pointer bg--primary-200 w-min--28  w-max--28 h-min--28 d--flex align-items--center justify-content--center radius--full text--center"
            >
              {props.getValue()?.length || 0}
            </div>
            |
            <div
              className=" bg--primary-200 w-min--28  w-max--28 h-min--28 d--flex align-items--center justify-content--center radius--full text--center "
              data-tooltip="Minor"
              tooltip-position="top"
            >
              {props?.row?.original?.minorFamilyMembers?.length || 0}
            </div>
          </div>
        );
      },

      meta: {
        textAlign: 'center',
        width: '8%',
      },
    },
    {
      accessorKey: 'hraDocumentSignStatus',
      header: () => 'HRA Status',
      cell: (props) => {
        const val = props.getValue();
        return (
          <span
            className={`${
              val === 'PENDING' ? 'text--warning' : 'text--success'
            }`}
          >
            {val?.at(0) + val?.slice(1)?.toLowerCase()}
          </span>
        );
      },

      meta: {
        textAlign: 'center',
        width: '8%',
      },
    },
    {
      accessorKey: 'enrollmentYear',
      header: () => 'Enrollment',

      meta: {
        textAlign: 'center',
        width: '8%',
      },
    },
    {
      accessorKey: 'action',
      header: () => 'Action',
      cell: (props) => {
        return (
          <>
            <div className="w-min--125 w-min--auto-xs">
              <Dropdown
                closeOnClickOutside={true}
                dropList={{
                  component: ({ item }) => {
                    if (!item?.access) return;
                    return (
                      <div
                        className="text--secondary p--sm w--full text--l"
                        onClick={() =>
                          handleProfileDropClick(
                            item?.value,
                            props.row?.original,
                            item,
                          )
                        }
                      >
                        {item?.title}
                      </div>
                    );
                  },

                  data: [
                    {
                      title: 'HRA',
                      value: 'HRA',
                      id: 1,
                      access: true,
                    },
                  ],
                }}
              >
                {dropEl}
              </Dropdown>
            </div>
          </>
        );
      },
      meta: {
        textAlign: 'center',
        width: '8%',
      },
    },
  ];
  return (
    <div className="d--flex flex--column gap--xl">
      <div className="d--flex gap--xl">
        {carrierCount && carrierCount?.length > 0 ? (
          <div className="w--full border-full--black-100 radius--md p--sm">
            <PieChart dataPoints={carrierCount} />
          </div>
        ) : null}
        {stateCount && stateCount?.length > 0 ? (
          <div className="w--full border-full--black-100  radius--md p--sm">
            <PieChart title="HRA by State" dataPoints={stateCount} />
          </div>
        ) : null}
      </div>

      <div className="w--full d--flex flex--column gap--lg memberPage">
        <TableManager
          {...{
            fetcherKey: 'hraConsumerList',
            fetcherFn: hraConsumerList,
            shouldFilter: false,
            name: 'Consumer',
            columns: columns,

            selectCBForTableData: (tblData) =>
              tblData?.data?.data?.memberResponseData,
            filterDefaultValues: filterDefaultValuesNew,
          }}
        />
        {openAsideModal ? (
          <AsideModal
            handleClose={() => setOpenAsideModal(false)}
            title={'HRA'}
            footerComponent={null}
            headerComponent={null}
            size={'lg'}
            width={'lg'}
          >
            <HRAView
              handleClose={() => setOpenAsideModal(false)}
              uuId={rowData?.consumerId}
            />
          </AsideModal>
        ) : null}
      </div>
    </div>
  );
};

export default HRAStep2;
