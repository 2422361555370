import { apiClient } from '../../api/axios';
import { API_ROUTES } from './constants';

export const getMemberList = (data) => {
  return apiClient({
    method: API_ROUTES.GET_MEMBER_LIST.METHOD,
    url: API_ROUTES.GET_MEMBER_LIST.URL,
    data,
  });
};
export const getMemberEnrollmentList = (data) => {
  return apiClient({
    method: API_ROUTES.GET_MEMBER_ENROLLMENT_LIST.METHOD,
    url: API_ROUTES.GET_MEMBER_ENROLLMENT_LIST.URL,
    data,
  });
};

export const createMember = (data) => {
  return apiClient({
    method: API_ROUTES.CREATE_MEMBER.METHOD,
    url: API_ROUTES.CREATE_MEMBER.URL,
    data,
  });
};
export const importMember = (data) => {
  return apiClient({
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    method: API_ROUTES.IMPORT_MEMBER.METHOD,
    url: API_ROUTES.IMPORT_MEMBER.URL,
    data,
  });
};

export const updateMember = (data) => {
  return apiClient({
    method: API_ROUTES.UPDATE_MEMBER.METHOD,
    url: API_ROUTES.UPDATE_MEMBER.URL,
    data,
  });
};

export const deleteMember = (data) => {
  return apiClient({
    method: API_ROUTES.DELETE_MEMBER.METHOD,
    url: API_ROUTES.DELETE_MEMBER.URL,
    data,
  });
};

export const updateMemberStatus = (data) => {
  return apiClient({
    method: API_ROUTES.UPDATE_MEMBER_STATUS.METHOD,
    url: API_ROUTES.UPDATE_MEMBER_STATUS.URL,
    data,
  });
};

export const sendInvite = (data) => {
  return apiClient({
    method: API_ROUTES.SEND_INVITE.METHOD,
    url: API_ROUTES.SEND_INVITE.URL,
    data,
  });
};
export const getAgencyListDropDown = (data) => {
  return apiClient({
    method: API_ROUTES.AGENCY_LIST_DROPDOWN.METHOD,
    url: API_ROUTES.AGENCY_LIST_DROPDOWN.URL,
    data,
  });
};

export const uploadConsentDoc = (data) => {
  return apiClient({
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    method: API_ROUTES.UPLOAD_CONSENT_DOC.METHOD,
    url: API_ROUTES.UPLOAD_CONSENT_DOC.URL,
    data,
  });
};
export const uploadEligibilityDoc = (data) => {
  return apiClient({
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    method: API_ROUTES.UPLOAD_ELIGIBILITY_DOC.METHOD,
    url: API_ROUTES.UPLOAD_ELIGIBILITY_DOC.URL,
    data,
  });
};
