import React from 'react';
import OuterHeader from '../Login/Components/OuterHeader';
import useIcons, {
  CallingIcon,
  CurveBottomIcon,
  FacebookIcon,
  InstagramIcon,
  LinkedInIcon,
  LogoXIcon,
} from '../../assets/icons/useIcons';
import Button from '../../components/Button';
import FormInput from '../../components/FormInput';
import LogoX from '../../assets/images/Xlogo.png';

const PlanPricing = () => {
  return (
    <div className="w--full h--full ">
      <OuterHeader />
      <div className="planPricingPage w--full h--full m-t--4xl">
        <div className="sectionBanner w--full h-min--700 position--relative">
          <div className="w--full d--flex flex--column gap--sm justify-content--center p-t--6xl p-b--3xl">
            <div className="w--full text--c font--8xl text--black">
              Find the right plans
            </div>
            <div className="w--full d--flex justify-content--center ">
              <div className="w--full text--c  font--8xl font--700 w-max--700  crewBlack position--relative   ">
                <span>for your needs</span>
              </div>
            </div>
          </div>
          <div className="curveBottom position--absolute w--full text--white">
            <CurveBottomIcon />
          </div>
        </div>
        <div className="priceSection z-index--md position--relative">
          <div className="container">
            <div className="w--full radius--md bg--white box--shadow shadow--green position--relative ">
              <div className="d--flex align-items--center justify-content--between w--full p-t--md p-b--md p-l--lg p-r--lg border-bottom--black-100 flex--column-xs gap--md-xs">
                <div className="font--3xl text--black-600">
                  <i>
                    Get started with our{' '}
                    <span className="font--600 text--black">Free Plan now</span>{' '}
                    and <br /> experience the benefits!
                  </i>
                </div>
                <div className="d--flex flex--column gap--sm">
                  <div className="d--flex bg--black-100 p--xs radius--md bg--black-100 w-min--200">
                    <Button variant="primary" color="black" btnClasses="btn">
                      Monthly
                    </Button>
                    <Button
                      variant="transparent"
                      color="black"
                      btnClasses="btn"
                    >
                      Yearly
                    </Button>
                  </div>
                  <div className="font--2sm text--c">
                    (Yearly Save up to 20%)
                  </div>
                </div>
              </div>
              <div className="d--flex w--full flex--column-xs">
                <div className=" border-right--black-100 d--flex flex--column gap--lg w--full p--lg">
                  <div className="w--full d--flex flex--column border-bottom--black-100 p-b--md">
                    <div className="w--full d--flex flex--column gap--lg">
                      <div className="p--sm d--flex align-items--center justify-content--center font--sm font--600 text-black radius--sm badge w-min--85 w-max--85 h-min--36">
                        Basic
                      </div>
                      <div className="d--flex gap--sm align-items--center">
                        <div className="font--2xl font--600">$9.99</div> /
                        <div className="font--md text--black-600">yearly</div>
                      </div>
                    </div>
                  </div>

                  <div className="d--flex w--full font--md line-height--1-dot-5">
                    Reduce costs, grow revenue, and run your business more
                    efficiently on a fully integrated platform. Use Stripe to
                    handle all of your payments-related needs, manage revenue
                    operations, and launch (or invent) new business models.
                  </div>
                  <div className="d--flex flex--column gap--md">
                    <div className="w--full font--600 font--md d--flex gap--sm font--500 align-items--center">
                      <div className="w-min--4 h-min--4 h-max--4 w-max--4 bg--black radius--full"></div>
                      Innovative Solutions with Latest Technology
                    </div>
                    <div className="w--full font--600 font--md d--flex gap--sm font--500  align-items--center">
                      <div className="w-min--4 h-min--4 h-max--4 w-max--4 bg--black radius--full"></div>
                      Intuitive platform to effortlessly
                    </div>
                  </div>
                </div>
                <div className=" border-right--black-100 d--flex flex--column gap--lg w--full p--lg">
                  <div className="w--full d--flex flex--column border-bottom--black-100 p-b--md">
                    <div className="w--full d--flex flex--column gap--lg">
                      <div className="p--sm d--flex align-items--center justify-content--center font--sm font--600 text-black radius--sm badge w-min--60 w-max--60 h-min--36">
                        Pro
                      </div>
                      <div className="d--flex gap--sm align-items--center">
                        <div className="font--2xl font--600">$9.99</div> /
                        <div className="font--md text--black-600">yearly</div>
                      </div>
                    </div>
                  </div>

                  <div className="d--flex  w--full font--md line-height--1-dot-5">
                    Reduce costs, grow revenue, and run your business more
                    efficiently on a fully integrated platform. Use Stripe to
                    handle all of your payments-related needs, manage revenue
                    operations, and launch (or invent) new business models.
                  </div>
                  <div className="d--flex flex--column gap--md">
                    <div className="w--full font--600 font--md d--flex gap--sm font--500 align-items--center">
                      <div className="w-min--4 h-min--4 h-max--4 w-max--4 bg--black radius--full"></div>
                      Innovative Solutions with Latest Technology
                    </div>
                    <div className="w--full font--600 font--md d--flex gap--sm font--500  align-items--center">
                      <div className="w-min--4 h-min--4 h-max--4 w-max--4 bg--black radius--full"></div>
                      Intuitive platform to effortlessly
                    </div>
                  </div>
                </div>
                <div className="  d--flex flex--column gap--lg w--full p--lg">
                  <div className="w--full d--flex flex--column border-bottom--black-100 p-b--md">
                    <div className="w--full d--flex flex--column gap--lg">
                      <div className="p--sm d--flex align-items--center justify-content--center font--sm font--600 text-black radius--sm badge w-min--120 w-max--120 h-min--36">
                        Enterprise
                      </div>
                      <div className="d--flex gap--sm align-items--center">
                        <div className="font--2xl font--600">$9.99</div> /
                        <div className="font--md text--black-600">yearly</div>
                      </div>
                    </div>
                  </div>

                  <div className="d--flex w--full font--md line-height--1-dot-5">
                    Reduce costs, grow revenue, and run your business more
                    efficiently on a fully integrated platform. Use Stripe to
                    handle all of your payments-related needs, manage revenue
                    operations, and launch (or invent) new business models.
                  </div>
                  <div className="d--flex flex--column gap--md">
                    <div className="w--full font--600 font--md d--flex gap--sm font--500 align-items--center">
                      <div className="w-min--4 h-min--4 h-max--4 w-max--4 bg--black radius--full"></div>
                      Innovative Solutions with Latest Technology
                    </div>
                    <div className="w--full font--600 font--md d--flex gap--sm font--500  align-items--center">
                      <div className="w-min--4 h-min--4 h-max--4 w-max--4 bg--black radius--full"></div>
                      Intuitive platform to effortlessly
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="includeSection bg--white p-t--6xl p-b--6xl">
          <div className="container">
            <div className="d--flex flex--column gap--xl">
              <div className="d--flex flex--column gap--lg justify-content--center align-items--center text--black w-max--700 m-x--auto">
                <div className="font--3xl d--flex gap--xs">
                  What’s <div className="font--700">Included</div>
                </div>
                <div className="font--lg text--c line-height--1-dot-5">
                  <i>
                    Utilize our all-in-one, intuitive platform to effortlessly
                    manage all your interactions with agents, agencies, and
                    FMOs, streamlining communication and collaboration in one
                    powerful solution
                  </i>
                </div>
              </div>
              <div className="bg--white box--shadow p--sm radius--md border-full--black-50">
                <div className="w--full d--grid grid--3 grid--3--xs bg--black-25 radius--md">
                  <div className="border-right--black-100 border-bottom--black-100 d--flex flex--column gap--lg w--full p--lg">
                    <div className="font--xl font--600 line-height--1-dot-5 text--black">
                      Innovative Solutions with <br /> Latest Technology
                    </div>
                    <div className="d--flex flex--column gap--md">
                      <div className="w--full font--400 font--md d--flex gap--sm align-items--center">
                        <div className="w-min--4 h-min--4 h-max--4 w-max--4 bg--black radius--full"></div>
                        Embrace cutting-edge technology
                      </div>
                      <div className="w--full font--400 font--md d--flex gap--sm   align-items--center">
                        <div className="w-min--4 h-min--4 h-max--4 w-max--4 bg--black radius--full"></div>
                        Intuitive platform to effortlessly
                      </div>
                      <div className="w--full font--400 font--md d--flex gap--sm   align-items--center">
                        <div className="w-min--4 h-min--4 h-max--4 w-max--4 bg--black radius--full"></div>
                        Interactions with agents, agencies,
                      </div>
                      <div className="w--full font--400 font--md d--flex gap--sm   align-items--center">
                        <div className="w-min--4 h-min--4 h-max--4 w-max--4 bg--black radius--full"></div>
                        Collaboration in one powerful solution
                      </div>
                    </div>
                  </div>
                  <div className="border-right--black-100 border-bottom--black-100 d--flex flex--column gap--lg w--full p--lg">
                    <div className="font--xl font--600 line-height--1-dot-5 text--black">
                      Innovative Solutions with <br /> Latest Technology
                    </div>
                    <div className="d--flex flex--column gap--md">
                      <div className="w--full font--400 font--md d--flex gap--sm align-items--center">
                        <div className="w-min--4 h-min--4 h-max--4 w-max--4 bg--black radius--full"></div>
                        Embrace cutting-edge technology
                      </div>
                      <div className="w--full font--400 font--md d--flex gap--sm   align-items--center">
                        <div className="w-min--4 h-min--4 h-max--4 w-max--4 bg--black radius--full"></div>
                        Intuitive platform to effortlessly
                      </div>
                      <div className="w--full font--400 font--md d--flex gap--sm   align-items--center">
                        <div className="w-min--4 h-min--4 h-max--4 w-max--4 bg--black radius--full"></div>
                        Interactions with agents, agencies,
                      </div>
                      <div className="w--full font--400 font--md d--flex gap--sm   align-items--center">
                        <div className="w-min--4 h-min--4 h-max--4 w-max--4 bg--black radius--full"></div>
                        Collaboration in one powerful solution
                      </div>
                    </div>
                  </div>
                  <div className=" border-bottom--black-100 d--flex flex--column gap--lg w--full p--lg">
                    <div className="font--xl font--600 line-height--1-dot-5 text--black">
                      Innovative Solutions with <br /> Latest Technology
                    </div>
                    <div className="d--flex flex--column gap--md">
                      <div className="w--full font--400 font--md d--flex gap--sm align-items--center">
                        <div className="w-min--4 h-min--4 h-max--4 w-max--4 bg--black radius--full"></div>
                        Embrace cutting-edge technology
                      </div>
                      <div className="w--full font--400 font--md d--flex gap--sm   align-items--center">
                        <div className="w-min--4 h-min--4 h-max--4 w-max--4 bg--black radius--full"></div>
                        Intuitive platform to effortlessly
                      </div>
                      <div className="w--full font--400 font--md d--flex gap--sm   align-items--center">
                        <div className="w-min--4 h-min--4 h-max--4 w-max--4 bg--black radius--full"></div>
                        Interactions with agents, agencies,
                      </div>
                      <div className="w--full font--400 font--md d--flex gap--sm   align-items--center">
                        <div className="w-min--4 h-min--4 h-max--4 w-max--4 bg--black radius--full"></div>
                        Collaboration in one powerful solution
                      </div>
                    </div>
                  </div>
                  <div className="border-right--black-100  d--flex flex--column gap--lg w--full p--lg">
                    <div className="font--xl font--600 line-height--1-dot-5 text--black">
                      Innovative Solutions with <br /> Latest Technology
                    </div>
                    <div className="d--flex flex--column gap--md">
                      <div className="w--full font--400 font--md d--flex gap--sm align-items--center">
                        <div className="w-min--4 h-min--4 h-max--4 w-max--4 bg--black radius--full"></div>
                        Embrace cutting-edge technology
                      </div>
                      <div className="w--full font--400 font--md d--flex gap--sm   align-items--center">
                        <div className="w-min--4 h-min--4 h-max--4 w-max--4 bg--black radius--full"></div>
                        Intuitive platform to effortlessly
                      </div>
                      <div className="w--full font--400 font--md d--flex gap--sm   align-items--center">
                        <div className="w-min--4 h-min--4 h-max--4 w-max--4 bg--black radius--full"></div>
                        Interactions with agents, agencies,
                      </div>
                      <div className="w--full font--400 font--md d--flex gap--sm   align-items--center">
                        <div className="w-min--4 h-min--4 h-max--4 w-max--4 bg--black radius--full"></div>
                        Collaboration in one powerful solution
                      </div>
                    </div>
                  </div>
                  <div className="border-right--black-100  d--flex flex--column gap--lg w--full p--lg">
                    <div className="font--xl font--600 line-height--1-dot-5 text--black">
                      Innovative Solutions with <br /> Latest Technology
                    </div>
                    <div className="d--flex flex--column gap--md">
                      <div className="w--full font--400 font--md d--flex gap--sm align-items--center">
                        <div className="w-min--4 h-min--4 h-max--4 w-max--4 bg--black radius--full"></div>
                        Embrace cutting-edge technology
                      </div>
                      <div className="w--full font--400 font--md d--flex gap--sm   align-items--center">
                        <div className="w-min--4 h-min--4 h-max--4 w-max--4 bg--black radius--full"></div>
                        Intuitive platform to effortlessly
                      </div>
                      <div className="w--full font--400 font--md d--flex gap--sm   align-items--center">
                        <div className="w-min--4 h-min--4 h-max--4 w-max--4 bg--black radius--full"></div>
                        Interactions with agents, agencies,
                      </div>
                      <div className="w--full font--400 font--md d--flex gap--sm   align-items--center">
                        <div className="w-min--4 h-min--4 h-max--4 w-max--4 bg--black radius--full"></div>
                        Collaboration in one powerful solution
                      </div>
                    </div>
                  </div>
                  <div className=" d--flex flex--column gap--lg w--full p--lg">
                    <div className="font--xl font--600 line-height--1-dot-5 text--black">
                      Innovative Solutions with <br /> Latest Technology
                    </div>
                    <div className="d--flex flex--column gap--md">
                      <div className="w--full font--400 font--md d--flex gap--sm align-items--center">
                        <div className="w-min--4 h-min--4 h-max--4 w-max--4 bg--black radius--full"></div>
                        Embrace cutting-edge technology
                      </div>
                      <div className="w--full font--400 font--md d--flex gap--sm   align-items--center">
                        <div className="w-min--4 h-min--4 h-max--4 w-max--4 bg--black radius--full"></div>
                        Intuitive platform to effortlessly
                      </div>
                      <div className="w--full font--400 font--md d--flex gap--sm   align-items--center">
                        <div className="w-min--4 h-min--4 h-max--4 w-max--4 bg--black radius--full"></div>
                        Interactions with agents, agencies,
                      </div>
                      <div className="w--full font--400 font--md d--flex gap--sm   align-items--center">
                        <div className="w-min--4 h-min--4 h-max--4 w-max--4 bg--black radius--full"></div>
                        Collaboration in one powerful solution
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="newlatterSection w--full  position--relative d--flex p-b--4xl p-t--4xl justify-content--center align-items--center ">
          <div className="w--full d--flex flex--column gap--sm justify-content--center p-t--6xl p-b--3xl">
            <div className="d--flex flex--column gap--lg justify-content--center align-items--center text--black w-max--700 m-x--auto">
              <div className="font--5xl d--flex align-items--center gap--xs ">
                Never miss an{' '}
                <div className="font--700 font-family--Playfair">updates!</div>
              </div>
              <div className="font--md font--400 text--c line-height--1-dot-5 text--black-600">
                Subscribe us and get all the latest update about news, blog
                posts and product updates from Nextere, delivered directly to
                your inbox. We’ll rarely send more than one email a month.
              </div>
              <div className="bg--white w--full w-max--500 radius--sm d--flex align-items--center p-t--sm p-b--sm p-r--sm m-t--3xl h-min--60">
                <FormInput
                  extraClasses="border--0 font--md"
                  paddingTop="lg"
                  placeholder="Enter Your Email"
                />
                <div className="w-max--120">
                  <Button
                    variant="transparent"
                    color="black"
                    btnClasses="btn w-min--120"
                    isOutline
                    borderColor="black"
                  >
                    Subscribe
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footerSection bg--white p-t--5xl  ">
          <div className="container ">
            <div className="w--full d--flex flex--column gap--lg w-max--1150 m-x--auto">
              <div className="d--flex w--full flex--column-xs gap--xl">
                <div className="w--full d--flex flex--column gap--lg">
                  <div className="font--lg font--600 text--dark">Product</div>
                  <div className="d--flex flex--column gap--md">
                    <div className="font--sm text--dark c--pointer">
                      Nextere Core
                    </div>
                    <div className="font--sm text--dark c--pointer">
                      Nextere Voice
                    </div>
                    <div className="font--sm text--dark c--pointer">
                      Nextere RTS
                    </div>
                    <div className="font--sm text--dark c--pointer">
                      Nextere Commissions
                    </div>
                    <div className="font--sm text--dark c--pointer">
                      Nextere CRM
                    </div>
                  </div>
                </div>
                <div className="w--full d--flex flex--column gap--lg">
                  <div className="font--lg font--600 text--dark">Nextere</div>
                  <div className="d--flex flex--column gap--md">
                    <div className="font--sm text--dark c--pointer">Home</div>
                    <div className="font--sm text--dark c--pointer">
                      Resource
                    </div>
                    <div className="font--sm text--dark c--pointer">Blogs</div>
                  </div>
                </div>
                <div className="w--full d--flex flex--column gap--lg">
                  <div className="font--lg font--600 text--dark">Company</div>
                  <div className="d--flex flex--column gap--md">
                    <div className="font--sm text--dark c--pointer">
                      About Nextere
                    </div>
                    <div className="font--sm text--dark c--pointer">
                      Why Nextere
                    </div>
                    <div className="font--sm text--dark c--pointer">
                      Pricing
                    </div>
                    <div className="font--sm text--dark c--pointer">
                      Security & Compliance
                    </div>
                    <div className="font--sm text--dark c--pointer">
                      Customer Stories
                    </div>
                    <div className="font--sm text--dark c--pointer">
                      Careers
                    </div>
                  </div>
                </div>
                <div className="w--full d--flex flex--column gap--lg">
                  <div className="font--lg font--600 text--dark">Help</div>
                  <div className="d--flex flex--column gap--md">
                    <div className="font--sm text--dark c--pointer">
                      Support
                    </div>
                    <div className="font--sm text--dark c--pointer">
                      Terms & Policies
                    </div>
                    <div className="font--sm text--dark c--pointer">
                      Contact Us
                    </div>
                    <div className="font--sm text--dark c--pointer">&nbsp;</div>
                    <div className="font--sm font--500 text--dark c--pointer d--flex gap--xs align-items--center">
                      <CallingIcon width={18} height={18} /> +1 833 639 8373
                    </div>
                    <div className="font--sm font--500 text--dark c--pointer d--flex gap--xs align-items--center">
                      <CallingIcon width={18} height={18} />
                      +1 833 NEX TERE
                    </div>
                  </div>
                </div>
              </div>
              <div className="d--flex justify-content--between align-items--center w--full border-top--black-25 p-t--md p-b--md h--min--50 flex--column-xs gap--sm">
                <div className="d--none-xs">
                  <img
                    src={LogoX}
                    alt="logoX"
                    width={38}
                    height={38}
                    className="m-t--sm"
                  />
                </div>
                <div className="font--sm ">
                  © 2024 Nextere LLC. All Rights Reserved.
                </div>
                <div className="d--flex align-items--center gap--md">
                  <div className="w-min--32 h-min--32 w-max--32 h-max--32 d--flex align-items--center justify-content--center bg--black radius--full text--white c--pointer">
                    <LogoXIcon width={16} height={16} />
                  </div>
                  <div className="w-min--32 h-min--32 w-max--32 h-max--32 d--flex align-items--center justify-content--center bg--black radius--full text--white c--pointer">
                    <LinkedInIcon width={16} height={16} />
                  </div>
                  <div className="w-min--32 h-min--32 w-max--32 h-max--32 d--flex align-items--center justify-content--center bg--black radius--full text--white c--pointer">
                    <InstagramIcon width={16} height={16} />
                  </div>
                  <div className="w-min--32 h-min--32 w-max--32 h-max--32 d--flex align-items--center justify-content--center bg--black radius--full text--white c--pointer">
                    <FacebookIcon width={30} height={30} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlanPricing;
