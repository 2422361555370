import React, { useCallback, useState } from 'react';
import TableManager from '../../../components/TableManager';
import { getClaimList } from '../actions';
import Modal from '../../../components/Modal';
import ViewCarrierDetails from './ViewCarrierDetails';
import {
  ChatFill,
  DotVetical,
  EyeOutlineIcon,
  UploadCloudFill,
} from '../../../assets/icons/useIcons';
import AsideModal from '../../../components/AsideModal';
import ReplyClaim from './ReplyClaim';
import UploadClaim from './UploadClaim.js';
import Dropdown from '../../../components/Dropdown/index.js';
import { useChat } from '../../../hooks/useChat/index.js';
import { useNavigate } from 'react-router-dom';
import { useSocket } from '../../../hooks/useSocket/index.js';
import { convertDateFunction } from '../../../helpers/functions.js';
import { useAlert } from '../../../hooks/useAlert/index.js';
import { agencyfilterDefaultValues, statusLookup } from '../constants.js';

const AgentClaim = () => {
  const [viewModal, setViewModal] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();
  const { chats } = useChat();
  const { socket } = useSocket();
  const [rowData, setRowData] = useState(false);
  const { showAlert } = useAlert();

  function handleCreateChat(rowData) {
    if (!rowData?.agentDetail?.agentId) {
      showAlert({ type: 'danger', message: 'Agent does not exist.' });
      return;
    }
    const ifElementExist =
      chats && chats?.length > 0
        ? chats?.find((chat) => chat?.chatId === rowData._id)
        : null;

    if (ifElementExist) {
      navigate(`/chat/${ifElementExist?.chatId}`);
    } else {
      socket?.emit('create-new-chat', {
        chatId: rowData?._id,
        claimId: rowData?.claimId,
        users: [rowData?.agentDetail, rowData?.carrierDetail],
      });

      navigate(`/chat/${rowData?._id}`);
    }
  }

  const dropEl = (
    <div className="d--flex align-items--center font--sm font--600 gap--sm text--black-800">
      <div className="w-min--32 h-min--32 h-max--32 w-max--32 radius--full  text--black   d--flex align-items--center justify-content--center">
        <DotVetical fallbackWidth={18} fallbackHeight={18} />
      </div>
    </div>
  );

  const handleProfileDropClick = (clickCase = '', rowData, item) => {
    switch (clickCase) {
      case 'upload':
        setRowData(rowData);
        setViewModal(true);

        break;
      case 'reply':
        handleCreateChat(rowData);
        setRowData(rowData);
        break;

      case 'view_details':
        setOpenModal('view');
        setRowData(rowData);
        break;

      default:
        break;
    }
  };

  const columns = [
    {
      accessorKey: 'createdAt',
      header: () => '',
      columns: [
        {
          accessorKey: 'createdAt',
          header: () => 'Date',
          cell: ({ getValue }) => convertDateFunction(getValue()),
        },
        {
          accessorKey: 'claimId',
          header: () => 'Claim Id',
        },
      ],
      meta: {
        colSpan: 2,
        textAlign: 'center',
      },
    },
    {
      accessorKey: 'carrierDetail',
      header: () => <span className="font--sm font--600">Carrier</span>,
      columns: [
        {
          accessorKey: 'carrierDetail',
          header: () => ' Name',
          cell: ({ row }) => {
            let value = row?.original?.carrierDetail;
            return `${value?.firstname} ${value?.lastname}`;
          },
          meta: {
            width: '30%',
            color: '#d32f2f',
          },
        },
        {
          accessorKey: 'carrierDetail.email',
          header: () => ' Email',
          meta: {
            width: '30%',
            color: '#d32f2f',
          },
        },
      ],
      meta: {
        colSpan: 2,
        textAlign: 'center',
        color: '#d32f2f',
        // width: '20%',
      },
    },
    {
      accessorKey: 'signedDate',
      header: () => <span className="font--sm font--600">Consumer</span>,
      columns: [
        {
          accessorKey: 'memberDetail',
          header: () => ' Name',
          cell: ({ row }) => {
            let value = row?.original?.memberDetail;
            return `${value?.firstname} ${value?.lastname}`;
          },
          meta: {
            width: '30%',
            color: '#1a9112',
          },
        },
        {
          accessorKey: 'memberDetail.email',
          header: () => ' Email',
          meta: {
            width: '30%',
            color: '#1a9112',
          },
        },
        {
          accessorKey: 'memberDetail.phone',
          header: () => ' Phone',
          meta: {
            color: '#1a9112',
          },
        },
      ],
      meta: {
        colSpan: 3,
        textAlign: 'center',
        color: '#1a9112',
        // width: '20%',

        // border: "none",
      },
    },

    {
      accessorKey: 'signedDate',
      header: () => '',
      columns: [
        {
          accessorKey: 'claimStatus',
          header: () => 'Status',
          cell: ({ row }) => {
            return (
              <span
                className={statusLookup?.[row?.original?.claimStatus]?.class}
              >
                {statusLookup?.[row?.original?.claimStatus]?.text}
              </span>
            );
          },
        },

        {
          accessorKey: 'action',
          header: () => 'Action',
          cell: (props) => {
            return (
              <>
                <div className="d--inline-block">
                  <Dropdown
                    closeOnClickOutside={true}
                    dropList={{
                      component: ({ item }) => {
                        return (
                          <div
                            className="text--secondary p--sm w--full d--flex align-items--center  justify-content--start gap--sm"
                            onClick={() =>
                              handleProfileDropClick(
                                item?.value,
                                props.row?.original,
                                item,
                              )
                            }
                          >
                            {item?.icon} {item?.title}
                          </div>
                        );
                      },

                      data: [
                        {
                          title: 'Upload',
                          value: 'upload',
                          id: 1,
                          icon: <UploadCloudFill width={16} height={16} />,
                        },
                        {
                          title: 'Chat',
                          value: 'reply',
                          id: 4,
                          icon: <ChatFill width={16} height={16} />,
                        },
                        {
                          value: 'view_details',
                          title: 'View ',
                          id: 5,
                          icon: <EyeOutlineIcon width={17} height={17} />,
                        },
                      ],
                    }}
                  >
                    {dropEl}
                  </Dropdown>
                </div>
              </>
            );
          },
          meta: {
            textAlign: 'center',
            colSpan: 3,
          },
        },
      ],
      meta: {
        textAlign: 'center',
        colSpan: 3,
      },
    },
  ];

  // const columns = [
  //   {
  //     accessorKey: 'createdAt',
  //     header: () => 'Date',
  //     cell: ({ getValue }) => convertDateFunction(getValue()),
  //   },
  //   {
  //     accessorKey: 'claimId',
  //     header: () => 'Claim Id',
  //   },
  //   {
  //     accessorKey: 'carrierDetail',
  //     header: () => 'Carrier Name',
  //     cell: ({ row }) => {
  //       let value = row?.original?.carrierDetail;
  //       return `${value?.firstname} ${value?.lastname}`;
  //     },
  //   },
  //   {
  //     accessorKey: 'carrierDetail.email',
  //     header: () => 'Carrier Email',
  //   },
  //   {
  //     accessorKey: 'memberDetail',
  //     header: () => 'Consumer Name',
  //     cell: ({ row }) => {
  //       let value = row?.original?.memberDetail;
  //       return `${value?.firstname} ${value?.lastname}`;
  //     },
  //   },
  //   {
  //     accessorKey: 'memberDetail.email',
  //     header: () => 'Consumer Email',
  //   },
  //   {
  //     accessorKey: 'memberDetail.phone',
  //     header: () => 'Consumer Phone',
  //   },
  //   {
  //     accessorKey: 'claimStatus',
  //     header: () => 'Status',
  //     cell: ({ row }) => {
  //       return (
  //         <span className={statusLookup?.[row?.original?.claimStatus]?.class}>
  //           {statusLookup?.[row?.original?.claimStatus]?.text}
  //         </span>
  //       );
  //     },
  //   },
  //   // {
  //   //   accessorKey: 'carrierId',
  //   //   header: () => 'Details',
  //   //   cell: ({ row }) => {
  //   //     let value = row?.original;

  //   //     return (
  //   //       <ActionIcon
  //   //         tooltipText="View Details"
  //   //         Icon={EyeIcon}
  //   //         onClick={() => {
  //   //           setOpenModal('view');

  //   //           setRowData(value);
  //   //         }}
  //   //       />
  //   //     );
  //   //   },
  //   //   meta: {
  //   //     textAlign: 'center',
  //   //   },
  //   // },
  //   {
  //     accessorKey: 'action',
  //     header: () => 'Action',
  //     cell: (props) => {
  //       return (
  //         <>
  //           <div className="d--inline-block">
  //             <Dropdown
  //               closeOnClickOutside={true}
  //               dropList={{
  //                 component: ({ item }) => {
  //                   return (
  //                     <div
  //                       className="text--secondary p--sm w--full d--flex align-items--center  justify-content--start gap--sm"
  //                       onClick={() =>
  //                         handleProfileDropClick(
  //                           item?.value,
  //                           props.row?.original,
  //                           item,
  //                         )
  //                       }
  //                     >
  //                       {item?.icon} {item?.title}
  //                     </div>
  //                   );
  //                 },

  //                 data: [
  //                   {
  //                     title: 'Upload',
  //                     value: 'upload',
  //                     id: 1,
  //                     icon: <UploadCloudFill width={16} height={16} />,
  //                   },
  //                   {
  //                     title: 'Chat',
  //                     value: 'reply',
  //                     id: 4,
  //                     icon: <ChatFill width={16} height={16} />,
  //                   },
  //                   {
  //                     value: 'view_details',
  //                     title: 'View ',
  //                     id: 5,
  //                     icon: <EyeOutlineIcon width={17} height={17} />,
  //                   },
  //                 ],
  //               }}
  //             >
  //               {dropEl}
  //             </Dropdown>
  //           </div>
  //         </>
  //       );
  //     },
  //     meta: {
  //       textAlign: 'center',
  //       width: '8%',
  //     },
  //   },
  // ];
  const handleClose = useCallback(() => {
    setOpenModal(false);
  }, []);

  const modalLookUp = {
    view: {
      component: (
        <ViewCarrierDetails handleClose={handleClose} rowData={rowData} />
      ),
      width: 'lg',
      size: 1200,
      title: 'Claim Details',
    },
    reply: {
      component: <ReplyClaim handleClose={handleClose} rowData={rowData} />,
      width: 'lg',
      size: 1200,
      title: 'Claim Chat ',
    },
  };
  return (
    <>
      {' '}
      <div className="w--full d--flex flex--column gap--lg memberPage">
        <div className="w--full thead-doubble-tr">
          <TableManager
            {...{
              fetcherKey: 'getClaimList',
              fetcherFn: getClaimList,
              shouldFilter: false,
              name: 'Claims',
              columns,
              filterDefaultValues: agencyfilterDefaultValues,
            }}
          />
        </div>
      </div>
      {openModal ? (
        <AsideModal
          handleClose={handleClose}
          width={modalLookUp?.[openModal]?.width}
          size={modalLookUp?.[openModal]?.size}
          title={modalLookUp?.[openModal]?.title}
          footerComponent={null}
          headerComponent={null}
        >
          {modalLookUp?.[openModal]?.component}
        </AsideModal>
      ) : null}
      {viewModal ? (
        <Modal
          title={'Upload'}
          headerComponent={null}
          footerComponent={null}
          handleClose={() => setViewModal(false)}
          shouldCloseOnClickOutside={false}
          width="md"
          height="200"
          size="600"
        >
          <UploadClaim
            {...{
              handleClose: () => setViewModal(false),
              rowData,
            }}
          />
        </Modal>
      ) : null}
    </>
  );
};

export default AgentClaim;
