import React from 'react';
import CanvasJSReact from '@canvasjs/react-charts';

var CanvasJSChart = CanvasJSReact.CanvasJSChart;
const PieChart = ({
  title = 'HRA by Carriers',
  type = 'pie',
  dataPoints = [{ y: 0, label: '' }],
}) => {
  const options = {
    animationEnabled: true,
    title: {
      text: title,
      fontSize: 20,
    },
    data: [
      {
        type,
        startAngle: 75,
        toolTipContent: '<b>{label}</b>: {y}',
        showInLegend: 'true',
        legendText: '{label}',
        indexLabelFontSize: 16,
        indexLabel: '{label} - {y}',
        dataPoints,
      },
    ],
  };
  return (
    <div>
      <CanvasJSChart options={options} />
    </div>
  );
};

export default PieChart;
