import { object, string } from 'yup';
import {
  requiredFieldFunction,
  requiredNumberField,
} from '../../../../helpers/yupValidations';
import GMAIL from '../../../../assets/images/gmail.png';
import Office365 from '../../../../assets/images/office365.png';
import YahooMail from '../../../../assets/images/yahoo.png';
import Outlook from '../../../../assets/images/outlook.png';
import WebCustom from '../../../../assets/images/website-icon.png';

export const imgLookUp = {
  GMAIL: GMAIL,
  OFFICE365: Office365,
  YAHOO: YahooMail,
  OUTLOOK: Outlook,
  CUSTOM: WebCustom,
};

export const emailIntegrationArr = [
  { id: 1, img: GMAIL, label: 'Gmail', type: 'GMAIL', value: 'GMAIL' },
  {
    id: 2,
    img: Office365,
    label: 'Office 365',
    type: 'OFFICE365',
    value: 'OFFICE365',
  },
  { id: 3, img: YahooMail, label: 'Yahoo Mail', type: 'YAHOO', value: 'YAHOO' },
  { id: 4, img: Outlook, label: 'Outlook', type: 'OUTLOOK', value: 'OUTLOOK' },
  { id: 5, img: WebCustom, label: 'Custom', type: 'CUSTOM', value: 'CUSTOM' },
];

export const defaultValues = {
  GMAIL: {
    fromAddress: '',
    host: 'smtp.gmail.com',
    username: '',
    password: '',
    port: 587,
    portType: 'TLS',
  },
  OFFICE365: {
    fromAddress: '',
    host: 'smtp.office365.com',
    username: '',
    password: '',
    port: 587,
    portType: 'TLS',
  },
  YAHOO: {
    fromAddress: '',
    host: 'smtp.mail.yahoo.com',
    username: '',
    password: '',
    port: 465,
    portType: 'TLS',
  },
  OUTLOOK: {
    fromAddress: '',
    host: 'smtp-mail.outlook.com',
    username: '',
    password: '',
    port: 587,
    portType: 'TLS',
  },
  CUSTOM: {
    fromAddress: '',
    host: '',
    // host: 'smtp-mail.outlook.com',
    username: '',
    password: '',
    port: '',
    portType: 'TLS',
  },
};

export const schema = object().shape({
  fromAddress: string()
    .email('Must be a valid email')
    .required('From Address is required')
    .max(50, 'Maximum length can be 50 characters'),
  host: requiredFieldFunction('Host'),
  username: requiredFieldFunction('Username'),
  password: requiredFieldFunction('Password'),
  portType: requiredFieldFunction('Port Type'),
  port: requiredNumberField('Port'),
});
