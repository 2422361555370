import { useForm, Controller, useFieldArray } from 'react-hook-form';
import FormInput from '../../../components/FormInput';
import PhoneInput from 'react-phone-number-input';
import Button from '../../../components/Button';
import { claimSchema, claimTypeOptions, initialValues } from '../constants';
import { createClaim, getClaimDetails, updateClaim } from '../actions';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useAlert } from '../../../hooks/useAlert';
import { yupResolver } from '@hookform/resolvers/yup';
import { convertDateFunction, submitBtnTxt } from '../../../helpers/functions';
import PDF from '../../../assets/images/pdf.png';
import {
  AddItem,
  CrossRectengalIcon,
  RemoveItem,
  UploadCloud,
} from '../../../assets/icons/useIcons';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import FormSelect from '../../../components/FormSelect';
import { yearsList } from '../../Members/constants';
import MultiDropDown from '../../../components/MultiDropdown';
import { useEffect } from 'react';
import CancelButton from '../../../components/Button/CancelButton';
import SubmitButton from '../../../components/Button/SubmitButton';

const AddClaim = ({ handleClose, rowData }) => {
  let isEdit = Boolean(rowData?._id);
  let queryClient = useQueryClient();
  const { showAlert } = useAlert();
  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    setError,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(claimSchema),
    context: { isEdit },
    defaultValues: initialValues,
    mode: 'onSubmit',
  });

  const { fields, append, remove } = useFieldArray({
    control: control,
    name: 'uploadDocuments',
  });

  const { data: claimData } = useQuery({
    queryKey: ['getClaimDetails', rowData?._id],
    queryFn: () => getClaimDetails({ claimUuId: rowData?._id }),
    enabled: Boolean(rowData?._id),
    select: (data) => data?.data?.data,
  });

  const {
    agentDetail,
    memberDetail,
    enrollmentYear,
    subject,
    policyId,
    policyEffectiveDate,
    claimType,
  } = claimData?.ClaimResponseData?.[0] || {};

  const { mutate, isLoading } = useMutation({
    mutationFn: isEdit ? updateClaim : createClaim,
    mutationKey: isEdit ? 'updateClaim' : 'createClaim',
    onSuccess: ({ data }) => {
      showAlert({ type: 'success', message: data?.data?.message });
      queryClient.invalidateQueries({ queryKey: ['getClaimList'] });
      handleClose();
    },
    onError: (error) => {
      const errMsg = error?.message ?? 'unexpected error';
      showAlert({ type: 'danger', message: errMsg });
    },
  });

  const onSubmit = ({ uploadDocuments, ...rest }) => {
    let formData = new FormData();

    for (let index = 0; index < Object.keys(rest).length; index++) {
      const element = Object.keys(rest)[index];

      formData?.append(element, rest?.[element]);
    }

    for (let index = 0; index < uploadDocuments?.length; index++) {
      formData?.append('files', uploadDocuments[index]?.file);
    }

    mutate(formData);
  };

  useEffect(() => {
    reset({
      consumerFirstname: memberDetail?.firstname,
      consumerLastname: memberDetail?.lastname,
      consumerPhone: memberDetail?.phone,
      consumerEmail: memberDetail?.email,
      consumerDob: memberDetail?.dob,

      agentFirstname: agentDetail?.firstname,
      agentLastname: agentDetail?.lastname,
      agentPhone: agentDetail?.phone,
      agentEmail: agentDetail?.email,
      agentNPN: agentDetail?.agentNPN,

      subject,
      policyId,
      policyEffectiveDate,
      enrollmentYear,
      claimType: claimType?.[0]?.split(','),
    });
  }, []);

  return (
    <form
      className="w--full h--full"
      onSubmit={handleSubmit(onSubmit)}
      autoComplete="off"
    >
      <div className="overflow--auto dialogScroll d--flex flex--column gap--md p--md">
        <div className="d--flex w--full gap--md flex--column">
          {/* Member form */}
          <div className="w--full bg--contrast p--md radius--sm d--flex flex--column gap--md ">
            <div className="w--full orText border-top--black-200 m-t--lg d--flex align-items--center justify-content--center m-b--xl position--relative">
              <span className="w-min--60 font--sm p--md bg--white d--flex justify-content--center align-items--center position--absolute top:--5 text--black">
                Consumer Information
              </span>
            </div>
            <div className="d--flex gap--md">
              <div className="w--full">
                <Controller
                  name="consumerFirstname"
                  control={control}
                  render={({ field }) => (
                    <FormInput
                      {...field}
                      label=" First Name"
                      error={errors?.consumerFirstname?.message}
                      height="40"
                    />
                  )}
                />
              </div>
              <div className="w--full">
                <Controller
                  name="consumerLastname"
                  control={control}
                  render={({ field }) => (
                    <FormInput
                      {...field}
                      label="Last Name"
                      error={errors?.consumerLastname?.message}
                      height="40"
                    />
                  )}
                />
              </div>
              <div className="w--full">
                <Controller
                  name="consumerEmail"
                  control={control}
                  render={({ field }) => (
                    <FormInput
                      {...field}
                      label="Email"
                      error={errors?.consumerEmail?.message}
                      height="40"
                    />
                  )}
                />
              </div>
              <div className="w--full">
                <label
                  className={`label--control font--sm font--500 m-b--sm d--inline-block ${
                    errors?.consumerPhone?.message
                      ? 'text--danger'
                      : 'text--grey'
                  }`}
                >
                  {errors?.consumerPhone?.message ?? 'Phone'}
                  <span className=" font--sm text--danger m-l--xs ">*</span>
                </label>
                <Controller
                  name="consumerPhone"
                  control={control}
                  render={({ field }) => (
                    <PhoneInput
                      {...field}
                      control={control}
                      defaultCountry={'US'}
                      international={true}
                      addInternationalOption={false}
                      withCountryCallingCode={true}
                      countryCallingCodeEditable={false}
                      internationalIcon={() => null}
                      flagComponent={() => null}
                      countrySelectComponent={() => null}
                      value={getValues(`consumerPhone`)}
                      limitMaxLength={true}
                      onChange={(phone) =>
                        setValue(`consumerPhone`, phone, {
                          shouldValidate: true,
                        })
                      }
                      className={`form--control w--full h-min--36  radius--sm p-l--md p-r--md  ${
                        errors?.consumerPhone?.message
                          ? 'border-full--danger'
                          : 'border-full--black-200'
                      }`}
                    />
                  )}
                />
              </div>
              <div className="w--full">
                <Controller
                  name="consumerDob"
                  control={control}
                  render={({ field }) => (
                    <FormInput
                      {...field}
                      type="date"
                      label="Date of Birth"
                      error={errors?.consumerDob?.message}
                      height="40"
                    />
                  )}
                />
              </div>
            </div>
          </div>

          {/* Agent Form */}
          <div className="w--full bg--contrast p--md radius--sm d--flex flex--column gap--md">
            <div className="w--full orText border-top--black-200 m-t--lg d--flex align-items--center justify-content--center m-b--xl position--relative">
              <span className="w-min--60 font--sm p--md bg--white d--flex justify-content--center align-items--center position--absolute top:--5 text--black">
                Agent Information
              </span>
            </div>
            <div className="d--flex gap--md">
              <div className="w--full">
                <Controller
                  name="agentFirstname"
                  control={control}
                  render={({ field }) => (
                    <FormInput
                      {...field}
                      label=" First Name"
                      // placeholder="Enter first name"
                      error={errors?.agentFirstname?.message}
                      height="40"
                    />
                  )}
                />
              </div>
              <div className="w--full">
                <Controller
                  name="agentLastname"
                  control={control}
                  render={({ field }) => (
                    <FormInput
                      {...field}
                      label=" Last Name"
                      // placeholder="Enter last name"
                      error={errors?.agentLastname?.message}
                      height="40"
                    />
                  )}
                />
              </div>
              <div className="w--full">
                <Controller
                  name="agentEmail"
                  control={control}
                  render={({ field }) => (
                    <FormInput
                      {...field}
                      label="Email"
                      // placeholder="Enter email"
                      error={errors?.agentEmail?.message}
                      height="40"
                    />
                  )}
                />
              </div>
              <div className="w--full">
                <label
                  className={`label--control font--sm font--500 m-b--sm d--inline-block ${
                    errors?.agentPhone?.message ? 'text--danger' : 'text--grey'
                  }`}
                >
                  {errors?.agentPhone?.message ?? 'Phone'}
                  <span className=" font--sm text--danger m-l--xs ">*</span>
                </label>
                <Controller
                  name="agentPhone"
                  control={control}
                  render={({ field }) => (
                    <PhoneInput
                      {...field}
                      control={control}
                      defaultCountry={'US'}
                      international={true}
                      addInternationalOption={false}
                      withCountryCallingCode={true}
                      countryCallingCodeEditable={false}
                      // placeholder="Enter your phone"
                      internationalIcon={() => null}
                      flagComponent={() => null}
                      countrySelectComponent={() => null}
                      value={getValues(`agentPhone`)}
                      limitMaxLength={true}
                      onChange={(phone) =>
                        setValue(`agentPhone`, phone, { shouldValidate: true })
                      }
                      className={`form--control w--full h-min--36  radius--sm p-l--md p-r--md  ${
                        errors?.phone?.message
                          ? 'border-full--danger'
                          : 'border-full--black-200'
                      }`}
                    />
                  )}
                />
              </div>
              <div className="w--full">
                <Controller
                  name="agentNPN"
                  control={control}
                  render={({ field }) => (
                    <FormInput
                      {...field}
                      label="NPN"
                      error={errors?.agentNPN?.message}
                      height="40"
                    />
                  )}
                />
              </div>
            </div>
          </div>

          {/* Claim form */}
          <div className="w--full bg--contrast p--md radius--sm d--flex flex--column gap--md">
            <div className="w--full orText border-top--black-200 m-t--lg d--flex align-items--center justify-content--center m-b--xl position--relative">
              <span className="w-min--60 font--sm p--md bg--white d--flex justify-content--center align-items--center position--absolute top:--5 text--black">
                Claim Information
              </span>
            </div>
            <div className="d--flex gap--md">
              <div className="w--full ">
                <Controller
                  name="subject"
                  control={control}
                  render={({ field }) => (
                    <FormInput
                      {...field}
                      label="Subject"
                      error={errors?.subject?.message}
                      height="40"
                    />
                  )}
                />
              </div>
              <div className="w--full ">
                <Controller
                  name="claimType"
                  control={control}
                  render={({ field }) => (
                    <MultiDropDown
                      {...field}
                      labelKey={'label'}
                      valueKey={'value'}
                      label="Claim Type"
                      optionList={claimTypeOptions || []}
                      error={errors?.claimType?.message}
                    />
                  )}
                />
              </div>
              <div className="w--full">
                <Controller
                  name="policyId"
                  control={control}
                  render={({ field }) => (
                    <FormInput
                      {...field}
                      label=" Policy ID"
                      error={errors?.policyId?.message}
                      height="40"
                    />
                  )}
                />
              </div>
              <div className="w--full">
                <Controller
                  name="policyEffectiveDate"
                  control={control}
                  render={({ field }) => (
                    <FormInput
                      {...field}
                      type="date"
                      label="Policy Effective Date"
                      error={errors?.policyEffectiveDate?.message}
                      height="40"
                    />
                  )}
                />
              </div>
              <div className="w--full">
                <Controller
                  name="enrollmentYear"
                  control={control}
                  render={({ field }) => (
                    <FormSelect
                      {...field}
                      label="Enrollment Year"
                      error={errors?.enrollmentYear?.message}
                      options={yearsList}
                      height="40"
                    />
                  )}
                />
              </div>
            </div>
            <div className="w--full d--flex flex--column new-claim-editor">
              <label
                className={`label--control font--sm font--500 m-b--sm   ${
                  false ? 'text--danger' : 'text--grey'
                }`}
              >
                Description
              </label>
              <ReactQuill
                theme="snow"
                value={watch('description')}
                onChange={(val) => setValue('description', val)}
                modules={{
                  toolbar: [
                    [
                      // { 'header': '1'}, {'header': '2'},
                      { font: [] },
                    ],
                    [{ size: [] }],
                    ['bold', 'italic', 'underline'],
                    // ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                    [
                      { list: 'ordered' },
                      { list: 'bullet' },
                      { indent: '-1' },
                      { indent: '+1' },
                    ],
                    // ['link', 'image', 'video'],
                    // ['clean']
                  ],
                  clipboard: {
                    matchVisual: false,
                  },
                }}
                formats={[
                  // "header",
                  'font',
                  'size',
                  'bold',
                  'italic',
                  'underline',
                  'strike',
                  'blockquote',
                  'list',
                  'bullet',
                  'indent',
                  'link',
                  'image',
                  'video',
                ]}
              />
            </div>
          </div>
          {/* uplaod form */}
          <div className="w--full bg--contrast p--md radius--sm d--flex flex--column gap--md">
            <div className="w--full orText border-top--black-200 m-t--lg d--flex align-items--center justify-content--center m-b--xl position--relative">
              <span className="w-min--60 font--sm p--md bg--white d--flex justify-content--center align-items--center position--absolute top:--5 text--black">
                Upload Document
              </span>
            </div>
            <div className="d--grid grid--4 gap--md">
              {claimData?.ClaimSupportResponseData &&
              claimData?.ClaimSupportResponseData?.length > 0
                ? claimData?.ClaimSupportResponseData?.map((data) => {
                    console.log({ data });
                    return (
                      <div className="d--flex  border-full--black-200 border--dashed p--lg radius--sm flex--column align-items--center justify-content--center c--pointer w--full h--full position--relative">
                        {data?.filename && data?.filename?.length > 0
                          ? data?.filename?.map((file) => {
                              return (
                                <img
                                  src={PDF}
                                  alt="..."
                                  className="w--full w-max--100 object--contain"
                                  onClick={() =>
                                    window.open(
                                      `https://conxent-api.nextere.com/files/${file}`,
                                      '_blank',
                                    )
                                  }
                                />
                              );
                            })
                          : null}

                        <p class="m-t--sm font--sm text--grey-600">
                          {' '}
                          {convertDateFunction(
                            data?.createdAt,
                            'MMMM Do YYYY, h:mm A',
                          )}{' '}
                        </p>
                        <span className="position--absolute c--pointer top--5 right--5 text--primary">
                          <CrossRectengalIcon />
                        </span>
                      </div>
                    );
                  })
                : null}

              {fields.map((field, index) => {
                return (
                  <div className="w--full position--relative" key={field.id}>
                    {fields.length - 1 === index ? (
                      <span
                        className="position--absolute c--pointer top--5 right--5 text--primary"
                        onClick={() => {
                          append({ file: null });
                        }}
                      >
                        <AddItem />
                      </span>
                    ) : (
                      <span
                        className="position--absolute c--pointer top--5 right--5 text--primary"
                        onClick={() => remove(index)}
                      >
                        <RemoveItem />
                      </span>
                    )}

                    <label
                      className="d--flex  border-full--black-200 border--dashed p--lg radius--sm flex--column align-items--center justify-content--center c--pointer w--full h--full"
                      htmlFor={field.id}
                    >
                      <input
                        type="file"
                        // onChange={(e) => {
                        //   setValue(
                        //     `uploadDocuments.[${index}].file`,
                        //     e.target.files?.[0],
                        //     {
                        //       shouldValidate: true,
                        //     },
                        //   );
                        // }}

                        onChange={(e) => {
                          const file = e.target.files?.[0];
                          if (file?.type.includes('pdf')) {
                            if (file.size <= 1048576) {
                              // 1 MB in bytes
                              setValue(
                                `uploadDocuments.[${index}].file`,
                                file,
                                {
                                  shouldValidate: true,
                                },
                              );
                              e.target.value = null;
                            } else {
                              setError(`uploadDocuments.[${index}].file`, {
                                type: 'manual',
                                message:
                                  'File size should be less than or equal to 1 MB',
                              });
                              setValue(`uploadDocuments.[${index}].file`, null);
                            }
                          } else {
                            setError(`uploadDocuments.[${index}].file`, {
                              type: 'manual',
                              message: 'Please upload a PDF file',
                            });
                            setValue(`uploadDocuments.[${index}].file`, null);
                          }
                        }}
                        id={field.id}
                        accept=".pdf"
                        hidden
                        multiple={false}
                      />
                      <span className="text--primary">
                        <UploadCloud width={30} height={30} />
                      </span>
                      <h3 className="text--grey font--sm">
                        Upload New Document
                      </h3>
                      <p className="m-t--sm font--sm text--grey-600">
                        Click to upload{' '}
                      </p>
                      <p className="m-t--xs text--danger text--c">
                        {watch(`uploadDocuments.[${index}].file`)?.name ?? ''}
                      </p>
                      <p className="m-t--xs text--danger text--c">
                        {errors?.uploadDocuments?.at(index)?.file?.message ||
                          ''}
                      </p>
                    </label>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      <div className="d--flex justify-content--end gap--md p-l--md p-r--md p-t--md border-top--primary-100">
        <CancelButton btnClasses="w-max--150" onClick={() => handleClose()}>
          Cancel
        </CancelButton>
        <SubmitButton btnClasses=" w-max--150" disabled={isLoading}>
          {submitBtnTxt(isLoading, isEdit ? 'Update' : 'Submit')}
        </SubmitButton>
      </div>
    </form>
  );
};

export default AddClaim;
