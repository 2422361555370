import React, { useCallback, useState } from 'react';
import Button from '../../components/Button';
import TableManager from '../../components/TableManager';
import AddAgency from './AddAgency';
import AsideModal from '../../components/AsideModal';
import { deleteAgency, getAgencyList } from './actions';
import { initialEditInstance } from '../../helpers/constants';
import ActionIcon from '../../components/ActionIcon';
import useIcons from '../../assets/icons/useIcons';
import AddAgent from '../Agents/AddAgent';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAlert } from '../../hooks/useAlert';
import AgentSidebar from './AgentSidebar';

const Agency = () => {
  const queryclient = useQueryClient();
  const { showAlert } = useAlert();
  const { UserLineIcon } = useIcons();

  const [openModal, setOpenModal] = useState(false);
  const [openModal2, setOpenModal2] = useState(false);
  const [agencyId, setAgencyId] = useState(false);
  const [editAgentInstance, setEditAgentInstance] =
    useState(initialEditInstance);
  const { mutate } = useMutation({
    mutationFn: deleteAgency,
    mutationKey: 'deleteAgency',
    onSuccess: (data) => {
      queryclient.invalidateQueries({ queryKey: ['getAgencyList'] });
      showAlert({
        type: 'success',
        message: data?.data?.message ?? 'agent deleted successfully',
      });
    },
    onError: (error) => {
      const errMsg = error?.message ?? 'unexpected error';
      showAlert({ type: 'danger', message: errMsg });
    },
  });

  const handleClose = useCallback(() => {
    setOpenModal(false);
    setEditAgentInstance(initialEditInstance);
  }, []);

  const handleActionClick = (actionName, data) => {
    switch (actionName) {
      case 'add_agent':
        setOpenModal2('add');
        setAgencyId(data?.agencyUserId?._id);
        break;
      case 'edit':
        setEditAgentInstance({ isEdit: true, editData: data });
        setOpenModal(true);
        break;
      case 'delete':
        mutate({ uuid: data?.agencyUserId?._id });
        break;

      default:
        break;
    }
  };
  const columns = [
    {
      accessorKey: 'agencyName',
      header: () => ' Agency Name',
    },

    {
      accessorKey: 'agencyUserId.firstName',
      header: () => 'Principal Name',
      meta: {
        textAlign: 'left',
      },
      cell: ({ row }) => {
        const element = row?.original;
        return `${element?.agencyUserId?.firstName} ${element?.agencyUserId?.lastName}`;
      },
    },
    {
      accessorKey: 'agencyUserId.email',
      header: () => 'Principal Email',
    },
    {
      accessorKey: 'agencyUserId.phone',
      header: () => 'Phone',
      meta: {
        textAlign: 'center',
      },
    },

    {
      accessorKey: 'agencyUserId.address',
      header: () => 'Address',
      meta: {
        textAlign: 'center',
      },
    },
    {
      accessorKey: 'agentCount',
      header: () => 'Agent',
      cell: ({ row, getValue }) => {
        const element = row?.original;
        return (
          <div className="d--flex align-items--center justify-content--center w--full">
            <span
              className="d--flex align-items--center justify-content--center radius--full p--sm bg--primary-200 text--primary w-max--25 w-min--25 h-max--25 h-min--25 box--shadow c--pointer"
              onClick={() => {
                setAgencyId(element.agencyUserId?._id);
                setOpenModal2(true);
              }}
            >
              {getValue()}
            </span>
          </div>
        );
      },
      meta: {
        textAlign: 'center',
      },
    },
    {
      accessorKey: 'action',
      header: () => 'Action',
      cell: ({ row }) => {
        const rowData = row?.original;
        return (
          <div className="d--flex gap--sm justify-content--center align-items--center">
            <ActionIcon
              tooltipText="Add Agent"
              Icon={UserLineIcon}
              onClick={() => handleActionClick('add_agent', rowData)}
            />
            {/* <ActionIcon tooltipText="Edit" Icon={EditIcon} onClick={() => handleActionClick('edit', rowData)} />
            <ActionConfirmation title="Delete Agency" message="Are you sure you want to delete this agency ?" onConfirm={() => handleActionClick('delete', rowData)}>
              <ActionIcon position="left" tooltipText="Delete" Icon={TrashIcon} />
            </ActionConfirmation> */}
          </div>
        );
      },
      meta: {
        textAlign: 'center',
      },
    },
  ];

  const tableActions = [
    {
      id: 1,
      access: true,
      label: 'Add Agency',
      component: (
        <Button
          btnClasses="btn w-min--150"
          variant="primary"
          isOutline
          onClick={() => {
            setEditAgentInstance({ isEdit: false, editData: {} });
            setOpenModal(true);
          }}
          size="sm"
        >
          Add Agency
        </Button>
      ),
    },
  ];

  return (
    <>
      {' '}
      <div className="w--full ">
        <TableManager
          {...{
            fetcherKey: 'getAgencyList',
            fetcherFn: getAgencyList,
            shouldFilter: false,
            name: 'Agency',
            columns,
            // tableActions,
          }}
        />
      </div>
      {openModal ? (
        <AsideModal
          handleClose={handleClose}
          title={`${!editAgentInstance?.isEdit ? 'Add' : 'Edit'} Agency`}
          footerComponent={null}
          headerComponent={null}
        >
          <AddAgency
            handleClose={handleClose}
            editAgentInstance={editAgentInstance}
          />
        </AsideModal>
      ) : null}
      {openModal2 ? (
        <AsideModal
          handleClose={() => setOpenModal2(false)}
          title={openModal2 === 'add' && `Add Agent`}
          showHeader={openModal2 === 'add' ? true : false}
          footerComponent={null}
          headerComponent={null}
          width={openModal2 === 'add' ? 'xs' : 'sm'}
        >
          {openModal2 === 'add' ? (
            <AddAgent
              handleClose={() => setOpenModal2(false)}
              agencyId={agencyId}
            />
          ) : (
            <AgentSidebar
              handleClose={() => setOpenModal2(false)}
              agencyId={agencyId}
            />
          )}
        </AsideModal>
      ) : null}
    </>
  );
};

export default Agency;
