import React from 'react';
import PDF from '../../../assets/images/pdf.png';
import { useQuery } from '@tanstack/react-query';
import { getClaimDetails } from '../actions';
import { convertDateFunction } from '../../../helpers/functions';
import moment from 'moment';
import { EyeIcon } from '../../../assets/icons/useIcons';
const ViewCarrierDetails = ({ rowData, activeTab = null }) => {
  const { data: claimData } = useQuery({
    queryKey: ['getClaimDetails', rowData?._id],
    queryFn: () => getClaimDetails({ claimUuId: rowData?._id }),
    enabled: Boolean(rowData?._id),
    select: (data) => data?.data?.data,
  });

  const {
    agentDetail,
    memberDetail,
    enrollmentYear,
    subject,
    policyId,
    policyEffectiveDate,
    claimType,
  } = claimData?.ClaimResponseData?.[0] || {};

  return (
    <div className="d--flex flex--column w--full gap--md p--md ">
      {!activeTab || activeTab === 1 ? (
        <div
          className={`d--flex w--full gap--md ${
            activeTab ? 'flex--column' : ''
          }`}
        >
          {/* Member form */}
          <div className="w--full border-full--black-100 p--md radius--sm d--flex flex--column gap--sm ">
            <span className="w-min--60 font--sm font--600 d--flex w--full  align-items--center  text--black m-b--sm">
              Consumer Information
            </span>

            <div className="d--flex gap--md flex--column w--full">
              <div className="w--full d--flex w--full">
                <div className="font--sm font--400 text--black-600  w-min--100">
                  First Name:
                </div>
                <div className="font--sm font--400 text--black">
                  {memberDetail?.firstname}
                </div>
              </div>
              <div className="w--full d--flex">
                <div className="font--sm font--400 text--black-600  w-min--100">
                  Last Name:
                </div>
                <div className="font--sm font--400 text--black">
                  {memberDetail?.lastname}
                </div>
              </div>
              <div className="w--full d--flex ">
                <div className="font--sm font--400 text--black-600  w-min--100">
                  Email:
                </div>
                <div className="d--flex align-items--center gap--sm">
                  <div
                    className="font--sm font--400 text--black overflow--hidden w-max--250 c--pointer"
                    style={{
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {memberDetail?.email}{' '}
                  </div>
                  {/* <div
                    data-tooltip={memberDetail?.email}
                    tooltip-position={'left'}
                    className="d--flex c--pointer "
                  >
                    <EyeIcon width={15} height={15} />
                  </div> */}
                </div>
              </div>
              <div className="w--full d--flex">
                <div className="font--sm font--400 text--black-600  w-min--100">
                  Phone:
                </div>
                <div className="font--sm font--400 text--black">
                  {memberDetail?.phone}
                </div>
              </div>
              <div className="w--full d--flex w--full">
                <div className="font--sm font--400 text--black-600  w-min--100">
                  Date of Birth:
                </div>
                <div className="font--sm font--400 text--black">
                  {memberDetail?.dob}
                </div>
              </div>
            </div>
          </div>

          {/* Agent Form */}
          <div className="w--full border-full--black-100 p--md radius--sm d--flex flex--column gap--sm">
            <span className="w-min--60 font--sm font--600 d--flex w--full  align-items--center  text--black m-b--sm">
              Agent Information
            </span>

            <div className="d--flex gap--md flex--column w--full">
              <div className="w--full d--flex w--full">
                <div className="font--sm font--400 text--black-600  w-min--100">
                  First Name:
                </div>
                <div className="font--sm font--400 text--black">
                  {agentDetail?.firstname}{' '}
                </div>
              </div>
              <div className="w--full d--flex">
                <div className="font--sm font--400 text--black-600  w-min--100">
                  Last Name:
                </div>
                <div className="font--sm font--400 text--black">
                  {agentDetail?.lastname}
                </div>
              </div>
              <div className="w--full d--flex w--full">
                <div className="font--sm font--400 text--black-600  w-min--100">
                  Email:
                </div>
                <div className="d--flex align-items--center gap--sm">
                  <div
                    className="font--sm font--400 text--black overflow--hidden w-max--250 c--pointer"
                    style={{
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {agentDetail?.email}{' '}
                  </div>
                  {/* <div
                    data-tooltip={agentDetail?.email}
                    tooltip-position={'left'}
                    className="d--flex c--pointer "
                  >
                    <EyeIcon width={15} />
                  </div> */}
                </div>
              </div>
              <div className="w--full d--flex">
                <div className="font--sm font--400 text--black-600  w-min--100">
                  Phone:
                </div>
                <div className="font--sm font--400 text--black">
                  {agentDetail?.phone}
                </div>
              </div>
              <div className="w--full d--flex w--full">
                <div className="font--sm font--400 text--black-600  w-min--100">
                  NPN:
                </div>
                <div className="font--sm font--400 text--black">
                  {agentDetail?.agentNPN}
                </div>
              </div>
            </div>
          </div>

          {/* Claim form */}
          <div className="w--full border-full--black-100 p--md radius--sm d--flex flex--column gap--sm">
            <span className="w-min--60 font--sm font--600 d--flex w--full  align-items--center  text--black m-b--sm">
              Claim Information
            </span>

            <div className="d--flex gap--md flex--column w--full">
              <div className="w--full d--flex w--full">
                <div className="font--sm font--400 text--black-600  w-min--150">
                  Enrollment Year:
                </div>
                <div className="font--sm font--400 text--black ">
                  {enrollmentYear}{' '}
                </div>
              </div>
              <div className="w--full d--flex">
                <div className="font--sm font--400 text--black-600  w-min--150">
                  Policy ID:
                </div>
                <div className="font--sm font--400 text--black ">
                  {policyId}
                </div>
              </div>
              <div className="w--full d--flex w--full">
                <div className="font--sm font--400 text--black-600  w-min--150">
                  Policy Effective Date:
                </div>
                <div className="font--sm font--400 text--black ">
                  {convertDateFunction(policyEffectiveDate)}
                </div>
              </div>
              <div className="w--full d--flex w--full">
                <div className="font--sm font--400 text--black-600  w-min--150">
                  Claim Type:
                </div>
                <div className="font--sm font--400 text--primary ">
                  {claimType?.map((v) => v)}
                </div>
              </div>
              <div className="w--full d--flex">
                <div className="font--sm font--400 text--black-600  w-min--150">
                  Subject:
                </div>
                <div className="font--sm font--400 text--black ">{subject}</div>
              </div>
            </div>
          </div>
        </div>
      ) : null}

      {!activeTab || activeTab === 2 ? (
        <>
          <div className="font--md font--600 text--grey  m-t--sm">Notes</div>
          <div
            className={`d--flex w--full gap--md flex--column h-max--350 overflow--auto ${
              activeTab ? '' : 'claim-details-note'
            }`}
          >
            {claimData?.ClaimSupportResponseData &&
            claimData?.ClaimSupportResponseData?.length > 0
              ? claimData?.ClaimSupportResponseData?.map((data) => {
                  return (
                    <div className="d--flex notes-box flex--column gap--sm w-max--1200">
                      <div className="w--full border-full--black-100 bg--white p--md radius--sm d--flex justify-content--between gap--sm ">
                        <div className="d--flex  gap--sm align-items--start justify-content--start justify-content--between gap--md">
                          <div className="d--flex flex--wrap gap--sm flex--column">
                            {/* <div className="font--sm font--400 text--black line-height--1-dot-5">
                              Description Lorem Ipsum has been the industry's
                              standard dummy text ever since the 1500s, when an
                              unknown printer took a galley of type and
                              scrambled it to make a type scrambled it to make a
                              type scrambled it to make a type
                            </div> */}
                            <div
                              className="font--sm font--400 text--grey"
                              dangerouslySetInnerHTML={{
                                __html: data?.description,
                              }}
                            ></div>
                          </div>
                        </div>
                        <div className="d--flex align-items--end flex--column gap--md w--full w-max--250">
                          <div className="d--flex align-items--end  gap--sm flex--column w-min--200">
                            <div className="font--2sm font--400 text--black-600">
                              {moment(data?.createdAt).format(
                                'MMMM Do YYYY, h:mm A',
                              )}
                            </div>
                          </div>
                          <div className="d--flex flex--wrap gap--sm align-items--center justify-content--between">
                            {/* {data?.filename && data?.filename?.length > 0 && (
                              <div className="font--sm font--500 text--secondary">
                                Files
                              </div>
                            )} */}
                            <div className="d--flex flex--wrap gap--sm ">
                              {data?.filename && data?.filename?.length > 0
                                ? data?.filename?.map((file) => {
                                    return (
                                      <div className="uploadItem radius--sm bg--black-50 w-min--60 w-max--60 h-min--60 h-max--60 c--pointer d--flex align-items--center justify-content--center ">
                                        <img
                                          onClick={() =>
                                            window.open(
                                              `https://conxent-api.nextere.com/files/${file}`,
                                              '_blank',
                                            )
                                          }
                                          src={PDF}
                                          className="w-min--50 w-max--50 h-min--50 h-max--50 object--contain"
                                          alt="..."
                                        />
                                      </div>
                                    );
                                  })
                                : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              : null}
          </div>
        </>
      ) : null}
    </div>
  );
};

export default ViewCarrierDetails;
