import { createContext, useEffect, useState } from 'react';
import { useAuth } from '../../hooks/useAuth';

export const ChatContext = createContext(null);

const Chat = ({ children }) => {
  const [chats, setChats] = useState([]);
  const [messageList, setMessageList] = useState([]);
  const [typingList, setTypingList] = useState([]);
  const [permission, setPermission] = useState('default');
  const { user } = useAuth();

  useEffect(() => {
    if (permission === 'default') {
      requestNotificationPermission();
    }
  }, [permission]);

  useEffect(() => {
    if (chats && chats.length > 0) {
      initiateMessageList(chats);
      initiateTypingList(chats);
    }
  }, [chats?.length]);

  function initiateMessageList(chats) {
    let messageListPayload = [];
    if (chats && chats.length > 0) {
      chats.forEach((c) => {
        const oldMessages =
          messageList && messageList?.length > 0
            ? messageList?.filter((ch) => ch?.chatId === c?.chatId)?.[0]
                ?.messages ?? []
            : [];
        messageListPayload.push({
          chatId: c?.chatId,
          messages: oldMessages || [],
        });
      });
      setMessageList(messageListPayload);
    }
  }

  function initiateTypingList(chats) {
    let typingListPayload = [];
    if (chats && chats.length > 0) {
      chats.forEach((c) => {
        typingListPayload.push({ chatId: c?.chatId, users: {} });
      });
      setTypingList(typingListPayload);
    }
  }

  function handleGetChats(chats) {
    setChats(chats);
    // initiateMessageList(chats);
    // initiateTypingList(chats);
  }

  function handleGetChatsManual(chats) {
    setChats(chats);
  }

  function handleGetChatMessages(messages) {
    if (messages && messages.length > 0) {
      messages?.forEach((message) => handleAppendChat(message));
    }
  }

  function requestNotificationPermission() {
    if (window.Notification) {
      window.Notification.requestPermission().then((permission) => {
        setPermission(permission);
      });
    }
  }

  function handleUserTyping(payload) {
    const { chatId = '', receiverId = '', ...rest } = payload;

    const key = Object.keys(rest)?.[0];
    const value = Object.values(rest)?.[0];

    setTypingList((prevTypingList) => {
      const newTypingList =
        prevTypingList && prevTypingList?.length > 0
          ? prevTypingList.map((typing) => {
              if (typing?.chatId === chatId) {
                typing.users[key] = value;
              }

              return typing;
            })
          : [];

      return newTypingList;
    });
  }

  function showNotification(message) {
    if (permission === 'granted') {
      const notification = new Notification(`${message}`, {
        body: 'New Message',
      });

      notification.onclick = () => {
        window.focus();
      };
    }
  }

  function handleAppendChat(chatObj, socket = null) {
    setMessageList((prevMessageList) => {
      const newMessageList = prevMessageList.map((list) => {
        if (list?.chatId === chatObj.chatId) {
          if (list?.messages && list.messages.length > 0) {
            list.messages = [...list?.messages, chatObj];
          } else {
            list.messages = [chatObj];
          }
        }

        return list;
      });

      return newMessageList;
    });

    if (
      window?.location?.pathname !== `/chat/${chatObj?.chatId}` &&
      chatObj?.senderId !== user?._id
    ) {
      setChats((prevChats) => {
        prevChats &&
          prevChats.length > 0 &&
          prevChats.map((chat) => {
            if (chat?.chatId === chatObj?.chatId) {
              const unReadMessages = chat?.unread
                ? chat?.unread + 1
                : chat?.metaData?.unReads?.[user?._id]
                ? chat?.metaData?.unReads?.[user?._id] + 1
                : 1;
              chat.unread = unReadMessages;
              if (socket) {
                socket?.emit('handle-unread', {
                  chatId: chatObj.chatId,
                  unread: unReadMessages,
                  id: user?._id,
                });
              }
            }
            return chat;
          });

        return prevChats;
      });
      if (chatObj?.senderId !== user?._id) {
        showNotification(chatObj?.message);
      }
    }
  }

  function handleAppendChatList(chatObj) {
    const chat = chatObj?.chat ?? {};
    if (chat && Object.keys(chat).length > 0) {
      setChats((prevChats) => [...prevChats, chat]);
    }
  }

  function handleResetUnread(chatObj, socket) {
    if (chatObj && Object.keys(chatObj).length > 0) {
      setChats((prevChats) => {
        const newChats = prevChats?.map((chat) => {
          if (chat?.chatId === chatObj.chatId) {
            chat.unread = 0;
            if (socket) {
              socket?.emit('handle-unread', {
                chatId: chatObj.chatId,
                unread: 0,
                id: user?._id,
              });
            }
          }
          return chat;
        });
        return newChats;
      });
    }
  }

  function handleDeleteMessage(data) {
    const messageId = data?.messageId;
    const chatId = data?.chatId;
    if (!messageId || !chatId) return;
    setMessageList((prevMessageList) => {
      const messageList =
        prevMessageList.find((list) => list?.chatId === chatId)?.messages ?? [];

      if (messageList && messageList.length > 0) {
        messageList.map((message) => {
          if (message?.messageId === messageId) {
            message.isDeleted = true;
          }
          return message;
        });
      }
      return [...prevMessageList];
    });
  }

  function handleUpdateMessage(data) {
    const messageId = data?.messageId;
    const chatId = data?.chatId;
    const messageToUpdate = data?.message;
    if (!messageId || !chatId || !messageToUpdate) return;
    setMessageList((prevMessageList) => {
      const messageList =
        prevMessageList.find((list) => list?.chatId === chatId)?.messages ?? [];

      if (messageList && messageList.length > 0) {
        messageList.map((message) => {
          if (message?.messageId === messageId) {
            message.isEdited = true;
            message.message = messageToUpdate;
          }
          return message;
        });
      }
      return [...prevMessageList];
    });
  }

  return (
    <ChatContext.Provider
      value={{
        chats,
        messageList,
        typingList,
        setTypingList,
        setChats,
        handleAppendChat,
        handleGetChats,
        handleAppendChatList,
        handleGetChatMessages,
        handleGetChatsManual,
        handleResetUnread,
        handleUserTyping,
        handleDeleteMessage,
        handleUpdateMessage,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};

export default Chat;
