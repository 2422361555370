import { apiClient } from '../../api/axios';
import { API_ROUTES } from './constants';

export const emailCreateProvider = (data) => {
  return apiClient({
    method: API_ROUTES.EMAIL_CREATE_PROVIDER.METHOD,
    url: API_ROUTES.EMAIL_CREATE_PROVIDER.URL,
    data,
  });
};
export const emailSettingList = (data) => {
  return apiClient({
    method: API_ROUTES.EMAIL_SETTING_LIST.METHOD,
    url: API_ROUTES.EMAIL_SETTING_LIST.URL,
    data,
  });
};
export const emailUpdateStatus = (data) => {
  return apiClient({
    method: API_ROUTES.EMAIL_UPDATE_STATUS.METHOD,
    url: API_ROUTES.EMAIL_UPDATE_STATUS.URL,
    data,
  });
};
export const emailList = (data) => {
  return apiClient({
    method: API_ROUTES.EMAIL_LIST.METHOD,
    url: API_ROUTES.EMAIL_LIST.URL,
    data,
  });
};
export const webhookInbound = (data) => {
  return apiClient({
    method: API_ROUTES.WEBHOOK_INBOUND.METHOD,
    url: API_ROUTES.WEBHOOK_INBOUND.URL,
    data,
  });
};
export const webhookUpdateMode = (data) => {
  return apiClient({
    method: API_ROUTES.WEBHOOK_UPDATE_MODE.METHOD,
    url: API_ROUTES.WEBHOOK_UPDATE_MODE.URL,
    data,
  });
};
export const getWebhookList = (data) => {
  return apiClient({
    method: API_ROUTES.WEBHOOK_LIST.METHOD,
    url: API_ROUTES.WEBHOOK_LIST.URL,
    data,
  });
};
export const createWebhookObj = (data) => {
  const { id, ...rest } = data || {};
  return apiClient({
    method: API_ROUTES.WEBHOOK_OBJ.METHOD,
    url: `${API_ROUTES.WEBHOOK_OBJ.URL}${id}`,
    data: rest,
  });
};
export const manageKey = (data) => {
  return apiClient({
    method: API_ROUTES.MANAGE_KEY.METHOD,
    url: API_ROUTES.MANAGE_KEY.URL,
    data,
  });
};
export const getKey = () => {
  return apiClient({
    method: API_ROUTES.GET_KEY.METHOD,
    url: API_ROUTES.GET_KEY.URL,
    // data,
  });
};
