import { apiClient } from '../../api/axios';
import { API_ROUTES } from './constants';

export const createClaim = (data) => {
  return apiClient({
    headers: {
      "Content-Type": "multipart/form-data",
    },
    method: API_ROUTES.CREATE_CLAIM.METHOD,
    url: API_ROUTES.CREATE_CLAIM.URL,
    data,
  });
};
export const updateClaim = (data) => {
  return apiClient({
    headers: {
      "Content-Type": "multipart/form-data",
    },
    method: API_ROUTES.UPDATE_CLAIM.METHOD,
    url: API_ROUTES.UPDATE_CLAIM.URL,
    data,
  });
};
export const getClaimList = (data) => {
  return apiClient({
    method: API_ROUTES.CREATE_LIST.METHOD,
    url: API_ROUTES.CREATE_LIST.URL,
    data,
  });
};
export const getClaimDetails = (data) => {
  return apiClient({
    method: API_ROUTES.CLAIM_DETAILS.METHOD,
    url: API_ROUTES.CLAIM_DETAILS.URL,
    data,
  });
};

