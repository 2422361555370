import React, { useEffect, useState } from 'react';
import FormInput from '../../../components/FormInput';
import FormSelect from '../../../components/FormSelect';
import { genderArr, marStatusArr, relationArr } from '../constants';
import { useMutation } from '@tanstack/react-query';
import moment from 'moment';
import Button from '../../../components/Button';
import { submitBtnTxt } from '../../../helpers/functions';
import { submitHRAForm } from '../../Dashboard/actions';
import { useAlert } from '../../../hooks/useAlert';
import Modal from '../../../components/Modal';
import SendConsentModal from './SendConsentModal';
import ActionConfirmation from '../../../components/ActionConfirmation';
import SendHRAFormModal from './SendHRAFormModal';
import HealthCheckForm from './HealthCheckForm';
import { Controller } from 'react-hook-form';
import PhoneInput from 'react-phone-number-input/react-hook-form';
import Select from 'react-select';

const HRAForm = ({
  register,
  control,
  errors,
  watch,
  setValue,
  index,
  getValues,
  refetch,
  trigger,
  consumerId,
  carrierList,
  rowData,
  isLoadingCarriers,
  relationList: relList,
}) => {
  const { showAlert } = useAlert();

  let [watchDob, watchRelation, isChild] = watch([
    `member.${index}.data.personal_demographic_info.date_of_birth`,
    `member.${index}.relation`,
    `member.${index}.isChild`,
  ]);
  let isSelf = watchRelation === 'Self';

  const [relationList, setRelationList] = useState(relationArr);
  const [openModal, setOpenModal] = useState(false);
  const [formData, setFormData] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const targetDate = moment(watchDob, 'YYYY-MM-DD');
  const yearsGap = targetDate.diff(moment(), 'years');

  const { mutate: addHRAMutate, isLoading } = useMutation({
    mutationFn: submitHRAForm,
    mutationKey: 'submitHRAForm',
    onSuccess: ({ data }) => {
      refetch();
      showAlert({
        type: 'success',
        message: data?.data?.message ?? 'HRA data saved successfully',
      });
    },
  });

  useEffect(() => {
    if (!isSelf) {
      setRelationList(relList);
    }
  }, [isSelf]);
  // useEffect(() => {
  //   if (watch(`member.${index}.relation`)) {
  //     const existingRelations = watch('member').map((item) =>
  //       item.relation?.trim(),
  //     );
  //     const filteredArr = relationArr.filter(
  //       (item) =>
  //         !existingRelations.includes(item.value) ||
  //         watch(`member.${index}.relation`) === item.value,
  //     );
  //     setRelationList(filteredArr);
  //   }
  // }, [index, watch('member')]);

  const handleClose = () => setOpenModal(false);

  const modalLookUp = {
    form: {
      component: (
        <SendHRAFormModal
          handleClose={() => setOpenModal(false)}
          {...{ formData, isChild }}
        />
      ),
      title: 'Send HRA Form',
    },
    consent: {
      component: (
        <SendConsentModal
          handleClose={() => setOpenModal(false)}
          {...{ formData }}
        />
      ),
      title: 'Send Consent',
    },
  };

  return (
    <>
      <div className="d--flex flex--column  border-full--black-100 radius--md ">
        <div className="font--md font--600 h-min--50 border-bottom--black-100 d--flex align-items--center p-l--md p-r--md">
          Personal and Demographic Information
        </div>
        <div className="w--full d--flex flex--column gap--lg p--lg h-min--100">
          <div className="d--flex w--full gap--lg">
            <div className="w--full d--flex">
              <FormInput
                label="Name"
                placeholder="Enter Name"
                {...register(
                  `member.${index}.data.personal_demographic_info.name`,
                )}
                error={errors?.personal_demographic_info?.name?.message}
              />
            </div>
            <div className="w--full d--flex">
              <FormInput
                label="Date of birth"
                placeholder="Date of birth"
                type="date"
                {...register(
                  `member.${index}.data.personal_demographic_info.date_of_birth`,
                )}
                max={new Date().toISOString().split('T')[0]}
                onChange={(e) => {
                  setValue(
                    `member.${index}.data.personal_demographic_info.date_of_birth`,
                    e?.target?.value,
                    { shouldValidate: true },
                  );
                  const targetDate = moment(e?.target?.value, 'YYYY-MM-DD');
                  const yearsGap = targetDate.diff(moment(), 'years');
                  if (!isSelf) {
                    setValue(`member.${index}.isChild`, yearsGap >= -18);
                  }
                }}
                error={
                  errors?.personal_demographic_info?.date_of_birth?.message
                }
              />
            </div>
            <div className="w--full d--flex">
              <FormSelect
                label="Gender"
                placeholder="Select Gender"
                options={genderArr}
                error={errors?.personal_demographic_info?.gender?.message}
                {...register(
                  `member.[${index}].data.personal_demographic_info.gender`,
                )}
              />
            </div>

            <div className="w--full d--flex">
              <Controller
                name={`member.${index}.data.personal_demographic_info.relation`}
                control={control}
                render={({ field }) => (
                  <FormSelect
                    {...field}
                    label="Relation"
                    placeholder="Select relationship"
                    options={relationList}
                    error={errors?.personal_demographic_info?.relation?.message}
                    onChange={(e) => {
                      if (isSelf) return;
                      field.onChange(e);
                      setValue(`member.${index}.relation`, e.target.value);

                      // const existingRelations = watch('member').map((item) =>
                      //   item.relation?.trim(),
                      // );
                      // const filteredArr = relationArr.filter(
                      //   (item) =>
                      //     !existingRelations.includes(item.value) ||
                      //     watch(`member.${index}.relation`) === item.value,
                      // );
                      // setRelationList(filteredArr);
                    }}
                    disabled={isSelf}
                    extraClasses={`${isSelf && 'c--not-allowed '}`}
                  />
                )}
              />
            </div>

            <div className="w--full d--flex">
              <FormSelect
                label=" Marital Status"
                placeholder="Select  Marital Status"
                options={marStatusArr}
                error={
                  errors?.personal_demographic_info?.marital_status?.message
                }
                {...register(
                  `member.${index}.data.personal_demographic_info.marital_status`,
                )}
              />
            </div>
          </div>

          <div className="d--flex w--full gap--lg">
            <div className="w--full d--flex">
              <FormInput
                label="Occupation"
                placeholder="Enter Occupation"
                error={errors?.personal_demographic_info?.occupation?.message}
                {...register(
                  `member.${index}.data.personal_demographic_info.occupation`,
                )}
                required={false}
              />
            </div>

            <FormInput
              label="Email"
              placeholder="Enter Email"
              {...register(
                `member.${index}.data.personal_demographic_info.email`,
              )}
              error={errors?.personal_demographic_info?.email?.message}
              disabled={isChild}
              extraClasses={`${isChild && 'c--not-allowed '}`}
            />
            <div className="w--full">
              <label
                className={`label--control font--sm font--500 m-b--sm d--inline-block ${
                  errors?.personal_demographic_info?.phone?.message
                    ? 'text--danger'
                    : 'text--grey'
                }`}
              >
                {errors?.personal_demographic_info?.phone?.message ?? 'Phone'}
                <span className=" font--sm text--danger m--xs">*</span>
              </label>
              <PhoneInput
                name={`member.${index}.data.personal_demographic_info.phone`}
                control={control}
                defaultCountry={'US'}
                international={true}
                withCountryCallingCode={true}
                placeholder="Enter your phone"
                internationalIcon={() => (
                  <span>
                    <i className="fa fa-phone icon-circle bg-danger"></i>
                  </span>
                )}
                limitMaxLength={true}
                className={`form--control w--full h-min--36  radius--sm p-l--md p-r--md  d--flex align-items--center ${
                  errors?.personal_demographic_info?.phone?.message
                    ? 'border-full--danger'
                    : 'border-full--black-200'
                } ${isChild && 'c--not-allowed'}`}
                disabled={isChild}
              />
            </div>
            <div className="w--full">
              <label
                htmlFor=""
                className={`label--control font--sm font--500 m-b--sm d--flex w--full ${
                  errors?.personal_demographic_info?.carrierId?.message
                    ? 'text--danger'
                    : 'text--grey'
                }`}
              >
                {errors?.personal_demographic_info?.carrierId?.message
                  ? errors?.personal_demographic_info?.carrierId?.message
                  : 'Carriers'}
                <span className=" font--sm text--danger m-l--xs">*</span>
              </label>

              <Controller
                name={`member.${index}.data.personal_demographic_info.carrierId`}
                control={control}
                render={() => (
                  <Select
                    isClearable
                    classNamePrefix={'react-select-input'}
                    options={carrierList}
                    placeholder="Enter Carrier"
                    escapeClearsValue
                    value={
                      carrierList.find(
                        (i) =>
                          i?.value ===
                          watch(
                            `member.${index}.data.personal_demographic_info.carrierId`,
                          ),
                      ) || ''
                    }
                    onChange={(e) => {
                      setValue(
                        `member.${index}.data.personal_demographic_info.carrierName`,
                        e?.label,
                      );
                      setValue(
                        `member.${index}.data.personal_demographic_info.carrierId`,
                        e?.value,
                      );
                    }}
                    className="async-select-input"
                    isLoading={isLoadingCarriers}
                  />
                )}
                rules={{ required: true }}
              />
            </div>

            <div className="w--full"></div>
          </div>
        </div>
      </div>

      {/* for self only */}
      {isSelf && rowData?.documentStatus === 'SIGNED' ? (
        <HealthCheckForm {...{ register, errors, watch, setValue, index }} />
      ) : null}

      {isSelf ? null : yearsGap >= -18 ||
        rowData?.documentStatus === 'SIGNED' ? (
        <HealthCheckForm {...{ register, errors, watch, setValue, index }} />
      ) : null}

      {/* for self only */}
      {isSelf ? (
        <div className="d--flex justify-content--center gap--md w--full m-t--xl ">
          {rowData?.formDocumentStatus ===
          'SIGNED' ? null : rowData?.documentStatus === 'SIGNED' ? (
            <>
              <ActionConfirmation
                message="Is the HRA ready to be signed by consumer?"
                onConfirm={() => {
                  let data = getValues();
                  const { consumer_info, member } = data;
                  let hraPayload = {
                    consumerInfo: consumer_info,
                    consumerRelationship: member?.[index],
                    consumerId,
                    relationId: member?.[index]?.relationId,
                    sendHraFormToConsumer: true,
                  };

                  setOpenModal('form');
                  setFormData(hraPayload);
                  setShowModal(false);
                }}
                onCancel={() => {
                  let data = getValues();
                  const { consumer_info, member } = data;
                  let hraPayload = {
                    consumerInfo: consumer_info,
                    consumerRelationship: member?.[index],
                    consumerId,
                    relationId: member?.[index]?.relationId,
                    sendHraFormToConsumer: false,
                  };
                  addHRAMutate(hraPayload);
                  setShowModal(false);
                }}
                controlled
                showModal={showModal}
              />
              <Button
                type="button"
                variant="primary"
                color="black"
                btnClasses="btn w-max--200 w-min--200"
                onClick={async () => {
                  const isValid = await trigger([
                    'consumer_info',
                    `member.${index}.data`,
                  ]);
                  if (isValid) {
                    setShowModal(true);
                  }
                }}
              >
                {submitBtnTxt(isLoading, 'Save HRA')}
              </Button>
            </>
          ) : rowData?.documentStatus === 'NO' ? (
            <div className="d--flex flex--column gap--sm justify-content--center align-items--center text--center font--sm font--600    ">
              <div>
                Waiting for document signature. Primary policy holder signed HRA
                consent is mandatory to proceed
              </div>
              <div>
                If consent not received, Please try again{' '}
                <span
                  className="text--primary c--pointer"
                  onClick={async () => {
                    let data = getValues();
                    const { consumer_info, member } = data;
                    let hraPayload = {
                      consumerInfo: consumer_info,
                      consumerRelationship: member?.[index],
                      consumerId,
                      relationId: member?.[index]?.relationId,
                    };
                    const isValid = await trigger([
                      'consumer_info',
                      `member.${index}.data`,
                    ]);
                    if (isValid) {
                      setOpenModal('consent');
                      setFormData(hraPayload);
                    }
                  }}
                >
                  {submitBtnTxt(isLoading, ' Resend Consent')}
                </span>
              </div>
            </div>
          ) : (
            <Button
              type="button"
              variant="primary"
              color="black"
              btnClasses="btn w-max--200 w-min--200"
              disabled={isLoading}
              onClick={async () => {
                let data = getValues();
                const { consumer_info, member } = data;
                let hraPayload = {
                  consumerInfo: consumer_info,
                  consumerRelationship: member?.[index],
                  consumerId,
                  relationId: member?.[index]?.relationId,
                };
                const isValid = await trigger([
                  'consumer_info',
                  `member.${index}.data`,
                ]);
                if (isValid) {
                  setOpenModal('consent');
                  setFormData(hraPayload);
                }
              }}
            >
              {submitBtnTxt(isLoading, 'Send Consent')}
            </Button>
          )}
        </div>
      ) : null}

      {isSelf ? null : watchDob ? (
        <div className="d--flex justify-content--center gap--md w--full m-t--xl ">
          <ActionConfirmation
            message={
              <div className="w--full text--c h-min--100 d--flex align-items--center justify-content--center">
                Is the HRA ready to be signed by consumer?
              </div>
            }
            onConfirm={() => {
              let data = getValues();
              const { consumer_info, member } = data;
              let hraPayload = {
                consumerInfo: consumer_info,
                consumerRelationship: member?.[index],
                consumerId,
                relationId: member?.[index]?.relationId,
                sendHraFormToConsumer: true,
              };

              setOpenModal('form');
              setFormData(hraPayload);
              setShowModal(false);
            }}
            onCancel={() => {
              let data = getValues();
              const { consumer_info, member } = data;
              let hraPayload = {
                consumerInfo: consumer_info,
                consumerRelationship: member?.[index],
                consumerId,
                relationId: member?.[index]?.relationId,
                sendHraFormToConsumer: false,
              };
              addHRAMutate(hraPayload);
              setShowModal(false);
            }}
            controlled
            showModal={showModal}
            bodyBg={false}
          />

          {rowData?.formDocumentStatus === 'SIGNED' ? null : yearsGap >= -18 ||
            rowData?.documentStatus === 'SIGNED' ? (
            <Button
              type="button"
              variant="primary"
              color="black"
              btnClasses="btn w-max--200 w-min--200"
              onClick={async () => {
                let data = getValues();

                const { consumer_info, member } = data;
                let hraPayload = {
                  consumerInfo: consumer_info,
                  consumerRelationship: {
                    ...member?.[index],
                    isChild: yearsGap > -18,
                  },
                  consumerId,
                  relationId: member?.[index]?.relationId,
                  sendHraFormToConsumer: false,
                };
                const isValid = await trigger([
                  'consumer_info',
                  `member.${index}.data`,
                ]);

                if (isValid) {
                  // if (isChild) {
                  if (yearsGap > -18) {
                    addHRAMutate(hraPayload);
                  } else {
                    setShowModal(true);
                  }
                }
              }}
            >
              {submitBtnTxt(isLoading, 'Save HRA')}
            </Button>
          ) : rowData?.documentStatus === 'NO' ? (
            <div className="d--flex flex--column gap--sm justify-content--center align-items--center text--center font--sm font--600    ">
              <div>
                Waiting for document signature. Signed HRA consent is mandatory
                for all adults
              </div>
              <div>
                If consent not received, Please try again{' '}
                <span
                  className="text--primary c--pointer"
                  onClick={async () => {
                    let data = getValues();
                    const { consumer_info, member } = data;
                    let hraPayload = {
                      consumerInfo: consumer_info,
                      consumerRelationship: member?.[index],
                      consumerId,
                      relationId: member?.[index]?.relationId,
                    };
                    const isValid = await trigger([
                      'consumer_info',
                      `member.${index}.data`,
                    ]);
                    if (isValid) {
                      setOpenModal('consent');
                      setFormData(hraPayload);
                    }
                  }}
                >
                  {submitBtnTxt(isLoading, ' Resend Consent')}
                </span>
              </div>
            </div>
          ) : (
            <Button
              type="button"
              variant="primary"
              color="black"
              btnClasses="btn w-max--200 w-min--200"
              disabled={isLoading}
              onClick={async () => {
                let data = getValues();
                const { consumer_info, member } = data;
                let hraPayload = {
                  consumerInfo: consumer_info,
                  consumerRelationship: member?.[index],
                  consumerId,
                  relationId: member?.[index]?.relationId,
                };
                const isValid = await trigger([
                  'consumer_info',
                  `member.${index}.data`,
                ]);
                if (isValid) {
                  setOpenModal('consent');
                  setFormData(hraPayload);
                }
              }}
            >
              {submitBtnTxt(isLoading, 'Send Consent')}
            </Button>
          )}
        </div>
      ) : null}

      {rowData?.formDocumentStatus === 'SIGNED' ? (
        <div className="d--flex justify-content--end w--full align-items--center">
          <div className="d--flex justify-content--between w--full align-items--center w-max--300">
            <div className="font--sm font--600 text--black-600">Signature:</div>
            <div className="d--flex justify-content--center gap--md w--full border--dashed border-full--black-200 w-max--200 p-r--lg radius--sm h-min--60">
              <img src={rowData?.formData?.SIGNATURE} alt="sign" width={150} />
            </div>
          </div>
        </div>
      ) : null}

      {openModal ? (
        <Modal
          width="md"
          height="200"
          size="600"
          shouldCloseOnClickOutside={false}
          headerComponent={null}
          footerComponent={null}
          handleClose={handleClose}
          title={modalLookUp?.[openModal]?.title}
        >
          {modalLookUp?.[openModal]?.component}
        </Modal>
      ) : null}
    </>
  );
};

export default HRAForm;
