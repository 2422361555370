import React, { useEffect } from 'react';
import useIcons, { FolderFillIcon } from '../../../../assets/icons/useIcons';
import Button from '../../../../components/Button';
import Modal from '../../../../components/Modal';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  createDropboxFolder,
  getDropboxFolders,
  dropBoxSetting,
} from '../../actions';
import { useAlert } from '../../../../hooks/useAlert';
import { object, string } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { getObjectLength } from '../../../../helpers/functions';
import CreateFolder from '../CreateFolder';

export const schema = object({
  dropboxDefaultFolderId: string().when('dropboxAutoUpload', {
    is: (val) => val === 'ON',
    then: () => string().required('Please select default folder'),
    otherwise: () => string(),
  }),
});

const DropboxSetting = ({ setShowInfo, rowData }) => {
  const { CrossIcon, AddIcon } = useIcons();
  const [showModal, setShowModal] = React.useState(false);
  const { showAlert } = useAlert();
  const queryClient = useQueryClient();

  const {
    watch,
    setValue,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { dropboxAutoUpload: 'OFF', dropboxDefaultFolderId: '' },
    mode: 'onSubmit',
  });

  const [dropboxAutoUpload, dropboxDefaultFolderId] = watch([
    'dropboxAutoUpload',
    'dropboxDefaultFolderId',
  ]);

  const {
    data: dropboxFolders,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: 'getDropboxFolders',
    queryFn: getDropboxFolders,
    select: (data) => data?.data?.data,
  });

  const { mutate: __, isLoading: settingLoading } = useMutation({
    mutationFn: dropBoxSetting,
    mutationKey: 'dropBoxSetting',
    onSuccess: ({ data }) => {
      showAlert({
        type: 'success',
        message: data?.data?.message || 'Setting saved successfully',
      });
      setShowInfo(false);

      queryClient.invalidateQueries({
        queryKey: ['getDefaultStorage'],
        exact: false,
      });
    },
    onError: (error) => {
      const errMsg = error?.message ?? 'unexpected error';
      showAlert({ type: 'danger', message: errMsg });
    },
  });

  const onSubmit = (_) => __(_);

  useEffect(() => {
    if (getObjectLength(rowData)) {
      const { dropboxAutoUpload, dropboxDefaultFolderId } = rowData || {};
      reset({
        dropboxAutoUpload: dropboxAutoUpload || 'OFF',
        dropboxDefaultFolderId,
      });
    }
  }, [rowData]);

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="d--flex flex--column border-full--black-100 radius--sm w--full  position--relative m-t--md"
    >
      <div
        className="toggle-sidebar-icon d--flex align-items--center justify-content--center radius--full  bg--black  text--white 
      position--absolute w-max--28 w-min--28 h-max--28 h-min--28 box--shadow c--pointer  top---13 right---10 c--pointer"
        onClick={() => setShowInfo(false)}
      >
        <CrossIcon width={12} height={12} />
      </div>

      <div className="label--control font--md font--500 border-bottom--secondary-100 h-min--60 p-l--lg p-r--md d--flex align-items--center justify-content--between w--full">
        Dropbox Setting
      </div>

      <div className=" d--flex flex--wrap gap--md  p--lg">
        <div className="d--flex w--full d--flex align-items--center justify-content--between">
          <div className="d--flex w--full d--flex align-items--center gap--md">
            <div className=" ">Auto Upload</div>
            <div className="w-max--120 d--flex w--full bg--primary border-full--primary radius--md ">
              <Button
                type="button"
                btnClasses="btn btn--p-0"
                color="black"
                borderColor="primary"
                isOutline
                size="xs"
                variant={dropboxAutoUpload !== 'ON' ? 'primary' : 'white'}
                onClick={() => setValue('dropboxAutoUpload', 'ON')}
              >
                On
              </Button>
              <Button
                type="button"
                btnClasses="btn btn--p-0"
                color="black"
                borderColor="primary"
                isOutline
                size="xs"
                variant={dropboxAutoUpload === 'ON' ? 'primary' : 'white'}
                onClick={() => {
                  setValue('dropboxAutoUpload', 'OFF');
                  setValue('dropboxDefaultFolderId', '', {
                    shouldValidate: true,
                  });

                  if (!settingLoading) {
                    console.log('==');
                    __({
                      dropboxAutoUpload: 'OFF',
                      dropboxDefaultFolderId: '',
                    });
                  }
                }}
              >
                Off
              </Button>
            </div>
          </div>

          {dropboxAutoUpload === 'ON' && (
            <div>
              {' '}
              <Button
                type="button"
                btnClasses="btn w-min--150   text--black gap--xs"
                variant="transparent"
                color="black"
                borderColor="primary"
                isOutline
                data-link-hover-primary
                icon={<AddIcon width={16} height={16} />}
                onClick={() => setShowModal(true)}
              >
                Create Folder
              </Button>
            </div>
          )}
        </div>

        {dropboxAutoUpload === 'ON' && (
          <>
            <div className="w--full ">
              {errors?.dropboxDefaultFolderId?.message ? (
                <span className="text--danger">
                  {errors?.dropboxDefaultFolderId?.message}
                </span>
              ) : (
                'Select Default Folder'
              )}
            </div>

            <div className="folderGrid  w--full d--grid grid--6 gap--xs ">
              {isLoading
                ? 'Loading...'
                : dropboxFolders && dropboxFolders?.length > 0
                ? dropboxFolders?.map(({ folderId, folderName }) => {
                    return (
                      <div
                        className={`folderItem c--pointer w--full w-max--200 p--sm h-min--100 w-min--100 d--flex flex--column justify-content--center align-items--center text--warning-600 bg--black-25 radius--sm  ${
                          dropboxDefaultFolderId === folderId && 'bg--black-100'
                        } p--md  position--relative`}
                        key={folderId}
                        onClick={() => {
                          setValue('dropboxDefaultFolderId', folderId, {
                            shouldValidate: true,
                          });
                          if (!settingLoading) {
                            __({
                              dropboxAutoUpload,
                              dropboxDefaultFolderId: folderId,
                            });
                          }
                        }}
                      >
                        <div className="w-min--24 h-min--24 position--absolute left--10 top--10">
                          <input
                            type="radio"
                            checked={dropboxDefaultFolderId === folderId}
                          />
                        </div>
                        <FolderFillIcon width={80} height={80} />

                        <div className="font--sm text--black-600 font--600  text--c ellipsis w-max--150">
                          {folderName}
                        </div>
                      </div>
                    );
                  })
                : 'No folder available'}
            </div>
          </>
        )}
      </div>

      {showModal ? (
        <Modal
          title={'Create Folder'}
          headerComponent={null}
          footerComponent={null}
          handleClose={() => setShowModal(false)}
          shouldCloseOnClickOutside={false}
          width="md"
          height="200"
          size="600"
        >
          <CreateFolder
            {...{ refetch, setShowModal, mutationFn: createDropboxFolder }}
          />
        </Modal>
      ) : null}
    </form>
  );
};

export default DropboxSetting;
