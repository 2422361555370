import React, { useMemo, useState } from 'react';
import FormSelect from '../../components/FormSelect';
import Button from '../../components/Button';
import { useMutation, useQuery } from '@tanstack/react-query';
import { uploadToDrive } from '../../api';
import { useAlert } from '../../hooks/useAlert';
import useIcons from '../../assets/icons/useIcons';
import { getGoogleDriveFoldersList, googleDriveCreateFolder } from './actions';
import FormInput from '../../components/FormInput';

const UploadDrive = ({ data = null, setDrive = () => {} }) => {
  const { AddIcon, CrossIcon, CheckIcon } = useIcons();
  const [file, setFile] = useState(data?.agreementUuid || '');
  const [folderId, setFolderId] = useState('');
  const [createFolder, setCreateFolder] = useState({ show: false, value: '' });
  const { showAlert } = useAlert();

  const { isLoading: loadingMoreData, mutate } = useMutation({
    mutationFn: uploadToDrive,
    mutationKey: [`${uploadToDrive}`],
    onError: (error) => {
      const errMsg = error?.message ?? 'unexpected error';
      showAlert({ type: 'danger', message: errMsg });
      setDrive({
        isOpen: false,
        data: null,
      });
    },
    onSuccess: () => {
      showAlert({ type: 'success', message: 'Successfully uploaded.' });
      setDrive({
        isOpen: false,
        data: null,
      });
    },
  });
  const { data: googleDriveFolders, refetch } = useQuery({
    queryKey: 'getGoogleDriveFoldersList',
    queryFn: getGoogleDriveFoldersList,
    select: (data) => data?.data?.data,
  });

  const { isLoading, mutate: createFolderMutate } = useMutation({
    mutationKey: `googleDriveCreateFolder`,
    mutationFn: googleDriveCreateFolder,
    onSuccess: ({ data }) => {
      showAlert({
        type: 'success',
        message: data?.data?.message || 'Folder created successfully',
      });
      refetch();
      setCreateFolder({ show: false, value: '' });
    },
    onError: (error) => {
      const errMsg = error?.message ?? 'unexpected error';
      showAlert({ type: 'danger', message: errMsg });
    },
  });

  const selectOptions = useMemo(() => {
    const options = [];

    if (data?.submittedDate) {
      options.push({ value: data?.agreementUuid, label: 'CONSENT' });
    }

    if (data?.eligibilitySubmittedDate) {
      options.push({
        value: data?.eligibilityAgreementUuid,
        label: 'ELIGIBILITY',
      });
    }

    return options;
  }, [data?.submittedDate, data?.eligibilitySubmittedDate]);

  function handleFileUploadToDrive() {
    if (!file) return;
    if (!folderId) return;

    mutate({
      uuid: file,
      folderId,
    });
  }

  return (
    <div className="d--flex flex--column gap--md">
      <div className="d--flex flex--column ">
        <label className="label--control font--sm font--500 m-b--sm   text--grey">
          {createFolder.show ? 'Folder Name' : 'Select Folder'}
          <span class=" font--sm text--danger m-l--xs">*</span>
        </label>
        <div className="w--full gap--sm d--flex align-items--center">
          {createFolder.show ? (
            <>
              <FormInput
                // label="Folder Name"
                placeholder="Enter your folder name"
                value={createFolder.value}
                onChange={(e) =>
                  setCreateFolder({ ...createFolder, value: e.target.value })
                }
              />
              <div
                className="w-min--40 h-min--40 w-max--40 h-max--40 d--flex align-items--center justify-content--center bg--success-400 radius--sm c--pointer"
                onClick={() => {
                  if (!isLoading && createFolder.value) {
                    createFolderMutate({ folderName: createFolder.value });
                  }
                }}
              >
                <CheckIcon width={17} height={17} />
              </div>
              <div
                className="w-min--40 h-min--40 w-max--40 h-max--40 d--flex align-items--center justify-content--center bg--danger-400  radius--sm c--pointer"
                onClick={() => {
                  setCreateFolder({ show: false, value: '' });
                }}
              >
                <CrossIcon width={14} height={14} />
              </div>
            </>
          ) : (
            <>
              <FormSelect
                value={folderId}
                onChange={(e) => setFolderId(e.target.value)}
                placeholder="Select Folder"
                height="40"
                options={
                  googleDriveFolders &&
                  googleDriveFolders?.length > 0 &&
                  googleDriveFolders?.map(({ folderName, folderId }) => {
                    return {
                      label: folderName,
                      value: folderId,
                    };
                  })
                }
              />
              <div
                className="w-min--40 h-min--40 w-max--40 h-max--40 d--flex align-items--center justify-content--center bg--primary-400 radius--sm c--pointer"
                onClick={() => {
                  setCreateFolder({ show: true, value: '' });
                }}
              >
                <AddIcon width={17} height={17} />
              </div>
            </>
          )}
        </div>
      </div>

      <FormSelect
        name="uuid"
        value={file}
        onChange={(e) => setFile(e.target.value)}
        label="Select File To Upload"
        error={undefined}
        height="40"
        options={selectOptions}
      />
      <div className="d--flex justify-content--center w--full gap--sm">
        <Button
          onClick={handleFileUploadToDrive}
          type="button"
          variant="primary"
          color="black"
          isOutline
          borderColor="primary"
          btnClasses="btn w-max--150"
          size="sm"
          disabled={loadingMoreData}
        >
          {loadingMoreData ? 'Please wait...' : 'Submit'}
        </Button>
      </div>
    </div>
  );
};

export default UploadDrive;
