import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { usePsPdfKit } from "../../hooks/usePsPdfKit";

const CreateTemplate = () => {
  const { loadPDFKit } = usePsPdfKit();
  const { state } = useLocation();
  const pdfContainerRef = useRef(null);

  useEffect(() => {
    if (pdfContainerRef.current && state?.file) {
      if (state?.file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          loadPDFKit(pdfContainerRef.current, false, e.target.result);
        };
        reader.readAsArrayBuffer(state?.file);
      }
    }
  }, [state?.file]);

  return <div>{!state?.file || !state?.name ? "Please select again" : <div ref={pdfContainerRef} style={{ width: "100%", height: "100vh" }} />}</div>;
};

export default CreateTemplate;
