export const API_ROUTES = {
  DASHBOARD_SATES: {
    METHOD: 'GET',
    URL: 'dashboard/sates',
  },
  DASHBOARD_CLAIM_SATES: {
    METHOD: 'GET',
    URL: 'dashboard/claim-sates',
  },
  DASHBOARD_CONSENT_AND_ELIGIBILITY_DATA: {
    METHOD: 'POST',
    URL: 'dashboard/member-consent-and-eligibility-sates',
  },
  DASHBOARD_CONSENT_NO_ELIGIBILITY_DATA: {
    METHOD: 'POST',
    URL: 'dashboard/member-no-eligibility-sates',
  },
  HRA_POST: {
    METHOD: 'POST',
    URL: 'hra/question',
  },
  HRA_LIST: {
    METHOD: 'POST',
    URL: 'hra/question-list',
  },
  GET_HRA: {
    METHOD: 'POST',
    URL: 'consumer/get-hra-question',
  },
};
