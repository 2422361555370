import { MEMBER } from '../../helpers/constants';
import { useAuth } from '../../hooks/useAuth';
import VerifyPage from './View/VerifyPage';
import VerifyByIframe from './View/VerifyPage/VerifyByIframe';
import VerifySignup from './View/VerifySignup';

export default function Verify() {
  const { user } = useAuth();

  if (user?.role === MEMBER) {
    return <>{true ? <VerifyByIframe /> : <VerifyPage />}</>;
  } else {
    return <VerifySignup />;
  }
}
