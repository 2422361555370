import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import FormInput from '../../../../components/FormInput';
import { useMutation } from '@tanstack/react-query';
import { submitBtnTxt } from '../../../../helpers/functions';
import { emailCreateProvider } from '../../actions';
import { useAlert } from '../../../../hooks/useAlert';
import FormSelect from '../../../../components/FormSelect';
import PasswordInput from '../../../../components/PasswordInput';
import Button from '../../../../components/Button';
// import OtpVerify from './OtpVerify';

export default function IntegrationForm({
  control,
  handleSubmit,
  errors,
  serviceType,
  handleClose,
  refetch,
}) {
  const { showAlert } = useAlert();
  // const [showOtp, setShowOtp] = useState(false);
  // const [formData, setFormData] = useState({});

  const { mutate, isLoading } = useMutation({
    mutationFn: emailCreateProvider,
    mutationKey: 'emailCreateProvider',
  });

  const onSubmit = (data) => {
    mutate(
      { ...data, serviceType },
      {
        onSuccess: ({ data }) => {
          // setShowOtp(true);

          handleClose();
          showAlert({ type: 'success', message: data?.data?.message });
          refetch();
        },
        onError: (error) => {
          const errMsg = error?.message ?? 'unexpected error';
          showAlert({ type: 'danger', message: errMsg });
        },
      },
    );

    // setFormData({ ...data, serviceType });
  };

  return (
    <div className="w--full">
      {/* {showOtp ? (
        <OtpVerify
          {...{ setShowOtp, formData, mutate, isLoading, refetch, handleClose }}
        />
      ) : ( */}
      <form
        className="w--full d--flex flex--column gap--md"
        onSubmit={handleSubmit(onSubmit)}
        autoComplete="off"
      >
        <div className=" d--flex flex--column gap--md ">
          <div className="w--full">
            <Controller
              name="fromAddress"
              control={control}
              render={({ field }) => (
                <FormInput
                  {...field}
                  label="From Address"
                  error={errors?.fromAddress?.message}
                />
              )}
            />
          </div>

          <div className="w--full">
            <Controller
              name="username"
              control={control}
              render={({ field }) => (
                <FormInput
                  {...field}
                  label="Username"
                  error={errors?.username?.message}
                />
              )}
            />
          </div>
          <div className="w--full">
            <Controller
              name="password"
              control={control}
              render={({ field }) => (
                <PasswordInput
                  paddingRight="2xl"
                  {...field}
                  label="Password"
                  error={errors?.password?.message}
                />
              )}
            />
          </div>
          <div className="w--full">
            <Controller
              name="host"
              control={control}
              render={({ field }) => (
                <FormInput
                  {...field}
                  label="Host"
                  error={errors?.host?.message}
                  disabled={serviceType === 'CUSTOM' ? false : true}
                />
              )}
            />
          </div>
          <div className="d--flex gap--md">
            <div className="w--full">
              <Controller
                name="port"
                control={control}
                render={({ field }) => (
                  <FormInput
                    {...field}
                    type="number"
                    label="Port Number"
                    error={errors?.port?.message}
                  />
                )}
              />
            </div>
            <div className="w--full">
              <Controller
                name="portType"
                control={control}
                render={({ field }) => (
                  <FormSelect
                    {...field}
                    label="Port Type"
                    error={errors?.portType?.message}
                    options={[
                      { value: 'TLS', label: 'TLS' },
                      { value: 'SSL', label: 'SSL' },
                    ]}
                  />
                )}
              />
            </div>
          </div>
        </div>
        <div className="w--full d--flex align-items--center justify-content--center m-t--md">
          <Button
            btnClasses="btn  w-max--150 text--black"
            variant="primary"
            color="black"
            type="submit"
            disabled={isLoading}
          >
            {submitBtnTxt(isLoading)}
          </Button>
        </div>
      </form>
      {/* )} */}
    </div>
  );
}
