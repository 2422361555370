import { useMutation } from '@tanstack/react-query';
import { setDefaultStorage } from './actions';

export const API_ROUTES = {
  SET_DEFAULT_STORAGE: {
    METHOD: 'POST',
    URL: 'storage/set-default-storage',
  },
  GET_DEFAULT_STORAGE: {
    METHOD: 'GET',
    URL: 'storage/get-default-storage',
  },
  //Google
  GOOGLE_DRIVE_LISTING: {
    METHOD: 'GET',
    URL: 'storage/google-drive-listing',
  },
  GOOGLE_DRIVE_CONFIGURE: {
    METHOD: 'POST',
    URL: 'storage/google-drive/configure',
  },
  GOOGLE_DRIVE_GET_ACCESS_TOKEN: {
    METHOD: 'POST',
    URL: 'storage/google-drive/get-access-token',
  },
  GOOGLE_DRIVE_SETTING: {
    METHOD: 'POST',
    URL: 'storage/google-drive/settings',
  },
  //Dropbox
  DROPBOX_CONFIGURE: {
    METHOD: 'POST',
    URL: 'storage/dropbox/configure',
  },

  DROPBOX_GET_ACCESS_TOKEN: {
    METHOD: 'POST',
    URL: 'storage/dropbox/get-access-token',
  },
  DROPBOX_SETTING: {
    METHOD: 'POST',
    URL: 'storage/dropbox/settings',
  },
  DROPBOX_CREATE_FOLDER: {
    METHOD: 'POST',
    URL: 'storage/dropbox/create-folder',
  },
  DROPBOX_FOLDERS_LIST: {
    METHOD: 'GET',
    URL: 'storage/dropbox/folders',
  },
  DROPBOX_DELETE_FOLDER: {
    METHOD: 'DELETE',
    URL: 'storage/dropbox/delete-folder',
  },
  DROPBOX_FILES_LIST: {
    METHOD: 'POST',
    URL: 'storage/dropbox/file-listing',
  },
  DROPBOX_GET_LINK: {
    METHOD: 'POST',
    URL: 'storage/dropbox/get-link',
  },
  DROPBOX_BULK_UPLOAD: {
    METHOD: 'POST',
    URL: 'storage/dropbox/bulk-upload',
  },
};

export const useSetDefaultStorageMutate = () => {
  const { mutate: setDefaultStorageMutate } = useMutation({
    mutationFn: setDefaultStorage,
    mutationKey: 'setDefaultStorage',
  });

  return { setDefaultStorageMutate };
};
