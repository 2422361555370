import * as yup from 'yup';

export const requiredFieldFunction = (fieldName) =>
  yup
    .string()
    .required(`${fieldName}  is required`)
    .typeError(`${fieldName}  is required`);

export const requiredFileFunction = (fieldName) =>
  yup.mixed().required(`${fieldName}  is required`);

export const requiredFieldText = (fieldName) =>
  `${fieldName} field is required`;

export const requiredNumberField = (fieldName) =>
  yup
    .number()
    .required(`${fieldName} field is required`)
    .min(1, 'Must be greater than or equal to 1')
    .typeError(`${fieldName} field is required`);

export const name = yup
  .string()
  .required('Name is required')
  .matches(/^[A-Za-z'-]+[ A-Za-z'-]*$/, 'Name required alphabets');

export const firstName = yup
  .string()
  .required('First name is required')
  .matches(/^[A-Za-z'-]+[ A-Za-z'-]*$/, 'First name required alphabets');
// .matches(/^[A-Za-z'-]+[ A-Za-z'-]*$/, 'First name cannot contain numbers or special characters');

export const lastName = yup
  .string()
  .required('Last name is required')
  .matches(
    /^[A-Za-z'-]+[ A-Za-z'-]*$/,
    'Last name cannot contain numbers or special characters',
  );

export const email = yup
  .string()
  .email('Must be a valid email')
  .required('Email is required')
  .max(50, 'Maximum length can be 50 characters');

export const phone = yup
  .string()
  .nullable()
  .matches(
    /^[+]?(\d{1,2})?[\s.-]?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
    'Enter a valid phone number',
  )
  .typeError('Enter a valid phone number');

export const passwordValidation = yup
  .string()
  .required('Password is required')
  .matches(
    /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@#$%^&+=!_]).{8,}$/,
    'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character',
  );

export const confirm_password = yup.string().when('password', {
  is: (val) => (val && val.length > 0 ? true : false),
  then: () =>
    yup
      .string()
      .oneOf([yup.ref('password')], 'Password not matched')
      .required(requiredFieldText('Confirm password')),
});

export const npn = yup
  // .number()
  .string()
  .required('NPN is required')
  .typeError('NPN is required')
  // .positive('Must be a positive number')
  .test('len', 'NPN should not be less than 6 digits.', (val) => {
    const length = val ? val.toString().length : 0;
    return length >= 6 && length <= 15;
  });
// .test('len', 'Must be between 5 and 15 digits', (val) => {
//   const length = val ? val.toString().length : 0;
//   return length >= 5 && length <= 15;
// });

export const ssn = yup
  .string()
  .required('SSN is required')
  .typeError('SSN is required')
  // .positive('Must be a positive number')
  .test('len', 'Must be between 5 and 12 digits', (val) => {
    const length = val ? val.toString().length : 0;
    return length >= 5 && length <= 12;
  });

export const postalCode = yup
  .string()
  .required(requiredFieldText('Zip code'))
  .matches(
    /^[A-Z0-9]+$/,
    'Zip code can contain only capital letters and numbers',
  )
  .min(5, 'Please enter a valid Zip code')
  .max(8, 'Zip code cannot be more than 8 digits long');

export const domainValidation = yup
  .string()
  .nullable()
  .notRequired()
  .test('is-valid-domain', 'Valid domain name', (value) => {
    if (!value || value.length === 0) {
      // If the value is empty, return true (no error)
      return true;
    }
    // Check if it matches the domain regex
    return /^(?!:\/\/)([a-zA-Z0-9-_]+\.)+[a-zA-Z]{2,}$/.test(value);
  });
