import React, { useEffect, useState } from 'react';
import IntegrationForm from './IntegrationForm';
import { useForm } from 'react-hook-form';
import { useUpdateEmailStatusMutate } from '../../constants';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQuery } from '@tanstack/react-query';
import { emailList, emailSettingList } from '../../actions';
import { getObjectLength } from '../../../../helpers/functions';
import Modal from '../../../../components/Modal';
import ActionConfirmation from '../../../../components/ActionConfirmation';
import FormSelect from '../../../../components/FormSelect';
import {
  defaultValues,
  emailIntegrationArr,
  imgLookUp,
  schema,
} from './constants';
import { ClaimIcon } from '../../../../assets/icons/useIcons';
import GmailInfo from './InfoSection/GmailInfo';

let foundedEmail;
const EmailIntegration = () => {
  const { updateEmailStatusMutate } = useUpdateEmailStatusMutate();
  const [show, setShow] = useState({ type: '' });
  const [openModal, setOpenModal] = useState(false);
  const [activeEmail, setActiveEmail] = useState({ obj: {}, value: '' });
  const [showInfo, setShowInfo] = useState(false);

  const { data: emailsList = [], refetch } = useQuery({
    queryKey: 'emailList',
    queryFn: emailList,
    select: (data) => data?.data?.data,
  });

  const { data } = useQuery({
    queryKey: ['emailSettingList', show.type],
    queryFn: () => emailSettingList({ serviceType: show.type }),
    select: (data) => data?.data?.data,
    enabled: Boolean(show.type),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: defaultValues?.[show.type],
    mode: 'onSubmit',
    resolver: yupResolver(schema),
  });

  const handleactiveEmailChange = (type) => {
    // if (type === show.type) {
    //   setShow((p) => ({ ...p, type: '' }));
    // } else {
    setShow((p) => ({ ...p, type }));
    // }
  };

  const handleUpdateStatus = (type, status) => {
    updateEmailStatusMutate(
      { serviceType: type, status },
      {
        onSuccess: () => {
          refetch();
        },
      },
    );
  };

  useEffect(() => {
    if (data && getObjectLength(data)) {
      // const { fromAddress, host, port, portType, username, password } =
      //   data?.emailSettingDetail || {};
      reset(data?.emailSettingDetail);
    } else {
      reset(defaultValues?.[show.type]);
    }
  }, [data, show.type]);

  useEffect(() => {
    if (emailsList && emailsList.length) {
      setActiveEmail({
        obj: emailsList.find((_) => _?.status)?.emailSettingDetail,
        value: emailsList.find((_) => _?.status)?.serviceType,
      });
    }
  }, [emailsList]);

  return (
    <>
      <div className="d--flex flex--column border-full--black-100 radius--sm w--full">
        <div className="label--control font--md font--500   border-bottom--secondary-100 h-min--60 p-l--lg p-r--md d--flex align-items--center  p-l--md p-r--md d--flex justify-content--end justify-content--center-xs">
          {/* Email Integration */}
          <div className="d--flex gap--sm align-items--center flex--column-xs w--full-xs">
            <div className="font--sm font--500"> Default mail client:</div>
            <div className="bg--black-50 p-l--md p-r--md p-t--sm p-b--sm d--flex  align-items--center gap--md radius--sm font--sm font--400 flex--column-xs">
              {activeEmail?.obj?.fromAddress}
              <div className="d--flex  font--600 align-items--center">
                <img
                  src={imgLookUp[activeEmail.value]}
                  alt={imgLookUp[activeEmail.value]}
                  width="20"
                />

                <div className="w-min--60">
                  <FormSelect
                    paddingLeft="xs"
                    placeholder="Select"
                    extraClasses="border--0 "
                    height="28"
                    // options={emailIntegrationArr}
                    options={
                      emailsList && emailsList.length > 0
                        ? emailsList.map((_) => ({
                            value: _?.serviceType,
                            label: _?.serviceType,
                          }))
                        : []
                    }
                    value={activeEmail?.value}
                    onChange={(e) => {
                      setActiveEmail((p) => ({ ...p, value: e.target.value }));
                      handleUpdateStatus(e.target.value, true);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w--full p--lg w--full-xs">
          <p className="font--sm m-b--md text--secondary ">
            Send signature request to your recipients directly from your email
          </p>
          <div className="w--full d--flex flex--wrap gap--md  m-t--xl m-b--md w--full-xs">
            {emailIntegrationArr?.map(({ img, label, type, id }) => {
              foundedEmail = emailsList.find((emailData) => {
                const serviceType = emailData?.serviceType;
                return serviceType === type;
              });
              return (
                <div className="d--flex flex--column gap--sm w--full-xs">
                  <div
                    key={id}
                    className={`w-min--200 d--flex flex--column gap--sm  align-items--center radius--sm ${
                      show.type === type
                        ? 'border-full--primary'
                        : 'border-full--secondary-600'
                    }  border--dashed p--md c--pointer`}
                    onClick={() => {
                      setOpenModal(type);
                      handleactiveEmailChange(type);
                    }}
                  >
                    <img src={img} alt="..." className="w-max--60  w--full" />
                    <h3 className="font--400 text--secondary m-t--sm">
                      {label}
                    </h3>
                  </div>

                  {foundedEmail ? (
                    <div className="d--flex flex--column gap--sm">
                      {foundedEmail?.status === true ? (
                        <div className="d--flex gap--sm justify-content--between p-r--sm">
                          <div className=" text--success  gap--xs d--flex align-items--center c--pointer ">
                            <ClaimIcon width={16} height={16} />
                            Default
                          </div>
                          <div
                            className=" text--black  d--flex align-items--center c--pointer"
                            onClick={() => {
                              if (type === 'GMAIL') {
                                setShowInfo(true);
                              }
                            }}
                          >
                            Info
                          </div>
                        </div>
                      ) : (
                        <div className="d--flex gap--sm justify-content--between p-r--sm">
                          <ActionConfirmation
                            message={`Are you sure you want to active this Integration?`}
                            title="Email Integration"
                            onConfirm={() => handleUpdateStatus(type, true)}
                          >
                            <div className=" text--primary  d--flex align-items--center c--pointer">
                              Click to set default
                            </div>
                          </ActionConfirmation>
                          <div
                            className=" text--black  d--flex align-items--center justify-content--end c--pointer"
                            onClick={() => {
                              if (type === 'GMAIL') {
                                setShowInfo(true);
                              }
                            }}
                          >
                            Info
                          </div>
                        </div>
                      )}
                      <div className="font--2sm h-min--16">
                        {foundedEmail?.emailSettingDetail?.fromAddress}
                      </div>
                    </div>
                  ) : (
                    <div className="d--flex flex--column gap--sm">
                      {['OFFICE365', 'YAHOO', 'OUTLOOK'].includes(type) && (
                        <div className=" text--black  d--flex align-items--center justify-content--center text--danger">
                          Coming Soon
                        </div>
                      )}
                      <div className="d--flex justify-content--end p-r--sm gap--sm">
                        {['GMAIL', 'CUSTOM'].includes(type) && (
                          <div
                            className=" text--black  d--flex align-items--center justify-content--end c--pointer"
                            onClick={() => {
                              if (type === 'GMAIL') {
                                setShowInfo(true);
                              }
                            }}
                          >
                            Info
                          </div>
                        )}
                      </div>
                      <div className="font--2sm h-min--16">
                        {foundedEmail?.emailSettingDetail?.fromAddress}
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>

      {showInfo && <GmailInfo {...{ setShowInfo }} />}

      {openModal ? (
        <Modal
          handleClose={() => setOpenModal(false)}
          footerComponent={null}
          headerComponent={null}
          shouldCloseOnClickOutside={true}
          title={
            emailIntegrationArr?.find((val) => val.type === show.type)?.label
          }
          width="xl"
          height="200"
          size="500"
        >
          <IntegrationForm
            {...{
              control,
              handleSubmit,
              errors,
              serviceType: show.type,
              handleClose: () => setOpenModal(false),
              refetch,
            }}
          />
        </Modal>
      ) : null}
    </>
  );
};

export default EmailIntegration;
