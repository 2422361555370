import { useAuth } from '../../hooks/useAuth';

const AccessControl = ({ children, allowedRoles }) => {
  const { user } = useAuth();
  const userRole = user?.role || localStorage.getItem('nph-agency-user-role');

  return [userRole]?.find((role) => allowedRoles?.includes(role)) ? (
    children
  ) : (
    <></>
  );
};

export default AccessControl;
