import { useMutation } from '@tanstack/react-query';
import AgencyInfo from './Components/AgencyInfo';
import ChangePassword from './Components/ChangePassword';
import EditInfo from './Components/EditInfo';
import Kyc from './Components/Kyc';
import { emailUpdateStatus } from './actions';
import Templates from '../Templates';
import GenerateKey from '../Configuration/Components/GenerateKey';

export const activeTabLookUp = {
  personalInfo: <EditInfo />,
  agency: <AgencyInfo />,
  changePassword: <ChangePassword />,
  kyc: <Kyc />,
  // email: <EmailIntegration />,
  document: <Templates />,
  // webhook: <Webhook />,
  // inboundWebhook: <InboundWebhook />,
  // conxentkey: <Conxentkey />,
  // uplineBranding: <UplineBranding />,
  generate_key: <GenerateKey />,
};

export const API_ROUTES = {
  EMAIL_CREATE_PROVIDER: {
    METHOD: 'POST',
    URL: 'email/create-provider',
  },
  EMAIL_SETTING_LIST: {
    METHOD: 'POST',
    URL: 'email/setting-list',
  },
  EMAIL_UPDATE_STATUS: {
    METHOD: 'POST',
    URL: 'email/update-status',
  },
  EMAIL_LIST: {
    METHOD: 'GET',
    URL: 'email/list',
  },
  WEBHOOK_INBOUND: {
    METHOD: 'POST',
    URL: 'webhook/inbound',
  },
  WEBHOOK_UPDATE_MODE: {
    METHOD: 'POST',
    URL: 'webhook/update-mode',
  },
  WEBHOOK_LIST: {
    METHOD: 'GET',
    URL: 'webhook/list',
  },
  WEBHOOK_OBJ: {
    METHOD: 'POST',
    URL: 'webhook/outbound?conxentkey=',
  },
  MANAGE_KEY: {
    METHOD: 'POST',
    URL: 'manage/key',
  },
  GET_KEY: {
    METHOD: 'GET',
    URL: 'manage/get-key-list',
  },
};

export const useUpdateEmailStatusMutate = () => {
  const { mutate: updateEmailStatusMutate } = useMutation({
    mutationFn: emailUpdateStatus,
    mutationKey: 'emailUpdateStatus',
  });

  return { updateEmailStatusMutate };
};
