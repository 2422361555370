import React from 'react';

const ActionIcon = ({
  tooltipText = '',
  position = 'top',
  onClick = () => {},
  Icon = () => null,
}) => {
  return (
    <span
      className="text--surface c--pointer"
      data-tooltip={tooltipText}
      tooltip-position={position}
      onClick={onClick}
    >
      <Icon width={16} height={16} />
    </span>
  );
};

export default ActionIcon;
