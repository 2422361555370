import { apiClient } from '../../../../api/axios';
import { API_ROUTES } from './constants';

export const verifyUser = (data) => {
  return apiClient({
    method: API_ROUTES.VERIFY_USER.METHOD,
    url: API_ROUTES.VERIFY_USER.URL,
    data,
  });
};
export const verifyConsumer = (data) => {
  return apiClient({
    method: API_ROUTES.VERIFY_CONSUMER.METHOD,
    url: API_ROUTES.VERIFY_CONSUMER.URL,
    data,
  });
};
export const createUser = (data) => {
  return apiClient({
    method: API_ROUTES.USER_SIGNUP.METHOD,
    url: API_ROUTES.USER_SIGNUP.URL,
    data,
  });
};
