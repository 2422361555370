export const API_ROUTES = {
  USER_DETAILS: {
    METHOD: 'GET',
    URL: 'users/get-user-details',
  },
  COMPANY_PROFILE_UPDATE: {
    METHOD: 'POST',
    URL: 'update/company',
  },
  UPDATE_PASSWORD: {
    METHOD: 'POST',
    URL: 'update/password',
  },
  UPDATE_TENANT: {
    METHOD: 'POST',
    URL: 'update/tenant-profile',
  },
  UPDATE_AGENCY: {
    METHOD: 'POST',
    URL: 'update/agency-profile',
  },
  UPDATE_AGENT: {
    METHOD: 'POST',
    URL: 'update/agent-profile',
  },
  UPDATE_CONSUMER: {
    METHOD: 'POST',
    URL: 'update/consumer-profile',
  },
  INVITE_AGENT_LIST: {
    METHOD: 'GET',
    URL: 'invite/list',
  },
  INVITE_AGENT: {
    METHOD: 'POST',
    URL: 'invite/agent',
  },
  INVITE_AGENT_UPDATE: {
    METHOD: 'POST',
    URL: 'invite/update-agent',
  },
  INVITE_AGENT_DELETE: {
    METHOD: 'POST',
    URL: 'invite/agent-delete',
  },
  INVITE_RESEND_INVITATION: {
    METHOD: 'POST',
    URL: 'invite/resend-invitation',
  },
  INVITE_UPDATE_STATUS: {
    METHOD: 'POST',
    URL: 'invite/update-status',
  },
  GET_DOC_STATUS: {
    METHOD: 'POST',
    URL: 'agreement/get-status',
  },
  VERIFY_DOC: {
    METHOD: 'POST',
    URL: 'agreement/verified',
  },
  VERIFY_DOC_NEW: {
    METHOD: 'POST',
    URL: 'agreement/verified-new',
  },
  GET_DOCUMENT_STATUS: {
    METHOD: 'POST',
    URL: 'consent/get-document-status',
  },
  VERIFY_SIGNATURE_DOC: {
    METHOD: 'POST',
    URL: 'consent/verified-signature-doc',
  },

  //Agrement for otp

  SEND_OTP_FOR_SIGN_DOC: {
    METHOD: 'POST',
    URL: 'auth/send-otp-for-sign-doc',
  },
  VERIFY_OTP_FOR_SIGN_DOC: {
    METHOD: 'POST',
    URL: 'auth/sign-doc-otp-verify',
  },
  CREATE_CHAT: {
    METHOD: 'POST',
    URL: 'chat/create',
  },
  GET_CHAT: {
    METHOD: 'POST',
    URL: 'chat/get',
  },
  GET_CHAT_MESSAGES: {
    METHOD: 'POST',
    URL: 'chat/get-chat',
  },
  INC_UNREAD: {
    METHOD: 'POST',
    URL: 'chat/inc-unread',
  },
  UPLOAD_CLAIM: {
    METHOD: 'POST',
    URL: 'claim/upload-doc',
  },
  UPDATE_CLAIM_STATUS: {
    METHOD: 'POST',
    URL: 'claim/update-status',
  },
  GET_NOTIFICATIONS: {
    METHOD: 'GET',
    URL: 'notification/list',
  },
  UPDATE_NOTIFICATION_STATUS: {
    METHOD: 'POST',
    URL: 'notification/update-status',
  },
  UPLOAD_CHAT_FILE: {
    METHOD: 'POST',
    URL: 'claim/upload-chat-doc',
  },
  GET_CHAT_FILE: {
    METHOD: 'POST',
    URL: 'claim/chat-doc-list',
  },
  WEBHOOK: {
    METHOD: 'POST',
    URL: 'webhook/outbound?conxentkey=MzI4MDk3YzFhOWI1ZTlhMmU0ZDc5YmY5NjA0OTU4Mjc3Zjg0YmU3OTIyZGI2ZDk0MWJjNmY0MjRlOGYyNDhjYQ==',
  },
  WEBHOOK_INBOUND_TEST: {
    METHOD: 'POST',
    URL: 'webhook/inbound-test',
  },
  UPLOAD_TO_DRIVE: {
    METHOD: 'POST',
    URL: 'storage/google-drive/upload',
    // URL: 'storage/upload-google-drive',
  },

  DOCUMENT_VERIFICATION: {
    METHOD: 'POST',
    URL: 'process',
  },
  UPLOAD_MEDIA_URL: {
    METHOD: 'POST',
    URL: 'media/upload-url',
  },
  CARRIER_LIST: {
    METHOD: 'POST',
    URL: 'carrier/provider-list',
  },
};
