import React from 'react';

import AccessControl from '../Dashboard/AccessControl';
import AgentInvitation from './View/AgentInvitation';
import AgencyInvitation from './View/AgencyInvitation';

const Invitation = () => {
  return (
    <>
      <AccessControl allowedRoles={['AGENT']}>
        <AgentInvitation />
      </AccessControl>
      <AccessControl allowedRoles={['AGENCY_USER']}>
        <AgencyInvitation />
      </AccessControl>
    </>
  );
};

export default Invitation;
