import { apiClient } from '../../api/axios';
import { API_ROUTES } from './constants';

export const getDashboardSates = (data) => {
  return apiClient({
    method: API_ROUTES.DASHBOARD_SATES.METHOD,
    url: API_ROUTES.DASHBOARD_SATES.URL,
    data,
  });
};
export const getDashboardClaimSates = (data) => {
  return apiClient({
    method: API_ROUTES.DASHBOARD_CLAIM_SATES.METHOD,
    url: API_ROUTES.DASHBOARD_CLAIM_SATES.URL,
    data,
  });
};
export const getDashboarConsentAndEligibilityList = (data) => {
  return apiClient({
    method: API_ROUTES.DASHBOARD_CONSENT_AND_ELIGIBILITY_DATA.METHOD,
    url: API_ROUTES.DASHBOARD_CONSENT_AND_ELIGIBILITY_DATA.URL,
    data,
  });
};
export const getDashboarConsentNoEligibilityList = (data) => {
  return apiClient({
    method: API_ROUTES.DASHBOARD_CONSENT_NO_ELIGIBILITY_DATA.METHOD,
    url: API_ROUTES.DASHBOARD_CONSENT_NO_ELIGIBILITY_DATA.URL,
    data,
  });
};

export const submitHRAForm = (data) => {
  return apiClient({
    method: API_ROUTES.HRA_POST.METHOD,
    url: API_ROUTES.HRA_POST.URL,
    data,
  });
};
export const HRAList = (data) => {
  return apiClient({
    method: API_ROUTES.HRA_LIST.METHOD,
    url: API_ROUTES.HRA_LIST.URL,
    data,
  });
};
export const getHRA = (data) => {
  return apiClient({
    method: API_ROUTES.GET_HRA.METHOD,
    url: API_ROUTES.GET_HRA.URL,
    data,
  });
};
