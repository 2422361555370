import { combineReducers } from 'redux';
import themeSlice from './Slices/themeSlice';
import pdfSlice from './Slices/pdfSlice';

const rootReducer = combineReducers({
  theme: themeSlice,
  pdfSlice: pdfSlice,
});

export default rootReducer;
