import {
    BankVerificationIcon,
  DriverlicenseIcon,
  NationalIndentityIcon,
  PassportIcon,
} from "../../../../assets/icons/useIcons";
import DriverLicense from "./Modal/DriverLicense";
import InternationalPassport from "./Modal/InternationalPassport";
import NationalIdentify from "./Modal/NationalIdentify";
import VoterCard from "./Modal/VoterCard";


export const DocTypeArray = [
  {
    label: "National identity number",
    icon: <NationalIndentityIcon width={40} height={40} />,
    type: "national_identify",
  },
  {
    label: "Driver License",
    icon: <DriverlicenseIcon width={40} height={40} />,
    type: "driver_license",
  },
  {
    label: "International Passport",
    icon: <PassportIcon width={40} height={40} />,
    type: "internation_passport",
  },
  {
    label: "Voter Card",
    icon: <BankVerificationIcon width={40} height={40} />,

    type: "voter_card",
  },
];
