import React from 'react';

const WebhookError = ({ rowData }) => {
  return (
    <div className="overflow--auto npn-info-json p--sm bg--black radius--sm h--full w--full text--white">
      <pre>
        <code>{JSON.stringify(JSON.parse(rowData), null, 2)}</code>
      </pre>
    </div>
  );
};

export default WebhookError;
