import React from 'react';

const Tooltip = ({
  children,
  text = '',
  position = 'top',
  onClick = () => {},
  className = '',
}) => {
  return (
    <div
      data-tooltip={text}
      tooltip-position={position}
      onClick={onClick}
      className={`d--flex c--pointer text--black-600 ${className}`}
    >
      {children}
    </div>
  );
};

export default Tooltip;
