import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import FormInput from '../../../../../components/FormInput';
import PhoneInput from 'react-phone-number-input/react-hook-form';
import FormSelect from '../../../../../components/FormSelect';
import { State, City } from 'country-state-city';
import RadioBoxInput from '../../../../../components/RadioBoxInput';
import Icloud from '../../../../../assets/images/icloud.png';

const statesList = State.getStatesOfCountry('US')?.map((state) => ({
  value: state.isoCode,
  label: state.name,
}));

const AssociateAgency = ({ control, errors, watch, setValue }) => {
  const [cityList, setCityList] = useState([]);
  let [watchisAssociatedAgency, watchState] = watch([
    'isAssociatedAgency',
    'associatedState',
  ]);

  useEffect(() => {
    if (watchState) {
      const cities = City.getCitiesOfState('US', watchState).map((city) => ({
        value: city.name,
        label: city.name,
      }));
      setCityList(cities);
    } else {
      setCityList([]);
    }
  }, [watchState]);
  return (
    <>
      <div className="w--full d--flex flex--column gap--xl justify-content--between align-items--center border-full--black-100 bg--white p--lg radius--sm">
        <div className="d--flex justify-content--between w--full flex--column-xs gap--sm">
          <h3 className="m-b--0 font--sm text--l font--500 w--full">
            Are you associated with an agency for at least one Carrier?
          </h3>

          <div className=" d--flex justify-content--center align-items--center gap--md">
            <Controller
              name="isAssociatedAgency"
              control={control}
              render={({ field }) => (
                <RadioBoxInput
                  {...field}
                  htmlFor="isAssociatedAgency_n"
                  label="No"
                  value="N"
                  checked={watchisAssociatedAgency === 'N'}
                />
              )}
            />
            <Controller
              name="isAssociatedAgency"
              control={control}
              render={({ field }) => (
                <RadioBoxInput
                  {...field}
                  htmlFor="isAssociatedAgency_y"
                  label="Yes"
                  value="Y"
                  checked={watchisAssociatedAgency === 'Y'}
                />
              )}
            />
          </div>
        </div>

        {watchisAssociatedAgency === 'Y' ? (
          <>
            <div className=" w--full d--flex"></div>
            <div className="w--full d--flex gap--md flex--column-xs">
              <div className="w--full">
                <Controller
                  name="associatedAgencyName"
                  control={control}
                  render={({ field }) => (
                    <FormInput
                      {...field}
                      label="Agency Name "
                      error={errors?.associatedAgencyName?.message}
                      height="40"
                    />
                  )}
                />
              </div>
              <div className="w--full">
                <Controller
                  name="associatedAgencyEmail"
                  control={control}
                  render={({ field }) => (
                    <FormInput
                      {...field}
                      label="Agency Email "
                      error={errors?.associatedAgencyEmail?.message}
                      height="40"
                    />
                  )}
                />
              </div>
            </div>
            <div className="w--full d--flex gap--md flex--column-xs">
              <div className="w--full">
                <label
                  className={`label--control font--sm font--500 m-b--sm d--inline-block ${
                    errors?.associatedAgencyPhone?.message
                      ? 'text--danger'
                      : 'text--grey'
                  }`}
                >
                  {errors?.associatedAgencyPhone?.message ?? 'Agency Phone'}{' '}
                  <span className=" font--sm text--danger ">*</span>
                </label>
                <PhoneInput
                  name="associatedAgencyPhone"
                  control={control}
                  defaultCountry={'US'}
                  international={true}
                  withCountryCallingCode={true}
                  placeholder="Enter your phone"
                  internationalIcon={() => (
                    <span>
                      <i className="fa fa-phone icon-circle bg-danger"></i>
                    </span>
                  )}
                  limitMaxLength={true}
                  className={`form--control w--full h-min--36  radius--md p-l--md p-r--md  d--flex align-items--center ${
                    errors?.associatedAgencyPhone?.message
                      ? 'border-full--danger'
                      : 'border-full--black-200'
                  }`}
                />
              </div>
              <div className="w--full">
                <Controller
                  name="associatedAgencyNPN"
                  control={control}
                  render={({ field }) => (
                    <FormInput
                      type="number"
                      {...field}
                      label="Agency NPN"
                      error={errors?.associatedAgencyNPN?.message}
                      height="40"
                      required={false}
                    />
                  )}
                />
              </div>
            </div>
            <div className="d--flex flex--column gap--md w--full">
              <div className="font--sm text-black--600">Agency Address</div>
              <div className="w--full d--flex flex--column gap--lg">
                <div className="d--flex gap--md w--full flex--column-xs">
                  <div className="w--full">
                    <Controller
                      name="associatedAddressOne"
                      control={control}
                      render={({ field }) => (
                        <FormInput
                          {...field}
                          label="Address 1"
                          error={errors?.associatedAddressOne?.message}
                          height="40"
                        />
                      )}
                    />
                  </div>
                  <div className="w--full">
                    <Controller
                      name="associatedAddressTwo"
                      control={control}
                      render={({ field }) => (
                        <FormInput
                          {...field}
                          label="Address 2"
                          error={errors?.associatedAddressTwo?.message}
                          height="40"
                          required={false}
                        />
                      )}
                    />
                  </div>
                </div>{' '}
                <div className="w--full d--flex gap--md flex--column-xs">
                  <div className="w--full">
                    <Controller
                      name="associatedState"
                      control={control}
                      rules={{
                        onChange() {
                          setValue('associatedCity', '');
                        },
                      }}
                      render={({ field }) => (
                        <FormSelect
                          {...field}
                          label="State "
                          error={errors?.associatedState?.message}
                          height="40"
                          options={statesList}
                          placeholder="Select State"
                        />
                      )}
                    />
                  </div>

                  <div className="w--full">
                    <Controller
                      name="associatedCity"
                      control={control}
                      render={({ field }) => (
                        <FormSelect
                          {...field}
                          label="City "
                          error={errors?.associatedCity?.message}
                          height="40"
                          options={cityList}
                          placeholder="Select City"
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="d--flex gap--md w--full">
                  <div className="w--full">
                    <Controller
                      name="associatedZipCode"
                      control={control}
                      render={({ field }) => (
                        <FormInput
                          {...field}
                          label="Zip Code"
                          error={errors?.associatedZipCode?.message}
                          height="40"
                        />
                      )}
                    />
                  </div>
                  <div className="w--full"></div>

                  {/* <div className="d--flex flex--column   w--full">
                    <label className="label--control font--sm font--500 m-b--sm   text--grey d--flex align-items--center">
                      Upload Logo{' '}
                    </label>
                    <label
                      for="file-upload"
                      className="w--full border-full--black-200 radius--sm p--xs d--flex align-items--center gap--sm c--pointer"
                    >
                      <div className="w-min--32 h-min--32 w-max--32 h-max--32 radius--full bg--black-25 p--sm d--flex align-items--center justify-content--center">
                        <div className="w-min--36 h-min--36 w-max--36 h-max--36 radius--full bg--black-50 p--sm d--flex align-items--center justify-content--center">
                          <label
                            for="file-upload"
                            className="d--flex w-min--36 h-min--36 d--flex justify-content--center align-items--center c--pointer"
                          >
                            <img
                              src={Icloud}
                              alt="LogoImg"
                              className="w--full h-max--20 w-max--20"
                            />
                            <input
                              id="file-upload"
                              type="file"
                              className=""
                              hidden
                            />
                          </label>
                        </div>
                      </div>
                      <div className="font--sm text--black-600">File name</div>
                    </label>
                    <div className="font--xs text--black-600 m-t--sm ">
                      ( PNG or JPG max size of 5MB)
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </>
        ) : null}
      </div>
    </>
  );
};

export default AssociateAgency;
