import React, { useEffect, useRef, useState } from 'react'

const SelfieCaptureModal = () => {
    const [localVideoTrack, setLocalVideoTrack] = useState({})
    const videoRef = useRef()
    useEffect(()=>{
        if (videoRef.current) {
            
            navigator.mediaDevices.getUserMedia({video:true,audio:false}).then((track) => {
            videoRef.current.srcObject =  track
        
        })
    }
    },[videoRef])
  return (
    <div className='d--flex flex--column gap--md'>
    <div className='p--sm border-full--primary border--dashed h-min--475'>
        <video className='object--contain w--full  h-min--475 h-max--475' ref={videoRef} autoPlay></video>
    </div>
        <span className='c--pointer w-max--60 w-min--60 h-max--60 h-min--60 d--flex align-items--center justify-content--center bg--primary radius--xl m-x--auto'></span>
    </div>
  )
}

export default SelfieCaptureModal