import React from 'react';
import { useAuth } from '../../../../hooks/useAuth';
import TenantForm from './View/TenantForm';
import AgentForm from './View/AgentForm';
import { MEMBER } from '../../../../helpers/constants';
import MemberForm from './View/MemberForm';

export default function EditInfo() {
  const { user } = useAuth();
  const userRole = user?.role || localStorage.getItem('nph-agency-user-role');
  return (
    <div className="w--full">
      {userRole === 'CARRIER' && <TenantForm />}
      {userRole === MEMBER && <MemberForm />}
      {(userRole === 'AGENT' || userRole === 'AGENCY_USER') && <AgentForm />}
    </div>
  );
}
